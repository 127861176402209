import React from 'react'
import { AddUserForm } from '../../settings/AddUserForm'
import { Body1 } from '../../../components/Typography'
import { Role } from '../../../graphql/generated'
import { Flex } from '../../../components/Flex'
import { colors, spacing } from '../../../theme'
import { SnackAlert } from '../../../components/SnackAlert'

interface Props {
  onSuccess?: () => void
}
export function FortnoxAddAccountantForm({
  onSuccess,
}: Props): React.JSX.Element {
  const [error, setError] = React.useState('')
  const roleOptions = Object.entries(Role)
    .filter(([name, role]) => {
      // ontly keep admin and accountant roles here as they are the only ones that make sens
      return [Role.Admin, Role.Accountant].includes(role)
    })
    .map(([name, role]) => {
      return {
        value: role,
        label: name,
      }
    })
  return (
    <Flex column style={{ gap: spacing.medium }}>
      <SnackAlert open={!!error} severity='error' onClose={() => setError('')}>
        <Body1>{error}</Body1>
      </SnackAlert>
      <Flex
        column
        style={{
          gap: spacing.medium,
          borderBottom: `1px solid ${colors.gray}`,
          paddingBottom: spacing.medium,
        }}
      >
        <Body1>
          För att kunna ladda ner organisationens data från Fortnox till Carbon
          Intelligence System, krävs att någon med rätt behörigheter i Fortnox
          loggar in.
        </Body1>
        <Body1>
          Det bör vara er ekonomiadministratör som gör detta, ibland är det en
          extern part som är ekonomiadministratör. Kopiera den här texten och
          skicka, dom vet vad som ska göras.
        </Body1>
        <Body1>
          Användaren måste inneha en Bokföringslicens med tillgång till
          funktionerna för Verifikationer och Leverantörsfakturor aktiverade,
          samt att registret Leverantörer är aktiverat.
        </Body1>
        <Body1>
          Direkt efter att behörig användare loggar in, börjar vårt system
          automatiskt ladda ner och behandla era data. Så snart processen är
          klar blir resultatet tillgängligt här. Det brukar inte ta mer än några
          minuter så registrera din ekonomiadministratören (med formuläret
          nedan) och kontakta hen direkt!
        </Body1>
        <Body1>
          <span>
            Mer information och detaljerad vägledning hittar du i vår{' '}
          </span>
          <a href='https://svalna.gitlab.io/business-intelligence-frontend/permissions/'>
            dokumentation.
          </a>
        </Body1>
      </Flex>
      <AddUserForm
        setError={setError}
        roleOptions={roleOptions}
        onSuccess={onSuccess}
        defaultRole={Role.Accountant}
      />
    </Flex>
  )
}
