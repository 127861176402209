/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTimeISO: Date;
};

export type AccountInfo = {
  __typename?: 'AccountInfo';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type AddUserResponse = {
  __typename?: 'AddUserResponse';
  email?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<FieldError>>;
};

export enum Aggregation {
  BookingAccount = 'BookingAccount',
  Date = 'Date',
  OrgUnit = 'OrgUnit',
  Organisation = 'Organisation',
  PrimaryCategory = 'PrimaryCategory',
  SubCategory = 'SubCategory',
  SupplierName = 'SupplierName'
}

export type Authentication = {
  __typename?: 'Authentication';
  expiresAt: Scalars['Float'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

/** Category */
export type Category = {
  __typename?: 'Category';
  children: Array<Category>;
  color: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['Float']>;
  sni?: Maybe<Scalars['String']>;
};

/** CategoryDescriptiveStats */
export type CategoryDescriptiveStats = {
  __typename?: 'CategoryDescriptiveStats';
  bookingAccountStats: Array<DescriptiveStat>;
  categoryId?: Maybe<Scalars['Float']>;
  supplierStats: Array<DescriptiveStat>;
  totalCO2e: Scalars['Float'];
};

export type CategoryInput = {
  children?: Array<CategoryInput>;
  color: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['Float']>;
  sni?: Maybe<Scalars['String']>;
};

/** CategoryTrendEmission */
export type CategoryTrendEmission = {
  __typename?: 'CategoryTrendEmission';
  categoryColor: Scalars['String'];
  categoryId: Scalars['Float'];
  categoryName: Scalars['String'];
  emissions: Array<Emission>;
  percentage: Scalars['Float'];
};

/** CategoryTrendPercentage */
export type CategoryTrendPercentage = {
  __typename?: 'CategoryTrendPercentage';
  categoryId: Scalars['String'];
  diff: Scalars['Float'];
  percentage: Scalars['Float'];
};

/** CompareOrg */
export type CompareOrg = {
  __typename?: 'CompareOrg';
  CO2e: Scalars['Float'];
  functionKey: CompareOrgKey;
  label: Scalars['String'];
  tooltip?: Maybe<Scalars['String']>;
};

export enum CompareOrgKey {
  Reference = 'Reference',
  Self = 'Self',
  Top20 = 'Top20'
}

/** Measure */
export type CustomMeasure = {
  __typename?: 'CustomMeasure';
  categoryId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
};


/** DescriptiveStatistic */
export type DescriptiveStat = {
  __typename?: 'DescriptiveStat';
  name: Scalars['String'];
  ownerId?: Maybe<Scalars['String']>;
  totalCO2e: Scalars['Float'];
};

/** Emission */
export type Emission = {
  __typename?: 'Emission';
  aggregationKeys: Array<Scalars['String']>;
  includedBills: Array<Scalars['String']>;
  period: Scalars['String'];
  totalAmount: Scalars['Float'];
  totalCO2e: Scalars['Float'];
  totalCO2eUncertainty: Scalars['Float'];
};

export type EmissionInput = {
  aggregations: Array<Aggregation>;
  filter: FilterInput;
  orgUnitLevel?: Maybe<Scalars['Float']>;
  period: Period;
};

export type EmissionLayerInput = {
  energy: Scalars['Boolean'];
  investments: Scalars['Boolean'];
  products: Scalars['Boolean'];
  scope1: Scalars['Boolean'];
  services: Scalars['Boolean'];
  ttm: Scalars['Boolean'];
};

export type EmissionLayers = {
  __typename?: 'EmissionLayers';
  energy: Scalars['Boolean'];
  investments: Scalars['Boolean'];
  products: Scalars['Boolean'];
  scope1: Scalars['Boolean'];
  services: Scalars['Boolean'];
  ttm: Scalars['Boolean'];
};

/** EmissionMetaData */
export type EmissionMetaData = {
  __typename?: 'EmissionMetaData';
  bestCategory?: Maybe<CategoryTrendPercentage>;
  emissionPerAnnualWorkForce?: Maybe<Scalars['Float']>;
  totalEmission?: Maybe<Scalars['Float']>;
  worstCategory?: Maybe<CategoryTrendPercentage>;
  yearlyPercentage?: Maybe<Scalars['Float']>;
};

/** EmissionRange */
export type EmissionRange = {
  __typename?: 'EmissionRange';
  from?: Maybe<Scalars['DateTimeISO']>;
  to?: Maybe<Scalars['DateTimeISO']>;
};

/** EmissionMetaData */
export type EmissionTrend = {
  __typename?: 'EmissionTrend';
  emissions: Array<Emission>;
  prognosis: Scalars['Float'];
  trendOffset: Scalars['Float'];
  trendPercentage: Scalars['Float'];
  trendRate: Scalars['Float'];
};

/** feature enabling status for an organisation */
export type EnabledFeatures = {
  __typename?: 'EnabledFeatures';
  features?: Maybe<Array<Feature>>;
  id: Scalars['String'];
};

export type EnumTextTuple = {
  __typename?: 'EnumTextTuple';
  key: Scalars['String'];
  value: Scalars['String'];
};

export enum ErrorType {
  AccessDenied = 'AccessDenied',
  FailedToSendEmail = 'FailedToSendEmail',
  InvalidOrgNb = 'InvalidOrgNb',
  InvalidRequest = 'InvalidRequest',
  NoOrg = 'NoOrg',
  NoUser = 'NoUser',
  NotApproved = 'NotApproved',
  RegistrationCodeExpired = 'RegistrationCodeExpired',
  RegistrationCodeMissing = 'RegistrationCodeMissing',
  RegistrationCodeNotFound = 'RegistrationCodeNotFound',
  SsoConfig = 'SSOConfig',
  TokenExpired = 'TokenExpired',
  TooManyEmails = 'TooManyEmails',
  UserNotRegistered = 'UserNotRegistered',
  WrongOrgType = 'WrongOrgType',
  WrongPassword = 'WrongPassword',
  WrongRequest = 'WrongRequest'
}

/** EstimateMeasureEffectInput */
export type EstimateMeasureEffectInput = {
  categoryMeasures: Array<MeasureInput>;
  measures: Array<MeasureInput>;
  orgId?: Maybe<Scalars['String']>;
};

/** EstimateMeasureEffectResponse */
export type EstimateMeasureEffectResponse = {
  __typename?: 'EstimateMeasureEffectResponse';
  after: Array<MeasureEmission>;
  before: Array<MeasureEmission>;
  measureResults: Array<MeasureResult>;
};

export enum Feature {
  Goal = 'Goal',
  Transactions = 'Transactions'
}

export type FieldError = {
  __typename?: 'FieldError';
  errorType: ErrorType;
  field: Scalars['String'];
  message: Scalars['String'];
};

export type FilterInput = {
  bookingAccount?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Float']>;
  orgUnitId?: Maybe<Scalars['String']>;
  /** use data for partial years */
  partialYear?: Maybe<Scalars['Boolean']>;
  supplierName?: Maybe<Scalars['String']>;
  useAnnualWorkForce: Scalars['Boolean'];
  year?: Maybe<Scalars['String']>;
};

export type FirstLastDate = {
  __typename?: 'FirstLastDate';
  first?: Maybe<Scalars['DateTimeISO']>;
  last?: Maybe<Scalars['DateTimeISO']>;
};

export type FortnoxPermissions = {
  __typename?: 'FortnoxPermissions';
  error?: Maybe<JobError>;
};

export enum Fuel {
  Annat = 'Annat',
  Bensin = 'Bensin',
  Biodiesel = 'Biodiesel',
  Biogas = 'Biogas',
  Cng = 'Cng',
  Diesel = 'Diesel',
  E85 = 'E85',
  El = 'El',
  Etanol = 'Etanol',
  Fotogen = 'Fotogen',
  Gasol = 'Gasol',
  Gengas = 'Gengas',
  Lng = 'Lng',
  Metangas = 'Metangas',
  Metanol = 'Metanol',
  Motorgas = 'Motorgas',
  Naturgas = 'Naturgas',
  None = 'None',
  Paraffinolja = 'Paraffinolja',
  Rapsolja = 'Rapsolja',
  Rme = 'Rme',
  Saknas = 'Saknas',
  Vatgas = 'Vatgas'
}

export enum HeatSystem {
  Annat = 'Annat',
  Ej = 'Ej',
  El = 'El',
  Fjarrvarme = 'Fjarrvarme',
  Okand = 'Okand',
  Pellets = 'Pellets',
  Varmepump = 'Varmepump'
}

export type IndustrySector = {
  __typename?: 'IndustrySector';
  id: Scalars['String'];
  label: Scalars['String'];
};

export type Job = {
  __typename?: 'Job';
  error?: Maybe<JobError>;
  progress?: Maybe<Scalars['Float']>;
  status: JobStatus;
  type: JobType;
};

export type JobError = {
  __typename?: 'JobError';
  message: Scalars['String'];
  type: JobErrorType;
};

export enum JobErrorType {
  DuplicateRule = 'DUPLICATE_RULE',
  JobAlreadyRunning = 'JOB_ALREADY_RUNNING',
  Other = 'OTHER',
  Permissiondenied = 'PERMISSIONDENIED',
  RuleApplicationFailed = 'RULE_APPLICATION_FAILED',
  RuleSavingFailed = 'RULE_SAVING_FAILED',
  Tokenexpired = 'TOKENEXPIRED'
}

export enum JobStatus {
  Done = 'DONE',
  Downloading = 'DOWNLOADING',
  Error = 'ERROR',
  None = 'NONE',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Started = 'STARTED'
}

export enum JobType {
  FortnoxGet = 'FORTNOX_GET',
  FortnoxProcess = 'FORTNOX_PROCESS',
  None = 'NONE',
  RuleApply = 'RULE_APPLY',
  RuleDeleteAndApply = 'RULE_DELETE_AND_APPLY',
  RuleDeleteNoApply = 'RULE_DELETE_NO_APPLY'
}

/** MeasureEmission */
export type MeasureEmission = {
  __typename?: 'MeasureEmission';
  CO2e: Scalars['Float'];
  categoryId: Scalars['String'];
};

/** MeasureInput */
export type MeasureInput = {
  id: Scalars['String'];
  percentage: Scalars['Float'];
};

/** Measure meta data */
export type MeasureMetaData = {
  __typename?: 'MeasureMetaData';
  categoryId?: Maybe<Scalars['String']>;
  cost: Scalars['Float'];
  description: Scalars['String'];
  effect: Scalars['Float'];
  imgSrc: Scalars['String'];
  type: MeasureType;
};

/** MeasureResult */
export type MeasureResult = {
  __typename?: 'MeasureResult';
  categoryId: Scalars['String'];
  cost: Scalars['Float'];
  effect: Scalars['Float'];
  measureId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export enum MeasureType {
  Checkbox = 'Checkbox',
  Scalable = 'Scalable'
}

export type Mutation = {
  __typename?: 'Mutation';
  addOrganisation: Scalars['Boolean'];
  addRecatRule: Scalars['Boolean'];
  addSSO: Scalars['Boolean'];
  addUser: AddUserResponse;
  applyRules: Job;
  approveOrgRegistration?: Maybe<Scalars['String']>;
  changePassword: UserResponse;
  changePasswordWithToken: UserResponse;
  changeRestrictionMode: Scalars['Boolean'];
  checkPermissions: FortnoxPermissions;
  deleteErrorJob: Scalars['Boolean'];
  deleteRecatRules: Job;
  deleteUser: Scalars['Boolean'];
  finishProfileCreation: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  getAndProcessFortnoxData: Job;
  login: UserResponse;
  loginWithFortnox: UserResponse;
  logout?: Maybe<Scalars['Boolean']>;
  registerOrganisation: RegistrationResponse;
  removeOrgRegistration: Scalars['Boolean'];
  replacePremises: Scalars['Boolean'];
  replaceVehicles: Scalars['Boolean'];
  setOrgGoal: Scalars['Boolean'];
  swamidLogin: UserResponse;
  token: Authentication;
  updateEnabledFeatures: EnabledFeatures;
  updateOrgRegistrationApprovalCode: Scalars['String'];
  updateOrganisationActions: Scalars['Boolean'];
  updateOrganisationBaseInfo: Scalars['Boolean'];
  updateOrganisationProfile: OrganisationProfile;
  updateRegistrationCode: Scalars['String'];
  updateRole: Scalars['Boolean'];
  updateUserTour: UserTour;
};


export type MutationAddOrganisationArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
  type: OrgType;
};


export type MutationAddRecatRuleArgs = {
  rule: RecatRuleInput;
};


export type MutationAddSsoArgs = {
  adminAttributeName: Scalars['String'];
  adminAttributeValue: Scalars['String'];
  id: Scalars['String'];
  idp: Scalars['String'];
  userAttributeName: Scalars['String'];
  userAttributeValue: Scalars['String'];
};


export type MutationAddUserArgs = {
  newUser: UserInput;
};


export type MutationApproveOrgRegistrationArgs = {
  approved: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationChangePasswordWithTokenArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationChangeRestrictionModeArgs = {
  restrictedAccess: Scalars['Boolean'];
};


export type MutationDeleteRecatRulesArgs = {
  rules: Array<RecatRuleInput>;
};


export type MutationDeleteUserArgs = {
  id: Scalars['String'];
  organisationId?: Maybe<Scalars['String']>;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationLoginWithFortnoxArgs = {
  code: Scalars['String'];
  redirectUri: Scalars['String'];
  registrationCode?: Maybe<Scalars['String']>;
};


export type MutationLogoutArgs = {
  refreshToken: Scalars['String'];
};


export type MutationRegisterOrganisationArgs = {
  acceptPolicies: Scalars['Boolean'];
  bookingSystem?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  orgNumber: Scalars['String'];
};


export type MutationRemoveOrgRegistrationArgs = {
  confirmationCode: Scalars['String'];
  id: Scalars['String'];
};


export type MutationReplacePremisesArgs = {
  premises: Array<PremisesInput>;
};


export type MutationReplaceVehiclesArgs = {
  vehicles: Array<VehicleInput>;
};


export type MutationSetOrgGoalArgs = {
  goal: OrgGoalInput;
};


export type MutationTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationUpdateEnabledFeaturesArgs = {
  features?: Maybe<Array<Feature>>;
  orgId: Scalars['String'];
};


export type MutationUpdateOrgRegistrationApprovalCodeArgs = {
  id: Scalars['String'];
};


export type MutationUpdateOrganisationActionsArgs = {
  actions?: Maybe<Scalars['String']>;
};


export type MutationUpdateOrganisationBaseInfoArgs = {
  industrialSectorId: Scalars['String'];
  industrialSectorName: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUpdateOrganisationProfileArgs = {
  input: UpdateOrganisationProfileInput;
};


export type MutationUpdateRegistrationCodeArgs = {
  id: Scalars['String'];
  organisationId?: Maybe<Scalars['String']>;
};


export type MutationUpdateRoleArgs = {
  newRole: Role;
  userId: Scalars['String'];
};


export type MutationUpdateUserTourArgs = {
  seen: Scalars['Boolean'];
  tourId: TourId;
};

export enum NumberSearchType {
  Between = 'between',
  Equal = 'equal',
  Greater = 'greater',
  GreaterOrEqual = 'greaterOrEqual',
  Lower = 'lower',
  LowerOrEqual = 'lowerOrEqual'
}

export type OrgGoal = {
  __typename?: 'OrgGoal';
  curve: Array<OrgGoalCurvePoint>;
  curveType: OrgGoalCurveType;
  referenceYear: Scalars['Float'];
  targetFactor: Scalars['Float'];
  targetYear: Scalars['Float'];
};

export type OrgGoalCurvePoint = {
  __typename?: 'OrgGoalCurvePoint';
  targetFactorRelReferenceYear: Scalars['Float'];
  year: Scalars['Float'];
};

export type OrgGoalCurvePointInput = {
  targetFactorRelReferenceYear: Scalars['Float'];
  year: Scalars['Float'];
};

export enum OrgGoalCurveType {
  Exp = 'exp',
  Line = 'line',
  S = 's'
}

export type OrgGoalInput = {
  curve: Array<OrgGoalCurvePointInput>;
  curveType: OrgGoalCurveType;
  referenceYear: Scalars['Float'];
  targetFactor: Scalars['Float'];
  targetYear: Scalars['Float'];
};

/** OrgRegistration object. */
export type OrgRegistration = {
  __typename?: 'OrgRegistration';
  approved: Scalars['Boolean'];
  bookingSystem?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  industrialSectorId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nbEmployees?: Maybe<Scalars['Float']>;
  orgNumber: Scalars['String'];
};

export enum OrgType {
  Fortnox = 'fortnox',
  RegionalGovernance = 'regionalGovernance',
  Test = 'test',
  University = 'university'
}

/** Org Unit object. */
export type OrgUnit = {
  __typename?: 'OrgUnit';
  id: Scalars['String'];
  level: Scalars['Float'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
};

/** Organisation object. */
export type Organisation = {
  __typename?: 'Organisation';
  dataUntil?: Maybe<Scalars['DateTimeISO']>;
  enabledFeatures?: Maybe<EnabledFeatures>;
  hasTransactions?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  idp?: Maybe<Scalars['String']>;
  industrialSectorId?: Maybe<Scalars['String']>;
  industrialSectorName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orgType: OrgType;
  orgUnits: Array<OrgUnit>;
  profile?: Maybe<OrganisationProfile>;
  profileCreated?: Maybe<Scalars['Boolean']>;
  restrictedAccess: Scalars['Boolean'];
  ssoMapping?: Maybe<SsoMapping>;
  topListAggLevel: Scalars['Float'];
  useAnnualWorkforce: Scalars['Boolean'];
};

/** Organisation Profile object. */
export type OrganisationProfile = {
  __typename?: 'OrganisationProfile';
  nbEmployees?: Maybe<Scalars['Float']>;
  organisationId: Scalars['String'];
};

export enum Period {
  Day = 'Day',
  Month = 'Month',
  Week = 'Week',
  Year = 'Year'
}

export type Premises = {
  __typename?: 'Premises';
  address: Scalars['String'];
  area: Scalars['Float'];
  city: Scalars['String'];
  heatingSystem: HeatSystem;
  id: Scalars['Float'];
  orgId: Scalars['String'];
};

export type PremisesInput = {
  address: Scalars['String'];
  area: Scalars['Float'];
  city: Scalars['String'];
  heatingSystem: HeatSystem;
  id: Scalars['Float'];
  orgId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  compareOrg: Array<CompareOrg>;
  containsNotRecatable: Scalars['Boolean'];
  containsRuleOverlap: Scalars['Boolean'];
  estimateMeasureEffect: EstimateMeasureEffectResponse;
  getAccounts: Array<AccountInfo>;
  getAggregatedDescriptiveStats: CategoryDescriptiveStats;
  getCategories: Array<Category>;
  getCategoryMeasureMetaData: MeasureMetaData;
  getCategoryTrends: Array<CategoryTrendEmission>;
  getCustomMeasures: Array<CustomMeasure>;
  getEmission: Array<Emission>;
  getEmissionRange: EmissionRange;
  getFirstLastDate: FirstLastDate;
  getFuelText: Array<EnumTextTuple>;
  getHeatSystemText: Array<EnumTextTuple>;
  getIndustrySectors: Array<IndustrySector>;
  getJob: Job;
  getMeasureMetaData: MeasureMetaData;
  getMetaData: EmissionMetaData;
  getOrgGoal?: Maybe<OrgGoal>;
  getOrganisationActions: Scalars['String'];
  getOrganisationProfile?: Maybe<OrganisationProfile>;
  getPremises: Array<Premises>;
  getRecatRules: Array<RecatRule>;
  getRegistrations: Array<OrgRegistration>;
  getResultVersion?: Maybe<ResultVersion>;
  getSuppliers: Array<SupplierInfo>;
  getTopList: Array<TopListResult>;
  getTotalKr: Scalars['Float'];
  getTransactionsCategories: Array<Category>;
  getTrend: EmissionTrend;
  getUploadUrl: Scalars['String'];
  getVehicleInfo: Vehicle;
  getVehicles: Array<Vehicle>;
  hasTransactions: Scalars['Boolean'];
  me: User;
  myOrganisation?: Maybe<Organisation>;
  organisations: Array<Organisation>;
  searchTransactions: TransactionsResponse;
  users: Array<User>;
};


export type QueryCompareOrgArgs = {
  filter?: Maybe<FilterInput>;
};


export type QueryContainsNotRecatableArgs = {
  searchCriteria: Array<TransactionSearchCriterion>;
};


export type QueryContainsRuleOverlapArgs = {
  searchCriteria: Array<TransactionSearchCriterion>;
};


export type QueryEstimateMeasureEffectArgs = {
  input: EstimateMeasureEffectInput;
};


export type QueryGetAccountsArgs = {
  searchCriteria: Array<TransactionSearchCriterion>;
};


export type QueryGetAggregatedDescriptiveStatsArgs = {
  categoryFilter: Array<Scalars['Int']>;
  filter: FilterInput;
};


export type QueryGetCategoryMeasureMetaDataArgs = {
  categoryId: Scalars['String'];
  orgId?: Maybe<Scalars['String']>;
  percentage: Scalars['Float'];
};


export type QueryGetCategoryTrendsArgs = {
  filter: FilterInput;
};


export type QueryGetCustomMeasuresArgs = {
  orgId?: Maybe<Scalars['String']>;
};


export type QueryGetEmissionArgs = {
  input: EmissionInput;
};


export type QueryGetFirstLastDateArgs = {
  searchCriteria: Array<TransactionSearchCriterion>;
};


export type QueryGetMeasureMetaDataArgs = {
  measureId: Scalars['String'];
  orgId?: Maybe<Scalars['String']>;
  percentage: Scalars['Float'];
};


export type QueryGetMetaDataArgs = {
  filter: FilterInput;
};


export type QueryGetSuppliersArgs = {
  searchCriteria: Array<TransactionSearchCriterion>;
};


export type QueryGetTopListArgs = {
  input: EmissionInput;
};


export type QueryGetTotalKrArgs = {
  searchCriteria: Array<TransactionSearchCriterion>;
};


export type QueryGetTransactionsCategoriesArgs = {
  searchCriteria: Array<TransactionSearchCriterion>;
};


export type QueryGetTrendArgs = {
  filter: FilterInput;
  period: Period;
};


export type QueryGetUploadUrlArgs = {
  fileName: Scalars['String'];
  fileType: Scalars['String'];
};


export type QueryGetVehicleInfoArgs = {
  regNumber: Scalars['String'];
};


export type QuerySearchTransactionsArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  searchCriteria: Array<TransactionSearchCriterion>;
};


export type QueryUsersArgs = {
  allOrgs?: Maybe<Scalars['Boolean']>;
};

/** RecatRule */
export type RecatRule = {
  __typename?: 'RecatRule';
  accounts?: Maybe<Array<Scalars['String']>>;
  applied?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  commentRegexp?: Maybe<Scalars['String']>;
  dateSearchType?: Maybe<NumberSearchType>;
  emissionIntensity?: Maybe<Scalars['Float']>;
  emissionLayers?: Maybe<EmissionLayers>;
  endDate?: Maybe<Scalars['DateTimeISO']>;
  id: Scalars['String'];
  organisationId: Scalars['String'];
  sni?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTimeISO']>;
  supplierIds?: Maybe<Array<Scalars['String']>>;
  supplierNames?: Maybe<Array<Scalars['String']>>;
};

export type RecatRuleInput = {
  accounts?: Maybe<Array<Scalars['String']>>;
  applied?: Maybe<Scalars['Boolean']>;
  category?: Maybe<CategoryInput>;
  categoryId?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  commentRegexp?: Maybe<Scalars['String']>;
  dateSearchType?: Maybe<NumberSearchType>;
  emissionIntensity?: Maybe<Scalars['Float']>;
  emissionLayers?: Maybe<EmissionLayerInput>;
  endDate?: Maybe<Scalars['DateTimeISO']>;
  id: Scalars['String'];
  organisationId: Scalars['String'];
  sni?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTimeISO']>;
  supplierIds?: Maybe<Array<Scalars['String']>>;
  supplierNames?: Maybe<Array<Scalars['String']>>;
};

export type RegistrationResponse = {
  __typename?: 'RegistrationResponse';
  canRegister?: Maybe<Scalars['Boolean']>;
  deletionConfirmationCode?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<FieldError>>;
  id?: Maybe<Scalars['String']>;
};

/** ResultVersion */
export type ResultVersion = {
  __typename?: 'ResultVersion';
  timestamp: Scalars['Float'];
};

export enum Role {
  Accountant = 'Accountant',
  Admin = 'Admin',
  SysAdmin = 'SysAdmin',
  User = 'User'
}

export enum SearchField {
  AccountId = 'AccountId',
  AccountName = 'AccountName',
  Comment = 'Comment',
  Date = 'Date',
  SupplierId = 'SupplierId',
  SupplierName = 'SupplierName'
}

/** SSO Mapping object. */
export type SsoMapping = {
  __typename?: 'SsoMapping';
  adminAttributeName: Scalars['String'];
  adminAttributeValue: Scalars['String'];
  organisationId: Scalars['String'];
  userAttributeName: Scalars['String'];
  userAttributeValue: Scalars['String'];
};

export enum StringSearchType {
  Contain = 'contain',
  Equal = 'equal',
  Regexp = 'regexp',
  StartWith = 'startWith'
}

export type SupplierInfo = {
  __typename?: 'SupplierInfo';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** TopListResult */
export type TopListResult = {
  __typename?: 'TopListResult';
  name: Scalars['String'];
  orgUnitId: Scalars['String'];
  parentName: Scalars['String'];
  totalCO2e: Scalars['Float'];
};

export enum TourId {
  EmissionTour = 'EmissionTour'
}

/** Transaction */
export type Transaction = {
  __typename?: 'Transaction';
  accountId: Scalars['Float'];
  accountName: Scalars['String'];
  allocation: Scalars['String'];
  ammount: Scalars['Float'];
  category: Category;
  comment?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  fakturanr?: Maybe<Scalars['String']>;
  notRecategorisable?: Maybe<Scalars['Boolean']>;
  organisationId: Scalars['String'];
  primaryEmissionCategory: Scalars['Float'];
  supplierId?: Maybe<Scalars['String']>;
  supplierName: Scalars['String'];
};

export type TransactionSearchCriterion = {
  endDate?: Maybe<Scalars['DateTimeISO']>;
  id: Scalars['String'];
  numberSearchType?: Maybe<NumberSearchType>;
  searchField: SearchField;
  searchString?: Maybe<Array<Scalars['String']>>;
  startDate?: Maybe<Scalars['DateTimeISO']>;
  stringSearchType?: Maybe<StringSearchType>;
};

/** Transaction */
export type TransactionWithAttachedRule = {
  __typename?: 'TransactionWithAttachedRule';
  attachedRule?: Maybe<RecatRule>;
  transaction: Transaction;
};

export type TransactionsResponse = {
  __typename?: 'TransactionsResponse';
  numRows: Scalars['Float'];
  transactions: Array<TransactionWithAttachedRule>;
};

export type UpdateOrganisationProfileInput = {
  nbEmployees?: Maybe<Scalars['Int']>;
};

/** User object. */
export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  organisation: Organisation;
  organisationId: Scalars['String'];
  role: Role;
  userTours: Array<UserTour>;
  username: Scalars['String'];
};

export type UserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organisationId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role: Role;
  username?: Maybe<Scalars['String']>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  authentication?: Maybe<Authentication>;
  errors?: Maybe<Array<FieldError>>;
  user?: Maybe<User>;
};

/** User-Tour mapping object. */
export type UserTour = {
  __typename?: 'UserTour';
  id: TourId;
  seen: Scalars['Boolean'];
};

/** Vehicle */
export type Vehicle = {
  __typename?: 'Vehicle';
  consumption: Scalars['Float'];
  mileage: Scalars['Float'];
  orgId: Scalars['String'];
  primaryFuel: Fuel;
  regNumber: Scalars['String'];
  secondaryFuel?: Maybe<Fuel>;
};

export type VehicleInput = {
  consumption: Scalars['Float'];
  mileage: Scalars['Float'];
  orgId: Scalars['String'];
  primaryFuel: Fuel;
  regNumber: Scalars['String'];
  secondaryFuel?: Maybe<Fuel>;
};

export type AccountInfoFragment = (
  { __typename?: 'AccountInfo' }
  & Pick<AccountInfo, 'id' | 'name'>
);

export type CategoryFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'name' | 'id' | 'color' | 'parentId' | 'description' | 'sni'>
  & { children: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'name' | 'id' | 'color' | 'parentId'>
  )> }
);

export type EmissionFieldsFragment = (
  { __typename?: 'Emission' }
  & Pick<Emission, 'period' | 'totalCO2e' | 'aggregationKeys'>
);

export type JobResponseFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'status' | 'progress' | 'type'>
  & { error?: Maybe<(
    { __typename?: 'JobError' }
    & JobErrorFragFragment
  )> }
);

export type JobErrorFragFragment = (
  { __typename?: 'JobError' }
  & Pick<JobError, 'type' | 'message'>
);

export type OrganisationFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, 'id' | 'name' | 'useAnnualWorkforce' | 'topListAggLevel' | 'dataUntil' | 'orgType' | 'idp' | 'industrialSectorName' | 'industrialSectorId' | 'profileCreated' | 'restrictedAccess' | 'hasTransactions'>
  & { ssoMapping?: Maybe<(
    { __typename?: 'SsoMapping' }
    & Pick<SsoMapping, 'adminAttributeName' | 'adminAttributeValue' | 'userAttributeName' | 'userAttributeValue'>
  )>, profile?: Maybe<(
    { __typename?: 'OrganisationProfile' }
    & Pick<OrganisationProfile, 'organisationId' | 'nbEmployees'>
  )>, orgUnits: Array<(
    { __typename?: 'OrgUnit' }
    & Pick<OrgUnit, 'id' | 'name' | 'level' | 'parentId'>
  )>, enabledFeatures?: Maybe<(
    { __typename?: 'EnabledFeatures' }
    & Pick<EnabledFeatures, 'id' | 'features'>
  )> }
);

export type RecatRuleInfoFragment = (
  { __typename?: 'RecatRule' }
  & Pick<RecatRule, 'id' | 'organisationId' | 'accounts' | 'supplierIds' | 'supplierNames' | 'commentRegexp' | 'comment' | 'startDate' | 'endDate' | 'dateSearchType' | 'emissionIntensity' | 'applied'>
  & { category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>, emissionLayers?: Maybe<(
    { __typename?: 'EmissionLayers' }
    & Pick<EmissionLayers, 'ttm' | 'scope1' | 'investments' | 'energy' | 'products' | 'services'>
  )> }
);

export type RegularErrorFragment = (
  { __typename?: 'FieldError' }
  & Pick<FieldError, 'field' | 'message' | 'errorType'>
);

export type RegularUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'role'>
  & { organisation: (
    { __typename?: 'Organisation' }
    & OrganisationFragment
  ), userTours: Array<(
    { __typename?: 'UserTour' }
    & Pick<UserTour, 'id' | 'seen'>
  )> }
);

export type RegularUserResponseFragment = (
  { __typename?: 'UserResponse' }
  & { errors?: Maybe<Array<(
    { __typename?: 'FieldError' }
    & RegularErrorFragment
  )>>, user?: Maybe<(
    { __typename?: 'User' }
    & RegularUserFragment
  )>, authentication?: Maybe<(
    { __typename?: 'Authentication' }
    & Pick<Authentication, 'refreshToken' | 'token' | 'expiresAt'>
  )> }
);

export type SupplierInfoFragment = (
  { __typename?: 'SupplierInfo' }
  & Pick<SupplierInfo, 'id' | 'name'>
);

export type TransactionInfoFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'fakturanr' | 'supplierId' | 'supplierName' | 'accountId' | 'accountName' | 'comment' | 'notRecategorisable' | 'date'>
  & { category: (
    { __typename?: 'Category' }
    & Pick<Category, 'name'>
  ) }
);

export type TransactionWithAttachedRuleFragment = (
  { __typename?: 'TransactionWithAttachedRule' }
  & { transaction: (
    { __typename?: 'Transaction' }
    & TransactionInfoFragment
  ), attachedRule?: Maybe<(
    { __typename?: 'RecatRule' }
    & RecatRuleInfoFragment
  )> }
);

export type UpdateRoleMutationVariables = Exact<{
  userId: Scalars['String'];
  newRole: Role;
}>;


export type UpdateRoleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRole'>
);

export type UpdateUserTourMutationVariables = Exact<{
  tourID: TourId;
  seen: Scalars['Boolean'];
}>;


export type UpdateUserTourMutation = (
  { __typename?: 'Mutation' }
  & { updateUserTour: (
    { __typename?: 'UserTour' }
    & Pick<UserTour, 'id' | 'seen'>
  ) }
);

export type AddSsoMutationVariables = Exact<{
  id: Scalars['String'];
  idp: Scalars['String'];
  adminAttributeName: Scalars['String'];
  adminAttributeValue: Scalars['String'];
  userAttributeName: Scalars['String'];
  userAttributeValue: Scalars['String'];
}>;


export type AddSsoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addSSO'>
);

export type AddUserMutationVariables = Exact<{
  newUser: UserInput;
}>;


export type AddUserMutation = (
  { __typename?: 'Mutation' }
  & { addUser: (
    { __typename?: 'AddUserResponse' }
    & Pick<AddUserResponse, 'email'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & RegularErrorFragment
    )>> }
  ) }
);

export type ApproveOrgRegistrationMutationVariables = Exact<{
  id: Scalars['String'];
  approved: Scalars['Boolean'];
}>;


export type ApproveOrgRegistrationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveOrgRegistration'>
);

export type ChangePasswordWithTokenMutationVariables = Exact<{
  token: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordWithTokenMutation = (
  { __typename?: 'Mutation' }
  & { changePasswordWithToken: (
    { __typename?: 'UserResponse' }
    & RegularUserResponseFragment
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePassword: (
    { __typename?: 'UserResponse' }
    & RegularUserResponseFragment
  ) }
);

export type ChangeRestrictionModeMutationVariables = Exact<{
  restrictedAccess: Scalars['Boolean'];
}>;


export type ChangeRestrictionModeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeRestrictionMode'>
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String'];
  organisationId?: Maybe<Scalars['String']>;
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type FinishProfileCreationMutationVariables = Exact<{ [key: string]: never; }>;


export type FinishProfileCreationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'finishProfileCreation'>
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type LoginWithFortnoxMutationVariables = Exact<{
  code: Scalars['String'];
  redirectUri: Scalars['String'];
  registrationCode?: Maybe<Scalars['String']>;
}>;


export type LoginWithFortnoxMutation = (
  { __typename?: 'Mutation' }
  & { loginWithFortnox: (
    { __typename?: 'UserResponse' }
    & RegularUserResponseFragment
  ) }
);

export type GetAndProcessFortnoxDataMutationVariables = Exact<{ [key: string]: never; }>;


export type GetAndProcessFortnoxDataMutation = (
  { __typename?: 'Mutation' }
  & { getAndProcessFortnoxData: (
    { __typename?: 'Job' }
    & JobResponseFragment
  ) }
);

export type DeleteErrorJobMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteErrorJobMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteErrorJob'>
);

export type CheckPermissionsMutationVariables = Exact<{ [key: string]: never; }>;


export type CheckPermissionsMutation = (
  { __typename?: 'Mutation' }
  & { checkPermissions: (
    { __typename?: 'FortnoxPermissions' }
    & { error?: Maybe<(
      { __typename?: 'JobError' }
      & JobErrorFragFragment
    )> }
  ) }
);

export type SetOrgGoalMutationVariables = Exact<{
  goal: OrgGoalInput;
}>;


export type SetOrgGoalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setOrgGoal'>
);

export type AuthenticationMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type AuthenticationMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'UserResponse' }
    & RegularUserResponseFragment
  ) }
);

export type SwamidAuthenticationMutationVariables = Exact<{ [key: string]: never; }>;


export type SwamidAuthenticationMutation = (
  { __typename?: 'Mutation' }
  & { swamidLogin: (
    { __typename?: 'UserResponse' }
    & RegularUserResponseFragment
  ) }
);

export type LogoutMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type AddRecatRuleMutationVariables = Exact<{
  rule: RecatRuleInput;
}>;


export type AddRecatRuleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addRecatRule'>
);

export type DeleteRecatRulesMutationVariables = Exact<{
  rules: Array<RecatRuleInput> | RecatRuleInput;
}>;


export type DeleteRecatRulesMutation = (
  { __typename?: 'Mutation' }
  & { deleteRecatRules: (
    { __typename?: 'Job' }
    & JobResponseFragment
  ) }
);

export type ApplyRulesMutationVariables = Exact<{ [key: string]: never; }>;


export type ApplyRulesMutation = (
  { __typename?: 'Mutation' }
  & { applyRules: (
    { __typename?: 'Job' }
    & JobResponseFragment
  ) }
);

export type ResfreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type ResfreshTokenMutation = (
  { __typename?: 'Mutation' }
  & { token: (
    { __typename?: 'Authentication' }
    & Pick<Authentication, 'refreshToken' | 'token' | 'expiresAt'>
  ) }
);

export type RegisterOrganisationMutationVariables = Exact<{
  orgNumber: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  bookingSystem?: Maybe<Scalars['String']>;
  acceptPolicies: Scalars['Boolean'];
}>;


export type RegisterOrganisationMutation = (
  { __typename?: 'Mutation' }
  & { registerOrganisation: (
    { __typename?: 'RegistrationResponse' }
    & Pick<RegistrationResponse, 'canRegister' | 'id' | 'deletionConfirmationCode'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & RegularErrorFragment
    )>> }
  ) }
);

export type RemoveOrgRegistrationMutationVariables = Exact<{
  id: Scalars['String'];
  confirmationCode: Scalars['String'];
}>;


export type RemoveOrgRegistrationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeOrgRegistration'>
);

export type ReplacePremisesMutationVariables = Exact<{
  premises: Array<PremisesInput> | PremisesInput;
}>;


export type ReplacePremisesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'replacePremises'>
);

export type ReplaceVehiclesMutationVariables = Exact<{
  vehicles: Array<VehicleInput> | VehicleInput;
}>;


export type ReplaceVehiclesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'replaceVehicles'>
);

export type UpdateEnabledFeaturesMutationVariables = Exact<{
  orgId: Scalars['String'];
  features?: Maybe<Array<Feature> | Feature>;
}>;


export type UpdateEnabledFeaturesMutation = (
  { __typename?: 'Mutation' }
  & { updateEnabledFeatures: (
    { __typename?: 'EnabledFeatures' }
    & Pick<EnabledFeatures, 'id' | 'features'>
  ) }
);

export type UpdateOrgRegistrationApprovalCodeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type UpdateOrgRegistrationApprovalCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOrgRegistrationApprovalCode'>
);

export type UpdateOrganisationActionsMutationVariables = Exact<{
  actions?: Maybe<Scalars['String']>;
}>;


export type UpdateOrganisationActionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOrganisationActions'>
);

export type UpdateOrganisationBaseInfoMutationVariables = Exact<{
  name: Scalars['String'];
  industrialSectorName: Scalars['String'];
  industrialSectorId: Scalars['String'];
}>;


export type UpdateOrganisationBaseInfoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOrganisationBaseInfo'>
);

export type UpdateOrganisationProfileMutationVariables = Exact<{
  input: UpdateOrganisationProfileInput;
}>;


export type UpdateOrganisationProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganisationProfile: (
    { __typename?: 'OrganisationProfile' }
    & Pick<OrganisationProfile, 'organisationId' | 'nbEmployees'>
  ) }
);

export type UpdateRegistrationCodeMutationVariables = Exact<{
  id: Scalars['String'];
  organisationId?: Maybe<Scalars['String']>;
}>;


export type UpdateRegistrationCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRegistrationCode'>
);

export type GetJobQueryVariables = Exact<{ [key: string]: never; }>;


export type GetJobQuery = (
  { __typename?: 'Query' }
  & { getJob: (
    { __typename?: 'Job' }
    & JobResponseFragment
  ) }
);

export type GetOrganisationActionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganisationActionsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getOrganisationActions'>
);

export type GetCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCategoriesQuery = (
  { __typename?: 'Query' }
  & { getCategories: Array<(
    { __typename?: 'Category' }
    & CategoryFragment
  )> }
);

export type GetAggregatedDescriptiveStatsQueryVariables = Exact<{
  filter: FilterInput;
  categoryFilter: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type GetAggregatedDescriptiveStatsQuery = (
  { __typename?: 'Query' }
  & { getAggregatedDescriptiveStats: (
    { __typename?: 'CategoryDescriptiveStats' }
    & Pick<CategoryDescriptiveStats, 'totalCO2e'>
    & { supplierStats: Array<(
      { __typename?: 'DescriptiveStat' }
      & Pick<DescriptiveStat, 'name' | 'ownerId' | 'totalCO2e'>
    )>, bookingAccountStats: Array<(
      { __typename?: 'DescriptiveStat' }
      & Pick<DescriptiveStat, 'name' | 'ownerId' | 'totalCO2e'>
    )> }
  ) }
);

export type EmissionsQueryVariables = Exact<{
  input: EmissionInput;
}>;


export type EmissionsQuery = (
  { __typename?: 'Query' }
  & { getEmission: Array<(
    { __typename?: 'Emission' }
    & EmissionFieldsFragment
  )> }
);

export type EstimateMeasureEffectQueryVariables = Exact<{
  input: EstimateMeasureEffectInput;
}>;


export type EstimateMeasureEffectQuery = (
  { __typename?: 'Query' }
  & { estimateMeasureEffect: (
    { __typename?: 'EstimateMeasureEffectResponse' }
    & { measureResults: Array<(
      { __typename?: 'MeasureResult' }
      & Pick<MeasureResult, 'measureId' | 'categoryId' | 'name' | 'cost' | 'effect'>
    )>, before: Array<(
      { __typename?: 'MeasureEmission' }
      & Pick<MeasureEmission, 'CO2e' | 'categoryId'>
    )>, after: Array<(
      { __typename?: 'MeasureEmission' }
      & Pick<MeasureEmission, 'CO2e' | 'categoryId'>
    )> }
  ) }
);

export type GetUploadUrlQueryVariables = Exact<{
  fileName: Scalars['String'];
  fileType: Scalars['String'];
}>;


export type GetUploadUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUploadUrl'>
);

export type GetCategoryMeasureMetaDataQueryVariables = Exact<{
  categoryId: Scalars['String'];
  percentage: Scalars['Float'];
  orgId?: Maybe<Scalars['String']>;
}>;


export type GetCategoryMeasureMetaDataQuery = (
  { __typename?: 'Query' }
  & { getCategoryMeasureMetaData: (
    { __typename?: 'MeasureMetaData' }
    & Pick<MeasureMetaData, 'categoryId' | 'effect' | 'cost' | 'imgSrc' | 'description' | 'type'>
  ) }
);

export type GetCategoryTrendsQueryVariables = Exact<{
  filter: FilterInput;
}>;


export type GetCategoryTrendsQuery = (
  { __typename?: 'Query' }
  & { getCategoryTrends: Array<(
    { __typename?: 'CategoryTrendEmission' }
    & Pick<CategoryTrendEmission, 'percentage' | 'categoryId' | 'categoryName' | 'categoryColor'>
    & { emissions: Array<(
      { __typename?: 'Emission' }
      & EmissionFieldsFragment
    )> }
  )> }
);

export type CompareOrgQueryVariables = Exact<{
  filter: FilterInput;
}>;


export type CompareOrgQuery = (
  { __typename?: 'Query' }
  & { compareOrg: Array<(
    { __typename?: 'CompareOrg' }
    & Pick<CompareOrg, 'label' | 'CO2e' | 'functionKey' | 'tooltip'>
  )> }
);

export type GetCustomMeasuresQueryVariables = Exact<{
  orgId?: Maybe<Scalars['String']>;
}>;


export type GetCustomMeasuresQuery = (
  { __typename?: 'Query' }
  & { getCustomMeasures: Array<(
    { __typename?: 'CustomMeasure' }
    & Pick<CustomMeasure, 'id' | 'categoryId' | 'name'>
  )> }
);

export type GetEmissionRangeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmissionRangeQuery = (
  { __typename?: 'Query' }
  & { getEmissionRange: (
    { __typename?: 'EmissionRange' }
    & Pick<EmissionRange, 'from' | 'to'>
  ) }
);

export type GetFuelTextQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFuelTextQuery = (
  { __typename?: 'Query' }
  & { getFuelText: Array<(
    { __typename?: 'EnumTextTuple' }
    & Pick<EnumTextTuple, 'key' | 'value'>
  )> }
);

export type GetHeatSystemTextQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHeatSystemTextQuery = (
  { __typename?: 'Query' }
  & { getHeatSystemText: Array<(
    { __typename?: 'EnumTextTuple' }
    & Pick<EnumTextTuple, 'key' | 'value'>
  )> }
);

export type GetIndustrySectorsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIndustrySectorsQuery = (
  { __typename?: 'Query' }
  & { getIndustrySectors: Array<(
    { __typename?: 'IndustrySector' }
    & Pick<IndustrySector, 'id' | 'label'>
  )> }
);

export type GetMeasureMetaDataQueryVariables = Exact<{
  measureId: Scalars['String'];
  percentage: Scalars['Float'];
  orgId?: Maybe<Scalars['String']>;
}>;


export type GetMeasureMetaDataQuery = (
  { __typename?: 'Query' }
  & { getMeasureMetaData: (
    { __typename?: 'MeasureMetaData' }
    & Pick<MeasureMetaData, 'categoryId' | 'effect' | 'cost' | 'imgSrc' | 'description' | 'type'>
  ) }
);

export type EmissionMetaDataQueryVariables = Exact<{
  filter: FilterInput;
}>;


export type EmissionMetaDataQuery = (
  { __typename?: 'Query' }
  & { getMetaData: (
    { __typename?: 'EmissionMetaData' }
    & Pick<EmissionMetaData, 'totalEmission' | 'yearlyPercentage' | 'emissionPerAnnualWorkForce'>
    & { worstCategory?: Maybe<(
      { __typename?: 'CategoryTrendPercentage' }
      & Pick<CategoryTrendPercentage, 'percentage' | 'diff' | 'categoryId'>
    )>, bestCategory?: Maybe<(
      { __typename?: 'CategoryTrendPercentage' }
      & Pick<CategoryTrendPercentage, 'percentage' | 'diff' | 'categoryId'>
    )> }
  ) }
);

export type GetOrganisationProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganisationProfileQuery = (
  { __typename?: 'Query' }
  & { getOrganisationProfile?: Maybe<(
    { __typename?: 'OrganisationProfile' }
    & Pick<OrganisationProfile, 'organisationId' | 'nbEmployees'>
  )> }
);

export type GetPremisesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPremisesQuery = (
  { __typename?: 'Query' }
  & { getPremises: Array<(
    { __typename?: 'Premises' }
    & Pick<Premises, 'id' | 'orgId' | 'address' | 'city' | 'area' | 'heatingSystem'>
  )> }
);

export type GetRegistrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRegistrationsQuery = (
  { __typename?: 'Query' }
  & { getRegistrations: Array<(
    { __typename?: 'OrgRegistration' }
    & Pick<OrgRegistration, 'id' | 'orgNumber' | 'name' | 'email' | 'bookingSystem' | 'nbEmployees' | 'industrialSectorId' | 'approved'>
  )> }
);

export type GetResultVersionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetResultVersionQuery = (
  { __typename?: 'Query' }
  & { getResultVersion?: Maybe<(
    { __typename?: 'ResultVersion' }
    & Pick<ResultVersion, 'timestamp'>
  )> }
);

export type GetTopListQueryVariables = Exact<{
  input: EmissionInput;
}>;


export type GetTopListQuery = (
  { __typename?: 'Query' }
  & { getTopList: Array<(
    { __typename?: 'TopListResult' }
    & Pick<TopListResult, 'parentName' | 'totalCO2e' | 'name' | 'orgUnitId'>
  )> }
);

export type GetTrendQueryVariables = Exact<{
  filter: FilterInput;
  period: Period;
}>;


export type GetTrendQuery = (
  { __typename?: 'Query' }
  & { getTrend: (
    { __typename?: 'EmissionTrend' }
    & Pick<EmissionTrend, 'trendOffset' | 'trendRate' | 'prognosis' | 'trendPercentage'>
    & { emissions: Array<(
      { __typename?: 'Emission' }
      & EmissionFieldsFragment
    )> }
  ) }
);

export type GetVehicleInfoQueryVariables = Exact<{
  regNumber: Scalars['String'];
}>;


export type GetVehicleInfoQuery = (
  { __typename?: 'Query' }
  & { getVehicleInfo: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'orgId' | 'regNumber' | 'primaryFuel' | 'secondaryFuel' | 'consumption' | 'mileage'>
  ) }
);

export type GetVehiclesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVehiclesQuery = (
  { __typename?: 'Query' }
  & { getVehicles: Array<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'orgId' | 'regNumber' | 'primaryFuel' | 'secondaryFuel' | 'consumption' | 'mileage'>
  )> }
);

export type GetOrgGoalQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrgGoalQuery = (
  { __typename?: 'Query' }
  & { getOrgGoal?: Maybe<(
    { __typename?: 'OrgGoal' }
    & Pick<OrgGoal, 'referenceYear' | 'targetYear' | 'targetFactor' | 'curveType'>
    & { curve: Array<(
      { __typename?: 'OrgGoalCurvePoint' }
      & Pick<OrgGoalCurvePoint, 'year' | 'targetFactorRelReferenceYear'>
    )> }
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & RegularUserFragment
  ) }
);

export type OrganisationsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganisationsQuery = (
  { __typename?: 'Query' }
  & { organisations: Array<(
    { __typename?: 'Organisation' }
    & OrganisationFragment
  )> }
);

export type GetRecatRulesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRecatRulesQuery = (
  { __typename?: 'Query' }
  & { getRecatRules: Array<(
    { __typename?: 'RecatRule' }
    & RecatRuleInfoFragment
  )> }
);

export type SearchTransactionsQueryVariables = Exact<{
  searchCriteria: Array<TransactionSearchCriterion> | TransactionSearchCriterion;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type SearchTransactionsQuery = (
  { __typename?: 'Query' }
  & { searchTransactions: (
    { __typename?: 'TransactionsResponse' }
    & Pick<TransactionsResponse, 'numRows'>
    & { transactions: Array<(
      { __typename?: 'TransactionWithAttachedRule' }
      & TransactionWithAttachedRuleFragment
    )> }
  ) }
);

export type GetSuppliersQueryVariables = Exact<{
  searchCriteria: Array<TransactionSearchCriterion> | TransactionSearchCriterion;
}>;


export type GetSuppliersQuery = (
  { __typename?: 'Query' }
  & { getSuppliers: Array<(
    { __typename?: 'SupplierInfo' }
    & SupplierInfoFragment
  )> }
);

export type GetAccountsQueryVariables = Exact<{
  searchCriteria: Array<TransactionSearchCriterion> | TransactionSearchCriterion;
}>;


export type GetAccountsQuery = (
  { __typename?: 'Query' }
  & { getAccounts: Array<(
    { __typename?: 'AccountInfo' }
    & AccountInfoFragment
  )> }
);

export type ContainsNotRecatableQueryVariables = Exact<{
  searchCriteria: Array<TransactionSearchCriterion> | TransactionSearchCriterion;
}>;


export type ContainsNotRecatableQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'containsNotRecatable'>
);

export type HasTransactionsQueryVariables = Exact<{ [key: string]: never; }>;


export type HasTransactionsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'hasTransactions'>
);

export type GetFirstLastDateQueryVariables = Exact<{
  searchCriteria: Array<TransactionSearchCriterion> | TransactionSearchCriterion;
}>;


export type GetFirstLastDateQuery = (
  { __typename?: 'Query' }
  & { getFirstLastDate: (
    { __typename?: 'FirstLastDate' }
    & Pick<FirstLastDate, 'first' | 'last'>
  ) }
);

export type GetTotalKrQueryVariables = Exact<{
  searchCriteria: Array<TransactionSearchCriterion> | TransactionSearchCriterion;
}>;


export type GetTotalKrQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTotalKr'>
);

export type ContainsRuleOverlapQueryVariables = Exact<{
  searchCriteria: Array<TransactionSearchCriterion> | TransactionSearchCriterion;
}>;


export type ContainsRuleOverlapQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'containsRuleOverlap'>
);

export type GetTransactionsCategoriesQueryVariables = Exact<{
  searchCriteria: Array<TransactionSearchCriterion> | TransactionSearchCriterion;
}>;


export type GetTransactionsCategoriesQuery = (
  { __typename?: 'Query' }
  & { getTransactionsCategories: Array<(
    { __typename?: 'Category' }
    & CategoryFragment
  )> }
);

export type UsersQueryVariables = Exact<{
  allOrgs?: Maybe<Scalars['Boolean']>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'username' | 'role'>
    & { organisation: (
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    ) }
  )> }
);

export const AccountInfoFragmentDoc = gql`
    fragment AccountInfo on AccountInfo {
  id
  name
}
    `;
export const CategoryFragmentDoc = gql`
    fragment Category on Category {
  name
  id
  color
  parentId
  description
  children {
    name
    id
    color
    parentId
  }
  sni
}
    `;
export const EmissionFieldsFragmentDoc = gql`
    fragment EmissionFields on Emission {
  period
  totalCO2e
  aggregationKeys
}
    `;
export const JobErrorFragFragmentDoc = gql`
    fragment JobErrorFrag on JobError {
  type
  message
}
    `;
export const JobResponseFragmentDoc = gql`
    fragment JobResponse on Job {
  status
  error {
    ...JobErrorFrag
  }
  progress
  type
}
    ${JobErrorFragFragmentDoc}`;
export const RegularErrorFragmentDoc = gql`
    fragment RegularError on FieldError {
  field
  message
  errorType
}
    `;
export const OrganisationFragmentDoc = gql`
    fragment Organisation on Organisation {
  id
  name
  useAnnualWorkforce
  topListAggLevel
  dataUntil
  orgType
  idp
  ssoMapping {
    adminAttributeName
    adminAttributeValue
    userAttributeName
    userAttributeValue
  }
  industrialSectorName
  industrialSectorId
  profile {
    organisationId
    nbEmployees
  }
  orgUnits {
    id
    name
    level
    parentId
  }
  profileCreated
  restrictedAccess
  hasTransactions
  enabledFeatures {
    id
    features
  }
}
    `;
export const RegularUserFragmentDoc = gql`
    fragment RegularUser on User {
  id
  firstName
  lastName
  email
  organisation {
    ...Organisation
  }
  role
  userTours {
    id
    seen
  }
}
    ${OrganisationFragmentDoc}`;
export const RegularUserResponseFragmentDoc = gql`
    fragment RegularUserResponse on UserResponse {
  errors {
    ...RegularError
  }
  user {
    ...RegularUser
  }
  authentication {
    refreshToken
    token
    expiresAt
  }
}
    ${RegularErrorFragmentDoc}
${RegularUserFragmentDoc}`;
export const SupplierInfoFragmentDoc = gql`
    fragment SupplierInfo on SupplierInfo {
  id
  name
}
    `;
export const TransactionInfoFragmentDoc = gql`
    fragment TransactionInfo on Transaction {
  fakturanr
  supplierId
  supplierName
  accountId
  accountName
  comment
  category {
    name
  }
  notRecategorisable
  date
}
    `;
export const RecatRuleInfoFragmentDoc = gql`
    fragment RecatRuleInfo on RecatRule {
  id
  organisationId
  accounts
  supplierIds
  supplierNames
  commentRegexp
  category {
    id
    name
  }
  comment
  startDate
  endDate
  dateSearchType
  emissionIntensity
  emissionLayers {
    ttm
    scope1
    investments
    energy
    products
    services
  }
  applied
}
    `;
export const TransactionWithAttachedRuleFragmentDoc = gql`
    fragment TransactionWithAttachedRule on TransactionWithAttachedRule {
  transaction {
    ...TransactionInfo
  }
  attachedRule {
    ...RecatRuleInfo
  }
}
    ${TransactionInfoFragmentDoc}
${RecatRuleInfoFragmentDoc}`;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($userId: String!, $newRole: Role!) {
  updateRole(userId: $userId, newRole: $newRole)
}
    `;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      newRole: // value for 'newRole'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const UpdateUserTourDocument = gql`
    mutation UpdateUserTour($tourID: TourID!, $seen: Boolean!) {
  updateUserTour(tourId: $tourID, seen: $seen) {
    id
    seen
  }
}
    `;
export type UpdateUserTourMutationFn = Apollo.MutationFunction<UpdateUserTourMutation, UpdateUserTourMutationVariables>;

/**
 * __useUpdateUserTourMutation__
 *
 * To run a mutation, you first call `useUpdateUserTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserTourMutation, { data, loading, error }] = useUpdateUserTourMutation({
 *   variables: {
 *      tourID: // value for 'tourID'
 *      seen: // value for 'seen'
 *   },
 * });
 */
export function useUpdateUserTourMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserTourMutation, UpdateUserTourMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserTourMutation, UpdateUserTourMutationVariables>(UpdateUserTourDocument, options);
      }
export type UpdateUserTourMutationHookResult = ReturnType<typeof useUpdateUserTourMutation>;
export type UpdateUserTourMutationResult = Apollo.MutationResult<UpdateUserTourMutation>;
export type UpdateUserTourMutationOptions = Apollo.BaseMutationOptions<UpdateUserTourMutation, UpdateUserTourMutationVariables>;
export const AddSsoDocument = gql`
    mutation AddSSO($id: String!, $idp: String!, $adminAttributeName: String!, $adminAttributeValue: String!, $userAttributeName: String!, $userAttributeValue: String!) {
  addSSO(
    id: $id
    idp: $idp
    adminAttributeName: $adminAttributeName
    adminAttributeValue: $adminAttributeValue
    userAttributeName: $userAttributeName
    userAttributeValue: $userAttributeValue
  )
}
    `;
export type AddSsoMutationFn = Apollo.MutationFunction<AddSsoMutation, AddSsoMutationVariables>;

/**
 * __useAddSsoMutation__
 *
 * To run a mutation, you first call `useAddSsoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSsoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSsoMutation, { data, loading, error }] = useAddSsoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      idp: // value for 'idp'
 *      adminAttributeName: // value for 'adminAttributeName'
 *      adminAttributeValue: // value for 'adminAttributeValue'
 *      userAttributeName: // value for 'userAttributeName'
 *      userAttributeValue: // value for 'userAttributeValue'
 *   },
 * });
 */
export function useAddSsoMutation(baseOptions?: Apollo.MutationHookOptions<AddSsoMutation, AddSsoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSsoMutation, AddSsoMutationVariables>(AddSsoDocument, options);
      }
export type AddSsoMutationHookResult = ReturnType<typeof useAddSsoMutation>;
export type AddSsoMutationResult = Apollo.MutationResult<AddSsoMutation>;
export type AddSsoMutationOptions = Apollo.BaseMutationOptions<AddSsoMutation, AddSsoMutationVariables>;
export const AddUserDocument = gql`
    mutation addUser($newUser: UserInput!) {
  addUser(newUser: $newUser) {
    errors {
      ...RegularError
    }
    email
  }
}
    ${RegularErrorFragmentDoc}`;
export type AddUserMutationFn = Apollo.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      newUser: // value for 'newUser'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, options);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const ApproveOrgRegistrationDocument = gql`
    mutation approveOrgRegistration($id: String!, $approved: Boolean!) {
  approveOrgRegistration(id: $id, approved: $approved)
}
    `;
export type ApproveOrgRegistrationMutationFn = Apollo.MutationFunction<ApproveOrgRegistrationMutation, ApproveOrgRegistrationMutationVariables>;

/**
 * __useApproveOrgRegistrationMutation__
 *
 * To run a mutation, you first call `useApproveOrgRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveOrgRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveOrgRegistrationMutation, { data, loading, error }] = useApproveOrgRegistrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      approved: // value for 'approved'
 *   },
 * });
 */
export function useApproveOrgRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<ApproveOrgRegistrationMutation, ApproveOrgRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveOrgRegistrationMutation, ApproveOrgRegistrationMutationVariables>(ApproveOrgRegistrationDocument, options);
      }
export type ApproveOrgRegistrationMutationHookResult = ReturnType<typeof useApproveOrgRegistrationMutation>;
export type ApproveOrgRegistrationMutationResult = Apollo.MutationResult<ApproveOrgRegistrationMutation>;
export type ApproveOrgRegistrationMutationOptions = Apollo.BaseMutationOptions<ApproveOrgRegistrationMutation, ApproveOrgRegistrationMutationVariables>;
export const ChangePasswordWithTokenDocument = gql`
    mutation ChangePasswordWithToken($token: String!, $newPassword: String!) {
  changePasswordWithToken(token: $token, newPassword: $newPassword) {
    ...RegularUserResponse
  }
}
    ${RegularUserResponseFragmentDoc}`;
export type ChangePasswordWithTokenMutationFn = Apollo.MutationFunction<ChangePasswordWithTokenMutation, ChangePasswordWithTokenMutationVariables>;

/**
 * __useChangePasswordWithTokenMutation__
 *
 * To run a mutation, you first call `useChangePasswordWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordWithTokenMutation, { data, loading, error }] = useChangePasswordWithTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordWithTokenMutation, ChangePasswordWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordWithTokenMutation, ChangePasswordWithTokenMutationVariables>(ChangePasswordWithTokenDocument, options);
      }
export type ChangePasswordWithTokenMutationHookResult = ReturnType<typeof useChangePasswordWithTokenMutation>;
export type ChangePasswordWithTokenMutationResult = Apollo.MutationResult<ChangePasswordWithTokenMutation>;
export type ChangePasswordWithTokenMutationOptions = Apollo.BaseMutationOptions<ChangePasswordWithTokenMutation, ChangePasswordWithTokenMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($newPassword: String!, $oldPassword: String!) {
  changePassword(newPassword: $newPassword, oldPassword: $oldPassword) {
    ...RegularUserResponse
  }
}
    ${RegularUserResponseFragmentDoc}`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      oldPassword: // value for 'oldPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ChangeRestrictionModeDocument = gql`
    mutation changeRestrictionMode($restrictedAccess: Boolean!) {
  changeRestrictionMode(restrictedAccess: $restrictedAccess)
}
    `;
export type ChangeRestrictionModeMutationFn = Apollo.MutationFunction<ChangeRestrictionModeMutation, ChangeRestrictionModeMutationVariables>;

/**
 * __useChangeRestrictionModeMutation__
 *
 * To run a mutation, you first call `useChangeRestrictionModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeRestrictionModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeRestrictionModeMutation, { data, loading, error }] = useChangeRestrictionModeMutation({
 *   variables: {
 *      restrictedAccess: // value for 'restrictedAccess'
 *   },
 * });
 */
export function useChangeRestrictionModeMutation(baseOptions?: Apollo.MutationHookOptions<ChangeRestrictionModeMutation, ChangeRestrictionModeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeRestrictionModeMutation, ChangeRestrictionModeMutationVariables>(ChangeRestrictionModeDocument, options);
      }
export type ChangeRestrictionModeMutationHookResult = ReturnType<typeof useChangeRestrictionModeMutation>;
export type ChangeRestrictionModeMutationResult = Apollo.MutationResult<ChangeRestrictionModeMutation>;
export type ChangeRestrictionModeMutationOptions = Apollo.BaseMutationOptions<ChangeRestrictionModeMutation, ChangeRestrictionModeMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: String!, $organisationId: String) {
  deleteUser(id: $id, organisationId: $organisationId)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const FinishProfileCreationDocument = gql`
    mutation FinishProfileCreation {
  finishProfileCreation
}
    `;
export type FinishProfileCreationMutationFn = Apollo.MutationFunction<FinishProfileCreationMutation, FinishProfileCreationMutationVariables>;

/**
 * __useFinishProfileCreationMutation__
 *
 * To run a mutation, you first call `useFinishProfileCreationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishProfileCreationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishProfileCreationMutation, { data, loading, error }] = useFinishProfileCreationMutation({
 *   variables: {
 *   },
 * });
 */
export function useFinishProfileCreationMutation(baseOptions?: Apollo.MutationHookOptions<FinishProfileCreationMutation, FinishProfileCreationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishProfileCreationMutation, FinishProfileCreationMutationVariables>(FinishProfileCreationDocument, options);
      }
export type FinishProfileCreationMutationHookResult = ReturnType<typeof useFinishProfileCreationMutation>;
export type FinishProfileCreationMutationResult = Apollo.MutationResult<FinishProfileCreationMutation>;
export type FinishProfileCreationMutationOptions = Apollo.BaseMutationOptions<FinishProfileCreationMutation, FinishProfileCreationMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const LoginWithFortnoxDocument = gql`
    mutation loginWithFortnox($code: String!, $redirectUri: String!, $registrationCode: String) {
  loginWithFortnox(
    code: $code
    redirectUri: $redirectUri
    registrationCode: $registrationCode
  ) {
    ...RegularUserResponse
  }
}
    ${RegularUserResponseFragmentDoc}`;
export type LoginWithFortnoxMutationFn = Apollo.MutationFunction<LoginWithFortnoxMutation, LoginWithFortnoxMutationVariables>;

/**
 * __useLoginWithFortnoxMutation__
 *
 * To run a mutation, you first call `useLoginWithFortnoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithFortnoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithFortnoxMutation, { data, loading, error }] = useLoginWithFortnoxMutation({
 *   variables: {
 *      code: // value for 'code'
 *      redirectUri: // value for 'redirectUri'
 *      registrationCode: // value for 'registrationCode'
 *   },
 * });
 */
export function useLoginWithFortnoxMutation(baseOptions?: Apollo.MutationHookOptions<LoginWithFortnoxMutation, LoginWithFortnoxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginWithFortnoxMutation, LoginWithFortnoxMutationVariables>(LoginWithFortnoxDocument, options);
      }
export type LoginWithFortnoxMutationHookResult = ReturnType<typeof useLoginWithFortnoxMutation>;
export type LoginWithFortnoxMutationResult = Apollo.MutationResult<LoginWithFortnoxMutation>;
export type LoginWithFortnoxMutationOptions = Apollo.BaseMutationOptions<LoginWithFortnoxMutation, LoginWithFortnoxMutationVariables>;
export const GetAndProcessFortnoxDataDocument = gql`
    mutation getAndProcessFortnoxData {
  getAndProcessFortnoxData {
    ...JobResponse
  }
}
    ${JobResponseFragmentDoc}`;
export type GetAndProcessFortnoxDataMutationFn = Apollo.MutationFunction<GetAndProcessFortnoxDataMutation, GetAndProcessFortnoxDataMutationVariables>;

/**
 * __useGetAndProcessFortnoxDataMutation__
 *
 * To run a mutation, you first call `useGetAndProcessFortnoxDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAndProcessFortnoxDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAndProcessFortnoxDataMutation, { data, loading, error }] = useGetAndProcessFortnoxDataMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetAndProcessFortnoxDataMutation(baseOptions?: Apollo.MutationHookOptions<GetAndProcessFortnoxDataMutation, GetAndProcessFortnoxDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetAndProcessFortnoxDataMutation, GetAndProcessFortnoxDataMutationVariables>(GetAndProcessFortnoxDataDocument, options);
      }
export type GetAndProcessFortnoxDataMutationHookResult = ReturnType<typeof useGetAndProcessFortnoxDataMutation>;
export type GetAndProcessFortnoxDataMutationResult = Apollo.MutationResult<GetAndProcessFortnoxDataMutation>;
export type GetAndProcessFortnoxDataMutationOptions = Apollo.BaseMutationOptions<GetAndProcessFortnoxDataMutation, GetAndProcessFortnoxDataMutationVariables>;
export const DeleteErrorJobDocument = gql`
    mutation deleteErrorJob {
  deleteErrorJob
}
    `;
export type DeleteErrorJobMutationFn = Apollo.MutationFunction<DeleteErrorJobMutation, DeleteErrorJobMutationVariables>;

/**
 * __useDeleteErrorJobMutation__
 *
 * To run a mutation, you first call `useDeleteErrorJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteErrorJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteErrorJobMutation, { data, loading, error }] = useDeleteErrorJobMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteErrorJobMutation(baseOptions?: Apollo.MutationHookOptions<DeleteErrorJobMutation, DeleteErrorJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteErrorJobMutation, DeleteErrorJobMutationVariables>(DeleteErrorJobDocument, options);
      }
export type DeleteErrorJobMutationHookResult = ReturnType<typeof useDeleteErrorJobMutation>;
export type DeleteErrorJobMutationResult = Apollo.MutationResult<DeleteErrorJobMutation>;
export type DeleteErrorJobMutationOptions = Apollo.BaseMutationOptions<DeleteErrorJobMutation, DeleteErrorJobMutationVariables>;
export const CheckPermissionsDocument = gql`
    mutation CheckPermissions {
  checkPermissions {
    error {
      ...JobErrorFrag
    }
  }
}
    ${JobErrorFragFragmentDoc}`;
export type CheckPermissionsMutationFn = Apollo.MutationFunction<CheckPermissionsMutation, CheckPermissionsMutationVariables>;

/**
 * __useCheckPermissionsMutation__
 *
 * To run a mutation, you first call `useCheckPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkPermissionsMutation, { data, loading, error }] = useCheckPermissionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useCheckPermissionsMutation(baseOptions?: Apollo.MutationHookOptions<CheckPermissionsMutation, CheckPermissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckPermissionsMutation, CheckPermissionsMutationVariables>(CheckPermissionsDocument, options);
      }
export type CheckPermissionsMutationHookResult = ReturnType<typeof useCheckPermissionsMutation>;
export type CheckPermissionsMutationResult = Apollo.MutationResult<CheckPermissionsMutation>;
export type CheckPermissionsMutationOptions = Apollo.BaseMutationOptions<CheckPermissionsMutation, CheckPermissionsMutationVariables>;
export const SetOrgGoalDocument = gql`
    mutation SetOrgGoal($goal: OrgGoalInput!) {
  setOrgGoal(goal: $goal)
}
    `;
export type SetOrgGoalMutationFn = Apollo.MutationFunction<SetOrgGoalMutation, SetOrgGoalMutationVariables>;

/**
 * __useSetOrgGoalMutation__
 *
 * To run a mutation, you first call `useSetOrgGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrgGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrgGoalMutation, { data, loading, error }] = useSetOrgGoalMutation({
 *   variables: {
 *      goal: // value for 'goal'
 *   },
 * });
 */
export function useSetOrgGoalMutation(baseOptions?: Apollo.MutationHookOptions<SetOrgGoalMutation, SetOrgGoalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOrgGoalMutation, SetOrgGoalMutationVariables>(SetOrgGoalDocument, options);
      }
export type SetOrgGoalMutationHookResult = ReturnType<typeof useSetOrgGoalMutation>;
export type SetOrgGoalMutationResult = Apollo.MutationResult<SetOrgGoalMutation>;
export type SetOrgGoalMutationOptions = Apollo.BaseMutationOptions<SetOrgGoalMutation, SetOrgGoalMutationVariables>;
export const AuthenticationDocument = gql`
    mutation Authentication($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    ...RegularUserResponse
  }
}
    ${RegularUserResponseFragmentDoc}`;
export type AuthenticationMutationFn = Apollo.MutationFunction<AuthenticationMutation, AuthenticationMutationVariables>;

/**
 * __useAuthenticationMutation__
 *
 * To run a mutation, you first call `useAuthenticationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticationMutation, { data, loading, error }] = useAuthenticationMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthenticationMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticationMutation, AuthenticationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticationMutation, AuthenticationMutationVariables>(AuthenticationDocument, options);
      }
export type AuthenticationMutationHookResult = ReturnType<typeof useAuthenticationMutation>;
export type AuthenticationMutationResult = Apollo.MutationResult<AuthenticationMutation>;
export type AuthenticationMutationOptions = Apollo.BaseMutationOptions<AuthenticationMutation, AuthenticationMutationVariables>;
export const SwamidAuthenticationDocument = gql`
    mutation SwamidAuthentication {
  swamidLogin {
    ...RegularUserResponse
  }
}
    ${RegularUserResponseFragmentDoc}`;
export type SwamidAuthenticationMutationFn = Apollo.MutationFunction<SwamidAuthenticationMutation, SwamidAuthenticationMutationVariables>;

/**
 * __useSwamidAuthenticationMutation__
 *
 * To run a mutation, you first call `useSwamidAuthenticationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwamidAuthenticationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [swamidAuthenticationMutation, { data, loading, error }] = useSwamidAuthenticationMutation({
 *   variables: {
 *   },
 * });
 */
export function useSwamidAuthenticationMutation(baseOptions?: Apollo.MutationHookOptions<SwamidAuthenticationMutation, SwamidAuthenticationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SwamidAuthenticationMutation, SwamidAuthenticationMutationVariables>(SwamidAuthenticationDocument, options);
      }
export type SwamidAuthenticationMutationHookResult = ReturnType<typeof useSwamidAuthenticationMutation>;
export type SwamidAuthenticationMutationResult = Apollo.MutationResult<SwamidAuthenticationMutation>;
export type SwamidAuthenticationMutationOptions = Apollo.BaseMutationOptions<SwamidAuthenticationMutation, SwamidAuthenticationMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout($refreshToken: String!) {
  logout(refreshToken: $refreshToken)
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const AddRecatRuleDocument = gql`
    mutation AddRecatRule($rule: RecatRuleInput!) {
  addRecatRule(rule: $rule)
}
    `;
export type AddRecatRuleMutationFn = Apollo.MutationFunction<AddRecatRuleMutation, AddRecatRuleMutationVariables>;

/**
 * __useAddRecatRuleMutation__
 *
 * To run a mutation, you first call `useAddRecatRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRecatRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRecatRuleMutation, { data, loading, error }] = useAddRecatRuleMutation({
 *   variables: {
 *      rule: // value for 'rule'
 *   },
 * });
 */
export function useAddRecatRuleMutation(baseOptions?: Apollo.MutationHookOptions<AddRecatRuleMutation, AddRecatRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRecatRuleMutation, AddRecatRuleMutationVariables>(AddRecatRuleDocument, options);
      }
export type AddRecatRuleMutationHookResult = ReturnType<typeof useAddRecatRuleMutation>;
export type AddRecatRuleMutationResult = Apollo.MutationResult<AddRecatRuleMutation>;
export type AddRecatRuleMutationOptions = Apollo.BaseMutationOptions<AddRecatRuleMutation, AddRecatRuleMutationVariables>;
export const DeleteRecatRulesDocument = gql`
    mutation DeleteRecatRules($rules: [RecatRuleInput!]!) {
  deleteRecatRules(rules: $rules) {
    ...JobResponse
  }
}
    ${JobResponseFragmentDoc}`;
export type DeleteRecatRulesMutationFn = Apollo.MutationFunction<DeleteRecatRulesMutation, DeleteRecatRulesMutationVariables>;

/**
 * __useDeleteRecatRulesMutation__
 *
 * To run a mutation, you first call `useDeleteRecatRulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecatRulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecatRulesMutation, { data, loading, error }] = useDeleteRecatRulesMutation({
 *   variables: {
 *      rules: // value for 'rules'
 *   },
 * });
 */
export function useDeleteRecatRulesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRecatRulesMutation, DeleteRecatRulesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRecatRulesMutation, DeleteRecatRulesMutationVariables>(DeleteRecatRulesDocument, options);
      }
export type DeleteRecatRulesMutationHookResult = ReturnType<typeof useDeleteRecatRulesMutation>;
export type DeleteRecatRulesMutationResult = Apollo.MutationResult<DeleteRecatRulesMutation>;
export type DeleteRecatRulesMutationOptions = Apollo.BaseMutationOptions<DeleteRecatRulesMutation, DeleteRecatRulesMutationVariables>;
export const ApplyRulesDocument = gql`
    mutation ApplyRules {
  applyRules {
    ...JobResponse
  }
}
    ${JobResponseFragmentDoc}`;
export type ApplyRulesMutationFn = Apollo.MutationFunction<ApplyRulesMutation, ApplyRulesMutationVariables>;

/**
 * __useApplyRulesMutation__
 *
 * To run a mutation, you first call `useApplyRulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyRulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyRulesMutation, { data, loading, error }] = useApplyRulesMutation({
 *   variables: {
 *   },
 * });
 */
export function useApplyRulesMutation(baseOptions?: Apollo.MutationHookOptions<ApplyRulesMutation, ApplyRulesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyRulesMutation, ApplyRulesMutationVariables>(ApplyRulesDocument, options);
      }
export type ApplyRulesMutationHookResult = ReturnType<typeof useApplyRulesMutation>;
export type ApplyRulesMutationResult = Apollo.MutationResult<ApplyRulesMutation>;
export type ApplyRulesMutationOptions = Apollo.BaseMutationOptions<ApplyRulesMutation, ApplyRulesMutationVariables>;
export const ResfreshTokenDocument = gql`
    mutation ResfreshToken($refreshToken: String!) {
  token(refreshToken: $refreshToken) {
    refreshToken
    token
    expiresAt
  }
}
    `;
export type ResfreshTokenMutationFn = Apollo.MutationFunction<ResfreshTokenMutation, ResfreshTokenMutationVariables>;

/**
 * __useResfreshTokenMutation__
 *
 * To run a mutation, you first call `useResfreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResfreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resfreshTokenMutation, { data, loading, error }] = useResfreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useResfreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<ResfreshTokenMutation, ResfreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResfreshTokenMutation, ResfreshTokenMutationVariables>(ResfreshTokenDocument, options);
      }
export type ResfreshTokenMutationHookResult = ReturnType<typeof useResfreshTokenMutation>;
export type ResfreshTokenMutationResult = Apollo.MutationResult<ResfreshTokenMutation>;
export type ResfreshTokenMutationOptions = Apollo.BaseMutationOptions<ResfreshTokenMutation, ResfreshTokenMutationVariables>;
export const RegisterOrganisationDocument = gql`
    mutation RegisterOrganisation($orgNumber: String!, $email: String, $bookingSystem: String, $acceptPolicies: Boolean!) {
  registerOrganisation(
    orgNumber: $orgNumber
    email: $email
    bookingSystem: $bookingSystem
    acceptPolicies: $acceptPolicies
  ) {
    canRegister
    id
    deletionConfirmationCode
    errors {
      ...RegularError
    }
  }
}
    ${RegularErrorFragmentDoc}`;
export type RegisterOrganisationMutationFn = Apollo.MutationFunction<RegisterOrganisationMutation, RegisterOrganisationMutationVariables>;

/**
 * __useRegisterOrganisationMutation__
 *
 * To run a mutation, you first call `useRegisterOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerOrganisationMutation, { data, loading, error }] = useRegisterOrganisationMutation({
 *   variables: {
 *      orgNumber: // value for 'orgNumber'
 *      email: // value for 'email'
 *      bookingSystem: // value for 'bookingSystem'
 *      acceptPolicies: // value for 'acceptPolicies'
 *   },
 * });
 */
export function useRegisterOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<RegisterOrganisationMutation, RegisterOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterOrganisationMutation, RegisterOrganisationMutationVariables>(RegisterOrganisationDocument, options);
      }
export type RegisterOrganisationMutationHookResult = ReturnType<typeof useRegisterOrganisationMutation>;
export type RegisterOrganisationMutationResult = Apollo.MutationResult<RegisterOrganisationMutation>;
export type RegisterOrganisationMutationOptions = Apollo.BaseMutationOptions<RegisterOrganisationMutation, RegisterOrganisationMutationVariables>;
export const RemoveOrgRegistrationDocument = gql`
    mutation RemoveOrgRegistration($id: String!, $confirmationCode: String!) {
  removeOrgRegistration(id: $id, confirmationCode: $confirmationCode)
}
    `;
export type RemoveOrgRegistrationMutationFn = Apollo.MutationFunction<RemoveOrgRegistrationMutation, RemoveOrgRegistrationMutationVariables>;

/**
 * __useRemoveOrgRegistrationMutation__
 *
 * To run a mutation, you first call `useRemoveOrgRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrgRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrgRegistrationMutation, { data, loading, error }] = useRemoveOrgRegistrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      confirmationCode: // value for 'confirmationCode'
 *   },
 * });
 */
export function useRemoveOrgRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrgRegistrationMutation, RemoveOrgRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOrgRegistrationMutation, RemoveOrgRegistrationMutationVariables>(RemoveOrgRegistrationDocument, options);
      }
export type RemoveOrgRegistrationMutationHookResult = ReturnType<typeof useRemoveOrgRegistrationMutation>;
export type RemoveOrgRegistrationMutationResult = Apollo.MutationResult<RemoveOrgRegistrationMutation>;
export type RemoveOrgRegistrationMutationOptions = Apollo.BaseMutationOptions<RemoveOrgRegistrationMutation, RemoveOrgRegistrationMutationVariables>;
export const ReplacePremisesDocument = gql`
    mutation ReplacePremises($premises: [PremisesInput!]!) {
  replacePremises(premises: $premises)
}
    `;
export type ReplacePremisesMutationFn = Apollo.MutationFunction<ReplacePremisesMutation, ReplacePremisesMutationVariables>;

/**
 * __useReplacePremisesMutation__
 *
 * To run a mutation, you first call `useReplacePremisesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplacePremisesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replacePremisesMutation, { data, loading, error }] = useReplacePremisesMutation({
 *   variables: {
 *      premises: // value for 'premises'
 *   },
 * });
 */
export function useReplacePremisesMutation(baseOptions?: Apollo.MutationHookOptions<ReplacePremisesMutation, ReplacePremisesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplacePremisesMutation, ReplacePremisesMutationVariables>(ReplacePremisesDocument, options);
      }
export type ReplacePremisesMutationHookResult = ReturnType<typeof useReplacePremisesMutation>;
export type ReplacePremisesMutationResult = Apollo.MutationResult<ReplacePremisesMutation>;
export type ReplacePremisesMutationOptions = Apollo.BaseMutationOptions<ReplacePremisesMutation, ReplacePremisesMutationVariables>;
export const ReplaceVehiclesDocument = gql`
    mutation ReplaceVehicles($vehicles: [VehicleInput!]!) {
  replaceVehicles(vehicles: $vehicles)
}
    `;
export type ReplaceVehiclesMutationFn = Apollo.MutationFunction<ReplaceVehiclesMutation, ReplaceVehiclesMutationVariables>;

/**
 * __useReplaceVehiclesMutation__
 *
 * To run a mutation, you first call `useReplaceVehiclesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceVehiclesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceVehiclesMutation, { data, loading, error }] = useReplaceVehiclesMutation({
 *   variables: {
 *      vehicles: // value for 'vehicles'
 *   },
 * });
 */
export function useReplaceVehiclesMutation(baseOptions?: Apollo.MutationHookOptions<ReplaceVehiclesMutation, ReplaceVehiclesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplaceVehiclesMutation, ReplaceVehiclesMutationVariables>(ReplaceVehiclesDocument, options);
      }
export type ReplaceVehiclesMutationHookResult = ReturnType<typeof useReplaceVehiclesMutation>;
export type ReplaceVehiclesMutationResult = Apollo.MutationResult<ReplaceVehiclesMutation>;
export type ReplaceVehiclesMutationOptions = Apollo.BaseMutationOptions<ReplaceVehiclesMutation, ReplaceVehiclesMutationVariables>;
export const UpdateEnabledFeaturesDocument = gql`
    mutation UpdateEnabledFeatures($orgId: String!, $features: [Feature!]) {
  updateEnabledFeatures(orgId: $orgId, features: $features) {
    id
    features
  }
}
    `;
export type UpdateEnabledFeaturesMutationFn = Apollo.MutationFunction<UpdateEnabledFeaturesMutation, UpdateEnabledFeaturesMutationVariables>;

/**
 * __useUpdateEnabledFeaturesMutation__
 *
 * To run a mutation, you first call `useUpdateEnabledFeaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnabledFeaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnabledFeaturesMutation, { data, loading, error }] = useUpdateEnabledFeaturesMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      features: // value for 'features'
 *   },
 * });
 */
export function useUpdateEnabledFeaturesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEnabledFeaturesMutation, UpdateEnabledFeaturesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEnabledFeaturesMutation, UpdateEnabledFeaturesMutationVariables>(UpdateEnabledFeaturesDocument, options);
      }
export type UpdateEnabledFeaturesMutationHookResult = ReturnType<typeof useUpdateEnabledFeaturesMutation>;
export type UpdateEnabledFeaturesMutationResult = Apollo.MutationResult<UpdateEnabledFeaturesMutation>;
export type UpdateEnabledFeaturesMutationOptions = Apollo.BaseMutationOptions<UpdateEnabledFeaturesMutation, UpdateEnabledFeaturesMutationVariables>;
export const UpdateOrgRegistrationApprovalCodeDocument = gql`
    mutation updateOrgRegistrationApprovalCode($id: String!) {
  updateOrgRegistrationApprovalCode(id: $id)
}
    `;
export type UpdateOrgRegistrationApprovalCodeMutationFn = Apollo.MutationFunction<UpdateOrgRegistrationApprovalCodeMutation, UpdateOrgRegistrationApprovalCodeMutationVariables>;

/**
 * __useUpdateOrgRegistrationApprovalCodeMutation__
 *
 * To run a mutation, you first call `useUpdateOrgRegistrationApprovalCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgRegistrationApprovalCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgRegistrationApprovalCodeMutation, { data, loading, error }] = useUpdateOrgRegistrationApprovalCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateOrgRegistrationApprovalCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrgRegistrationApprovalCodeMutation, UpdateOrgRegistrationApprovalCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrgRegistrationApprovalCodeMutation, UpdateOrgRegistrationApprovalCodeMutationVariables>(UpdateOrgRegistrationApprovalCodeDocument, options);
      }
export type UpdateOrgRegistrationApprovalCodeMutationHookResult = ReturnType<typeof useUpdateOrgRegistrationApprovalCodeMutation>;
export type UpdateOrgRegistrationApprovalCodeMutationResult = Apollo.MutationResult<UpdateOrgRegistrationApprovalCodeMutation>;
export type UpdateOrgRegistrationApprovalCodeMutationOptions = Apollo.BaseMutationOptions<UpdateOrgRegistrationApprovalCodeMutation, UpdateOrgRegistrationApprovalCodeMutationVariables>;
export const UpdateOrganisationActionsDocument = gql`
    mutation UpdateOrganisationActions($actions: String) {
  updateOrganisationActions(actions: $actions)
}
    `;
export type UpdateOrganisationActionsMutationFn = Apollo.MutationFunction<UpdateOrganisationActionsMutation, UpdateOrganisationActionsMutationVariables>;

/**
 * __useUpdateOrganisationActionsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationActionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationActionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationActionsMutation, { data, loading, error }] = useUpdateOrganisationActionsMutation({
 *   variables: {
 *      actions: // value for 'actions'
 *   },
 * });
 */
export function useUpdateOrganisationActionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganisationActionsMutation, UpdateOrganisationActionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganisationActionsMutation, UpdateOrganisationActionsMutationVariables>(UpdateOrganisationActionsDocument, options);
      }
export type UpdateOrganisationActionsMutationHookResult = ReturnType<typeof useUpdateOrganisationActionsMutation>;
export type UpdateOrganisationActionsMutationResult = Apollo.MutationResult<UpdateOrganisationActionsMutation>;
export type UpdateOrganisationActionsMutationOptions = Apollo.BaseMutationOptions<UpdateOrganisationActionsMutation, UpdateOrganisationActionsMutationVariables>;
export const UpdateOrganisationBaseInfoDocument = gql`
    mutation UpdateOrganisationBaseInfo($name: String!, $industrialSectorName: String!, $industrialSectorId: String!) {
  updateOrganisationBaseInfo(
    name: $name
    industrialSectorName: $industrialSectorName
    industrialSectorId: $industrialSectorId
  )
}
    `;
export type UpdateOrganisationBaseInfoMutationFn = Apollo.MutationFunction<UpdateOrganisationBaseInfoMutation, UpdateOrganisationBaseInfoMutationVariables>;

/**
 * __useUpdateOrganisationBaseInfoMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationBaseInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationBaseInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationBaseInfoMutation, { data, loading, error }] = useUpdateOrganisationBaseInfoMutation({
 *   variables: {
 *      name: // value for 'name'
 *      industrialSectorName: // value for 'industrialSectorName'
 *      industrialSectorId: // value for 'industrialSectorId'
 *   },
 * });
 */
export function useUpdateOrganisationBaseInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganisationBaseInfoMutation, UpdateOrganisationBaseInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganisationBaseInfoMutation, UpdateOrganisationBaseInfoMutationVariables>(UpdateOrganisationBaseInfoDocument, options);
      }
export type UpdateOrganisationBaseInfoMutationHookResult = ReturnType<typeof useUpdateOrganisationBaseInfoMutation>;
export type UpdateOrganisationBaseInfoMutationResult = Apollo.MutationResult<UpdateOrganisationBaseInfoMutation>;
export type UpdateOrganisationBaseInfoMutationOptions = Apollo.BaseMutationOptions<UpdateOrganisationBaseInfoMutation, UpdateOrganisationBaseInfoMutationVariables>;
export const UpdateOrganisationProfileDocument = gql`
    mutation UpdateOrganisationProfile($input: UpdateOrganisationProfileInput!) {
  updateOrganisationProfile(input: $input) {
    organisationId
    nbEmployees
  }
}
    `;
export type UpdateOrganisationProfileMutationFn = Apollo.MutationFunction<UpdateOrganisationProfileMutation, UpdateOrganisationProfileMutationVariables>;

/**
 * __useUpdateOrganisationProfileMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationProfileMutation, { data, loading, error }] = useUpdateOrganisationProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganisationProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganisationProfileMutation, UpdateOrganisationProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganisationProfileMutation, UpdateOrganisationProfileMutationVariables>(UpdateOrganisationProfileDocument, options);
      }
export type UpdateOrganisationProfileMutationHookResult = ReturnType<typeof useUpdateOrganisationProfileMutation>;
export type UpdateOrganisationProfileMutationResult = Apollo.MutationResult<UpdateOrganisationProfileMutation>;
export type UpdateOrganisationProfileMutationOptions = Apollo.BaseMutationOptions<UpdateOrganisationProfileMutation, UpdateOrganisationProfileMutationVariables>;
export const UpdateRegistrationCodeDocument = gql`
    mutation UpdateRegistrationCode($id: String!, $organisationId: String) {
  updateRegistrationCode(id: $id, organisationId: $organisationId)
}
    `;
export type UpdateRegistrationCodeMutationFn = Apollo.MutationFunction<UpdateRegistrationCodeMutation, UpdateRegistrationCodeMutationVariables>;

/**
 * __useUpdateRegistrationCodeMutation__
 *
 * To run a mutation, you first call `useUpdateRegistrationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegistrationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegistrationCodeMutation, { data, loading, error }] = useUpdateRegistrationCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useUpdateRegistrationCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRegistrationCodeMutation, UpdateRegistrationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRegistrationCodeMutation, UpdateRegistrationCodeMutationVariables>(UpdateRegistrationCodeDocument, options);
      }
export type UpdateRegistrationCodeMutationHookResult = ReturnType<typeof useUpdateRegistrationCodeMutation>;
export type UpdateRegistrationCodeMutationResult = Apollo.MutationResult<UpdateRegistrationCodeMutation>;
export type UpdateRegistrationCodeMutationOptions = Apollo.BaseMutationOptions<UpdateRegistrationCodeMutation, UpdateRegistrationCodeMutationVariables>;
export const GetJobDocument = gql`
    query GetJob {
  getJob {
    ...JobResponse
  }
}
    ${JobResponseFragmentDoc}`;

/**
 * __useGetJobQuery__
 *
 * To run a query within a React component, call `useGetJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetJobQuery(baseOptions?: Apollo.QueryHookOptions<GetJobQuery, GetJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobQuery, GetJobQueryVariables>(GetJobDocument, options);
      }
export function useGetJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobQuery, GetJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobQuery, GetJobQueryVariables>(GetJobDocument, options);
        }
export type GetJobQueryHookResult = ReturnType<typeof useGetJobQuery>;
export type GetJobLazyQueryHookResult = ReturnType<typeof useGetJobLazyQuery>;
export type GetJobQueryResult = Apollo.QueryResult<GetJobQuery, GetJobQueryVariables>;
export const GetOrganisationActionsDocument = gql`
    query GetOrganisationActions {
  getOrganisationActions
}
    `;

/**
 * __useGetOrganisationActionsQuery__
 *
 * To run a query within a React component, call `useGetOrganisationActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationActionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganisationActionsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganisationActionsQuery, GetOrganisationActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganisationActionsQuery, GetOrganisationActionsQueryVariables>(GetOrganisationActionsDocument, options);
      }
export function useGetOrganisationActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationActionsQuery, GetOrganisationActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganisationActionsQuery, GetOrganisationActionsQueryVariables>(GetOrganisationActionsDocument, options);
        }
export type GetOrganisationActionsQueryHookResult = ReturnType<typeof useGetOrganisationActionsQuery>;
export type GetOrganisationActionsLazyQueryHookResult = ReturnType<typeof useGetOrganisationActionsLazyQuery>;
export type GetOrganisationActionsQueryResult = Apollo.QueryResult<GetOrganisationActionsQuery, GetOrganisationActionsQueryVariables>;
export const GetCategoriesDocument = gql`
    query GetCategories {
  getCategories {
    ...Category
  }
}
    ${CategoryFragmentDoc}`;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
      }
export function useGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
        }
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>;
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>;
export type GetCategoriesQueryResult = Apollo.QueryResult<GetCategoriesQuery, GetCategoriesQueryVariables>;
export const GetAggregatedDescriptiveStatsDocument = gql`
    query GetAggregatedDescriptiveStats($filter: FilterInput!, $categoryFilter: [Int!]!) {
  getAggregatedDescriptiveStats(filter: $filter, categoryFilter: $categoryFilter) {
    totalCO2e
    supplierStats {
      name
      ownerId
      totalCO2e
    }
    bookingAccountStats {
      name
      ownerId
      totalCO2e
    }
  }
}
    `;

/**
 * __useGetAggregatedDescriptiveStatsQuery__
 *
 * To run a query within a React component, call `useGetAggregatedDescriptiveStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregatedDescriptiveStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregatedDescriptiveStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      categoryFilter: // value for 'categoryFilter'
 *   },
 * });
 */
export function useGetAggregatedDescriptiveStatsQuery(baseOptions: Apollo.QueryHookOptions<GetAggregatedDescriptiveStatsQuery, GetAggregatedDescriptiveStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAggregatedDescriptiveStatsQuery, GetAggregatedDescriptiveStatsQueryVariables>(GetAggregatedDescriptiveStatsDocument, options);
      }
export function useGetAggregatedDescriptiveStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAggregatedDescriptiveStatsQuery, GetAggregatedDescriptiveStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAggregatedDescriptiveStatsQuery, GetAggregatedDescriptiveStatsQueryVariables>(GetAggregatedDescriptiveStatsDocument, options);
        }
export type GetAggregatedDescriptiveStatsQueryHookResult = ReturnType<typeof useGetAggregatedDescriptiveStatsQuery>;
export type GetAggregatedDescriptiveStatsLazyQueryHookResult = ReturnType<typeof useGetAggregatedDescriptiveStatsLazyQuery>;
export type GetAggregatedDescriptiveStatsQueryResult = Apollo.QueryResult<GetAggregatedDescriptiveStatsQuery, GetAggregatedDescriptiveStatsQueryVariables>;
export const EmissionsDocument = gql`
    query Emissions($input: EmissionInput!) {
  getEmission(input: $input) {
    ...EmissionFields
  }
}
    ${EmissionFieldsFragmentDoc}`;

/**
 * __useEmissionsQuery__
 *
 * To run a query within a React component, call `useEmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmissionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmissionsQuery(baseOptions: Apollo.QueryHookOptions<EmissionsQuery, EmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmissionsQuery, EmissionsQueryVariables>(EmissionsDocument, options);
      }
export function useEmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmissionsQuery, EmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmissionsQuery, EmissionsQueryVariables>(EmissionsDocument, options);
        }
export type EmissionsQueryHookResult = ReturnType<typeof useEmissionsQuery>;
export type EmissionsLazyQueryHookResult = ReturnType<typeof useEmissionsLazyQuery>;
export type EmissionsQueryResult = Apollo.QueryResult<EmissionsQuery, EmissionsQueryVariables>;
export const EstimateMeasureEffectDocument = gql`
    query EstimateMeasureEffect($input: EstimateMeasureEffectInput!) {
  estimateMeasureEffect(input: $input) {
    measureResults {
      measureId
      categoryId
      name
      cost
      effect
    }
    before {
      CO2e
      categoryId
    }
    after {
      CO2e
      categoryId
    }
  }
}
    `;

/**
 * __useEstimateMeasureEffectQuery__
 *
 * To run a query within a React component, call `useEstimateMeasureEffectQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimateMeasureEffectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimateMeasureEffectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEstimateMeasureEffectQuery(baseOptions: Apollo.QueryHookOptions<EstimateMeasureEffectQuery, EstimateMeasureEffectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EstimateMeasureEffectQuery, EstimateMeasureEffectQueryVariables>(EstimateMeasureEffectDocument, options);
      }
export function useEstimateMeasureEffectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EstimateMeasureEffectQuery, EstimateMeasureEffectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EstimateMeasureEffectQuery, EstimateMeasureEffectQueryVariables>(EstimateMeasureEffectDocument, options);
        }
export type EstimateMeasureEffectQueryHookResult = ReturnType<typeof useEstimateMeasureEffectQuery>;
export type EstimateMeasureEffectLazyQueryHookResult = ReturnType<typeof useEstimateMeasureEffectLazyQuery>;
export type EstimateMeasureEffectQueryResult = Apollo.QueryResult<EstimateMeasureEffectQuery, EstimateMeasureEffectQueryVariables>;
export const GetUploadUrlDocument = gql`
    query GetUploadUrl($fileName: String!, $fileType: String!) {
  getUploadUrl(fileName: $fileName, fileType: $fileType)
}
    `;

/**
 * __useGetUploadUrlQuery__
 *
 * To run a query within a React component, call `useGetUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadUrlQuery({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      fileType: // value for 'fileType'
 *   },
 * });
 */
export function useGetUploadUrlQuery(baseOptions: Apollo.QueryHookOptions<GetUploadUrlQuery, GetUploadUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUploadUrlQuery, GetUploadUrlQueryVariables>(GetUploadUrlDocument, options);
      }
export function useGetUploadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUploadUrlQuery, GetUploadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUploadUrlQuery, GetUploadUrlQueryVariables>(GetUploadUrlDocument, options);
        }
export type GetUploadUrlQueryHookResult = ReturnType<typeof useGetUploadUrlQuery>;
export type GetUploadUrlLazyQueryHookResult = ReturnType<typeof useGetUploadUrlLazyQuery>;
export type GetUploadUrlQueryResult = Apollo.QueryResult<GetUploadUrlQuery, GetUploadUrlQueryVariables>;
export const GetCategoryMeasureMetaDataDocument = gql`
    query GetCategoryMeasureMetaData($categoryId: String!, $percentage: Float!, $orgId: String) {
  getCategoryMeasureMetaData(
    categoryId: $categoryId
    percentage: $percentage
    orgId: $orgId
  ) {
    categoryId
    effect
    cost
    imgSrc
    description
    type
  }
}
    `;

/**
 * __useGetCategoryMeasureMetaDataQuery__
 *
 * To run a query within a React component, call `useGetCategoryMeasureMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryMeasureMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryMeasureMetaDataQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      percentage: // value for 'percentage'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetCategoryMeasureMetaDataQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryMeasureMetaDataQuery, GetCategoryMeasureMetaDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryMeasureMetaDataQuery, GetCategoryMeasureMetaDataQueryVariables>(GetCategoryMeasureMetaDataDocument, options);
      }
export function useGetCategoryMeasureMetaDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryMeasureMetaDataQuery, GetCategoryMeasureMetaDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryMeasureMetaDataQuery, GetCategoryMeasureMetaDataQueryVariables>(GetCategoryMeasureMetaDataDocument, options);
        }
export type GetCategoryMeasureMetaDataQueryHookResult = ReturnType<typeof useGetCategoryMeasureMetaDataQuery>;
export type GetCategoryMeasureMetaDataLazyQueryHookResult = ReturnType<typeof useGetCategoryMeasureMetaDataLazyQuery>;
export type GetCategoryMeasureMetaDataQueryResult = Apollo.QueryResult<GetCategoryMeasureMetaDataQuery, GetCategoryMeasureMetaDataQueryVariables>;
export const GetCategoryTrendsDocument = gql`
    query GetCategoryTrends($filter: FilterInput!) {
  getCategoryTrends(filter: $filter) {
    emissions {
      ...EmissionFields
    }
    percentage
    categoryId
    categoryName
    categoryColor
  }
}
    ${EmissionFieldsFragmentDoc}`;

/**
 * __useGetCategoryTrendsQuery__
 *
 * To run a query within a React component, call `useGetCategoryTrendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryTrendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryTrendsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCategoryTrendsQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryTrendsQuery, GetCategoryTrendsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryTrendsQuery, GetCategoryTrendsQueryVariables>(GetCategoryTrendsDocument, options);
      }
export function useGetCategoryTrendsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryTrendsQuery, GetCategoryTrendsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryTrendsQuery, GetCategoryTrendsQueryVariables>(GetCategoryTrendsDocument, options);
        }
export type GetCategoryTrendsQueryHookResult = ReturnType<typeof useGetCategoryTrendsQuery>;
export type GetCategoryTrendsLazyQueryHookResult = ReturnType<typeof useGetCategoryTrendsLazyQuery>;
export type GetCategoryTrendsQueryResult = Apollo.QueryResult<GetCategoryTrendsQuery, GetCategoryTrendsQueryVariables>;
export const CompareOrgDocument = gql`
    query CompareOrg($filter: FilterInput!) {
  compareOrg(filter: $filter) {
    label
    CO2e
    functionKey
    tooltip
  }
}
    `;

/**
 * __useCompareOrgQuery__
 *
 * To run a query within a React component, call `useCompareOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompareOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompareOrgQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompareOrgQuery(baseOptions: Apollo.QueryHookOptions<CompareOrgQuery, CompareOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompareOrgQuery, CompareOrgQueryVariables>(CompareOrgDocument, options);
      }
export function useCompareOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompareOrgQuery, CompareOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompareOrgQuery, CompareOrgQueryVariables>(CompareOrgDocument, options);
        }
export type CompareOrgQueryHookResult = ReturnType<typeof useCompareOrgQuery>;
export type CompareOrgLazyQueryHookResult = ReturnType<typeof useCompareOrgLazyQuery>;
export type CompareOrgQueryResult = Apollo.QueryResult<CompareOrgQuery, CompareOrgQueryVariables>;
export const GetCustomMeasuresDocument = gql`
    query GetCustomMeasures($orgId: String) {
  getCustomMeasures(orgId: $orgId) {
    id
    categoryId
    name
  }
}
    `;

/**
 * __useGetCustomMeasuresQuery__
 *
 * To run a query within a React component, call `useGetCustomMeasuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomMeasuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomMeasuresQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetCustomMeasuresQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomMeasuresQuery, GetCustomMeasuresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomMeasuresQuery, GetCustomMeasuresQueryVariables>(GetCustomMeasuresDocument, options);
      }
export function useGetCustomMeasuresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomMeasuresQuery, GetCustomMeasuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomMeasuresQuery, GetCustomMeasuresQueryVariables>(GetCustomMeasuresDocument, options);
        }
export type GetCustomMeasuresQueryHookResult = ReturnType<typeof useGetCustomMeasuresQuery>;
export type GetCustomMeasuresLazyQueryHookResult = ReturnType<typeof useGetCustomMeasuresLazyQuery>;
export type GetCustomMeasuresQueryResult = Apollo.QueryResult<GetCustomMeasuresQuery, GetCustomMeasuresQueryVariables>;
export const GetEmissionRangeDocument = gql`
    query GetEmissionRange {
  getEmissionRange {
    from
    to
  }
}
    `;

/**
 * __useGetEmissionRangeQuery__
 *
 * To run a query within a React component, call `useGetEmissionRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmissionRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmissionRangeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmissionRangeQuery(baseOptions?: Apollo.QueryHookOptions<GetEmissionRangeQuery, GetEmissionRangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmissionRangeQuery, GetEmissionRangeQueryVariables>(GetEmissionRangeDocument, options);
      }
export function useGetEmissionRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmissionRangeQuery, GetEmissionRangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmissionRangeQuery, GetEmissionRangeQueryVariables>(GetEmissionRangeDocument, options);
        }
export type GetEmissionRangeQueryHookResult = ReturnType<typeof useGetEmissionRangeQuery>;
export type GetEmissionRangeLazyQueryHookResult = ReturnType<typeof useGetEmissionRangeLazyQuery>;
export type GetEmissionRangeQueryResult = Apollo.QueryResult<GetEmissionRangeQuery, GetEmissionRangeQueryVariables>;
export const GetFuelTextDocument = gql`
    query GetFuelText {
  getFuelText {
    key
    value
  }
}
    `;

/**
 * __useGetFuelTextQuery__
 *
 * To run a query within a React component, call `useGetFuelTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFuelTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFuelTextQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFuelTextQuery(baseOptions?: Apollo.QueryHookOptions<GetFuelTextQuery, GetFuelTextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFuelTextQuery, GetFuelTextQueryVariables>(GetFuelTextDocument, options);
      }
export function useGetFuelTextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFuelTextQuery, GetFuelTextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFuelTextQuery, GetFuelTextQueryVariables>(GetFuelTextDocument, options);
        }
export type GetFuelTextQueryHookResult = ReturnType<typeof useGetFuelTextQuery>;
export type GetFuelTextLazyQueryHookResult = ReturnType<typeof useGetFuelTextLazyQuery>;
export type GetFuelTextQueryResult = Apollo.QueryResult<GetFuelTextQuery, GetFuelTextQueryVariables>;
export const GetHeatSystemTextDocument = gql`
    query GetHeatSystemText {
  getHeatSystemText {
    key
    value
  }
}
    `;

/**
 * __useGetHeatSystemTextQuery__
 *
 * To run a query within a React component, call `useGetHeatSystemTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeatSystemTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeatSystemTextQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHeatSystemTextQuery(baseOptions?: Apollo.QueryHookOptions<GetHeatSystemTextQuery, GetHeatSystemTextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHeatSystemTextQuery, GetHeatSystemTextQueryVariables>(GetHeatSystemTextDocument, options);
      }
export function useGetHeatSystemTextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHeatSystemTextQuery, GetHeatSystemTextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHeatSystemTextQuery, GetHeatSystemTextQueryVariables>(GetHeatSystemTextDocument, options);
        }
export type GetHeatSystemTextQueryHookResult = ReturnType<typeof useGetHeatSystemTextQuery>;
export type GetHeatSystemTextLazyQueryHookResult = ReturnType<typeof useGetHeatSystemTextLazyQuery>;
export type GetHeatSystemTextQueryResult = Apollo.QueryResult<GetHeatSystemTextQuery, GetHeatSystemTextQueryVariables>;
export const GetIndustrySectorsDocument = gql`
    query GetIndustrySectors {
  getIndustrySectors {
    id
    label
  }
}
    `;

/**
 * __useGetIndustrySectorsQuery__
 *
 * To run a query within a React component, call `useGetIndustrySectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIndustrySectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIndustrySectorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIndustrySectorsQuery(baseOptions?: Apollo.QueryHookOptions<GetIndustrySectorsQuery, GetIndustrySectorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIndustrySectorsQuery, GetIndustrySectorsQueryVariables>(GetIndustrySectorsDocument, options);
      }
export function useGetIndustrySectorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIndustrySectorsQuery, GetIndustrySectorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIndustrySectorsQuery, GetIndustrySectorsQueryVariables>(GetIndustrySectorsDocument, options);
        }
export type GetIndustrySectorsQueryHookResult = ReturnType<typeof useGetIndustrySectorsQuery>;
export type GetIndustrySectorsLazyQueryHookResult = ReturnType<typeof useGetIndustrySectorsLazyQuery>;
export type GetIndustrySectorsQueryResult = Apollo.QueryResult<GetIndustrySectorsQuery, GetIndustrySectorsQueryVariables>;
export const GetMeasureMetaDataDocument = gql`
    query GetMeasureMetaData($measureId: String!, $percentage: Float!, $orgId: String) {
  getMeasureMetaData(
    measureId: $measureId
    percentage: $percentage
    orgId: $orgId
  ) {
    categoryId
    effect
    cost
    imgSrc
    description
    type
  }
}
    `;

/**
 * __useGetMeasureMetaDataQuery__
 *
 * To run a query within a React component, call `useGetMeasureMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasureMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasureMetaDataQuery({
 *   variables: {
 *      measureId: // value for 'measureId'
 *      percentage: // value for 'percentage'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetMeasureMetaDataQuery(baseOptions: Apollo.QueryHookOptions<GetMeasureMetaDataQuery, GetMeasureMetaDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeasureMetaDataQuery, GetMeasureMetaDataQueryVariables>(GetMeasureMetaDataDocument, options);
      }
export function useGetMeasureMetaDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeasureMetaDataQuery, GetMeasureMetaDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeasureMetaDataQuery, GetMeasureMetaDataQueryVariables>(GetMeasureMetaDataDocument, options);
        }
export type GetMeasureMetaDataQueryHookResult = ReturnType<typeof useGetMeasureMetaDataQuery>;
export type GetMeasureMetaDataLazyQueryHookResult = ReturnType<typeof useGetMeasureMetaDataLazyQuery>;
export type GetMeasureMetaDataQueryResult = Apollo.QueryResult<GetMeasureMetaDataQuery, GetMeasureMetaDataQueryVariables>;
export const EmissionMetaDataDocument = gql`
    query EmissionMetaData($filter: FilterInput!) {
  getMetaData(filter: $filter) {
    totalEmission
    yearlyPercentage
    worstCategory {
      percentage
      diff
      categoryId
    }
    bestCategory {
      percentage
      diff
      categoryId
    }
    emissionPerAnnualWorkForce
  }
}
    `;

/**
 * __useEmissionMetaDataQuery__
 *
 * To run a query within a React component, call `useEmissionMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmissionMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmissionMetaDataQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEmissionMetaDataQuery(baseOptions: Apollo.QueryHookOptions<EmissionMetaDataQuery, EmissionMetaDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmissionMetaDataQuery, EmissionMetaDataQueryVariables>(EmissionMetaDataDocument, options);
      }
export function useEmissionMetaDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmissionMetaDataQuery, EmissionMetaDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmissionMetaDataQuery, EmissionMetaDataQueryVariables>(EmissionMetaDataDocument, options);
        }
export type EmissionMetaDataQueryHookResult = ReturnType<typeof useEmissionMetaDataQuery>;
export type EmissionMetaDataLazyQueryHookResult = ReturnType<typeof useEmissionMetaDataLazyQuery>;
export type EmissionMetaDataQueryResult = Apollo.QueryResult<EmissionMetaDataQuery, EmissionMetaDataQueryVariables>;
export const GetOrganisationProfileDocument = gql`
    query GetOrganisationProfile {
  getOrganisationProfile {
    organisationId
    nbEmployees
  }
}
    `;

/**
 * __useGetOrganisationProfileQuery__
 *
 * To run a query within a React component, call `useGetOrganisationProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganisationProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganisationProfileQuery, GetOrganisationProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganisationProfileQuery, GetOrganisationProfileQueryVariables>(GetOrganisationProfileDocument, options);
      }
export function useGetOrganisationProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationProfileQuery, GetOrganisationProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganisationProfileQuery, GetOrganisationProfileQueryVariables>(GetOrganisationProfileDocument, options);
        }
export type GetOrganisationProfileQueryHookResult = ReturnType<typeof useGetOrganisationProfileQuery>;
export type GetOrganisationProfileLazyQueryHookResult = ReturnType<typeof useGetOrganisationProfileLazyQuery>;
export type GetOrganisationProfileQueryResult = Apollo.QueryResult<GetOrganisationProfileQuery, GetOrganisationProfileQueryVariables>;
export const GetPremisesDocument = gql`
    query GetPremises {
  getPremises {
    id
    orgId
    address
    city
    area
    heatingSystem
  }
}
    `;

/**
 * __useGetPremisesQuery__
 *
 * To run a query within a React component, call `useGetPremisesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPremisesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPremisesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPremisesQuery(baseOptions?: Apollo.QueryHookOptions<GetPremisesQuery, GetPremisesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPremisesQuery, GetPremisesQueryVariables>(GetPremisesDocument, options);
      }
export function useGetPremisesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPremisesQuery, GetPremisesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPremisesQuery, GetPremisesQueryVariables>(GetPremisesDocument, options);
        }
export type GetPremisesQueryHookResult = ReturnType<typeof useGetPremisesQuery>;
export type GetPremisesLazyQueryHookResult = ReturnType<typeof useGetPremisesLazyQuery>;
export type GetPremisesQueryResult = Apollo.QueryResult<GetPremisesQuery, GetPremisesQueryVariables>;
export const GetRegistrationsDocument = gql`
    query GetRegistrations {
  getRegistrations {
    id
    orgNumber
    name
    email
    bookingSystem
    nbEmployees
    industrialSectorId
    approved
  }
}
    `;

/**
 * __useGetRegistrationsQuery__
 *
 * To run a query within a React component, call `useGetRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRegistrationsQuery(baseOptions?: Apollo.QueryHookOptions<GetRegistrationsQuery, GetRegistrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegistrationsQuery, GetRegistrationsQueryVariables>(GetRegistrationsDocument, options);
      }
export function useGetRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegistrationsQuery, GetRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegistrationsQuery, GetRegistrationsQueryVariables>(GetRegistrationsDocument, options);
        }
export type GetRegistrationsQueryHookResult = ReturnType<typeof useGetRegistrationsQuery>;
export type GetRegistrationsLazyQueryHookResult = ReturnType<typeof useGetRegistrationsLazyQuery>;
export type GetRegistrationsQueryResult = Apollo.QueryResult<GetRegistrationsQuery, GetRegistrationsQueryVariables>;
export const GetResultVersionDocument = gql`
    query GetResultVersion {
  getResultVersion {
    timestamp
  }
}
    `;

/**
 * __useGetResultVersionQuery__
 *
 * To run a query within a React component, call `useGetResultVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResultVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResultVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetResultVersionQuery(baseOptions?: Apollo.QueryHookOptions<GetResultVersionQuery, GetResultVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResultVersionQuery, GetResultVersionQueryVariables>(GetResultVersionDocument, options);
      }
export function useGetResultVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResultVersionQuery, GetResultVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResultVersionQuery, GetResultVersionQueryVariables>(GetResultVersionDocument, options);
        }
export type GetResultVersionQueryHookResult = ReturnType<typeof useGetResultVersionQuery>;
export type GetResultVersionLazyQueryHookResult = ReturnType<typeof useGetResultVersionLazyQuery>;
export type GetResultVersionQueryResult = Apollo.QueryResult<GetResultVersionQuery, GetResultVersionQueryVariables>;
export const GetTopListDocument = gql`
    query GetTopList($input: EmissionInput!) {
  getTopList(input: $input) {
    parentName
    totalCO2e
    name
    orgUnitId
  }
}
    `;

/**
 * __useGetTopListQuery__
 *
 * To run a query within a React component, call `useGetTopListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTopListQuery(baseOptions: Apollo.QueryHookOptions<GetTopListQuery, GetTopListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopListQuery, GetTopListQueryVariables>(GetTopListDocument, options);
      }
export function useGetTopListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopListQuery, GetTopListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopListQuery, GetTopListQueryVariables>(GetTopListDocument, options);
        }
export type GetTopListQueryHookResult = ReturnType<typeof useGetTopListQuery>;
export type GetTopListLazyQueryHookResult = ReturnType<typeof useGetTopListLazyQuery>;
export type GetTopListQueryResult = Apollo.QueryResult<GetTopListQuery, GetTopListQueryVariables>;
export const GetTrendDocument = gql`
    query GetTrend($filter: FilterInput!, $period: Period!) {
  getTrend(filter: $filter, period: $period) {
    emissions {
      ...EmissionFields
    }
    trendOffset
    trendRate
    prognosis
    trendPercentage
  }
}
    ${EmissionFieldsFragmentDoc}`;

/**
 * __useGetTrendQuery__
 *
 * To run a query within a React component, call `useGetTrendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrendQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useGetTrendQuery(baseOptions: Apollo.QueryHookOptions<GetTrendQuery, GetTrendQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrendQuery, GetTrendQueryVariables>(GetTrendDocument, options);
      }
export function useGetTrendLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrendQuery, GetTrendQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrendQuery, GetTrendQueryVariables>(GetTrendDocument, options);
        }
export type GetTrendQueryHookResult = ReturnType<typeof useGetTrendQuery>;
export type GetTrendLazyQueryHookResult = ReturnType<typeof useGetTrendLazyQuery>;
export type GetTrendQueryResult = Apollo.QueryResult<GetTrendQuery, GetTrendQueryVariables>;
export const GetVehicleInfoDocument = gql`
    query GetVehicleInfo($regNumber: String!) {
  getVehicleInfo(regNumber: $regNumber) {
    orgId
    regNumber
    primaryFuel
    secondaryFuel
    consumption
    mileage
  }
}
    `;

/**
 * __useGetVehicleInfoQuery__
 *
 * To run a query within a React component, call `useGetVehicleInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleInfoQuery({
 *   variables: {
 *      regNumber: // value for 'regNumber'
 *   },
 * });
 */
export function useGetVehicleInfoQuery(baseOptions: Apollo.QueryHookOptions<GetVehicleInfoQuery, GetVehicleInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehicleInfoQuery, GetVehicleInfoQueryVariables>(GetVehicleInfoDocument, options);
      }
export function useGetVehicleInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleInfoQuery, GetVehicleInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehicleInfoQuery, GetVehicleInfoQueryVariables>(GetVehicleInfoDocument, options);
        }
export type GetVehicleInfoQueryHookResult = ReturnType<typeof useGetVehicleInfoQuery>;
export type GetVehicleInfoLazyQueryHookResult = ReturnType<typeof useGetVehicleInfoLazyQuery>;
export type GetVehicleInfoQueryResult = Apollo.QueryResult<GetVehicleInfoQuery, GetVehicleInfoQueryVariables>;
export const GetVehiclesDocument = gql`
    query GetVehicles {
  getVehicles {
    orgId
    regNumber
    primaryFuel
    secondaryFuel
    consumption
    mileage
  }
}
    `;

/**
 * __useGetVehiclesQuery__
 *
 * To run a query within a React component, call `useGetVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehiclesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVehiclesQuery(baseOptions?: Apollo.QueryHookOptions<GetVehiclesQuery, GetVehiclesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehiclesQuery, GetVehiclesQueryVariables>(GetVehiclesDocument, options);
      }
export function useGetVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehiclesQuery, GetVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehiclesQuery, GetVehiclesQueryVariables>(GetVehiclesDocument, options);
        }
export type GetVehiclesQueryHookResult = ReturnType<typeof useGetVehiclesQuery>;
export type GetVehiclesLazyQueryHookResult = ReturnType<typeof useGetVehiclesLazyQuery>;
export type GetVehiclesQueryResult = Apollo.QueryResult<GetVehiclesQuery, GetVehiclesQueryVariables>;
export const GetOrgGoalDocument = gql`
    query GetOrgGoal {
  getOrgGoal {
    referenceYear
    targetYear
    targetFactor
    curveType
    curve {
      year
      targetFactorRelReferenceYear
    }
  }
}
    `;

/**
 * __useGetOrgGoalQuery__
 *
 * To run a query within a React component, call `useGetOrgGoalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgGoalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgGoalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrgGoalQuery(baseOptions?: Apollo.QueryHookOptions<GetOrgGoalQuery, GetOrgGoalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrgGoalQuery, GetOrgGoalQueryVariables>(GetOrgGoalDocument, options);
      }
export function useGetOrgGoalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgGoalQuery, GetOrgGoalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrgGoalQuery, GetOrgGoalQueryVariables>(GetOrgGoalDocument, options);
        }
export type GetOrgGoalQueryHookResult = ReturnType<typeof useGetOrgGoalQuery>;
export type GetOrgGoalLazyQueryHookResult = ReturnType<typeof useGetOrgGoalLazyQuery>;
export type GetOrgGoalQueryResult = Apollo.QueryResult<GetOrgGoalQuery, GetOrgGoalQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...RegularUser
  }
}
    ${RegularUserFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const OrganisationsDocument = gql`
    query Organisations {
  organisations {
    ...Organisation
  }
}
    ${OrganisationFragmentDoc}`;

/**
 * __useOrganisationsQuery__
 *
 * To run a query within a React component, call `useOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganisationsQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationsQuery, OrganisationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationsQuery, OrganisationsQueryVariables>(OrganisationsDocument, options);
      }
export function useOrganisationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationsQuery, OrganisationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationsQuery, OrganisationsQueryVariables>(OrganisationsDocument, options);
        }
export type OrganisationsQueryHookResult = ReturnType<typeof useOrganisationsQuery>;
export type OrganisationsLazyQueryHookResult = ReturnType<typeof useOrganisationsLazyQuery>;
export type OrganisationsQueryResult = Apollo.QueryResult<OrganisationsQuery, OrganisationsQueryVariables>;
export const GetRecatRulesDocument = gql`
    query GetRecatRules {
  getRecatRules {
    ...RecatRuleInfo
  }
}
    ${RecatRuleInfoFragmentDoc}`;

/**
 * __useGetRecatRulesQuery__
 *
 * To run a query within a React component, call `useGetRecatRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecatRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecatRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecatRulesQuery(baseOptions?: Apollo.QueryHookOptions<GetRecatRulesQuery, GetRecatRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecatRulesQuery, GetRecatRulesQueryVariables>(GetRecatRulesDocument, options);
      }
export function useGetRecatRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecatRulesQuery, GetRecatRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecatRulesQuery, GetRecatRulesQueryVariables>(GetRecatRulesDocument, options);
        }
export type GetRecatRulesQueryHookResult = ReturnType<typeof useGetRecatRulesQuery>;
export type GetRecatRulesLazyQueryHookResult = ReturnType<typeof useGetRecatRulesLazyQuery>;
export type GetRecatRulesQueryResult = Apollo.QueryResult<GetRecatRulesQuery, GetRecatRulesQueryVariables>;
export const SearchTransactionsDocument = gql`
    query SearchTransactions($searchCriteria: [TransactionSearchCriterion!]!, $page: Int, $pageSize: Int) {
  searchTransactions(
    searchCriteria: $searchCriteria
    page: $page
    pageSize: $pageSize
  ) {
    transactions {
      ...TransactionWithAttachedRule
    }
    numRows
  }
}
    ${TransactionWithAttachedRuleFragmentDoc}`;

/**
 * __useSearchTransactionsQuery__
 *
 * To run a query within a React component, call `useSearchTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTransactionsQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useSearchTransactionsQuery(baseOptions: Apollo.QueryHookOptions<SearchTransactionsQuery, SearchTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchTransactionsQuery, SearchTransactionsQueryVariables>(SearchTransactionsDocument, options);
      }
export function useSearchTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchTransactionsQuery, SearchTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchTransactionsQuery, SearchTransactionsQueryVariables>(SearchTransactionsDocument, options);
        }
export type SearchTransactionsQueryHookResult = ReturnType<typeof useSearchTransactionsQuery>;
export type SearchTransactionsLazyQueryHookResult = ReturnType<typeof useSearchTransactionsLazyQuery>;
export type SearchTransactionsQueryResult = Apollo.QueryResult<SearchTransactionsQuery, SearchTransactionsQueryVariables>;
export const GetSuppliersDocument = gql`
    query GetSuppliers($searchCriteria: [TransactionSearchCriterion!]!) {
  getSuppliers(searchCriteria: $searchCriteria) {
    ...SupplierInfo
  }
}
    ${SupplierInfoFragmentDoc}`;

/**
 * __useGetSuppliersQuery__
 *
 * To run a query within a React component, call `useGetSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuppliersQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetSuppliersQuery(baseOptions: Apollo.QueryHookOptions<GetSuppliersQuery, GetSuppliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSuppliersQuery, GetSuppliersQueryVariables>(GetSuppliersDocument, options);
      }
export function useGetSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSuppliersQuery, GetSuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSuppliersQuery, GetSuppliersQueryVariables>(GetSuppliersDocument, options);
        }
export type GetSuppliersQueryHookResult = ReturnType<typeof useGetSuppliersQuery>;
export type GetSuppliersLazyQueryHookResult = ReturnType<typeof useGetSuppliersLazyQuery>;
export type GetSuppliersQueryResult = Apollo.QueryResult<GetSuppliersQuery, GetSuppliersQueryVariables>;
export const GetAccountsDocument = gql`
    query GetAccounts($searchCriteria: [TransactionSearchCriterion!]!) {
  getAccounts(searchCriteria: $searchCriteria) {
    ...AccountInfo
  }
}
    ${AccountInfoFragmentDoc}`;

/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetAccountsQuery(baseOptions: Apollo.QueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options);
      }
export function useGetAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options);
        }
export type GetAccountsQueryHookResult = ReturnType<typeof useGetAccountsQuery>;
export type GetAccountsLazyQueryHookResult = ReturnType<typeof useGetAccountsLazyQuery>;
export type GetAccountsQueryResult = Apollo.QueryResult<GetAccountsQuery, GetAccountsQueryVariables>;
export const ContainsNotRecatableDocument = gql`
    query containsNotRecatable($searchCriteria: [TransactionSearchCriterion!]!) {
  containsNotRecatable(searchCriteria: $searchCriteria)
}
    `;

/**
 * __useContainsNotRecatableQuery__
 *
 * To run a query within a React component, call `useContainsNotRecatableQuery` and pass it any options that fit your needs.
 * When your component renders, `useContainsNotRecatableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContainsNotRecatableQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useContainsNotRecatableQuery(baseOptions: Apollo.QueryHookOptions<ContainsNotRecatableQuery, ContainsNotRecatableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContainsNotRecatableQuery, ContainsNotRecatableQueryVariables>(ContainsNotRecatableDocument, options);
      }
export function useContainsNotRecatableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContainsNotRecatableQuery, ContainsNotRecatableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContainsNotRecatableQuery, ContainsNotRecatableQueryVariables>(ContainsNotRecatableDocument, options);
        }
export type ContainsNotRecatableQueryHookResult = ReturnType<typeof useContainsNotRecatableQuery>;
export type ContainsNotRecatableLazyQueryHookResult = ReturnType<typeof useContainsNotRecatableLazyQuery>;
export type ContainsNotRecatableQueryResult = Apollo.QueryResult<ContainsNotRecatableQuery, ContainsNotRecatableQueryVariables>;
export const HasTransactionsDocument = gql`
    query hasTransactions {
  hasTransactions
}
    `;

/**
 * __useHasTransactionsQuery__
 *
 * To run a query within a React component, call `useHasTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasTransactionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<HasTransactionsQuery, HasTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasTransactionsQuery, HasTransactionsQueryVariables>(HasTransactionsDocument, options);
      }
export function useHasTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasTransactionsQuery, HasTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasTransactionsQuery, HasTransactionsQueryVariables>(HasTransactionsDocument, options);
        }
export type HasTransactionsQueryHookResult = ReturnType<typeof useHasTransactionsQuery>;
export type HasTransactionsLazyQueryHookResult = ReturnType<typeof useHasTransactionsLazyQuery>;
export type HasTransactionsQueryResult = Apollo.QueryResult<HasTransactionsQuery, HasTransactionsQueryVariables>;
export const GetFirstLastDateDocument = gql`
    query getFirstLastDate($searchCriteria: [TransactionSearchCriterion!]!) {
  getFirstLastDate(searchCriteria: $searchCriteria) {
    first
    last
  }
}
    `;

/**
 * __useGetFirstLastDateQuery__
 *
 * To run a query within a React component, call `useGetFirstLastDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirstLastDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirstLastDateQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetFirstLastDateQuery(baseOptions: Apollo.QueryHookOptions<GetFirstLastDateQuery, GetFirstLastDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFirstLastDateQuery, GetFirstLastDateQueryVariables>(GetFirstLastDateDocument, options);
      }
export function useGetFirstLastDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFirstLastDateQuery, GetFirstLastDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFirstLastDateQuery, GetFirstLastDateQueryVariables>(GetFirstLastDateDocument, options);
        }
export type GetFirstLastDateQueryHookResult = ReturnType<typeof useGetFirstLastDateQuery>;
export type GetFirstLastDateLazyQueryHookResult = ReturnType<typeof useGetFirstLastDateLazyQuery>;
export type GetFirstLastDateQueryResult = Apollo.QueryResult<GetFirstLastDateQuery, GetFirstLastDateQueryVariables>;
export const GetTotalKrDocument = gql`
    query getTotalKr($searchCriteria: [TransactionSearchCriterion!]!) {
  getTotalKr(searchCriteria: $searchCriteria)
}
    `;

/**
 * __useGetTotalKrQuery__
 *
 * To run a query within a React component, call `useGetTotalKrQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalKrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalKrQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetTotalKrQuery(baseOptions: Apollo.QueryHookOptions<GetTotalKrQuery, GetTotalKrQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTotalKrQuery, GetTotalKrQueryVariables>(GetTotalKrDocument, options);
      }
export function useGetTotalKrLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTotalKrQuery, GetTotalKrQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTotalKrQuery, GetTotalKrQueryVariables>(GetTotalKrDocument, options);
        }
export type GetTotalKrQueryHookResult = ReturnType<typeof useGetTotalKrQuery>;
export type GetTotalKrLazyQueryHookResult = ReturnType<typeof useGetTotalKrLazyQuery>;
export type GetTotalKrQueryResult = Apollo.QueryResult<GetTotalKrQuery, GetTotalKrQueryVariables>;
export const ContainsRuleOverlapDocument = gql`
    query containsRuleOverlap($searchCriteria: [TransactionSearchCriterion!]!) {
  containsRuleOverlap(searchCriteria: $searchCriteria)
}
    `;

/**
 * __useContainsRuleOverlapQuery__
 *
 * To run a query within a React component, call `useContainsRuleOverlapQuery` and pass it any options that fit your needs.
 * When your component renders, `useContainsRuleOverlapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContainsRuleOverlapQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useContainsRuleOverlapQuery(baseOptions: Apollo.QueryHookOptions<ContainsRuleOverlapQuery, ContainsRuleOverlapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContainsRuleOverlapQuery, ContainsRuleOverlapQueryVariables>(ContainsRuleOverlapDocument, options);
      }
export function useContainsRuleOverlapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContainsRuleOverlapQuery, ContainsRuleOverlapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContainsRuleOverlapQuery, ContainsRuleOverlapQueryVariables>(ContainsRuleOverlapDocument, options);
        }
export type ContainsRuleOverlapQueryHookResult = ReturnType<typeof useContainsRuleOverlapQuery>;
export type ContainsRuleOverlapLazyQueryHookResult = ReturnType<typeof useContainsRuleOverlapLazyQuery>;
export type ContainsRuleOverlapQueryResult = Apollo.QueryResult<ContainsRuleOverlapQuery, ContainsRuleOverlapQueryVariables>;
export const GetTransactionsCategoriesDocument = gql`
    query getTransactionsCategories($searchCriteria: [TransactionSearchCriterion!]!) {
  getTransactionsCategories(searchCriteria: $searchCriteria) {
    ...Category
  }
}
    ${CategoryFragmentDoc}`;

/**
 * __useGetTransactionsCategoriesQuery__
 *
 * To run a query within a React component, call `useGetTransactionsCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsCategoriesQuery({
 *   variables: {
 *      searchCriteria: // value for 'searchCriteria'
 *   },
 * });
 */
export function useGetTransactionsCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetTransactionsCategoriesQuery, GetTransactionsCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionsCategoriesQuery, GetTransactionsCategoriesQueryVariables>(GetTransactionsCategoriesDocument, options);
      }
export function useGetTransactionsCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionsCategoriesQuery, GetTransactionsCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionsCategoriesQuery, GetTransactionsCategoriesQueryVariables>(GetTransactionsCategoriesDocument, options);
        }
export type GetTransactionsCategoriesQueryHookResult = ReturnType<typeof useGetTransactionsCategoriesQuery>;
export type GetTransactionsCategoriesLazyQueryHookResult = ReturnType<typeof useGetTransactionsCategoriesLazyQuery>;
export type GetTransactionsCategoriesQueryResult = Apollo.QueryResult<GetTransactionsCategoriesQuery, GetTransactionsCategoriesQueryVariables>;
export const UsersDocument = gql`
    query Users($allOrgs: Boolean) {
  users(allOrgs: $allOrgs) {
    id
    firstName
    lastName
    username
    organisation {
      id
      name
    }
    role
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      allOrgs: // value for 'allOrgs'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;