import React, { useCallback, useState } from 'react'
import { Layout } from '../../components/Layout'
import { SideColumn } from '../../components/TableOfContent'
import { LayoutContent } from '../../components/LayoutContent'
import { useOrganisationsQuery } from '../../graphql/generated'
import { TableOfContentButton } from '../../components/Buttons'
import { SectionId, SysadminSectionId } from '../emission/Section'
import { Organisations } from './sysadminSections/Organisations'
import { SSO } from './sysadminSections/SSO'
import { Users } from './sysadminSections/Users'
import { Registrations } from './sysadminSections/Registrations'

type SectionDef = {
  text: string
  sectionId: SysadminSectionId
  section: React.JSX.Element
}

export function Sysadmin(): React.JSX.Element {
  const [active, setActiveSessionId] = useState<{
    scroll: boolean
    sectionId: SectionId
  }>({ scroll: false, sectionId: SysadminSectionId.users })

  const { data: orgData } = useOrganisationsQuery()

  const handleSectionVisibilityChange = useCallback(
    (sectionId: SectionId, isVisible: boolean) => {
      if (isVisible) {
        setActiveSessionId({ scroll: false, sectionId })
      }
    },
    [setActiveSessionId],
  )

  const tableOfContent: Array<SectionDef> = []

  tableOfContent.push({
    text: 'Registrations',
    sectionId: SysadminSectionId.registrations,
    section: (
      <Registrations
        active={active}
        handleSectionVisibilityChange={handleSectionVisibilityChange}
        key='registration-box'
      />
    ),
  })

  tableOfContent.push({
    text: 'Organisations',
    sectionId: SysadminSectionId.organisations,
    section: (
      <Organisations
        active={active}
        handleSectionVisibilityChange={handleSectionVisibilityChange}
        orgData={orgData}
        key='organisation-box'
      />
    ),
  })

  tableOfContent.push({
    text: 'Users',
    sectionId: SysadminSectionId.users,
    section: (
      <Users
        active={active}
        handleSectionVisibilityChange={handleSectionVisibilityChange}
        key='user-box'
      />
    ),
  })

  tableOfContent.push({
    text: 'SSO',
    sectionId: SysadminSectionId.sso,
    section: (
      <SSO
        active={active}
        handleSectionVisibilityChange={handleSectionVisibilityChange}
        orgData={orgData}
        key='sso-box'
      />
    ),
  })

  return (
    <Layout title='Sysadmin'>
      <SideColumn>
        {tableOfContent.map((c) => {
          if (c) {
            return (
              <TableOfContentButton
                isActive={c.sectionId === active.sectionId}
                onClick={() =>
                  setActiveSessionId({ sectionId: c.sectionId, scroll: true })
                }
                key={`${c.sectionId}-button`}
              >
                {c.text}
              </TableOfContentButton>
            )
          } else {
            return undefined
          }
        })}
      </SideColumn>

      <LayoutContent>
        {tableOfContent.map((c) => {
          if (c) {
            return c.section
          } else {
            return undefined
          }
        })}
      </LayoutContent>
      {/* Empty ToC to have even spacing on the left/right side of main content. */}
      <SideColumn />
    </Layout>
  )
}
