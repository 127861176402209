import * as React from 'react'
import { Point, Text, TextProps } from 'victory'
import _ from 'lodash'
import type { getTextMetrics } from '../../../utils/chart'
import { getChartNameFromLabel } from './labelUtils'
import { chartNames, planLegendStripeId } from './chartConstants'
import { ChartLabelDashedLine } from '../../charts/victory-util/ChartLabelDashedLine'
import { ChartLegendOpenDot } from '../../charts/victory-util/ChartLegendOpenDot'
import { emissionLineColor } from '../goalGraphColors'
import { colors } from '../../../theme'

// Implementation note: this is an `interface` because the props
// are passed to `<Text>` with spread, and using `type`
// causes issues with the linter.
interface LabelProps extends TextProps {
  /**
   * SVG x-coordinate is expected to be supplied by Victory.
   */
  x?: number
  /**
   * SVG x-coordinate is expected to be supplied by Victory.
   */
  y?: number
  /**
   * Custom Svalna-related props.
   */
  svalnaProps: CustomDataProp
}

export type TextMetric = NonNullable<ReturnType<typeof getTextMetrics>>

export type CustomDataProp = {
  maxMetric: TextMetric | undefined
  fontSize: number
  /**
   * Approximate SVG pixel radius of the icons to render for the legend labels.
   */
  iconRadius: number
  /**
   * A list of labels generated by {@link valueLabelWithChartName}.
   */
  textsWithFormatting: string[]
  /** Padding between label lines. */
  lineBottomPadding: number
}

export function LabelWithIcon(props: LabelProps): React.JSX.Element {
  const { svalnaProps, x, y, children } = props

  const {
    textsWithFormatting,
    maxMetric,
    lineBottomPadding,
    fontSize,
    iconRadius,
  } = svalnaProps

  // This is expected to always exist, but just in case, use a default
  // which at least avoids NaN.
  const maxTextMetric = maxMetric?.actual ?? { width: 0, height: 0 }

  // Omit props that should not be spread to the DOM elements
  // (children should be passed inside <Text> .. </Text>,
  // and `svalnaProps` would cause a console log warning if passed to SVG DOM elements)
  const cleanedProps = _.omit(props, ['children', 'svalnaProps'])

  return (
    <g>
      {/* 
          The children of `LabelWithIcon` are a list of <tspan> at this point,
          generated by Victory in some previous component.
          Victory `Text` generates a <text> element which should have <tspan> elements
          for every line of text as its children.
          The distance between each line is the font size plus padding, 
          so we use that fact to determine the icon placements.
        */}
      <Text
        {...cleanedProps}
        // In order to left-align the texts, the parent VictoryLabel
        // uses `textAnchor="start"`, but this also centres the text
        // lines inside the flyout (they start at the centre of the box)
        // So translate them by half the max text width to
        // left-align them inside the flyout.
        transform={`translate(-${maxTextMetric.width / 2})`}
      >
        {children}
      </Text>
      {/* Render the appropriate icons, in the same order as the text lines. */}
      {textsWithFormatting.map(
        (s: string, i: number): React.JSX.Element | null => {
          // The label string contains information about the chart for the icon:
          const chartName = getChartNameFromLabel(s)

          // Each icon is offset vertically to align with its text line,
          // using a base line offset per row, plus a bit of extra space.
          const lineOffset = i * (fontSize + lineBottomPadding) + fontSize * 0.1

          // The x,y coordinates are given automatically by Victory and
          // are meant for the text lines, so the icons need some adjustment
          // in order to be left of the texts with some spacing.
          // x and y are expected to be SVG pixels.
          const xx = (x ?? 0) - maxTextMetric.width / 2 - iconRadius - 4
          const yy = (y ?? 0) - maxTextMetric.height / 2 + lineOffset

          if (chartName === chartNames.budgetArea) {
            return (
              <Point
                key={s}
                data-testid='budget-area-icon'
                x={xx}
                y={yy}
                size={iconRadius}
                symbol='square'
                style={{ fill: `url(#${planLegendStripeId})` }}
              />
            )
          } else if (chartName === chartNames.emissionDot) {
            return (
              <Point
                key={s}
                data-testid='emission-dot-icon'
                x={xx}
                y={yy}
                size={iconRadius}
                symbol='circle'
                style={{ fill: emissionLineColor }}
              />
            )
          } else if (chartName === chartNames.partialYearDot) {
            return (
              <ChartLegendOpenDot
                key={s}
                data-testid='partial-year-icon'
                x={xx}
                y={yy}
                // Hackish: the open dot uses stroke instead of fill,
                //          so compensate for the extra size which that causes.
                size={iconRadius - fontSize * 0.1}
                style={{ fill: emissionLineColor }}
              />
            )
          } else if (chartName === chartNames.goalLine) {
            return (
              // Hackish x and size:
              // the dashed line is sligthly wider than the other icons
              // if it uses the same size -- move it, and shrink it slightly.
              // This adjustment is also dependent on the font size.
              <ChartLabelDashedLine
                key={s}
                data-testid='goal-line-icon'
                x={xx + fontSize * 0.05}
                y={yy}
                size={iconRadius - fontSize * 0.1}
                style={{ fill: colors.goalBlue }}
              />
            )
          } else {
            return null
          }
        },
      )}
    </g>
  )
}
