import { useContext, useMemo } from 'react'
import { ApolloError } from '@apollo/client'
import dayjs from 'dayjs'
import { AppDataContext } from '../context/AppDataContext'
import { DataPoint } from '../context/GoalContext'
import {
  useEmissionsQuery,
  Period,
  Aggregation,
  FilterInput,
} from '../graphql/generated'

type Params = {
  filter?: FilterInput
}

/**
 * @returns The emission for the partial year along with query information.
 */
export function usePartialYearEmission(params?: Params): {
  loading: boolean
  error?: ApolloError | Error
  partialYearEmission?: DataPoint
} {
  const { filter } = params ?? {}
  const { organisation } = useContext(AppDataContext)

  // Get the partial year as a string, if there is one.
  const maybePartialYear: string | undefined = organisation.dataUntil
    ? dayjs.utc(organisation.dataUntil).year().toFixed(0)
    : undefined

  // Fetch the partial year emissions if there is such a year.
  // Again: the partial year is never in the trend query result.
  const { data, loading, error } = useEmissionsQuery({
    skip: maybePartialYear === undefined,
    variables: {
      input: {
        filter: {
          // `useAnnualWorkForce` does not accept undefined, so use a
          // default of `false` before the spread.
          useAnnualWorkForce: false,
          ...(filter ?? {}),
          partialYear: true, // true, as query is otherwise skipped.
          year: maybePartialYear, // only fetch the partial year.
        },
        period: Period.Year,
        aggregations: [Aggregation.Date],
      },
    },
  })

  return useMemo(() => {
    let err: ApolloError | Error | undefined = error
    // Check if there should be a partial year, but we've somehow failed to
    // actually get the emissions:
    if (!error && !loading && maybePartialYear && !data?.getEmission.length) {
      err = new Error('Kunde inte hämta data')
    }
    return {
      loading,
      error: err,
      // There should be zero or one emissions in this list.
      partialYearEmission: (data?.getEmission ?? []).map((p) => ({
        x: Number.parseInt(p.period, 10),
        y: p.totalCO2e,
      }))[0],
    }
  }, [error, loading, maybePartialYear, data?.getEmission])
}
