import _ from 'lodash'
import { DomainTuple } from 'victory'
import { Emission } from '../graphql/generated'
import { theme } from '../theme'

export const lineChartLineWidth = 3
export const lineChartDotSize = 5

export interface DataPoint {
  x: string
  y: number
  label?: string
}

export const getTotalCO2e = (emissions: Emission[]): number =>
  emissions.reduce((total, emission) => emission.totalCO2e + total, 0)

export const getMaxValue = (data: Array<{ y: number }>): number =>
  data.reduce(
    (prev, curr) => (curr.y > prev ? curr.y : prev),
    Number.MIN_SAFE_INTEGER,
  )

export const getMinValue = (data: Array<{ y: number }>): number =>
  data.reduce(
    (prev, curr) => (curr.y < prev ? curr.y : prev),
    Number.MAX_SAFE_INTEGER,
  )

export const getAverage = (data: Array<{ y: number }>): number => {
  const totalSum = data.reduce((sum, curr) => curr.y + sum, 0)
  return totalSum / data.length
}

export const getAverageOffset = (data: Array<{ y: number }>): number => {
  return getAverage(data) * 0.2
}

interface DomainOptions {
  offsetY?: number
  yMin?: number
}

export const calculateDomain = (
  data?: {
    y: number
  }[],
  options: DomainOptions = {},
): { x: DomainTuple; y: DomainTuple } | undefined => {
  if (!data || data.length === 0) {
    return undefined
  }

  const yMax = getMaxValue(data)
  const yMin = options.yMin !== undefined ? options.yMin : getMinValue(data)
  const offset =
    options.offsetY !== undefined ? options.offsetY : getAverageOffset(data)

  const domain = {
    x: [0, data.length + 1] as DomainTuple,
    y: [Math.max(yMin - offset, 0), yMax + offset] as DomainTuple,
  }

  if (domain.y[0] === 0 && domain.y[1] === 0) {
    domain.y = [0, 10000]
  }

  return domain
}

const defaultMetricFont = `10px ${theme.typography.fontFamily}`

export function getTextMetrics(
  text: string,
  contextFont: string = defaultMetricFont,
):
  | {
      raw: TextMetrics
      font: { width: number; height: number }
      actual: { width: number; height: number }
    }
  | undefined {
  if (!text) {
    return undefined
  }
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (!context) {
    return undefined
  }
  context.font = contextFont
  const rawMetrics = context.measureText(text)
  // Note: TextMetrics is not (guaranteed to be) an object, and cannot be spread,
  // so for convenience an equivalent object is constructed (note: TextMetrics may have more fields than these).
  type Writeable<T> = { -readonly [P in keyof T]: T[P] }
  const metrics: Writeable<
    Pick<
      TextMetrics,
      | 'actualBoundingBoxAscent'
      | 'actualBoundingBoxDescent'
      | 'actualBoundingBoxLeft'
      | 'actualBoundingBoxRight'
      | 'fontBoundingBoxAscent'
      | 'fontBoundingBoxDescent'
      | 'width'
    >
  > = {
    actualBoundingBoxAscent: rawMetrics.actualBoundingBoxAscent,
    actualBoundingBoxDescent: rawMetrics.actualBoundingBoxDescent,
    actualBoundingBoxLeft: rawMetrics.actualBoundingBoxLeft,
    actualBoundingBoxRight: rawMetrics.actualBoundingBoxRight,
    fontBoundingBoxAscent: rawMetrics.fontBoundingBoxAscent,
    fontBoundingBoxDescent: rawMetrics.fontBoundingBoxDescent,
    width: rawMetrics.width,
  }
  // Note: 'fontBoundingBoxAscent' et al require relatively newer browsers, so fallback to better-supported
  //        'actualBoundingBoxAscent' et al. (e.g. Firefox started supporting font* in summer of 2023).
  if (
    !_.isNumber(metrics.fontBoundingBoxAscent) ||
    !_.isNumber(metrics.fontBoundingBoxDescent)
  ) {
    metrics.fontBoundingBoxAscent = metrics.actualBoundingBoxAscent
    metrics.fontBoundingBoxDescent = metrics.actualBoundingBoxDescent
  }
  return {
    raw: Object.freeze(rawMetrics), // Freezing to comply with TextMetrics readonly state.
    font: {
      width: metrics.width,
      height: metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent,
    },
    actual: {
      width: metrics.actualBoundingBoxLeft + metrics.actualBoundingBoxRight,
      height:
        metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent,
    },
  }
}

export function getTextWidth(
  text?: string,
  contextFont: string = defaultMetricFont,
): number {
  return getTextMetrics(text ?? '', contextFont)?.raw.width ?? 0
}

export function getActualTextHeight(
  text?: string,
  contextFont: string = defaultMetricFont,
): number {
  return getTextMetrics(text ?? '', contextFont)?.actual.height ?? 0
}

export function getTextFontHeight(
  text?: string,
  contextFont: string = defaultMetricFont,
): number {
  return getTextMetrics(text ?? '', contextFont)?.font.height ?? 0
}
