import styled from 'styled-components'
import { colors } from '../theme'
import { Body1Bold } from './Typography'

export const BoxTitle = styled(Body1Bold)`
  padding: 0;
  /* position: sticky; */ /* TODO: Sticky box headers is probably not intended? */
  top: 0;
`

export const Box = styled.div`
  border-radius: 10px;
  padding: 0;
  background-color: ${colors.white};
  overflow: hidden;
  border: 1px solid ${colors.gray};
`

export const FullWidthBox = styled(Box)`
  width: 100%;
`
