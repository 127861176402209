import React from 'react'
import styled from 'styled-components'
import { Button } from '@mui/material'
import { Flex } from '../Flex'
import { CaptionBold } from '../Typography'
import { colors } from '../../theme'
import { ButtonGreen } from '../Buttons'

const Container = styled(Flex)`
  border-top: 0.25px solid ${colors.gray};
  padding: 8px 16px;
`

interface Props {
  onSave: () => void
  onClear?: () => void
}

export function DropdownFooter({ onClear, onSave }: Props): React.JSX.Element {
  return (
    <Container spaceBetween itemsCenter>
      {onClear && (
        <Button onClick={onClear} color='secondary'>
          Avbryt
        </Button>
      )}
      <ButtonGreen onClick={onSave}>
        <CaptionBold>Spara</CaptionBold>
      </ButtonGreen>
    </Container>
  )
}
