import React, { CSSProperties, useContext, useState } from 'react'
import styled from 'styled-components'
import { FilterContext, FilterContextType } from '../context/FilterContext'
import { Flex } from './Flex'
import { TooltipInfo } from './InfoIcon'
import { Body2 } from './Typography'

const Label = styled.label`
  cursor: pointer;
`

let checkbox_counter = 0
const newId = () => (++checkbox_counter).toString()

interface Props {
  isChecked: boolean
  label: string
  onChange: () => void
  titleTooltipGenerator?: (f: FilterContextType) => string | React.JSX.Element
  style?: CSSProperties
}

export function Checkbox({
  label,
  isChecked,
  onChange,
  titleTooltipGenerator,
  style = {},
}: Props): React.JSX.Element {
  const context = useContext(FilterContext)
  const maybeTooltipText = titleTooltipGenerator?.(context)
  // Each checkbox component aquires a unique id the first time they render.
  const [id] = useState(() => newId())
  const uniqueName = `checkbox-component-${id}`
  return (
    <Flex row itemsCenter style={{ ...style, gap: '5px' }}>
      <input
        style={{ margin: 0 }}
        type='checkbox'
        id={uniqueName}
        name={uniqueName}
        checked={!!isChecked}
        onChange={onChange}
      />
      <Label htmlFor={uniqueName}>
        <Body2>{label}</Body2>
      </Label>
      {maybeTooltipText && <TooltipInfo info={maybeTooltipText} size={14} />}
    </Flex>
  )
}
