import _ from 'lodash'
import type { DataPoint } from '../../context/GoalContext'
import { generateSigmoid, lerp } from './goalPlanning'
import { OrgGoalCurveType } from '../../graphql/generated'

/**
 * Get a goal curve in the form of points of { x: year, y: CO2e }.
 *
 * @param type
 * @param referenceYear
 * @param targetYear
 * @param reductionFactor
 * @param emissions
 * @returns
 */
export const generateGoalCurve = (
  type: OrgGoalCurveType,
  referenceYear: number,
  targetYear: number,
  reductionFactor: number,
  emissions: DataPoint[],
): DataPoint[] => {
  const span = targetYear - referenceYear
  if (span <= 0) {
    return []
  }
  const refEmission = _.find(emissions, (e) => e.x === referenceYear)?.y
  if (!_.isNumber(refEmission)) {
    return []
  }
  const targetEmission = refEmission * reductionFactor

  const dataPointIndices = _.range(0, 1 + span)

  if (type === OrgGoalCurveType.Line) {
    return dataPointIndices.map((i) => ({
      x: i + referenceYear,
      y: lerp(i / span, refEmission, targetEmission),
    }))
  } else if (type === OrgGoalCurveType.Exp) {
    // Solve for exponential factor 'r' which yields desired reduction percent after 'span' years.
    // Note: When the reduction is 100 %, this approximates to "near zero" `m`; the final value will not actually be zero.
    const m = 10 ** -Math.ceil(Math.log10(refEmission))
    const r = _.clamp(reductionFactor, m, 1) ** (1 / span)
    return dataPointIndices.map((i) => ({
      x: referenceYear + i,
      y: refEmission * r ** i,
    }))
  } else if (type === OrgGoalCurveType.S) {
    const sigmoidPoints = generateSigmoid(
      span,
      0.8,
      0,
      0.5,
      targetEmission,
      refEmission,
      true,
    )
    return dataPointIndices.map((i) => ({
      x: i + referenceYear,
      y: sigmoidPoints[i].y,
    }))
  } else {
    // Should not happen; only the defined curve types should ever be used.
    return dataPointIndices.map((i) => ({
      x: referenceYear + i,
      y: targetEmission,
    }))
  }
}
