import React, { useContext } from 'react'
import { Layout } from '../../components/Layout'
import { RulesBox } from '../../components/recategorisation/RulesBox'
import { RecatContext } from '../../context/RecatContext'
import { SearchBox } from '../../components/recategorisation/SearchBox'
import { LayoutContent } from '../../components/LayoutContent'
import { NoTransactionView } from '../../components/recategorisation/NoTransactionView'
import { RecatOnboardSnack } from '../../components/onboard/RecatOnboardSnack'

export function RecategorisationPage(): React.JSX.Element {
  const { hasTransactions } = useContext(RecatContext)

  return (
    <Layout title='Transaktioner'>
      {!hasTransactions && <NoTransactionView />}
      <LayoutContent>
        <SearchBox />
        <RulesBox />
      </LayoutContent>
      <RecatOnboardSnack />
    </Layout>
  )
}
