import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { IconButton } from '@mui/material'
import { Flex } from '../../Flex'
import { RecatType } from './SelectRecatType'
import { ReverseButton } from '../../charts/category-pie/CategoryBreadCrumbs'

interface Props {
  setRecatType: (recatType: RecatType) => void
  recatType: RecatType
}
export function RulesBreadCrumbs({
  setRecatType,
  recatType,
}: Props): React.JSX.Element {
  return (
    <Flex itemsCenter>
      <IconButton
        aria-label='back'
        onClick={() => setRecatType(RecatType.CHOOSE)}
        disabled={recatType === RecatType.CHOOSE}
        data-testid='back-button'
        style={{ padding: 0 }}
      >
        <ArrowBackIcon />
      </IconButton>
      <ReverseButton
        canGoBack={recatType !== RecatType.CHOOSE}
        onClick={() => setRecatType(RecatType.CHOOSE)}
        disabled={recatType === RecatType.CHOOSE}
        data-testid='all-cat'
      >
        VÄLJ REGEL
      </ReverseButton>
      {recatType === RecatType.CHANGE_CATEGORY && (
        <>
          <KeyboardArrowRightIcon />
          <ReverseButton canGoBack={false} disabled>
            ÄNDRA KATEGORI
          </ReverseButton>
        </>
      )}
      {recatType === RecatType.SET_EMISSIONS && (
        <>
          <KeyboardArrowRightIcon />
          <ReverseButton canGoBack={false} disabled>
            ÄNDRA UTSLÄPPSVÄRDE
          </ReverseButton>
        </>
      )}
    </Flex>
  )
}
