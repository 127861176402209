import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { AlertSuccess } from '../../../components/alert'
import { CaptionBold } from '../../../components/Typography'
import { getLastLoggedOutTime } from '../../../utils/auth'

export function LogoutSuccessAlert(): React.JSX.Element | null {
  const [show, setShow] = useState(false)
  const showForMS = 5000

  useEffect(() => {
    const timeAt = getLastLoggedOutTime()
    if (timeAt !== null) {
      const setShowWrapper = () => {
        setShow(dayjs.utc().valueOf() < dayjs.utc(timeAt).valueOf() + showForMS)
      }
      setShowWrapper()
      const handler = setInterval(setShowWrapper, 100)
      return () => clearInterval(handler)
    }
    return undefined
  }, [])

  if (!show) {
    return null
  }

  return (
    <AlertSuccess>
      <CaptionBold>Loggats ut!</CaptionBold>
    </AlertSuccess>
  )
}
