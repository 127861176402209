import React from 'react'
import { Point } from 'victory'
import { LegendSymbolProps } from './victory-prop-types'

/**
 * Draws an open dot as a symbol for a Victory legend.
 */
export function ChartLegendOpenDot(
  props: LegendSymbolProps,
): React.JSX.Element {
  const { style } = props
  return (
    <Point
      {...props}
      symbol='circle'
      style={{
        ...(props?.style ?? {}),
        fill: 'none',
        stroke: style?.fill,
        strokeWidth: 2,
      }}
    />
  )
}
