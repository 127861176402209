import React, { useCallback, useContext } from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { IconButton } from '@mui/material'
import { FilterContext } from '../../context/FilterContext'
import { IFilterDatePeriod } from '../../utils/datePeriod'
import { Flex } from '../Flex'
import { Body1Bold, Body2 } from '../Typography'
import { spacing } from '../../theme'

interface Props {
  selectedDatePeriod: IFilterDatePeriod
  setDatePeriod: React.Dispatch<React.SetStateAction<IFilterDatePeriod>>
  size?: 'small' | 'large'
}

export function periodLabel(per: IFilterDatePeriod): string {
  return per.partialYear ? `${per.name} (${per.partialPeriodText})` : per.name
}

export function SelectYear({
  selectedDatePeriod,
  setDatePeriod,
  size,
}: Props): React.JSX.Element {
  // yearPeriods contains the years for which we have data in revers order
  // all the navigation logic bellow is based on this ordering
  const { yearPeriods } = useContext(FilterContext)

  const handleMinusOneYear = useCallback(() => {
    const currentIndex = yearPeriods.findIndex(
      (period) => period.name === selectedDatePeriod.name,
    )
    setDatePeriod(yearPeriods[currentIndex + 1])
  }, [selectedDatePeriod.name, setDatePeriod, yearPeriods])

  const handlePlusOneYear = useCallback(() => {
    const currentIndex = yearPeriods.findIndex(
      (period) => period.name === selectedDatePeriod.name,
    )
    setDatePeriod(yearPeriods[currentIndex - 1])
  }, [selectedDatePeriod.name, setDatePeriod, yearPeriods])

  const canAddOneYear = useCallback(() => {
    const currentIndex = yearPeriods.findIndex(
      (period) => period.name === selectedDatePeriod.name,
    )
    return currentIndex !== 0
  }, [selectedDatePeriod.name, yearPeriods])

  const canRemoveOneYear = useCallback(() => {
    const currentIndex = yearPeriods.findIndex(
      (period) => period.name === selectedDatePeriod.name,
    )
    return currentIndex !== yearPeriods.length - 1
  }, [selectedDatePeriod.name, yearPeriods])

  return (
    <Flex itemsCenter>
      <IconButton
        aria-label='minus-one-year'
        size='small'
        onClick={handleMinusOneYear}
        disabled={!canRemoveOneYear()}
        style={{ marginRight: size === 'large' ? spacing.xl : 0 }}
      >
        <ArrowBackIosNewIcon fontSize='inherit' />
      </IconButton>
      {size === 'large' ? (
        <Body1Bold>{periodLabel(selectedDatePeriod)}</Body1Bold>
      ) : (
        <Body2>{periodLabel(selectedDatePeriod)}</Body2>
      )}

      <IconButton
        aria-label='plus-one-year'
        size='small'
        onClick={handlePlusOneYear}
        disabled={!canAddOneYear()}
        style={{ marginLeft: size === 'large' ? spacing.xl : 0 }}
      >
        <ArrowForwardIosIcon fontSize='inherit' />
      </IconButton>
    </Flex>
  )
}
