import React, { useContext } from 'react'
import { Button, FormHelperText } from '@mui/material'
import { Flex } from '../../Flex'
import { ButtonGreen } from '../../Buttons'
import { Loader } from '../../Loader'
import { RecatContext } from '../../../context/RecatContext'
import { JobContext } from '../../../context/JobContext'

import { RecatType } from './SelectRecatType'
import { spacing } from '../../../theme'

interface Props {
  disabled: boolean
  setRecatType: (recatType: RecatType) => void
  recatType: RecatType
}

export function RecatButtons({
  disabled,
  setRecatType,
  recatType,
}: Props): React.JSX.Element {
  const { jobRunning } = useContext(JobContext)

  const { transactionsError, validationError } = useContext(RecatContext)

  return (
    <Flex column>
      <Flex row style={{ gap: spacing.xl }}>
        <Button onClick={() => setRecatType(RecatType.HIDE)}>Avbryt</Button>
        {recatType !== RecatType.CHOOSE && (
          <ButtonGreen
            variant='outlined'
            type='submit'
            form='recatForm'
            disabled={
              jobRunning || !!transactionsError || !!validationError || disabled
            }
            data-testid='add-button'
            startIcon={jobRunning && <Loader size={15} borderSize={4} />}
          >
            Lägg till regel
          </ButtonGreen>
        )}
      </Flex>
      {validationError && <FormHelperText>{validationError}</FormHelperText>}
    </Flex>
  )
}
