import { useContext } from 'react'
import type { ApolloError } from '@apollo/client'
import { Maybe } from 'graphql/jsutils/Maybe'
import { FilterContext } from '../../../context/FilterContext'
import {
  CategoryTrendPercentage,
  EmissionMetaData,
  useEmissionMetaDataQuery,
} from '../../../graphql/generated'
import { useFilterInput } from '../../../hooks/useFilterInput'
import type { IFilterDatePeriod } from '../../../utils/datePeriod'

type Metadata = {
  metaData:
    | (Pick<
        EmissionMetaData,
        'totalEmission' | 'yearlyPercentage' | 'emissionPerAnnualWorkForce'
      > & {
        worstCategory?: Maybe<
          Pick<CategoryTrendPercentage, 'percentage' | 'diff' | 'categoryId'>
        >
        bestCategory?: Maybe<
          Pick<CategoryTrendPercentage, 'percentage' | 'diff' | 'categoryId'>
        >
      })
    | undefined
  loading: boolean
  error: ApolloError | undefined
  datePeriod: IFilterDatePeriod
}

export const useMetaData = (): Metadata => {
  const { defaultDatePeriod } = useContext(FilterContext)
  const filter = useFilterInput()

  const { data, loading, error } = useEmissionMetaDataQuery({
    variables: { filter },
  })

  if (data?.getMetaData) {
    return {
      metaData: data.getMetaData,
      loading,
      error: error,
      datePeriod: defaultDatePeriod,
    }
  } else {
    return {
      metaData: undefined,
      loading,
      error: error,
      datePeriod: defaultDatePeriod,
    }
  }
}
