import type { ApolloError } from '@apollo/client'
import { useMemo } from 'react'
import _ from 'lodash'
import {
  FilterInput,
  GetTrendQuery,
  Period,
  useGetTrendQuery,
} from '../../../graphql/generated'
import { DataPoint } from '../../../utils/chart'

export type TrendGraphData = {
  /** Raw query data. */
  chartData: GetTrendQuery | undefined
  /** Sorted trend data. */
  trendData: DataPoint[]
  /** Sorted emission data (only full years). */
  emissionData: DataPoint[]
  trendPercentage: number | undefined
  trendRate: number | undefined
  loading: boolean
  error: ApolloError | undefined
}

/**
 * Note: Never contains partial year data; emissions are always full years.
 * @param filter The filter to use, note that year selections are ignored.
 * @returns Data for trend graphs.
 */
export const useTrendGraphData = (filter: FilterInput): TrendGraphData => {
  const { data, loading, error, previousData } = useGetTrendQuery({
    variables: {
      filter: { ...filter, year: undefined },
      period: Period.Year,
    },
  })

  const { trendPercentage, emissionData, trendData } = useMemo(() => {
    const res = loading ? previousData?.getTrend : data?.getTrend

    // Emissions as `DataPoint[]`.
    // Sorted, since the data is usually displayed in graphs,
    // and victory chart displays data with a non-numeric "x" in the order of the list.
    const emis = _.sortBy(
      res?.emissions.map<DataPoint>((emission) => ({
        x: emission.period,
        y: emission.totalCO2e,
      })) ?? [],
      (p) => p.x,
    )

    const trend =
      emis.map((emission, i) => ({
        x: emission.x,
        y: res ? Math.max(0, res.trendOffset + (res.trendRate * i + 1)) : 0,
      })) ?? []

    return {
      trendPercentage: res?.trendPercentage,
      emissionData: emis,
      trendData: trend,
    }
  }, [data?.getTrend, loading, previousData?.getTrend])

  return {
    chartData: loading ? previousData : data,
    trendPercentage,
    emissionData,
    trendData,
    trendRate: loading
      ? previousData?.getTrend.trendRate
      : data?.getTrend.trendRate,
    loading,
    error,
  }
}
