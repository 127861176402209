import { ApolloError } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Svalna from '../@types'
import { Loader } from './Loader'
import { H6 } from './Typography'
import { Flex } from './Flex'
import { spacing } from '../theme'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 200;
`

const LoadingText = styled(H6)`
  margin-top: ${spacing.medium};
`

const useShowWidthDelay = (ms: number) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => setShow(true), ms)
    return () => clearTimeout(timeoutId)
  }, [ms])

  return show
}

export function PageLoader({
  delayMs,
}: {
  delayMs: number
}): React.JSX.Element | null {
  const show = useShowWidthDelay(delayMs)
  if (!show) {
    return null
  }
  return (
    <Wrapper>
      <Loader size={80} borderSize={20} />
      <LoadingText>Laddar...</LoadingText>
    </Wrapper>
  )
}

const LoaderCenterWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const ChillLoader = styled(Loader)`
  opacity: 0.7;
`

interface Props extends Svalna.PropWithChildren {
  loading: boolean
  hasData: boolean
  orgHasInitializedData: boolean
  loadingPlaceholderHeight?: number
  error?: Error | ApolloError
  defaultErrorMessage?: string
}

export function MaybeLoading({
  loading,
  hasData,
  orgHasInitializedData,
  error,
  loadingPlaceholderHeight,
  defaultErrorMessage,
  children,
}: Props): React.JSX.Element {
  let ermes = error?.message && 'Något gick fel!'
  if (!(loading || ermes || hasData || !orgHasInitializedData)) {
    ermes = defaultErrorMessage ?? 'Data saknas för valt filter'
  }

  if (ermes) {
    return <LoaderCenterWrapper id='loader-error'>{ermes}</LoaderCenterWrapper>
  }

  if ((loading && !hasData) || !orgHasInitializedData) {
    return (
      <LoaderCenterWrapper
        style={{
          minHeight: loadingPlaceholderHeight,
        }}
      >
        <ChillLoader id='loader' size={50} borderSize={7} />
      </LoaderCenterWrapper>
    )
  }

  return (
    <Flex
      stretchWidth
      style={{
        position: 'relative',
      }}
    >
      {loading && (
        <LoaderCenterWrapper
          style={{
            position: 'absolute',
            zIndex: 10,
            width: '100%',
            height: '100%',
            background: 'rgb(255,255,255, 0.5)',
          }}
        >
          <ChillLoader id='loader' size={50} borderSize={7} />
        </LoaderCenterWrapper>
      )}
      {children}
    </Flex>
  )
}
