import _ from 'lodash'
import React, { useContext } from 'react'
import { colors } from '@mui/material'
import dayjs from 'dayjs'
import Svalna from '../../../@types'
import { AppDataContext } from '../../../context/AppDataContext'
import { spacing, theme, colors as themeColors } from '../../../theme'
import { TooltipInfo } from '../../InfoIcon'
import { MaybeLoading } from '../../PageLoader'
import { H4Bold, S1 } from '../../Typography'
import {
  GeneralSmallCardProps,
  noGoalDefinedErrorMessage,
  SmallCardBox,
  SmallCardContent,
  SmallCardFooter,
  SmallCardHeader,
  SmallCardTitle,
} from './SmallBudgetCard'
import {
  GoalContext,
  GoalContextData,
  GoalReductionFactors,
} from '../../../context/GoalContext'

type GoalProgressCardProps = GeneralSmallCardProps & {
  /**
   * Set to true if the card can reflect an org unit selection --
   * this changes the tooltip text.
   */
  canSelectOrgUnit?: boolean
  PercentTextComponent?: Svalna.FC<unknown>
}

/**
 * Display a "mini card" with goal progress.
 *
 * If the organisation has no goal, this component will display "no data".
 */
export function GoalProgressCard({
  inlined,
  canSelectOrgUnit,
  PercentTextComponent = H4Bold,
}: GoalProgressCardProps): React.JSX.Element {
  const { orgHasInitializedData, emissionRange } = useContext(AppDataContext)

  const { loading, error, data } = useContext(GoalContext)
  const hasGoals = !loading && !error && !!data

  const lastEmissionDate = !!emissionRange.to && dayjs.utc(emissionRange.to)
  const currentYear = (lastEmissionDate && lastEmissionDate.year()) || undefined

  const hasGoalProgessData =
    hasGoals && _.isNumber(currentYear) && !!data?.reductionFactors

  return (
    <SmallCardBox inlined={inlined} data-testid='goal-progress-card'>
      <SmallCardHeader inlined={inlined}>
        <SmallCardTitle>
          <span>Målutveckling sedan basår</span>
          <br />
          <span>{`${data?.lastOrgDataYear} jmf ${data?.referenceYear}`}</span>
        </SmallCardTitle>
        <TooltipInfo
          info={
            <>
              <p>
                {/* 
                  TODO: These texts are modified with issue [265],
                        they are not identical to requests from [223].
                */}
                <p>Når ni ert mål?</p>
                <p>
                  <span>
                    Det här kortet visar den procentuella förändringen i utsläpp
                    från basåret till det senaste avslutade året (stor siffra).
                    Dessutom visas hur mycket ni ligger över eller under er
                    målsatta utsläppskurva (liten siffra).
                  </span>
                </p>
              </p>
              <p>
                <span>Färger för utsläppsutveckling:</span>
                <ul style={{ marginTop: 0 }}>
                  <li>Grön: Utsläppen är på eller nära målet.</li>
                  <li>
                    Gul: Utsläppen avviker något från målet (mer än 2,5% över
                    målnivån).
                  </li>
                  <li>
                    Röd: Utsläppen avviker kraftigt från målet (mer än 5% över
                    målnivån).
                  </li>
                </ul>
              </p>
            </>
          }
        />
      </SmallCardHeader>
      <MaybeLoading
        loading={!!loading}
        error={error}
        hasData={!!hasGoalProgessData}
        defaultErrorMessage={!hasGoals ? noGoalDefinedErrorMessage : undefined}
        orgHasInitializedData={orgHasInitializedData}
      >
        <ProgressTexts
          // Using TS non-null assertion operator (!) since
          // `hasGoalProgessData` guarantees reductionFactors is defined,
          // as MaybeLoading will not render this unless that is true.
          reductionFactors={data?.reductionFactors!}
          partialYearReductionFactors={data?.partialYearReductionFactors}
          PercentTextComponent={PercentTextComponent}
        />
      </MaybeLoading>
      <SmallCardFooter />
    </SmallCardBox>
  )
}

/**
 * Make all fields in the given type required and non-nullable. This is not recursive.
 */
type RequireNonNullFields<T extends object> = {
  [P in keyof T]-?: NonNullable<T[P]>
}

type ProgressProps = RequireNonNullFields<
  Pick<GoalProgressCardProps, 'PercentTextComponent'>
> & {
  reductionFactors: NonNullable<GoalContextData['data']>['reductionFactors']
  partialYearReductionFactors?: NonNullable<
    GoalContextData['data']
  >['partialYearReductionFactors']
}

function ProgressTexts({
  reductionFactors,
  partialYearReductionFactors,
  PercentTextComponent,
}: ProgressProps): React.JSX.Element {
  const {
    changeFromGoal: fullYearChangeFromGoal,
    changeFromGoalInPercent: fullYearChangeGoalPct,
    percentSignForRefChange,
    percentChangeFromRef,
  } = getReductionInfo(reductionFactors)

  const hasPartialYear = !!partialYearReductionFactors

  // If there is a partial year, the subtitle text should
  // relate to it, otherwise it should relate to the full year.
  let changeFromGoal = fullYearChangeFromGoal
  let changeFromGoalInPercent = fullYearChangeGoalPct
  if (hasPartialYear) {
    const {
      changeFromGoal: partialYearChangeFromGoal,
      changeFromGoalInPercent: partialYearChangeGoalPct,
    } = getReductionInfo(partialYearReductionFactors)
    changeFromGoal = partialYearChangeFromGoal
    changeFromGoalInPercent = partialYearChangeGoalPct
  }

  // Main percent text always relates to the full year, set colour based on that.
  const { textOverWhite } = getGoalRatioColors(fullYearChangeFromGoal)

  return (
    <SmallCardContent>
      {/* Change in emissions for last data year, in percent, compared to the goal reference year: */}
      {/* TODO: Colour indicates pace, but for accessibility, it should not be the only indication. */}
      {/* Note: Setting colour style on wrapping <span> since `PercentTextComponent` has `unknown` as props,
                meaning the interface does not require it to be style-able, thus instead this relies
                on the text inside inheriting styles from its parent's hierarchy. */}
      <span
        data-testid='goal-progress-percent-change-from-start'
        style={{ color: textOverWhite, marginBottom: spacing.small }}
      >
        <PercentTextComponent>
          {percentSignForRefChange} {Math.abs(percentChangeFromRef).toFixed(0)}%
        </PercentTextComponent>
      </span>
      <S1 data-testid='goal-progress-text'>
        {/* Percentage change between actual value and goal value: */}
        <span>{hasPartialYear ? 'Hittills i år:' : 'Det är'}</span>{' '}
        <span>{changeFromGoalInPercent}%</span>
        {/* TODO: Include a case for a narrow band considered "on" the goal curve, i.e. neither over nor under? */}
        <span>{changeFromGoal > 0 ? ' över' : ' under'}</span>{' '}
        <span>målkurvan</span>
      </S1>
    </SmallCardContent>
  )
}

/**
 * Interpret reduction factors:
 * Get information about the change in emissions compared to
 * the reference year and goal.
 *
 * @param param0 GoalReductionFactors
 * @returns Comparison information
 */
function getReductionInfo({
  currentReductionFactor,
  currentTargetReductionFactor,
}: GoalReductionFactors): {
  /**
   * Ratio for the change between the current reduction compared to the
   * goal reduction. < 0 if target is below goal.
   */
  changeFromGoal: number
  /**
   * `changeFromGoal` expressed in percent.
   * E.g. 50 % means 0.5, 150 % means 1.5.
   */
  changeFromGoalInPercent: string
  /**
   * Percentage change of the current emissions compared to the
   * reference year's emissions.
   * E.g. -50 means emissions are down 50 %.
   */
  percentChangeFromRef: number
  /** Sign for `percentChangeFromRef`.  */
  percentSignForRefChange: '+' | '-'
} {
  // Ratio for percent change from goal to actual value;
  // < 0 if target is below goal, otherwise > 0.
  // TODO / HACK: Currently using a very small default if the target is zero
  const changeFromGoal =
    (currentReductionFactor - currentTargetReductionFactor) /
    Math.abs(currentTargetReductionFactor || 0.00001)

  const changeFromGoalInPercent = Math.abs(changeFromGoal * 100).toFixed(0)

  // Difference from reference year emissions in percent, and the sign as a string:
  const percentChangeFromRef = 100 * (1 - currentReductionFactor)
  const percentSignForRefChange = currentReductionFactor < 1 ? '-' : '+'

  return {
    changeFromGoal,
    changeFromGoalInPercent,
    percentChangeFromRef,
    percentSignForRefChange,
  }
}

/**
 * Get a colour representative of the given goal ratio, or "pace of change in emissions".
 *
 * @param changeFromGoal The current ratio of change from goal emissions to actual emissions;
 *                       a value > 0 means the current emissions are greater than the goal emissions.
 * @returns A colour indicating how "good" the change is.
 */
function getGoalRatioColors(changeFromGoal: number): {
  /** Text colour to use when printing over the background. */
  textOverBackground: string
  /** Background colour for text. */
  background: string
  /** Text colour to use if the background is white. */
  textOverWhite: string
} {
  // Goal ratio/pace thresholds:
  // We want a measure of if the current rate of change in emissions compared to the goal rate is "sufficient".
  // These somewhat arbitrary ratio limits of "bad" and "slow" are used to indicate if the pace is
  // off by a lot (bad) or close but not sufficient (slow). Otherwise the pace is considered sufficient.
  const badPaceRatio = 0.05
  const slowPaceRatio = 0.025

  // Note: make sure to pick colour combinations with WCAG-compliant contrast.
  // Note: WCAG 2 uses a poor algorithm for contrast, and they will update this in WCAG 3.
  // WCAG 3 (APCA) contrast tool:
  // https://contrast.tools/?tab=apca
  // More on APCA:
  // https://git.apcacontrast.com/documentation/APCAeasyIntro
  // Relevant Lc values for goal texts (subtract 10 from Lc for the minimum viable contrast):
  //  * 16px 400 weight should have an Lc of 90.
  //  * 16px 700 weight should have an Lc of 60.
  //  * 34px 700 weight should have an Lc of 40.
  // It is difficult to find a warning colour (yellow/orange) which passes with both
  // 16 px 400 text on this colour as the background and at the same time
  // 32 px 700 text using this colour on a white background,
  // so the text is changed to 16 px 700 when drawn on coloured background,
  // as that makes the text readable with a smaller contrast value.

  if (changeFromGoal >= badPaceRatio) {
    // Note: using same red as the other "metadata" cards.
    // APCA for theme color red #ea5a52:
    //  * white on red: -66.64 -- 16px 700w is perfect.
    //  * red on white: 61.18 -- 34px 700w is perfect.
    return {
      textOverBackground: theme.palette.common.white,
      background: themeColors.red,
      textOverWhite: themeColors.red,
    }
  } else if (changeFromGoal >= slowPaceRatio) {
    // APCA for orange500 #ff9800:
    // * orange500 on white: 62.11 -- 16px 700w is perfect.
    // * orange500 on white: 41.84 -- 34px 700w is perfect.
    // Note: the palette "warning" colour has no passable contrast for any text colour,
    //       when that orange was used as the background colour.
    return {
      textOverBackground: theme.palette.common.black,
      background: colors.orange[500],
      textOverWhite: colors.orange[500],
    }
  } else {
    // Note: using same green as the other "metadata" cards.
    // APCA for deepGreen #00a96a:
    // * white on deepGreen: -62.02 -- 16px 700w is perfect.
    // * deepGreen on white: 56.66 -- 34px 700w is perfect.
    return {
      textOverBackground: theme.palette.common.white,
      background: themeColors.deepGreen,
      textOverWhite: themeColors.deepGreen,
    }
  }
}
