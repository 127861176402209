import React, { useContext } from 'react'
import { MaybeLoading } from '../../../components/PageLoader'
import { H3 } from '../../../components/Typography'
import {
  Stat,
  StatContent,
  StatFooter,
  StatHeader,
  StatTitle,
} from './metaDataCardStyle'
import { GoalProgressCard } from '../../../components/goal/budget/GoalProgressCard'
import { GoalContext } from '../../../context/GoalContext'
import { ChangeCard } from './ChangeCard'

/**
 * Displays either a "metadata card" for goal status,
 * if the org has a goal, or otherwise the total change since last year.
 */
export function ChangeOrGoalCard({
  rangeText,
}: {
  rangeText: string
}): React.JSX.Element {
  const { loading, error, data } = useContext(GoalContext)

  // Display a loading card while waiting for confirmation on if
  // the org has goal data or not -- since we don't know what the
  // title/data of the card should be until we know if there is a goal.
  if (loading) {
    return <LoadingCard />
  } else if (error) {
    // Return an error message if there was an error fetching goal data.
    // Future considerations:
    // - Perhaps it is better to fall back to the change card?
    //   Or to display the change card, but along with some indication there
    //   was an error while fetching goal data?
    return <ErrorCard />
  }

  // Not loading or error -- if `data` is set, the org has a goal:
  if (data) {
    return (
      <GoalProgressCard
        canSelectOrgUnit
        PercentTextComponent={H3} // Same text component as the other "metadata" cards.
      />
    )
  } else {
    return <ChangeCard rangeText={rangeText} />
  }
}

function LoadingCard(): React.JSX.Element {
  return (
    <Stat data-testid='metadata-change-or-goal-loading'>
      <StatHeader>
        <StatTitle />
      </StatHeader>
      {/* Display a loader via `MaybeLoading` */}
      <MaybeLoading loading hasData orgHasInitializedData>
        <StatContent />
      </MaybeLoading>
      <StatFooter />
    </Stat>
  )
}

function ErrorCard(): React.JSX.Element {
  return (
    <Stat data-testid='metadata-change-or-goal-error'>
      <StatHeader>
        <StatTitle>
          <span>Ett fel uppstod</span>
        </StatTitle>
      </StatHeader>
      <MaybeLoading
        // With the current implementation of `MaybeLoading`, the error message in
        // the error object is never used, and `defaultErrorMessage` is
        // only used if `error` is undefined and:
        //  ` !loading && !hasData && orgHasInitializedData `
        defaultErrorMessage='Kunde inte hämta måldata'
        error={undefined}
        loading={false}
        hasData={false}
        orgHasInitializedData
      >
        <StatContent />
      </MaybeLoading>
      <StatFooter />
    </Stat>
  )
}
