// we are not doing any logical operation in this file so we do not have risk of running into the
// error that require this check
/* eslint-disable @sayari/no-unwrapped-jsx-text */
import React from 'react'
import styled from 'styled-components'
import { LoginPageWrapper } from '../components/LoginPageWrapper'
import { Body1, H5, H6, S2Bold } from '../../../components/Typography'
import { Li, LiSection, Ol, RubricTitle } from './components/Styles'
import { spacing } from '../../../theme'

const Td = styled.td`
  border: 1px solid black;
  border-collapse: collapse;
  padding: ${spacing.small};
`

const TableTitle = styled(S2Bold)`
  text-align: start;
`

export function DataProcessingAgreement(): React.JSX.Element {
  return (
    <LoginPageWrapper error=''>
      <div style={{ textAlign: 'justify', paddingBottom: spacing.medium }}>
        <H5 style={{ paddingBottom: spacing.xl }}>Data Processing Agreement</H5>
        <Body1>
          This Data Processing Agreement (the “<b>Processing Agreement</b>”)
          forms part of the agreement (the “<b>Main Agreement</b>”) between the
          Service Provider (the “<b>Processor</b>”) and the Customer (the “
          <b>Controller</b>”). Under the Main Agreement, the Processor processes
          Personal Data on behalf of the Controller. The Controller hereby
          instructs the Processor to process Personal Data in accordance with
          the terms of this Processing Agreement, the purpose of which is to
          ensure the protection and security of Personal Data and to meet the
          requirements imposed by law.
        </Body1>
        <Ol>
          <LiSection>
            <RubricTitle>DEFINITIONS</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  <Body1>
                    Should the Regulation (EU) 2016/679 of the European
                    Parliament and of the Council of 27 April 2016 (“<b>GDPR</b>
                    ”) contain terms that conflict with those used in the
                    Processing Agreement, such terms shall be interpreted and
                    applied in accordance with the GDPR.
                  </Body1>
                </Li>
              </Body1>
              <Body1>
                <Li>For the purposes of this Processing Agreement:</Li>
              </Body1>
              <ol type='a' style={{ paddingLeft: spacing.xxxl }}>
                <li>
                  <Body1>
                    “<b>Controller</b>” means the legal person who, in
                    accordance with this Processing Agreement, determines the
                    means and the purposes of the Processing of Personal Data;
                  </Body1>
                </li>
                <li>
                  <Body1>
                    “<b>Data Protection Laws</b>” means the applicable data
                    protection legislation in force, including instructions
                    drafted by competent authorities, from time to time during
                    the term of this Processing Agreement. The Data Protection
                    Laws include the GDPR and national legislation supplementary
                    to the GDPR;
                  </Body1>
                </li>
                <li>
                  <Body1>
                    “<b>Data Subject</b>” means the person to whom Personal Data
                    relates;
                  </Body1>
                </li>
                <li>
                  <Body1>
                    “<b>Personal Data</b>” means any information (including
                    information which is processed by the Processor, and
                    Subprocessors as applicable, on behalf of the Controller)
                    relating to an identified or identifiable natural person; an
                    identifiable natural person is one who can be identified,
                    directly or indirectly, in particular by reference to an
                    identifier such as a name, an identification number,
                    location data, an online identifier or one or more factors
                    specific to the physical, physiological, genetic, mental,
                    economic, cultural or social identity of that natural
                    person;
                  </Body1>
                </li>
                <li>
                  <Body1>
                    “<b>Personal Data Breach</b>” means breaches and attempted
                    breaches of security leading to the accidental or unlawful
                    destruction, loss, alteration, unauthorised disclosure of,
                    or access to, Personal Data transmitted, stored or otherwise
                    processed;
                  </Body1>
                </li>
                <li>
                  <Body1>
                    “<b>Processing</b>” means any operation or set of operations
                    which is performed on Personal Data or on sets of Personal
                    Data, whether or not by automated means, such as collection,
                    recording, organisation, structuring, storage, adaptation or
                    alteration, retrieval, consultation, use, disclosure by
                    transmission, dissemination or otherwise making available,
                    alignment or combination, restriction, erasure or
                    destruction;
                  </Body1>
                </li>
                <li>
                  <Body1>
                    “<b>Processor</b>” means the legal person who processes
                    Personal Data on behalf of the Controller in accordance with
                    this Processing Agreement;
                  </Body1>
                </li>
                <li>
                  <Body1>
                    “<b>Subprocessor</b>” means a third-party processor engaged
                    by the Processor, or by any other subprocessor of the
                    Processor, to process Personal Data on behalf of the
                    Controller.
                  </Body1>
                </li>
              </ol>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>Processing of Personal Data</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  <span>
                    The Processor shall only process the Personal Data to the
                    extent necessary to fulfill its contractual obligations
                    towards the Controller under the Main Agreement, the
                    Processing Agreement and the written instructions provided
                    by the Controller from time to time, unless required to do
                    so by Union or Member State law to which the Processor is
                    subject. In this case, the Processor shall inform the
                    Controller of that legal requirement before Processing,
                    unless the law prohibits this on important grounds of public
                    interest. Any other form of Processing of Personal Data is
                    prohibited. The original instructions from the Controller
                    concerning the subject-matter and duration of the
                    Processing, the nature and purpose of the Processing, the
                    type of Personal Data and categories of Data Subjects are
                    set out in this Processing Agreement and in the{' '}
                    <a href='#instructions'>instructions sheet</a> attached
                    hereto.
                  </span>
                </Li>
              </Body1>
              <Body1>
                <Li>
                  The Processor agrees to process the Personal Data in
                  accordance with the Data Protection Laws and the instructions
                  of supervisory authorities. The Processor may not take any
                  measures that may cause the Controller infringing the Data
                  Protection Laws.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  The Controller ensures that it has the right to delegate the
                  processing of the Personal Data to the Processor and that its
                  instructions to the Processor shall always be in line with the
                  Data Protection Laws.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  The Processor shall, in accordance with the Data Protection
                  Laws, maintain a record of Processing activities carried out
                  on behalf of the Controller and provide the Controller with a
                  copy of the record of processing activities on the
                  Controller’s request.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  The Processor undertakes to assist the Controller by
                  appropriate technical and organisational measures, insofar as
                  this is possible, in the fulfillment of the Controller’s
                  obligations towards Data Subjects, such as responding to Data
                  Subjects’ requests to exercise their rights in accordance with
                  the Data Protection Laws.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  The Processor undertakes to assist the Controller in
                  accordance with the Data Protection Laws should the Controller
                  consult the supervisory authority prior to Processing or to
                  carry out an assessment of the impact of the envisaged
                  Processing activities on the protection of the Personal Data.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>SECURITY</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  The Processor warrants that it has implemented appropriate
                  technical and organisational measures to protect the Personal
                  Data and to ensure a level of security appropriate to the
                  risk, including <i>inter alia</i> as appropriate:
                </Li>
              </Body1>
              <ol type='a' style={{ paddingLeft: spacing.xxxl }}>
                <li>
                  <Body1>
                    the pseudonymisation and encryption of the Personal Data;
                  </Body1>
                </li>
                <li>
                  <Body1>
                    the ability to ensure the ongoing confidentiality,
                    integrity, availability and resilience of processing systems
                    and services;
                  </Body1>
                </li>
                <li>
                  <Body1>
                    the ability to restore the availability and access to the
                    Personal Data in a timely manner in the event of a physical
                    or technical incident;
                  </Body1>
                </li>
                <li>
                  <Body1>
                    a process for regularly testing, assessing and evaluating
                    the effectiveness of technical and organisational measures
                    for ensuring the security of the Processing.
                  </Body1>
                </li>
              </ol>
              <Body1>
                <Li>
                  In assessing the appropriate level of security pursuant to
                  section 3.1, account shall be taken in particular of the risks
                  that are presented by Processing, in particular from
                  accidental or unlawful destruction, loss, alteration,
                  unauthorised disclosure of, or access to the Personal Data.
                  Existing technological options, the costs for implementing the
                  measures and the sensitivity of the data shall also be taken
                  into account.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  The Processor shall ensure that access to Personal Data is
                  documented and can be monitored. The Processor shall have
                  periodical and systematic controls to ensure that no
                  unauthorised access to Personal Data is allowed.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>PERSONAL DATA BREACH</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  In the event of a Personal Data Breach, the Processor shall
                  cooperate with and assist the Controller for the Controller to
                  follow its obligations under the Data Protection Laws.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  The Processor shall without undue delay after having become
                  aware of it, notify the Controller of a Personal Data Breach.
                  The notification shall at least:
                </Li>
              </Body1>
              <ol type='a' style={{ paddingLeft: spacing.xxxl }}>
                <li>
                  <Body1>
                    describe the nature of the breach including where possible,
                    the categories and approximate number of Data Subjects
                    concerned, and the categories and approximate number of
                    Personal Data records concerned;
                  </Body1>
                </li>
                <li>
                  <Body1>
                    communicate the name and contact details of the Processor’s
                    data protection officer or other contact point where more
                    information can be obtained;
                  </Body1>
                </li>
                <li>
                  <Body1>describe the likely consequences of the breach;</Body1>
                </li>
                <li>
                  <Body1>
                    describe the measures taken or proposed to be taken by the
                    Processor to address the breach, including, where
                    appropriate, measures to mitigate its possible adverse
                    effects.
                  </Body1>
                </li>
              </ol>
              <Body1>
                <Li>
                  Where, and in so far as, it is impossible for the Processor to
                  provide the information specified in section 4.1 at the same
                  time, the information may be provided in phases without undue
                  further delay.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>CONFIDENTIALITY</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  The Processor shall ensure that access to the Personal Data is
                  allowed only to such employees, consultants and others under
                  the control of the Processor who, in the course of their work,
                  process the Personal Data to fulfill the Processor’s
                  obligations towards the Controller. The Processor shall ensure
                  that such employees, consultants and others under the control
                  of the Processor are kept apprised of the conditions for
                  Processing the Personal Data provided by this Processing
                  Agreement and that they have committed to confidentiality or
                  are subject to an appropriate statutory obligation of
                  confidentiality.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  The Processor may not without prior written authorisation from
                  the Controller disclose the Personal Data to third parties.
                  The Processor may only disclose Personal Data to third parties
                  where the Processor is subject to a legal obligation to
                  disclose the information. In cases where the Processor is
                  under legal obligation to disclose information, it shall
                  notify the Controller of any disclosure of the Personal Data
                  to third parties and request that the information disclosed is
                  kept confidential by the third party.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>SUBPROCESSORS</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  <span>
                    The Controller has authorised the Processor to engage
                    Subprocessors, as specified in the{' '}
                    <a href='#instructions'>instructions sheet</a>.
                  </span>
                </Li>
              </Body1>
              <Body1>
                <Li>
                  The Processor shall inform the Controller of any intended
                  changes concerning the addition or replacement of
                  Subprocessors.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Where the Processor engages a Subprocessor pursuant to section
                  6.1, the Processor shall have a duty to impose the same
                  obligations regarding the Processing of Personal Data on the
                  Subprocessor, as those that are imposed on the Processor under
                  this Processing Agreement.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  The Processor shall upon the Controller’s request without
                  undue delay provide a copy of the data Processing agreement
                  between the Processor and a Subprocessor to demonstrate that
                  the Processor has fulfilled its obligations under this
                  Processing Agreement.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  The Processor shall upon the Controller’s request without
                  undue delay provide contact information to a Subprocessor
                  Processing the Personal Data.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  The Processor shall remain fully liable towards the Controller
                  for the performance of a Subprocessor’s Processing. The
                  Processor shall notify the Controller without undue delay of
                  any failure by the Subprocessor to fulfill its contractual
                  obligations.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>
              TRANSFER OF PERSONAL DATA TO A THIRD COUNTRY
            </RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  The Processor undertakes not to transfer the Personal Data
                  outside the European Economic Area or to an international
                  organisation without the prior written authorisation of the
                  Controller. Such consent shall be set forth in the
                  instructions sheet attached hereto. Where the Processor is
                  subject to a legal obligation to make such a transfer, the
                  Processor shall promptly notify the Controller and request
                  that the information transferred is kept confidential by the
                  recipient.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Where the Controller authorises the transfer of the Personal
                  Data outside the European Economic Area or to an international
                  organisation pursuant to this Processing Agreement, the
                  Processor and the recipient of the Personal Data shall enter
                  into an agreement based on the EU’s applicable standard
                  contractual clauses (“Commission Decision on standard
                  contractual clauses for the transfer of personal data to
                  processors established in third countries”) or otherwise
                  ensure the transfer to third countries is legitimised in
                  accordance with the Data Protection Laws. If Personal Data is
                  transferred to the United States of America, the Processor
                  will ensure that the recipient is participating in the EU-US
                  Data Privacy Framework.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>AUDITS AND INSPECTIONS</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  The Controller has the right to take appropriate and adequate
                  measures to verify that the Processor complies with the
                  obligations under this Processing Agreement. The Processor
                  shall, on the request of the Controller, make available all
                  information necessary to demonstrate compliance with the
                  obligations set forth in this Processing Agreement and allow
                  for and contribute to audits conducted by the Controller,
                  subject to reasonable notice.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>LIABILITY</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  The limitation of liability stated in the Main Agreement shall
                  apply for this Processing Agreement.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>INSURANCE</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  The Processor will take out insurance to cover the Processor’s
                  liability towards the Controller, Data Subjects or third
                  parties provided that such insurance products are available on
                  the market.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>REMUNERATION</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  The Processor is entitled to remuneration for fulfilling the
                  obligations set out in this Processing Agreement.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>GOVERNING LAW</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  The governing law stated in the Main Agreement shall apply to
                  this Processing Agreement.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>DISPUTE RESOLUTION</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  All disputes arising out of or in connection with this
                  Processing Agreement shall be finally settled in accordance
                  with any applicable dispute resolution clause in the Main
                  Agreement.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>TERM AND TERMINATION</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  This Processing Agreement is in effect as of the entry into
                  force date of the Main Agreement and is valid until the Main
                  Agreement expires or if this Processing Agreement is replaced
                  by another data processing agreement entered into by the
                  Parties.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>AMENDMENTS</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  No amendment to this Processing Agreement shall be considered
                  in effect unless it is made in writing and signed by duly
                  authorised representatives of each of the Parties.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>DESTRUCTION & SURVIVAL</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  Upon expiry of this Processing Agreement, the Processor shall
                  within three (3) months, at the choice of the Controller,
                  delete or return all the Personal Data to the Controller,
                  delete existing copies and confirm compliance with this
                  obligation in writing. The Processor shall ensure that
                  Subprocessors take equivalent measures regarding the Personal
                  Data they process.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  Section 16.1 does not apply to the extent that the Processor
                  or a Subprocessor is subject to a legal obligation to store or
                  otherwise process all or part of the Personal Data. In any
                  such case, the Processor undertakes to observe confidentiality
                  as regards the Personal Data and to refrain from actively
                  Processing the Personal Data unless such Processing is
                  necessary to fulfill the legal obligation.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  The confidentiality obligations as set forth in this
                  Processing Agreement shall survive the termination of the
                  Processing Agreement.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
          <LiSection>
            <RubricTitle>MISCELLANEOUS</RubricTitle>
            <Ol>
              <Body1>
                <Li>
                  The Processing Agreement takes precedence over the Main
                  Agreement in regard to the Processing of Personal Data. In the
                  event of a conflict between the Main Agreement and the
                  Processing Agreement, the terms of the Processing Agreement
                  shall prevail if the conflict relates to Processing of
                  Personal Data. In all other cases the Main Agreement shall
                  prevail.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  This Processing Agreement may not be assigned or otherwise
                  transferred by any Party without the prior written consent of
                  the other Party, provided however that a Party may, without
                  such consent, assign its rights and obligations under this
                  Processing Agreement in connection with a merger,
                  consolidation or sale of substantially all of the business to
                  which this Processing Agreement relates. Any purported
                  assignment or transfer in violation of this section shall be
                  void.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  The Parties understand and agree that no failure or delay in
                  exercising any right, power or privilege hereunder will
                  operate as a waiver thereof, nor will any single or partial
                  exercise of such rights, powers or privileges preclude any
                  other or further exercise thereof. To be effective, any waiver
                  of any right, power or privilege under this Processing
                  Agreement shall be in writing and signed by the Party against
                  whom the waiver is sought to be enforced.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  This Processing Agreement contains and represents the entire
                  agreement between the Parties with respect to the Personal
                  Data and supersedes any prior agreement and understanding,
                  whether written or oral, relating to Personal Data between the
                  Parties.
                </Li>
              </Body1>
              <Body1>
                <Li>
                  By Accepting the Main Agreement, the Controller affirms to
                  have read, understood, and agreed to terms in this Processing
                  Agreement.
                </Li>
              </Body1>
            </Ol>
          </LiSection>
        </Ol>
        <hr style={{ margin: spacing.medium }} />
        <H6 id='instructions'>Instructions sheet</H6>
        <S2Bold>Instructions for the Processing of the Personal Data</S2Bold>
        <table
          style={{
            border: '1px solid black',
            borderCollapse: 'collapse',
          }}
        >
          <tr>
            <Td style={{ width: '30%' }}>
              <TableTitle>Purpose of Processing</TableTitle>
            </Td>
            <Td>
              <Body1>
                The Processor may process Personal Data on behalf of the
                Controller for the purpose of providing the Service, which
                includes receiving bookkeeping data from the Controller,
                connecting the data to a CO2e emissions model, calculating
                results, and presenting them visually to the Controller through
                a web service. The processing of Personal Data is necessary for
                the Processor to deliver the Service to the Controller as
                described in the Main Agreement. The Processor acknowledges that
                the Personal Data may include information related to the
                Controller’s employees, suppliers, and other business relations,
                and that such data may be required for the calculation of
                emissions or may be incidentally included in the bookkeeping
                data provided by the Controller.
              </Body1>
            </Td>
          </tr>
          <tr>
            <Td>
              <TableTitle>Categories of Personal Data</TableTitle>
            </Td>
            <Td>
              <Body1>
                The Processor may process the following categories of Personal
                Data on behalf of the Controller in connection with the
                provision of the Service:
              </Body1>
              <ul>
                <li>
                  <Body1>
                    Contact information: This may include names, email
                    addresses, phone numbers, and postal addresses of the
                    Controller’s employees, suppliers, and other business
                    relations.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Identification information: This may include personal
                    identification numbers, such as social security numbers or
                    tax identification numbers, of the Controller’s suppliers or
                    other business relations, which may be necessary for the
                    Processor to look up parameters for the emissions model.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Financial information: This may include bank account
                    details, payment information, and transaction data related
                    to the Controller’s business operations, which may contain
                    personal names or other identifying information that is
                    difficult to remove.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Other information: Any additional Personal Data that may be
                    included in the bookkeeping data provided by the Controller
                    to the Processor, such as description text for transactions
                    or other data fields that may contain personal information.
                  </Body1>
                </li>
              </ul>
              <Body1>
                The Controller acknowledges that it is responsible for ensuring
                that it has a valid legal basis for processing and transferring
                the Personal Data to the Processor and that it has provided all
                necessary information to the relevant individuals regarding the
                processing of their Personal Data in connection with the
                Service.
              </Body1>
            </Td>
          </tr>
          <tr>
            <Td>
              <TableTitle>Categories of Data Subjects</TableTitle>
            </Td>
            <Td>
              <Body1>
                The Personal Data processed by the Processor on behalf of the
                Controller in connection with the provision of the Service may
                relate to the following categories of Data Subjects:
              </Body1>
              <ul>
                <li>
                  <Body1>
                    Employees: Individuals employed by the Controller, whose
                    Personal Data may be included in the bookkeeping data
                    provided to the Processor.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Suppliers: Individuals or entities that provide goods or
                    services to the Controller, whose Personal Data, such as
                    contact information or identification numbers, may be
                    included in the bookkeeping data for the purpose of
                    calculating emissions or conducting business transactions.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Customers: Individuals or entities that purchase goods or
                    services from the Controller, whose Personal Data may be
                    included in the bookkeeping data in connection with sales
                    transactions or customer management.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Business partners: Individuals or entities that have a
                    business relationship with the Controller, such as
                    consultants, contractors, or collaborators, whose Personal
                    Data may be included in the bookkeeping data.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Other individuals: Any other individuals whose Personal Data
                    may be incidentally included in the bookkeeping data
                    provided by the Controller to the Processor, such as
                    individuals mentioned in transaction descriptions or other
                    data fields.
                  </Body1>
                </li>
              </ul>
              <Body1>
                The Controller acknowledges that it is responsible for informing
                the relevant Data Subjects about the processing of their
                Personal Data by the Processor in connection with the Service
                and for ensuring that such processing is lawful under applicable
                data protection laws.
              </Body1>
            </Td>
          </tr>
          <tr>
            <Td>
              <TableTitle>Processing activities</TableTitle>
            </Td>
            <Td>
              <Body1>
                In order to provide the Service to the Controller, the Processor
                may engage in the following processing activities with respect
                to the Personal Data:
              </Body1>
              <ul>
                <li>
                  <Body1>
                    Collection: The Processor may collect Personal Data from the
                    Controller through the receipt of bookkeeping data.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Storage: The Processor may store the Personal Data on its
                    servers or other data storage systems for the duration
                    necessary to provide the Service and follow legal
                    obligations.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Analysis: The Processor may analyze the Personal Data to
                    connect it to the CO2e emissions model, calculate results,
                    and generate insights or reports for the Controller.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Transformation: The Processor may transform or modify the
                    Personal Data as necessary to perform the analysis and
                    generate the required outputs for the Service.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Disclosure: The Processor may disclose the Personal Data to
                    its employees, subprocessors, or other third parties, but
                    only to the extent necessary to provide the Service and in
                    compliance with the terms of this Processing Agreement and
                    applicable data protection laws.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Deletion: The Processor shall delete or return all the
                    Personal Data to the Controller upon the termination of the
                    Main Agreement, or upon the Controller’s request, unless
                    storage of the Personal Data is required by law.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Security: The Processor shall implement and maintain
                    appropriate technical and organizational measures to ensure
                    the security, confidentiality, and integrity of the Personal
                    Data, as further described in this Processing Agreement.
                  </Body1>
                </li>
              </ul>
              <Body1>
                The Processor shall only process the Personal Data in accordance
                with the Controller’s documented instructions, the terms of this
                Processing Agreement, and applicable data protection laws. The
                Processor shall not process the Personal Data for any other
                purpose or in any other manner unless required to do so by law.
              </Body1>
            </Td>
          </tr>
          <tr>
            <Td>
              <TableTitle>Transfer to third countries</TableTitle>
            </Td>
            <Td>
              <Body1>
                The Processor shall process Personal Data in Sweden, within the
                European Economic Area and using companies in the United States
                of America participating in the EU-US Data Privacy Framework.
              </Body1>
              <Body1>
                Transfers to third countries are authorised provided that the
                Processor adheres to the conditions set forth in 7.2.
              </Body1>
            </Td>
          </tr>
          <tr>
            <Td>
              <TableTitle>Retention and destruction</TableTitle>
            </Td>
            <Td>
              <ul>
                <li>
                  <Body1>
                    Retention period: The Processor shall retain the Personal
                    Data for as long as necessary to fulfill the purposes of the
                    processing as described in this Processing Agreement and the
                    Main Agreement, or for as long as required by applicable
                    laws and regulations.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Data minimization: The Processor shall ensure that the
                    Personal Data is adequate, relevant, and limited to what is
                    necessary for the purposes of the processing. The Processor
                    acknowledges that the bookkeeping data provided by the
                    Controller may contain descriptive texts that are necessary
                    for categorization purposes and that such texts may include
                    Personal Data that is difficult or impractical to separate
                    from the rest of the text without compromising the
                    categorization process. In such cases, the Processor shall:
                  </Body1>
                </li>
                <ol type='a'>
                  <li>
                    <Body1>
                      Process the descriptive texts containing Personal Data
                      only to the extent necessary for the categorization and
                      analysis required to provide the Service;
                    </Body1>
                  </li>
                  <li>
                    <Body1>
                      Not use or disclose the Personal Data embedded within the
                      descriptive texts for any other purpose;
                    </Body1>
                  </li>
                  <li>
                    <Body1>
                      Implement appropriate technical and organizational
                      measures to protect the Personal Data within the
                      descriptive texts from unauthorized access, use, or
                      disclosure;
                    </Body1>
                  </li>
                  <li>
                    <Body1>
                      Delete or anonymize the descriptive texts containing
                      Personal Data when they are no longer needed for the
                      provision of the Service, in accordance with the data
                      retention and destruction provisions of this Processing
                      Agreement.
                    </Body1>
                  </li>
                  <li>
                    <Body1>
                      The Processor shall periodically review the Personal Data
                      it holds and delete or anonymize any data that is no
                      longer needed for the provision of the Service, to the
                      extent that it can do so without compromising the
                      categorization process or the integrity of the bookkeeping
                      data.
                    </Body1>
                  </li>
                </ol>
                <li>
                  <Body1>
                    Destruction upon termination: Upon the termination of the
                    Main Agreement, or upon the Controller’s written request,
                    the Processor shall, at the choice of the Controller, delete
                    or return all the Personal Data to the Controller and
                    instruct its subprocessor, Amazon Web Services (AWS), to
                    delete existing copies in accordance with the standard
                    service agreement between the Processor and AWS, unless
                    storage of the Personal Data is required by law.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Subprocessor’s destruction methods: The Processor relies on
                    AWS, as a subprocessor, to securely and permanently erase
                    the Personal Data using industry-standard methods, such as
                    secure data wiping or physical destruction of storage media,
                    to prevent unauthorized access or reconstruction of the
                    data, in accordance with the terms of the standard service
                    agreement between the Processor and AWS.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Certification of destruction: Upon request by the
                    Controller, the Processor shall provide written
                    certification that it has instructed AWS to delete all
                    Personal Data in accordance with this Processing Agreement
                    and the standard service agreement between the Processor and
                    AWS.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Retention for legal purposes: Notwithstanding the foregoing,
                    the Processor may instruct AWS to retain copies of the
                    Personal Data to the extent required by applicable laws and
                    regulations, such as for tax, accounting, or legal
                    compliance purposes. In such cases, the Processor shall
                    ensure that the retained Personal Data is kept confidential
                    and secure and that it is not processed for any other
                    purpose.
                  </Body1>
                </li>
              </ul>
              <Body1>
                The Processor shall document its data retention and destruction
                procedures, including its instructions to AWS, and make such
                documentation available to the Controller upon request. The
                Processor shall also ensure that its employees and sub
                processors are aware of and follow these procedures.
              </Body1>
            </Td>
          </tr>
          <tr>
            <Td>
              <TableTitle>
                Technical and organisational security measures
              </TableTitle>
            </Td>
            <Td>
              <Body1>
                In addition to the security measures described in the “Security“
                section of this Processing Agreement, the Processor shall
                implement the following measures specific to the processing of
                Personal Data in connection with the Service:
              </Body1>
              <ul>
                <li>
                  <Body1>
                    Access control for categorization and analysis process: The
                    Processor shall ensure that access to the descriptive texts
                    and other variables containing Personal Data, which are
                    necessary for the categorization and analysis process, is
                    strictly limited to the employees or systems involved in the
                    categorization and analysis of the data. Access shall be
                    granted on a need-to-know basis and revoked when no longer
                    necessary.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Secure data transfer: When receiving bookkeeping data from
                    the Controller, the Processor shall use secure file transfer
                    protocols or other encrypted transmission methods to protect
                    the Personal Data from unauthorized access or interception
                    during transit.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Data minimization in outputs: When presenting the results of
                    the analysis to the Controller through the web service, the
                    Processor shall ensure that any Personal Data included in
                    the outputs is limited to what is necessary for the
                    Controller’s purposes and that, where possible, the data is
                    aggregated, anonymized, or pseudonymized to reduce the risk
                    of individual identification.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Subprocessor security: The Processor shall ensure that its
                    subprocessor, Amazon Web Services (AWS), maintains
                    appropriate technical and organizational security measures
                    in accordance with the terms of the standard service
                    agreement between the Processor and AWS. The Processor shall
                    regularly monitor and assess AWS’s compliance with these
                    security obligations.
                  </Body1>
                </li>

                <li>
                  <Body1>
                    Employee confidentiality: The Processor shall ensure that
                    all employees involved in the processing of Personal Data
                    under this Processing Agreement are bound by obligations of
                    confidentiality and have received appropriate training on
                    data protection and security practices specific to the
                    Service.
                  </Body1>
                </li>
              </ul>
              <Body1>
                These additional measures, combined with the security measures
                described in the “Security” section of this Processing
                Agreement, shall ensure a level of security appropriate to the
                risks presented by the processing of Personal Data in connection
                with the Service. The Processor shall regularly review and
                update these measures as necessary to maintain the ongoing
                security of the Personal Data.
              </Body1>
            </Td>
          </tr>
          <tr>
            <Td>
              <TableTitle>Subprocessors</TableTitle>
            </Td>
            <Td>
              <table
                style={{
                  border: '1px solid black',
                  borderCollapse: 'collapse',
                }}
              >
                <tr>
                  <Td>
                    <Body1 style={{ textAlign: 'start' }}>
                      Sub–Processor (entity name)
                    </Body1>
                  </Td>
                  <Td>
                    <Body1>Role and services provided; Location</Body1>
                  </Td>
                </tr>
                <tr>
                  <Td>
                    <Body1>Amazon</Body1>
                  </Td>
                  <Td>
                    <Body1>
                      Server operating system (AWS EC2) and storing files
                      uploaded by the user (AWS S3); Germany{' '}
                    </Body1>
                  </Td>
                </tr>
                <tr>
                  <Td>
                    <Body1>Proff AS</Body1>
                  </Td>
                  <Td>
                    <Body1>
                      Proff API for fetching business information from public
                      registers; Sweden
                    </Body1>
                  </Td>
                </tr>
              </table>
            </Td>
          </tr>
        </table>
      </div>
    </LoginPageWrapper>
  )
}
