import React from 'react'
import { Body1, H5 } from '../../../../components/Typography'

export function CanRegisterPage(): React.JSX.Element {
  return (
    <>
      <H5>Förfrågan har skickats!</H5>
      <Body1>
        Vi behandlar din förfrågan och återkopplar när du kan logga in.
      </Body1>
    </>
  )
}
