import React, { useMemo } from 'react'
import styled from 'styled-components'
import {
  Point,
  PointProps,
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
  VictoryStyleInterface,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory'
import dayjs from 'dayjs'
import { defaultChartTheme } from '../../../chartTheme'
import { Category } from '../../../graphql/generated'
import { colors, spacing } from '../../../theme'
import { getNumberFormatter } from '../../../utils/adaptiveNumberFormat'
import {
  calculateDomain,
  DataPoint as ChartDataPoint,
  lineChartDotSize,
  lineChartLineWidth,
} from '../../../utils/chart'
import { formatYear } from '../../../utils/date'
import { trendColor, TrendPercentage } from '../../TrendPercentage'
import { Body1 } from '../../Typography'
import { TrendGraphData } from './useTrendChartData'
import { DataPoint, GoalData } from '../../../context/GoalContext'
import { ChartLegendOpenDot } from '../victory-util/ChartLegendOpenDot'
import { ChartLabelDashedLine } from '../victory-util/ChartLabelDashedLine'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const Trend = styled('div')`
  position: absolute;
  top: -20px;
  right: 24px;
`

/** Name displayed in chart legend for the partial year (if any). */
const partialYearLabel = 'Hittils i år'
const trendLineLabel = 'Trendutveckling'

/** Utility type for better coding experience: VictoryChart uses `any`. */
type LabelDatum = {
  x: string
  y: number
  style?: VictoryStyleInterface
  childName: string
}

type ChartProps = {
  trendGraphData?: TrendGraphData
  goalEmissionCurve: GoalData['goalEmissionCurve']
  /** Data point for the partial year, if one exists. */
  partialYearEmission: ChartDataPoint | undefined
  useAnnualWorkForce: boolean
  width: number
  /** Selected category, if any. */
  category?: Category
}

/**
 * ChartProps, but all fields except `category` are required.
 * Note that this only affects fields marked with `?:` not `| undefined`.
 */
type ChartPropsForInner = Pick<ChartProps, 'category'> &
  Required<Omit<ChartProps, 'category'>>

/**
 * Expects the parent component to handle any loading or error states
 * for the GQL data. If this component is called with undefuned data,
 * it displays nothing.
 */
export function TrendChart(props: ChartProps): React.JSX.Element | null {
  // This wrapper component is a convenience with two purposes:
  //
  // 1) allows the parent component to pass `undefined` in props.
  // 2) allows the actual implementation (`TrendChartImplementation`)
  //    to know the required props cannot be undefined.
  //
  // Both of these make the code a little less convoluted,
  // since it removes the need for parent/implementation to
  // use `??` and such handling in many cases.
  const { trendGraphData } = props
  if (!trendGraphData) {
    // If the field is undefined, no SVG can be displayed,
    // and the parent should be displayng loading/error,
    // so the return does not matter.
    return null
  }
  return (
    <TrendChartImplementation
      {...props}
      // The linter is not smart enough to realise the null check above
      // proves something about `props`, so pass this field
      // proven to not be undefined by itself.
      trendGraphData={trendGraphData}
    />
  )
}

/**
 * Custom legend symbol (SVG icon) for the trend chart labels.
 */
function LegendSymbol(props: PointProps): React.JSX.Element {
  const { datum } = props
  // Make the partial year legend icon a circle with no fill,
  // somewhat hackish by using the name of the label:
  if (datum?.name === partialYearLabel) {
    return (
      <ChartLegendOpenDot
        {...props}
        data-testid='trend-legend-partial-year-sym'
      />
    )
  } else if (datum?.name === trendLineLabel) {
    return <ChartLabelDashedLine {...props} />
  }
  // The name `Point` is an odd choice of Victory chart;
  // This default component will respect the "symbol" prop and draw
  // any of the standard Victory symbol types:
  return <Point {...props} />
}

/**
 * Implementation of an SVG chart for trend data.
 */
function TrendChartImplementation({
  trendGraphData,
  goalEmissionCurve,
  partialYearEmission,
  useAnnualWorkForce,
  width,
  category,
}: ChartPropsForInner): React.JSX.Element {
  const { emissionData, trendData, trendPercentage, trendRate } = trendGraphData

  const lastEmisDataPoint: ChartDataPoint | undefined =
    emissionData.slice(-1)[0]

  // Goal points, if any:
  // The goal is undefined if displaying per-worker data (useAnnualWorkForce),
  // or if a category is selected, since it cannot be related to either of those [2024-09].
  const goalEmissions: DataPoint[] =
    !useAnnualWorkForce && !category ? goalEmissionCurve : []
  // Extract the last years goal from the list, if one exists,
  // the year can either be the partial year or the last full year.
  // Undefined if there is no goal to display, for any reason.
  const goalThisYear: ChartDataPoint | undefined = goalEmissions
    .map((p) => ({ x: p.x.toFixed(0), y: p.y }))
    .find((p) => p.x === (partialYearEmission?.x ?? lastEmisDataPoint?.x))

  const height = width / 3
  const trendStroke = trendColor(trendPercentage).color

  const formatter = getNumberFormatter(emissionData.map((x) => x.y))

  // All data displayed in any of the graphs, for the purpose
  // of calculating the total domain (for visually displaying only the actual domain).
  const combinedData: ChartDataPoint[] = emissionData
    .concat(partialYearEmission ? [partialYearEmission] : [])
    .concat(goalThisYear ? [goalThisYear] : [])

  const emisColour = category?.color ?? colors.darkGray

  const mainEmissionLineName = 'main'
  const trendLineName = 'trend'
  const partialYearDotName = 'partialYearDot'
  const partialYearLineName = 'partialYearLine'

  // Legend items and their colours.
  // Depending on data and selections, partial year and goal are optional.
  const { legendColours, legendItems } = useMemo(() => {
    return {
      legendItems: [
        { name: category?.name ?? 'Totala utsläpp' },
        // Somewhat ugly way of conditionally including an item in the list:
        ...(partialYearEmission ? [{ name: partialYearLabel }] : []),
        { name: trendLineLabel, symbol: { type: 'minus' } },
        ...(goalThisYear ? [{ name: 'Mål' }] : []),
      ],
      legendColours: [
        emisColour,
        ...(partialYearEmission ? [emisColour] : []),
        trendStroke,
        ...(goalThisYear ? [colors.goalBlue] : []),
      ],
    }
  }, [
    category?.name,
    emisColour,
    partialYearEmission,
    goalThisYear,
    trendStroke,
  ])

  return (
    <Wrapper>
      {trendPercentage !== undefined && (
        <Trend>
          <TrendPercentage percentage={trendPercentage} />
          <Body1>
            {trendRate ? (
              <span>({formatter.format(Math.abs(trendRate))} / år)</span>
            ) : (
              <span>-</span>
            )}
          </Body1>
        </Trend>
      )}

      <VictoryChart
        padding={{
          top: 40,
          left: 80,
          right: 24,
          bottom: 30,
        }}
        theme={defaultChartTheme}
        domain={calculateDomain(combinedData)}
        width={width}
        height={height}
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }: { datum: LabelDatum }) => {
              const { y, childName } = datum
              // Flyout tooltip includes extra description for partial year:
              if (childName === partialYearDotName) {
                return `${formatter.format(y)}\nhittils i år`
              }
              return `${formatter.format(y)}`
            }}
            labelComponent={
              <VictoryTooltip
                flyoutPadding={10}
                flyoutStyle={{ fill: colors.white, padding: spacing.medium }}
                style={{ textAnchor: 'start' }}
                dy={-7}
              />
            }
            // Avoid displaying double flyouts in the chart for
            // overlapping lines/dots by excluding the lines:
            voronoiBlacklist={[
              partialYearLineName,
              trendLineName,
              mainEmissionLineName,
            ]}
          />
        }
      >
        <VictoryLegend
          centerTitle
          orientation='horizontal'
          gutter={20}
          colorScale={legendColours}
          data={legendItems}
          // Use custom legend symbol to enable a different symbol
          // for the partial year:
          dataComponent={<LegendSymbol />}
        />
        <VictoryGroup>
          {/* Main emission line, can be coloured by category. */}
          <VictoryLine
            style={{
              data: {
                stroke: category?.color ?? colors.darkGray,
                strokeWidth: lineChartLineWidth,
              },
            }}
            data={emissionData}
            interpolation='linear'
            name={mainEmissionLineName}
          />
          {/* Main emission dots. */}
          <VictoryScatter
            data={emissionData}
            size={lineChartDotSize}
            style={{
              data: {
                fill: category?.color ?? colors.darkGray,
              },
            }}
          />
          {/* Partial year line continuing from the main emission line (if partial year). */}
          {!!partialYearEmission && (
            <VictoryLine
              data-testid='trendchart-partial-line'
              data={[lastEmisDataPoint, partialYearEmission]}
              style={{
                data: {
                  stroke: category?.color ?? colors.darkGray,
                  strokeWidth: lineChartLineWidth,
                  strokeDasharray: '4, 6',
                },
              }}
              name={partialYearLineName}
            />
          )}
          {/* Partial year open dot (if partial year). */}
          {!!partialYearEmission && (
            <VictoryScatter
              data-testid='trendchart-partial-dot'
              name={partialYearDotName}
              data={[partialYearEmission]}
              size={lineChartDotSize}
              style={{
                data: {
                  fill: colors.white,
                  stroke: category?.color ?? colors.darkGray,
                  strokeWidth: lineChartLineWidth,
                },
                labels: {
                  padding: 20,
                },
              }}
            />
          )}
          <VictoryLine
            data={trendData}
            style={{
              data: {
                stroke: trendStroke,
                strokeWidth: lineChartLineWidth,
                strokeDasharray: '4, 6',
              },
            }}
            name={trendLineName}
          />
          {!!goalThisYear && (
            <VictoryScatter
              data-testid='trendchart-goal-dot'
              data={[goalThisYear]}
              size={lineChartDotSize}
              style={{
                data: {
                  fill: colors.goalBlue,
                },
              }}
            />
          )}
        </VictoryGroup>
        <VictoryAxis
          dependentAxis
          fixLabelOverlap
          orientation='left'
          tickFormat={(emission: number) => formatter.format(emission)}
          style={{
            axis: { stroke: 'transparent' },
            grid: {
              stroke: 'rgba(0,0,0,0.2)',
              strokeDasharray: '4, 8',
            },
          }}
        />
        <VictoryAxis
          fixLabelOverlap
          orientation='bottom'
          tickFormat={(date: number) => formatYear(dayjs.utc(date))}
        />
      </VictoryChart>
    </Wrapper>
  )
}
