export const roundToDecimals = (num: number, numOfDecimals: number): number => {
  const roundWidth = 10 ** numOfDecimals
  return Math.round((num + Number.EPSILON) * roundWidth) / roundWidth
}

/**
 * Round an integer to the nearest multiple of the given value.
 *
 * @param x The value to round
 * @param multipleOf The integer to get multiples of (default 10)
 * @param mode How to perform the rounding (default ceil)
 * @returns The rounded value
 *
 * @example
 * nearestMultiple(2037, 5, 'ceil') === 2040
 * nearestMultiple(2037, 5, 'floor') === 2035
 * nearestMultiple(2037, 5, 'round') === 2035
 * nearestMultiple(2038, 5, 'round') === 2040
 */
export function nearestMultiple(
  x: number,
  multipleOf: number = 10,
  mode: 'ceil' | 'floor' | 'round' = 'ceil',
): number {
  const absMultipleOf = Math.abs(multipleOf) // Allows negative multiples.
  switch (mode) {
    case 'ceil':
      return Math.ceil(x / multipleOf) * absMultipleOf
    case 'floor':
      return Math.floor(x / multipleOf) * absMultipleOf
    case 'round':
      return Math.round(x / multipleOf) * absMultipleOf
    default:
      return Math.ceil(x / multipleOf) * absMultipleOf
  }
}
