import React, { useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { OrganisationFragment, OrgUnit } from '../../../graphql/generated'
import { Dropdown, OutsideClickCatcher } from '../../dropdown/Dropdown'
import { DropdownFooter } from '../../dropdown/DropdownFooter'
import { usePendingSelection } from '../usePendingSelection'
import { FilterOrgList } from './FilterOrgList'
import { useOpenMap } from './useOpenMap'
import { ButtonSelector } from '../../Buttons'

interface Props {
  organisation: OrganisationFragment
  selectedOrgUnit?: OrgUnit
  setOrgUnit: (unit?: OrgUnit) => void
}

export function FilterOrg({
  organisation,
  selectedOrgUnit,
  setOrgUnit,
}: Props): React.JSX.Element {
  const [isOpen, setOpen] = useState(false)
  const { pending, addPending, isDoubleClick } = usePendingSelection<OrgUnit>({
    isOpen,
    selected: selectedOrgUnit,
  })
  const { openMap, openUnit, closeUnit } = useOpenMap(pending)

  const saveUnit = (unit?: OrgUnit) => {
    setOpen(false)
    setOrgUnit(unit)
  }

  const handleClear = () => {
    addPending(undefined)
    setOrgUnit(undefined)
    setOpen(false)
  }

  const handleClick = (unit?: OrgUnit) => {
    if (isDoubleClick(unit)) {
      saveUnit(unit)
    } else if (unit && openMap[unit.id]) {
      addPending(unit)
      closeUnit(unit.id)
    } else {
      addPending(unit)
      openUnit(unit)
    }
  }

  return (
    <OutsideClickCatcher onClickOutside={() => isOpen && setOpen(false)}>
      <ButtonSelector
        onClick={() => setOpen(!isOpen)}
        data-testid='orga-dropdown'
        endIcon={
          isOpen ? (
            <ArrowDropUpIcon style={{ color: '#757575', fontSize: '24px' }} />
          ) : (
            <ArrowDropDownIcon style={{ color: '#757575', fontSize: '24px' }} />
          )
        }
        isActive={isOpen}
      >
        {selectedOrgUnit?.name ?? organisation?.name}
      </ButtonSelector>
      <Dropdown isOpen={isOpen}>
        <FilterOrgList
          organisation={organisation}
          selectedOrgUnit={pending}
          onClick={handleClick}
          openMap={openMap}
        />
        <DropdownFooter
          onClear={handleClear}
          onSave={() => saveUnit(pending)}
        />
      </Dropdown>
    </OutsideClickCatcher>
  )
}
