import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { InnerInnerBox } from '../../../components/Flex'
import { Body1 } from '../../../components/Typography'
import { AppDataContext } from '../../../context/AppDataContext'
import {
  MeDocument,
  useGetIndustrySectorsQuery,
  useUpdateOrganisationBaseInfoMutation,
  useUpdateOrganisationProfileMutation,
} from '../../../graphql/generated'
import { Stepprops } from './ProfileStepper'

export function GeneralStep({
  setSnackbar,
  editEnabled,
  setLoading,
  setSaveAndClose,
  setSaveAndContinue,
  forward,
  close,
  setReset,
}: Stepprops): React.JSX.Element {
  const { organisation } = useContext(AppDataContext)
  const [updateOrganisationBaseInfo] = useUpdateOrganisationBaseInfoMutation({
    refetchQueries: [{ query: MeDocument }],
  })
  const [updateOrganisationProfile] = useUpdateOrganisationProfileMutation({
    refetchQueries: [{ query: MeDocument }],
  })
  const { data: industrySectorData, loading } = useGetIndustrySectorsQuery()

  const signupSchema = Yup.object().shape({
    companyName: Yup.string()
      .min(2, 'För kort!')
      .max(50, 'För lång text, max 50 tecken!')
      .required('Nödvändig'),
    nbEmployees: Yup.number()
      .typeError('Måste vara ett heltal')
      .required('Nödvändig (Måste vara ett heltal)')
      .positive('Måste vara positiv')
      .integer('Måste vara ett heltal'),
    sector: Yup.object({
      id: Yup.string().required(),
      label: Yup.string().required(),
    }).required('Required'),
  })

  const initialValues = useMemo(
    () => ({
      sector: {
        id: organisation.industrialSectorId ?? '',
        label: organisation.industrialSectorName ?? '',
      },
      companyName: organisation.name,
      nbEmployees: organisation.profile?.nbEmployees ?? 1,
    }),
    [
      organisation.industrialSectorId,
      organisation.industrialSectorName,
      organisation.name,
      organisation.profile?.nbEmployees,
    ],
  )

  const {
    submitForm,
    isSubmitting,
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues,
    onSubmit: async (variables): Promise<boolean> => {
      try {
        await updateOrganisationBaseInfo({
          variables: {
            name: variables.companyName,
            industrialSectorId: variables.sector.id,
            industrialSectorName: variables.sector.label,
          },
        })

        await updateOrganisationProfile({
          variables: {
            input: {
              nbEmployees: variables.nbEmployees,
            },
          },
        })

        return true
      } catch (err) {
        setSnackbar({
          children: 'Ett fel uppstod när värdena skickades, försök igen',
          severity: 'error',
        })
      }
      return false
    },
    validationSchema: signupSchema,
  })

  const reset = useCallback(async () => {
    setValues(initialValues)
    close()
  }, [close, initialValues, setValues])

  useEffect(() => {
    setReset(() => reset)
  })

  const saveAndContinue = useCallback(async () => {
    const success = await submitForm()
    if (success) {
      forward()
    }
  }, [forward, submitForm])

  useEffect(() => {
    setLoading(isSubmitting)
  }, [isSubmitting, setLoading])

  useEffect(() => {
    setSaveAndContinue(() => saveAndContinue)
  }, [saveAndContinue, setSaveAndContinue])

  const saveAndClose = useCallback(async () => {
    const success = await submitForm()
    if (success) {
      close()
    }
  }, [close, submitForm])

  useEffect(() => {
    setSaveAndClose(() => saveAndClose)
  }, [saveAndClose, setSaveAndClose])

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <InnerInnerBox>
        <Body1 data-testid='general-step'>
          Kontrollera nedan information om er organisation från
          Företagsregistret. Ändra om något inte stämmer.
        </Body1>
        <TextField
          id='companyName'
          label='Företagsnamn'
          variant='standard'
          style={{ width: '100%' }}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.companyName}
          error={!!errors.companyName}
          helperText={errors.companyName}
          disabled={!editEnabled}
          required
        />
        <Autocomplete
          id='sector'
          disableClearable
          options={industrySectorData?.getIndustrySectors ?? []}
          loading={loading}
          sx={{ width: '100%' }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='standard'
              label='Bransch (SNI)'
              error={!!errors.sector}
              helperText={
                errors.sector
                  ? 'Du måste välja en sektor från listan'
                  : undefined
              }
              required
            />
          )}
          onChange={(event, newValue) => {
            setFieldValue('sector', newValue)
          }}
          onBlur={handleBlur}
          value={values.sector}
          disabled={!editEnabled}
        />
        <TextField
          id='nbEmployees'
          label='Antal anställda (heltid)'
          type='number'
          variant='standard'
          style={{ width: '100%' }}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.nbEmployees}
          error={!!errors.nbEmployees}
          helperText={errors.nbEmployees}
          disabled={!editEnabled}
          fullWidth
          required
        />
      </InnerInnerBox>
    </form>
  )
}
