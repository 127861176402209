import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import Svalna from '../@types'

export const FixedOverlay = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //z-index: 1;
`

export const Portal: Svalna.FCRC = ({ children }) => {
  const ref = useRef(document.createElement('div'))

  useEffect(() => {
    const cur = ref.current
    if (!cur) {
      return () => {}
    }
    document.body.appendChild(cur)
    return () => {
      document.body.removeChild(cur)
    }
  }, [ref])

  return createPortal(children, ref.current)
}
