import React from 'react'
import { Flex } from '../Flex'
import { Body1, H5Bold } from '../Typography'
import { AppDataContext } from '../../context/AppDataContext'
import {
  contentPadding,
  headerHeight,
  contentWidth,
  navbarWidth,
  spacing,
} from '../../theme'

export function NoTransactionView(): React.JSX.Element {
  const { orgHasInitializedData } = React.useContext(AppDataContext)

  return (
    <Flex
      column
      itemsCenter
      style={{
        position: 'fixed',
        left: navbarWidth,
        top: headerHeight,
        padding: contentPadding,
        zIndex: 100,
        height: '100vh',
        width: contentWidth,
        backdropFilter: 'blur(7px)',
        gap: spacing.medium,
      }}
    >
      <>
        <H5Bold>
          På den här sidan kan du korrigera kategoriseringen av transaktioner.
        </H5Bold>
        {!orgHasInitializedData ? (
          <Body1 data-testid='default'>
            Dina uppgifter har inte behandlats ännu. Den här funktionen blir
            tillgänglig när din data har hanterats.
          </Body1>
        ) : (
          <Body1 data-testid='default'>
            För att kunna använda denna funktion behöver vi lagra dina
            transaktionsdata på våra servrar. Detta kan kräva vissa ändringar i
            det användaravtal vi har med din organisation. Vänligen kontakta oss
            om du är intresserad av denna funktion.
          </Body1>
        )}
      </>
    </Flex>
  )
}
