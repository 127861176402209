import React from 'react'
import { Body1, Body1Bold, H5Bold } from '../../../components/Typography'
import { ButtonGreen } from '../../../components/Buttons'
import onboardingImage from '../../../assets/images/onboarding.png'
import { Flex, InnerInnerBox } from '../../../components/Flex'

export function WelcomeStep({
  setShowWelcome,
}: {
  setShowWelcome: (step: boolean) => void
}): React.JSX.Element {
  return (
    <>
      <Body1Bold>Klimatprofilen</Body1Bold>
      <InnerInnerBox>
        <img
          src={onboardingImage}
          alt=''
          style={{
            alignSelf: 'center',
            maxHeight: 328,
            minHeight: 200,
            height: '30vh',
          }}
        />
        <H5Bold data-testid='welcome-step'>Välkommen!</H5Bold>
        <Flex column>
          <Body1>Nu är det dags att fylla i klimatprofilen.</Body1>
          <Body1>
            Vi hämtar nu data från ert ekonomisystem och olika offentliga
            register. Datan som hämtas från offentliga register används för att
            komplettera den ekonomiska datan och ta fram minskningsförslag.
            Hjälp oss kontrollera och komplettera informationen. Du kan
            komplettera profilen senare om du saknar information nu.
          </Body1>
        </Flex>
      </InnerInnerBox>
      <ButtonGreen
        style={{ alignSelf: 'center' }}
        onClick={() => {
          setShowWelcome(false)
        }}
      >
        Starta Klimatprofilen
      </ButtonGreen>
    </>
  )
}
