import React from 'react'
import Joyride from 'react-joyride'
import { Dialog } from '@mui/material'
import { useEmissionTourContext } from '../../context/EmissionTourContext'
import { TourTooltip } from './TourTooltip'

// General notes on Joyride:
// - Must use "controlled mode" to accomplish intended design,
//   see example https://github.com/gilbarbara/react-joyride-demo/blob/main/src/Controlled/index.tsx
// - The "spotlight" misses the target if elements
//   resize as they load fully. Should probably not be an issue,
//   as the tour is intended to start after a delay,
//   which should give enough time for everything to load.
// - If the header/nav need to be scrollable, expect to
//   have to handle issues of the highlight not positioning,
//   see e.g. https://github.com/gilbarbara/react-joyride/issues/376

/**
 * Joyride tour portal, controlled by the tour context state.
 *
 * This component handles creating the various components for
 * displaying the tour (overlay, highlighting, etc).
 */
export function Tour(): React.JSX.Element {
  // TODO: If we add more tours, add more contexts;
  //       Or perhaps even better, make a utility hook which
  //       uses all the contexts and presents only the active one
  //       so this component can be very straight forward.
  const { callback, ongoing, tourActive, steps, stepIndex, minOverlayWidth } =
    useEmissionTourContext()

  const stepData = steps[stepIndex]?.data
  const stepScrollOffset: number | undefined = stepData?.scrollOffset?.()

  return (
    <>
      <Joyride
        tooltipComponent={TourTooltip}
        callback={callback}
        steps={steps}
        // `run` is true when there is an active step, which is
        // currently displaying a tooltip.
        // This may temporarily be set to `false` during an ongoing tour,
        // if it is waiting for a programmatically controlled action
        // such as opening the pie accordion.
        run={ongoing && tourActive}
        stepIndex={stepIndex}
        continuous
        // Strings displayed for the tooltip buttons.
        locale={{
          back: 'TILLBAKA',
          next: 'NÄSTA TIPS',
          last: 'FÄRDIG',
        }}
        styles={{
          overlay: {
            // Required when the page content is not tall enough
            // to cover the full height of the viewport.
            minHeight: '100vh',
            // Required for the overlay to be wide enough to cover the whole page
            // when the screen is small enough to need horisontal scroll.
            minWidth: minOverlayWidth,
          },
        }}
        scrollOffset={stepScrollOffset}
        // Clicking the overlay normally closes the tour,
        // we want a click to do nothing.
        disableOverlayClose
      />
      {/*
        When the tour is ongoing but not active, i.e. between controlled steps
        such as waiting for the pie accordion animation,
        the Joyride overlay disappears. For that span of time,
        this Dialog is displayed so that there is still an overlay.
        Note: There is still a slight blink when the overlay changes.
      */}
      <Dialog open={ongoing && !tourActive} sx={{ zIndex: 5 }} />
    </>
  )
}
