import styled from 'styled-components'
import { Box } from '../../../components/Box'
import { Flex } from '../../../components/Flex'
import { Body1Bold, Body2 } from '../../../components/Typography'
import { spacing } from '../../../theme'

export const SmallCardBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: ${spacing.large};
`

export const SmallCardHeader = styled('div')`
  display: flex;
  justify-content: space-between;
`

export const SmallCardTitle = styled(Body1Bold)`
  min-height: 2rem;
`

export const SmallCardWrapper = styled(Flex)`
  flex-direction: row;
  align-items: stretch;
  gap: ${spacing.large};
`

export const SmallCardFooter = styled(Body2)`
  min-height: 0.75rem;
`
export const SmallCardFooterBold = styled(SmallCardFooter)`
  font-weight: bold;
`

export const SmallCardContent = styled.div`
  min-height: 58px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 16px 0;
`
