import _ from 'lodash'
import { textCO2e } from '../components/Typography'
import { CO2eFormat } from '../constants'
import { FilterInput } from '../graphql/generated'

type FormatOptions = {
  co2ePostfix?: boolean
  hideUnit?: boolean
  showSeparateSign?: boolean
}

export type NumberFormatter = {
  unit: string
  round: (x: number) => number
  format: (val: number, optionsOverride?: FormatOptions) => string
}

export const getNumberFormatter = (
  vals: number[],
  options?: FormatOptions,
): NumberFormatter => {
  const res: NumberFormatter = {
    round: (x: number) => Math.round(x),
    unit: 'kg',
    format: () => '',
  }

  const maxval = Math.abs(_.max(vals) ?? 1e4)

  // Get a format option, using the supplied options if available, otherwise falling back to this formatter's options.
  const getOption = <K extends keyof FormatOptions>(
    k: K,
    op?: FormatOptions,
  ): FormatOptions[K] | undefined => op?.[k] ?? options?.[k]

  // Locale used for cultural context when formatting numbers.
  const localeCulture = 'sv' // TODO: Read from user preferences?

  // Internal formatter for numeric value: captures behaviour such as culture and number of fractional digits.
  const numberFormatter: {
    numberFormat: (
      x: number,
      overrideOpt?: FormatOptions,
      intlOpts?: Intl.NumberFormatOptions,
    ) => string
  } = {
    numberFormat: () => '',
  }

  // NOTE: This captures 'res' and uses res.round -- make sure to set res.round before calling.
  const format_value = (
    x: number,
    overrideOpt?: FormatOptions,
    intlOpts?: Intl.NumberFormatOptions,
  ) => {
    const valsign = x >= 0 ? '+' : '-'
    // When a prefixed sign should be displayed, the normal sign formatting must be disabled;
    // Prevent double minus-signs when forcing a prefix-sign, by using the absolute value for display.
    const show_sign = getOption('showSeparateSign', overrideOpt)
    const prefix_sign = show_sign ? `${valsign} ` : ''
    const v = show_sign ? Math.abs(x) : x
    return `${prefix_sign}${res
      .round(v)
      .toLocaleString(localeCulture, intlOpts)}`
  }

  if (maxval < 1e3) {
    res.unit = 'kg'
    res.round = (val) => val
    numberFormatter.numberFormat = (x: number, overrideOpt?: FormatOptions) =>
      format_value(x, overrideOpt, { maximumFractionDigits: 0 })
  } else if (maxval < 1e6) {
    res.unit = 'ton'
    res.round = (val) => val / 1e3
    numberFormatter.numberFormat = (x: number, overrideOpt?: FormatOptions) =>
      format_value(x, overrideOpt, {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      })
  } else if (maxval < 1e9) {
    res.unit = 'kton'
    res.round = (val) => val / 1e6
    numberFormatter.numberFormat = (x: number, overrideOpt?: FormatOptions) =>
      format_value(x, overrideOpt, {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      })
  } else {
    res.unit = 'Mton'
    res.round = (val) => val / 1e9
    numberFormatter.numberFormat = (x: number, overrideOpt?: FormatOptions) =>
      format_value(x, overrideOpt, {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      })
  }

  const unit = (overrideOpt?: FormatOptions) =>
    getOption('hideUnit', overrideOpt) ? '' : ` ${res.unit}`
  const postfix = (overrideOpt?: FormatOptions) =>
    getOption('co2ePostfix', overrideOpt) ? ` ${CO2eFormat}` : ''

  res.format = (val, overrideOpt) =>
    `${numberFormatter.numberFormat(val, overrideOpt)}${unit(
      overrideOpt,
    )}${postfix(overrideOpt)}`

  return res
}

type UnitDescriptionOptions = {
  hideUnit?: boolean
}
export const unitDescription = (
  filter: FilterInput,
  options?: UnitDescriptionOptions,
): string => {
  let suffix = ''
  if (filter.useAnnualWorkForce) {
    suffix = `${suffix} / anställd`
  }

  if (filter.partialYear) {
    if (filter.useAnnualWorkForce) {
      suffix = `${suffix} (snitt delår)`
    } else {
      suffix = `${suffix} (totalt delår)`
    }
  } else if (!filter.year) {
    if (filter.useAnnualWorkForce) {
      suffix = `${suffix} (snitt alla år)`
    } else {
      suffix = `${suffix} (totalt alla år)`
    }
  }

  return `${!options?.hideUnit ? textCO2e : ''}${suffix}`
}
