import React, { useContext, useEffect, useState } from 'react'
import {
  FlexingBox,
  FlexingBoxContent,
  HeadRow,
  InnerBox,
  InnerBoxText,
} from '../Flex'
import { TitleWithSub } from '../TitleWithSub'
import { ButtonGreen } from '../Buttons'
import {
  Job,
  RecatRuleInfoFragment,
  useApplyRulesMutation,
  useGetRecatRulesQuery,
} from '../../graphql/generated'
import { JobContext } from '../../context/JobContext'
import { formatDateFilter } from '../../utils/date'
import { Body2, Body2Bold } from '../Typography'
import { RuleTable } from './components/RuleTable'
import { SnackAlert } from '../SnackAlert'

export function RulesBox(): React.JSX.Element {
  const { jobRunning, setJobRunning, handleJob, jobSnack } =
    useContext(JobContext)

  const [error, setError] = useState('')

  const [rulesRows, setRulesRows] = useState<
    Array<RecatRuleInfoFragment & { dateFilter: string }>
  >([])
  const recatRules = useGetRecatRulesQuery()
  const [applyRulesMutation] = useApplyRulesMutation()

  const applyRules = async () => {
    try {
      const result = await applyRulesMutation()
      if (result.data?.applyRules) {
        handleJob(result.data?.applyRules as Job)
      } else if (result.errors) {
        setError('Ett fel inträffade när regeln skulle tillämpa')
        setJobRunning(false)
      }
    } catch (err) {
      setError('Ett fel inträffade när regeln skulle tillämpa')
      setJobRunning(false)
    }
  }

  useEffect(() => {
    setRulesRows(
      recatRules.data?.getRecatRules.map((t) => {
        const supplierNames = t.supplierNames?.map((name) =>
          // remove regex characters
          name.substring(1, name.length - 1),
        )
        const suppliers = (t.supplierIds ?? []).concat(supplierNames ?? [])

        return {
          ...t,
          dateFilter: formatDateFilter(
            t.startDate,
            t.endDate,
            t.dateSearchType,
          ),
          suppliers,
        }
      }) ?? [],
    )
  }, [recatRules])

  return (
    <FlexingBox>
      <SnackAlert open={!!error} severity='error' onClose={() => setError('')}>
        {error}
      </SnackAlert>
      <HeadRow>
        <TitleWithSub title='Regler' sub='' />
      </HeadRow>
      <FlexingBoxContent>
        {rulesRows.find((rule) => !rule.applied) && (
          <InnerBox>
            <InnerBoxText>
              <Body2Bold>Regler att tillämpa</Body2Bold>
              <Body2>
                Dessa regler är inte tillämpade och har ingen effekt på
                utsläppsresultaten ännu. Samla fler regler och tillämpa dem
                samtidigt för att spara tid. Det kan ta en stund att räkna om
                utsläppen.
              </Body2>
            </InnerBoxText>
            <RuleTable rulesRows={rulesRows.filter((rule) => !rule.applied)} />
            <ButtonGreen
              onClick={() => applyRules()}
              disabled={
                jobRunning ||
                jobSnack.severity === 'error' ||
                !rulesRows.find((rule) => !rule.applied)
              }
              style={{ alignSelf: 'center' }}
            >
              Tillämpa regler
            </ButtonGreen>
          </InnerBox>
        )}

        <InnerBox>
          <Body2Bold>Tillämpade regler</Body2Bold>
          <Body2>
            Dessa regler är tillämpade och påverkar organisationens
            utsläppsresultat.
          </Body2>
          <RuleTable rulesRows={rulesRows.filter((rule) => rule.applied)} />
        </InnerBox>
      </FlexingBoxContent>
    </FlexingBox>
  )
}
