import React, { useState } from 'react'
import Svalna from '../@types'

interface IOnboardContext {
  currentSlide?: number
  setCurrentSlide: (index?: number) => void
}

const initialState: IOnboardContext = {
  setCurrentSlide: () => {},
}

export const OnboardContext = React.createContext<IOnboardContext>(initialState)

const { Provider } = OnboardContext

export function OnboardContextProvider({
  children,
}: Svalna.PropWithChildren): React.JSX.Element {
  const [currentSlide, setCurrentSlide] = useState(initialState.currentSlide)

  return (
    <Provider
      value={{
        currentSlide,
        setCurrentSlide,
      }}
    >
      {children}
    </Provider>
  )
}
