import type React from 'react'
import { Job, JobError, JobStatus, Maybe } from '../graphql/generated'

export type onSuccessCallback = (job: Job) => Promise<void>

export const handleJobInt = async (
  job: Job,
  errorHandler: (error: Maybe<JobError> | undefined) => boolean,
  setState: React.Dispatch<React.SetStateAction<string | undefined>>,
  onDone: onSuccessCallback,
): Promise<boolean> => {
  let jobRunning = true
  const error = job?.error

  let msg: string | undefined
  if (errorHandler(error)) {
    msg = 'ERROR'
    jobRunning = false
  }
  if (job?.status) {
    switch (job.status) {
      case JobStatus.Downloading:
        msg = `Laddar ner`
        break
      case JobStatus.Pending:
        msg = `Inväntar svar`
        break
      case JobStatus.Processing:
        msg = `Bearbetar`
        break
      case JobStatus.Started:
        msg = `Satte igång`
        break
      case JobStatus.None:
        jobRunning = false
        break
      case JobStatus.Done:
        await onDone(job)
        return false
      case JobStatus.Error:
        jobRunning = false
        msg = job.status
        break
      default:
        msg = job.status
    }
    if (job?.progress !== undefined && job.progress !== null) {
      msg = `${msg ?? ''} (${job.progress}%)`
    }
  }
  setState(msg)
  return jobRunning
}
