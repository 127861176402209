import React, { useEffect, useLayoutEffect, useRef } from 'react'
import Svalna from '../../@types'

export enum EmissionSectionId {
  category,
  subDepartments,
  emissionsOverTime,
  toplist,
  compare,
  trend,
  trendPerCategory,
}

export enum SysadminSectionId {
  users,
  organisations,
  sso,
  registrations,
}

export enum RecatSectionId {
  searchCriteria,
  ruleCreation,
  searchResult,
  rules,
}

export type SectionId = EmissionSectionId | SysadminSectionId | RecatSectionId

export type SectionDef = {
  text: string
  sectionId: SectionId
  section: React.JSX.Element
}

interface SectionProps extends Svalna.PropWithChildren {
  active: { scroll: boolean; sectionId: SectionId }
  onVisibilityChange: (sectionId: SectionId, isVisible: boolean) => void
  sectionId: SectionId
}

export function Section({
  children,
  sectionId,
  onVisibilityChange,
  active,
}: SectionProps): React.JSX.Element {
  const ref = useRef<HTMLDivElement>(null)
  const scroll = active.sectionId === sectionId && active.scroll

  useEffect(() => {
    const element = ref.current
    if (!element) {
      return
    }
    const observer = new IntersectionObserver(
      (list) => {
        const [entry] = list
        onVisibilityChange(sectionId, entry.isIntersecting)
      },
      {
        // null = compare target's intersection with the browser viewport.
        root: null,
        // Extra margin for the root (here, viewport) intersection rectangle when
        // computing the intersection. Positive values mean the target rectangle
        // is considered intersecting even if it is outside the root by that amount.
        // Negative values mean it is considered not intersecting when it reaches that
        // amount.
        // The order is top, right, bottom, left. Values must be % or px.
        //
        // Note: at the time of writing, the largest graphs do not fully
        // fit in the viewport with normal zoom on some screens, and so they
        // are never considered visible if the margin is zero.
        // With some extra margin, it works as intended.
        //
        // Note: If zoomed out, multiple elements are visible at the same time,
        // and it is not clear which one will win focus.
        rootMargin: '50px 0px 50px 0px',
        // Ratio of how much of the target must be visible
        // before the callback is called.
        threshold: 1.0,
      },
    )

    observer.observe(element)
    return () => observer.unobserve(element)
  }, [sectionId, onVisibilityChange])

  useLayoutEffect(() => {
    if (ref?.current && scroll) {
      ref.current.scrollIntoView({
        behavior: 'auto',
        inline: 'center',
        block: 'center',
      })
    }
  }, [scroll, ref])

  return <div ref={ref}>{children}</div>
}
