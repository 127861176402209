import { Category } from '../graphql/generated'

export const toCategoryMap = (
  categories: Category[],
): Record<string, Category> => {
  let result: Record<string, Category> = {}

  categories?.forEach((c) => {
    result[c.id] = c

    if (c.children) {
      result = { ...result, ...toCategoryMap(c.children) }
    }
  })

  return result
}

/**
 * Fallback to category if parent does not exist
 * */
export const getParentColor = (
  categoryMap: Record<string, Category | undefined>,
  categoryId?: string | null,
): string | undefined => {
  if (!categoryId) return undefined

  const category = categoryMap[categoryId]
  if (!category) return undefined

  if (!category.parentId) return category.color

  return categoryMap[category.parentId]?.color
}

export const findTopLevelCategory = (
  categoryMap: Record<number, Category | undefined>,
  categoryId: number,
): Category | undefined => {
  const category = categoryMap[categoryId]

  if (!category) {
    return undefined
  }

  if (!category.parentId) {
    return category
  }

  return findTopLevelCategory(categoryMap, category.parentId)
}
