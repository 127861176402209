import { useMemo } from 'react'
import { ApolloError } from '@apollo/client'
import _ from 'lodash'
import { FilterInput, Period, useGetTrendQuery } from '../../graphql/generated'
import type { DataPoint } from '../../context/GoalContext'
import { OpenEndedRange } from '../../utils/sequence'
import { interpolateSequence } from './interpolationUtil'

/**
 * Retrieve emission data without the partial year.
 * The emissions are interpolated over any gaps in the existing data.
 *
 * Note: for the purposes of computing goal progress, emission interpolation
 *       can be defended -- it makes more sense to assume a gap in emisson
 *       data actually had some emissions, as opposed to zero -- for other purposes,
 *       it may not make sense to use interpolated data.
 *
 * @param filter
 * @returns Query status, and interpolated data on success.
 */
export function useInterpolatedEmissions(filter: FilterInput): {
  /**
   * Linearly interpolated emissions, for the given filter.
   * All gaps returned in `emissonGaps` are interpolated in this data.
   */
  interpolatedEmissions: DataPoint[]
  /**
   * List of ranges where emissions are defined in the emission data (before interpolation).
   */
  emissionRanges: OpenEndedRange[]
  /**
   * List of ranges where there are gaps in the emission data (before interpolation).
   */
  emissonGaps: OpenEndedRange[]
  loading: boolean
  error: ApolloError | undefined
} {
  // Note: `useGetTrendQuery` never returns the partial year;
  //       we actually only care about the emissions, not the trend.
  const { data, loading, error } = useGetTrendQuery({
    variables: {
      filter,
      period: Period.Year,
    },
  })

  const { emissions, emissionRanges, emissonGaps } = useMemo<{
    emissions: DataPoint[]
    emissionRanges: OpenEndedRange[]
    emissonGaps: OpenEndedRange[]
  }>(() => {
    const unorderedEmissions = (data?.getTrend.emissions ?? [])?.map<DataPoint>(
      (e) => ({
        x: Number.parseInt(e.period, 10),
        y: e.totalCO2e,
      }),
    )
    // Interpolate data for any gaps in the emission data, i.e. years where
    // there is no data at all.
    const {
      ranges,
      gaps,
      filledSequence: filledEmissions,
    } = interpolateSequence(
      unorderedEmissions,
      'x',
      (x, i, n, before, after) => {
        const delta = after.y - before.y
        const c = delta / (n + 1)
        return {
          x,
          y: before.y + c * (i + 1),
        }
      },
    )
    // Sort by year:
    const es = _.orderBy(filledEmissions, (e) => e.x)
    return {
      emissions: es,
      emissionRanges: ranges,
      emissonGaps: gaps,
    }
  }, [data])

  return {
    interpolatedEmissions: emissions,
    emissionRanges,
    emissonGaps,
    loading,
    error,
  }
}
