import { createHttpLink } from '@apollo/client'

export const httpLink = createHttpLink({
  /*
   * Uses the configured uri if present.
   * If not the uri will be based on the hostname and protocol the page is curently using.
   * we need this flexibility to be able to run two hostnames on the same host (for SSO).
   */
  uri: process.env.REACT_APP_SERVER
    ? `${process.env.REACT_APP_SERVER}/graphql`
    : `/graphql`,
})
