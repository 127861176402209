import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Button, ButtonProps, IconButton } from '@mui/material'
import { Flex } from '../../Flex'
import { Category } from '../../../graphql/generated'
import { colors } from '../../../theme'
import { CategroyPieData, EmissionsData } from './useCategoryPieData'

interface Props {
  emissions: EmissionsData | undefined
  category: Category | undefined
  setCategory: React.Dispatch<React.SetStateAction<Category | undefined>>
  selectedCategory: Category | undefined
  setSelectedCategory: React.Dispatch<
    React.SetStateAction<Category | undefined>
  >
  setEmissions: React.Dispatch<React.SetStateAction<EmissionsData | undefined>>
  pieData: CategroyPieData
}

// Button which is grey when active and black when disabled
// it make sens with the design
export function ReverseButton({
  children,
  canGoBack,
  ...props
}: ButtonProps & { canGoBack: boolean }): React.JSX.Element {
  return (
    <Button
      {...props}
      style={{
        ...props.style,
        color: canGoBack ? colors.gray : colors.black,
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      {children}
    </Button>
  )
}

export function CategoryBreadCrumbs({
  emissions,
  category,
  setCategory,
  selectedCategory,
  setSelectedCategory,
  setEmissions,
  pieData,
}: Props): React.JSX.Element {
  const handleBackClick = () => {
    setSelectedCategory(undefined)
    if (emissions?.history) {
      setEmissions(emissions.history)
    } else {
      setCategory(undefined)
    }
  }

  // returns to the view with all the categories
  const backToAllCategories = () => {
    setCategory(undefined)
    setSelectedCategory(undefined)
  }

  // return to the category view
  const backToCategory = () => {
    setEmissions(pieData.data)
    setSelectedCategory(undefined)
  }

  const getHistoryPath = () => {
    let history = emissions
    const historyArray = []
    while (history) {
      if (history.history) {
        historyArray.push(history)
      }
      history = history.history
    }
    return historyArray.reverse()
  }

  return (
    <Flex itemsCenter>
      <IconButton
        aria-label='back'
        onClick={handleBackClick}
        disabled={!category}
        data-testid='back-button'
      >
        <ArrowBackIcon />
      </IconButton>
      <ReverseButton
        canGoBack={!!category}
        onClick={backToAllCategories}
        disabled={!category && !selectedCategory}
        data-testid='all-cat'
      >
        ALLA KATEGORIER
      </ReverseButton>
      {category && (
        <>
          <KeyboardArrowRightIcon />
          <ReverseButton
            canGoBack={!!emissions?.history}
            onClick={backToCategory}
            disabled={!emissions?.history && !selectedCategory}
            data-testid='category-button'
          >
            {category.name}
          </ReverseButton>
        </>
      )}
      {getHistoryPath().map((history, index, array) => {
        return (
          <>
            <KeyboardArrowRightIcon />
            <ReverseButton
              canGoBack={index !== array.length - 1}
              onClick={() => {
                setEmissions(history)
                setSelectedCategory(undefined)
              }}
              disabled={index === array.length - 1 && !selectedCategory}
              data-testid={`other-${index}`}
            >
              Övrigt
            </ReverseButton>
          </>
        )
      })}
    </Flex>
  )
}
