import { ApolloError } from '@apollo/client'
import {
  Emission,
  EmissionInput,
  useEmissionsQuery,
} from '../graphql/generated'

export function useEmissionData(input: EmissionInput): {
  data: Emission[]
  loading: boolean
  error: ApolloError | undefined
} {
  const { loading, data, error, previousData } = useEmissionsQuery({
    variables: { input },
  })
  const emissions = loading ? previousData?.getEmission : data?.getEmission
  return { data: (emissions ?? []) as Emission[], loading, error }
}
