import React, { useContext } from 'react'
import { Layout } from '../../components/Layout'
import { Flex } from '../../components/Flex'
import { GoalContext } from '../../context/GoalContext'
import { LayoutContent } from '../../components/LayoutContent'
import { GoalSetting } from '../../components/goal/GoalSetting'
import { Box } from '../../components/Box'

export function GoalPage(): React.JSX.Element {
  const { loading } = useContext(GoalContext)
  return (
    <Layout title='Målsättning' loading={loading}>
      <LayoutContent>
        <Flex row justifyCenter>
          <Box>
            <GoalSetting />
          </Box>
        </Flex>
      </LayoutContent>
    </Layout>
  )
}
