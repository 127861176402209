import type React from 'react'

type CSSColor = NonNullable<React.CSSProperties['color']>

/** @deprecated Use `colors.goalBlue` from theme */
export const goalLineColor: CSSColor = '#00A96A'
export const emissionLineColor: CSSColor = '#2D2D3C'
export const emissionAreaColor: CSSColor = '#5B5B65'
export const interpolatedEmissionLineColor: CSSColor = '#5d5d6f'
export const interpolatedEmissionAreaColor: CSSColor = '#8d8d9f'
export const planLineColor: CSSColor = '#A3A3A7'
export const guideLineColor: CSSColor = '#B0B0B0'
