/* eslint-disable */
import { Observable, ServerError, fromPromise } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { updateToken } from '../utils/auth'
import { appHistory } from '../utils/history'
import { LogoutError } from '../components/error-boundry/LogoutError'
import { GraphQLError } from 'graphql'
import { captureException } from '@sentry/react'

type CustomerGraphQLError = {
  code: number
  message: string
  path: string
}

let isRefreshing = false
let pendingRequests: any[] = []

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback())
  pendingRequests = []
}

export const errorLink = onError(
  ({ networkError, operation, graphQLErrors, forward }) => {
    console.log('GraphQL Error', networkError, graphQLErrors)
    captureException([networkError, graphQLErrors])
    if (graphQLErrors !== undefined) {
      const customErrors = graphQLErrors as unknown as CustomerGraphQLError[]
      for (const err of customErrors) {
        switch (err.code) {
          case 401: {
            // Unauthenticated
            if (operation.operationName === 'ResfreshToken') {
              // The server normaly return a 403 when failling the RefreshToken operation.
              // But, if we use SWAMID the shibboleth system may intercept the query before it reach the server
              // and keep sending back 401 error.
              // In this case the user will need to log back into shibboleth, which can only be done by loging them out first.
              // Just returning here will fail the RefreshToken operation, this will automatically throw an error which will be
              // handled by the caller of updateToken (bellow).
              return
            }

            // forward$ is an observable, the apollo client subscribe to it and wait for it to be done
            // by callin fromPromise we create an observable which will call it's subscribers when the
            // promise finish
            if (!isRefreshing) {
              // no other operation is trying to refresh the token. let's do it
              isRefreshing = true
              // create an obeservable that will finish when the token is updated
              const forward$ = fromPromise(
                updateToken()
                  .then(({ accessToken, refreshToken }) => {
                    // the token is refreshed. Resovle the other observable promises
                    // so that they stop waiting.
                    resolvePendingRequests()
                    return accessToken
                  })
                  .catch((error) => {
                    pendingRequests = []
                    appHistory.push('/logout')
                  })
                  .finally(() => {
                    isRefreshing = false
                  }),
              )

              // flatMap on an observable call the callback passed to it as an argument
              // each time the observable emit (in this case only once).
              // So, this will retrie the operation or return the result when the forward$ promise has finish executing
              return forward$.flatMap((accessToken) => {
                if (accessToken) {
                  // the token has been refreshed. Retry the operation
                  return forward(operation)
                }
                // The token as not been refreshed, we have called appHistory.push('logout').
                // To avoid a race condition between the redirection to the logout page and
                // the handling of this query response, we return a LogoutError. If this error
                // reach the error boundary, the error boundary will also redirect to logout instead
                // of showing an error to the user and stoping the redirection to logout.
                return Observable.of({
                  errors: [
                    new GraphQLError(
                      'logout', // message
                      undefined, // nodes
                      undefined, // source
                      undefined, // postion
                      undefined, // path
                      new LogoutError(), // originalError
                    ),
                  ],
                })
              })
            } else {
              // Put the request to wait for the token to be refreshed before to retry
              // This obeservable will only emit once the Promise is resolved
              // which will be done when calling resolvePendingRequests
              const forward$ = fromPromise(
                new Promise<void>((resolve) => {
                  pendingRequests.push(() => resolve())
                }),
              )

              // flatMap on an observable call the callback passed to it as an argument
              // each time the observable emit (in this case only once).
              // So, this will retries the operation when the forward$ promises have resolved.
              return forward$.flatMap(() => forward(operation))
            }
          }
          case 403: {
            // Forbidden
            appHistory.push('/logout')
            return undefined
          }
          default:
          // no op
        }
      }
    }
    if (networkError) {
      if (Object.prototype.hasOwnProperty.call(networkError, 'statusCode')) {
        const err = networkError as ServerError
        if (err.statusCode === 401) {
          // We get a 401 before reaching the graphql server.
          // This happen when the shibboleth session as expired and intercept the requests.
          // We need to log them out for them to relog into shibboleth
          appHistory.push('/logout')
          return undefined
        }
      }
    }
  },
)
