import type { ApolloError } from '@apollo/client'
import {
  CategoryTrendEmission,
  EmissionFieldsFragment,
  FilterInput,
  Period,
  useGetCategoryTrendsQuery,
} from '../../../graphql/generated'

type LineChartInfo = {
  loading: boolean
  error: ApolloError | undefined
  period: Period
  categoryTrends: Array<
    Pick<
      CategoryTrendEmission,
      'percentage' | 'categoryId' | 'categoryName' | 'categoryColor'
    > & { emissions: Array<EmissionFieldsFragment> }
  >
}

export const useCategoryLineCharts = (filter: FilterInput): LineChartInfo => {
  const { data, loading, error, previousData } = useGetCategoryTrendsQuery({
    variables: {
      filter,
    },
  })

  return {
    loading,
    error,
    categoryTrends:
      (loading ? previousData?.getCategoryTrends : data?.getCategoryTrends) ??
      [],
    period: Period.Year,
  }
}
