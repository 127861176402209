import React from 'react'
import styled from 'styled-components'
import logoSvg from '../../../assets/icons/logoOriginal.svg'
import { Flex } from '../../../components/Flex'
import { Icon } from '../../../components/Icon'
import { Body1Bold } from '../../../components/Typography'
import { spacing } from '../../../theme'

const Title = styled(Body1Bold)`
  margin-left: ${spacing.medium};
`

export function AuthHeader(): React.JSX.Element {
  return (
    <Flex itemsCenter>
      <Icon src={logoSvg} alt='logotype' width={40} height={40} />
      <Title>Carbon Intelligence</Title>
    </Flex>
  )
}
