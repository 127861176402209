import React, { useCallback, useEffect, useState } from 'react'

type ElementSize = { width?: number; height?: number }

export const useElementSize = (): [
  React.RefCallback<HTMLElement>,
  ElementSize,
] => {
  const [node, setNode] = useState<HTMLElement>()
  const [elementRect, setElementRect] = useState<DOMRect | undefined>()
  const handleRect = useCallback((ref: HTMLElement) => {
    if (ref) {
      setNode(ref)
    }
    setElementRect(ref?.getBoundingClientRect())
  }, [])

  useEffect(() => {
    const onResize = () => {
      setElementRect(node?.getBoundingClientRect())
    }
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [node])

  return [
    handleRect,
    { width: elementRect?.width, height: elementRect?.height },
  ]
}
