import React, { useState } from 'react'
import type Svalna from '../@types'
import { getToken } from '../utils/auth'

export interface IUserContext {
  isLoggedIn?: boolean
  setLoggedIn: (loggedIn: boolean) => void
}

const initialState: IUserContext = {
  isLoggedIn: getToken() !== undefined,
  setLoggedIn: () => {},
}

export const UserContext = React.createContext<IUserContext>(initialState)

const { Provider } = UserContext

export function UserProvider({
  children,
}: Svalna.PropWithChildren): React.JSX.Element {
  const [isLoggedIn, setLoggedIn] = useState(initialState.isLoggedIn)

  return (
    <Provider
      value={{
        isLoggedIn,
        setLoggedIn,
      }}
    >
      {children}
    </Provider>
  )
}
