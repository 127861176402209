import { useApolloClient } from '@apollo/client'
import { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { UserContext } from '../../context/UserContext'
import { useLogoutMutation } from '../../graphql/generated'
import { clearToken, getToken, setLastLoggedOutTime } from '../../utils/auth'

export function LogoutPage(): null {
  const [logout] = useLogoutMutation()
  const client = useApolloClient()
  const history = useHistory()
  const { setLoggedIn } = useContext(UserContext)
  const location = useLocation<{ orgType: string }>()

  useEffect(() => {
    const clearStateAndRedirectToLogin = () => {
      //setting logged in to false in the case of a swamid connection result in a race condition that flash an error message
      //before to go back to normal. As in the case of a swamid connection we get redirected to another page, we can do
      //setLoggedIn only for non swamid connections
      clearToken()
      setLastLoggedOutTime()
      if (window.location.hostname === process.env.REACT_APP_SWAMID_HOST) {
        window.location.replace(
          `https://${process.env.REACT_APP_SWAMID_HOST}/Shibboleth.sso/Logout?return=https://${process.env.REACT_APP_SWAMID_HOST}/shibboleth`,
        )
      } else {
        setLoggedIn(false)
        history.push('/login')
      }
      client.clearStore() //clear the apollo cache
    }

    if (client && logout) {
      const token = getToken()
      if (!token) {
        clearStateAndRedirectToLogin()
        return
      }

      logout({
        variables: { refreshToken: token?.refreshToken ?? '' },
        fetchPolicy: 'no-cache',
      }).catch(console.log) // eslint-disable-line

      clearStateAndRedirectToLogin()
    }
  }, [client, history, location.state, logout, setLoggedIn])

  return null
}
