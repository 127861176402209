import React from 'react'
import { LegendSymbolProps } from './victory-prop-types'

/**
 * Draws a dashed line as a symbol for a Victory legend.
 */
export function ChartLabelDashedLine(
  props: LegendSymbolProps,
): React.JSX.Element {
  // Note on props: Experimentally determined what Victory passes [version ^36.6.11]:
  // The `size` prop is something like a "radius" (approximately), and
  // the props `x`, `y` are the centre of the symbol.
  // i.e. a line has close to the expected width when it goes from (x - size) to (x + size).
  const { x, y, size, style } = props
  // `size` should be a number, but the type says it can also be a function,
  // so just in case it is, extract the numeric size value:
  let sizeNum = 5
  if (typeof size === 'number') {
    sizeNum = size
  } else if (typeof size === 'function') {
    sizeNum = size()
  }
  return (
    <line
      x1={x! - sizeNum}
      x2={x! + sizeNum}
      y1={y!}
      y2={y!}
      stroke={style.fill}
      strokeWidth={2.5}
      // These values happen to make a nice dashed line;
      // the dash array is intended to make a longer centre line,
      // with two shorter lines on each side. Since the dashes
      // are tiled, repeating for the entire length of the line,
      // there is no way to strictly specify such an appearance.
      strokeDasharray='2.5 1 4 1'
    />
  )
}
