import React from 'react'
import { useElementSize } from '../../hooks/useElementSize'
import { FlexingBoxContent, HeadRow, InnerInnerBox } from '../Flex'
import { Body1 } from '../Typography'
import { GoalGraph } from './GoalGraph'
import { GoalParameters } from './GoalParameters'
import { GoalSettingContextProvider } from '../../context/GoalSettingContext'
import { TitleWithSub } from '../TitleWithSub'

/**
 * Controls for changing the organisation goal,
 * and components which visualise these settings.
 */
export function GoalSetting(): React.JSX.Element {
  const [ref, size] = useElementSize()
  const width = size?.width ?? 400

  // The `GoalSettingContextProvider` provides contexts for both
  // `GoalSettingContext` and `GoalContext` -- when the settings
  // are changed, any children of this component will see the
  // changes reflected in their goal context consumer.
  return (
    <GoalSettingContextProvider>
      <div ref={ref}>
        <HeadRow>
          <TitleWithSub title='Målsättning' sub='' />
        </HeadRow>
        <FlexingBoxContent>
          <InnerInnerBox>
            <Body1>
              Ställ in ett mål som gäller för hela organisationen. Målet
              appliceras på utsläppsnivåer för samtliga avdelningar. Ange det
              basår som din organisation använder som startpunkt för
              minskningen.
            </Body1>
            <GoalParameters />
          </InnerInnerBox>
          <GoalGraph width={width} />
        </FlexingBoxContent>
      </div>
    </GoalSettingContextProvider>
  )
}
