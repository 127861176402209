import {
  SmallCardBox,
  SmallCardContent,
  SmallCardFooterBold,
  SmallCardHeader,
  SmallCardTitle,
  SmallCardWrapper,
} from './smallCardBox'

export const Stat = SmallCardBox

export const StatHeader = SmallCardHeader

export const StatTitle = SmallCardTitle

export const Wrapper = SmallCardWrapper

export const StatFooter = SmallCardFooterBold

export const StatContent = SmallCardContent
