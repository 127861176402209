import { useContext } from 'react'
import type { ApolloError } from '@apollo/client'
import { AppDataContext } from '../../context/AppDataContext'
import { FilterContext } from '../../context/FilterContext'
import {
  FilterInput,
  Period,
  useGetTopListQuery,
} from '../../graphql/generated'
import { findParents } from '../../utils/org'

type Data = {
  parentName: string
  totalCO2e: number
  name: string
  orgUnitId: string
  highlight?: boolean
}

type TopListData = {
  data: Data[]
  loading: boolean
  error: ApolloError | undefined
}
export const useTopListData = (filter: FilterInput): TopListData => {
  const { orgUnitMap } = useContext(AppDataContext)
  const { orgUnit } = useContext(FilterContext)
  const { data, loading, error } = useGetTopListQuery({
    variables: {
      input: {
        aggregations: [],
        period: Period.Year,
        filter: {
          ...filter,
          orgUnitId: undefined,
        },
      },
    },
  })

  const rawData = data?.getTopList ?? []

  const topListMap = rawData.reduce<Record<string, Data>>((map, org) => {
    map[org.orgUnitId] = org
    return map
  }, {})

  if (orgUnit) {
    if (topListMap[orgUnit.id]) {
      topListMap[orgUnit.id] = { ...topListMap[orgUnit.id], highlight: true }
    } else {
      rawData.forEach((org) => {
        const rowOrgUnit = orgUnitMap[org.orgUnitId]
        if (rowOrgUnit) {
          const ancestorsIds = findParents(rowOrgUnit, orgUnitMap).map(
            (row) => row.id,
          )
          const isAncestor = ancestorsIds.includes(orgUnit.id)
          if (isAncestor) {
            topListMap[org.orgUnitId] = {
              ...topListMap[org.orgUnitId],
              highlight: true,
            }
          }
        }
      })
    }
  }

  return {
    data: Object.values(topListMap),
    loading,
    error,
  }
}
