import React from 'react'
import styled from 'styled-components'
import Svalna from '../@types'
import errorImage from '../assets/images/error.png'
import { Body1, H6 } from './Typography'
import { spacing } from '../theme'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
`

const ErrorText = styled(H6)`
  margin-top: ${spacing.medium};
  margin-bottom: ${spacing.small};
`

const ErrorImage = styled.img`
  margin-top: ${spacing.medium};
`

interface Props extends Svalna.PropWithChildren {
  errorMessage: string
}

export function Oops({ errorMessage, children }: Props): React.JSX.Element {
  return (
    <Wrapper>
      <ErrorImage src={errorImage} />
      <ErrorText>Något gick fel! :(</ErrorText>
      <Body1>{errorMessage}</Body1>
      {children}
    </Wrapper>
  )
}
