import React from 'react'
import InformationCircle from '../assets/icons/information-circle-outline.svg'
import { Icon, IconProps } from './Icon'
import { Tooltip } from './Tooltip'

interface InfoIconProps {
  info: string | React.JSX.Element
  size?: number
  style?: React.CSSProperties
  /** Passed as `data-testid` to the icon element. */
  'data-testid'?: string
}

export function TooltipInfo({
  info,
  size = 25,
  style,
  'data-testid': dataTestId,
}: InfoIconProps): React.JSX.Element {
  return (
    <Tooltip info={info} style={style}>
      <InfoIcon width={size} height={size} data-testid={dataTestId} />
    </Tooltip>
  )
}

export function InfoIcon(props: IconProps): React.JSX.Element {
  return (
    <Icon
      src={InformationCircle}
      alt='information-circle'
      {...props}
      style={{ verticalAlign: 'middle' }}
    />
  )
}
