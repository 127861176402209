import styled, { keyframes } from 'styled-components'
import { colors } from '../theme'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const Loader = styled.span.withConfig({
  shouldForwardProp: (prop) => !['borderSize', 'reversed'].includes(prop),
})<{ size: number; borderSize: number; reversed?: boolean }>`
  display: block;
  border: ${(props) => props.borderSize}px solid
    ${(props) => (props.reversed ? colors.white : colors.black)};
  border-top: ${(props) => props.borderSize}px solid ${colors.deepGreen};
  border-radius: 50%;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  animation: ${rotate} 2s linear infinite;
`
