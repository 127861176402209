import React, { useContext, useState } from 'react'
import { CO2eFormat } from '../../../constants'
import { Category } from '../../../graphql/generated'
import { useElementSize } from '../../../hooks/useElementSize'
import { useFilterInput } from '../../../hooks/useFilterInput'
import { testHasData } from '../../../utils/testHasData'
import CardTemplate from '../../CardTemplate'
import { Body1 } from '../../Typography'
import { useTrendGraphData } from './useTrendChartData'
import { GoalContext } from '../../../context/GoalContext'
import { TrendChart } from './TrendChart'
import { usePartialYearEmission } from '../../../hooks/usePartialYearEmission'
import { spacing } from '../../../theme'

export function TrendChartCard(): React.JSX.Element {
  const [useAnnualWorkForce, setUseAnnualWorkForce] = useState(false)
  const [category, setCategory] = useState<Category | undefined>()
  const filter = useFilterInput(category, undefined, useAnnualWorkForce)
  // Note: trend data never returns partial years.
  const trendGraphData = useTrendGraphData(filter)

  const { emissionData, loading, error } = trendGraphData

  const {
    partialYearEmission,
    loading: partialYearEmisLoading,
    error: partialYearEmisError,
  } = usePartialYearEmission({ filter })

  // Goal data for the current year's goal, if any.
  const {
    data: goalData,
    loading: goalLoading,
    error: goalError,
  } = useContext(GoalContext)

  const [ref, size] = useElementSize()

  return (
    <CardTemplate
      titleData={{
        title: 'Trendutveckling',
        subtitle: CO2eFormat,
      }}
      useAnnualWorkForce={useAnnualWorkForce}
      setUseAnnualWorkForce={setUseAnnualWorkForce}
      error={error || goalError || partialYearEmisError}
      loading={loading || goalLoading || partialYearEmisLoading}
      hasData={testHasData(emissionData)}
      tooltip={
        <Body1>
          Visar utsläpp över tid samt utvecklingstrenden över perioden.
          Procentvärdet och streckad linje representerar den genomsnittliga
          årliga utveckligen över alla avslutade år med tillgänglig data.
        </Body1>
      }
      selectedCategory={category}
      setCategory={setCategory}
    >
      <div style={{ padding: spacing.large }} ref={ref}>
        <TrendChart
          trendGraphData={trendGraphData}
          goalEmissionCurve={goalData?.goalEmissionCurve ?? []}
          partialYearEmission={
            partialYearEmission
              ? {
                  x: partialYearEmission.x.toFixed(0),
                  y: partialYearEmission.y,
                }
              : undefined
          }
          useAnnualWorkForce={useAnnualWorkForce}
          width={size.width ?? 600}
          category={category}
        />
      </div>
    </CardTemplate>
  )
}
