import React, { useContext } from 'react'
import { MenuItem } from '@mui/material'
import CardTemplate from '../../../components/CardTemplate'
import { Body1, textCO2e } from '../../../components/Typography'
import {
  Aggregation,
  Category,
  FilterInput,
  Period,
} from '../../../graphql/generated'
import { useEmissionData } from '../../../hooks/useEmissionData'
import { FilterContext } from '../../../context/FilterContext'
import { useElementSize } from '../../../hooks/useElementSize'
import { Flex } from '../../../components/Flex'
import { SelectLightBorder } from '../../../components/Select'
import { Checkbox } from '../../../components/Checkbox'
import { EmissionGraph } from './EmissionGraph'
import { IFilterDatePeriod } from '../../../utils/datePeriod'
import { useFilterInput, getYear } from '../../../hooks/useFilterInput'
import { spacing } from '../../../theme'

const allYearsDatePeriod: IFilterDatePeriod = {
  period: Period.Year,
  end: undefined, // setting end to undefined is the way to get data for all years
  name: 'all years',
  partialYear: true,
}

export function EmissionCard(): React.JSX.Element {
  const { yearPeriods } = useContext(FilterContext)
  const [selectedDatePeriod, setDatePeriod] = React.useState(allYearsDatePeriod)
  const [showPreviousYear, setShowPreviousYears] = React.useState(false)
  const [selectedCategory, setCategory] = React.useState<Category | undefined>()
  const [useAnnualWorkForce, setUseAnnualWorkForce] = React.useState(false)
  const [ref, elSize] = useElementSize()

  //get data to display
  const filter = useFilterInput(
    undefined,
    selectedDatePeriod,
    useAnnualWorkForce,
  )

  //the order of the aggregation keys is important, it is used
  //when processing the data in EmissionsOverTimeGraph
  const aggregations: Aggregation[] = [
    Aggregation.PrimaryCategory,
    Aggregation.Date,
  ]

  const {
    data: emissions,
    loading,
    error,
  } = useEmissionData({
    filter,
    aggregations,
    period: selectedDatePeriod.period,
  })

  //get data for previous year
  //If we are not looking at a praticuliar year. This will do the same request
  //as the one to get data to display, but this is ok because this will just hit the cach
  const prevFilter: FilterInput = {
    partialYear: true,
    useAnnualWorkForce: useAnnualWorkForce,
    year: selectedDatePeriod.end
      ? `${(getYear(selectedDatePeriod) ?? 0) - 1}`
      : undefined,
  }

  const {
    data: prevEmissions,
    loading: prevLoading,
    error: prevError,
  } = useEmissionData({
    filter: prevFilter,
    aggregations,
    period: selectedDatePeriod.period,
  })

  const getLatestYear = () => {
    if (!yearPeriods) {
      //it does not matter, there is nothing to display
      return '2023'
    }
    //yearPeriods contain the list of all the year in reverse order
    // TODO check that it is still correct with partial years
    return yearPeriods[0].name
  }

  return (
    <CardTemplate
      titleData={{
        title: `Utsläpp fördelat över tid`,
        subtitle: textCO2e,
      }}
      tooltip={
        <Body1>
          Visar hur utsläppen förändras över tid, uppdelat på olika kategorier.
          Justera filtren för att växla mellan att visa per år eller per månad.
          Klicka på kategorierna i legenden för skifta mellan kategorier.
        </Body1>
      }
      useAnnualWorkForce={useAnnualWorkForce}
      setUseAnnualWorkForce={setUseAnnualWorkForce}
      loading={loading || prevLoading}
      error={error ?? prevError}
      hasData
      ControlsStart={
        <Controls
          {...{
            //display the show previous year control if we are showing data
            //for a particular year
            showPrevControl: !!selectedDatePeriod.end,
            showPreviousYear,
            setShowPreviousYears,
            selectedDatePeriod,
            setDatePeriod,
            latestYear: getLatestYear(),
          }}
        />
      }
      ControlsEnd={
        <Checkbox
          isChecked={showPreviousYear}
          label='Visa föregående år'
          onChange={() => setShowPreviousYears(!showPreviousYear)}
          style={{
            visibility: selectedDatePeriod.end ? 'visible' : 'hidden',
          }}
        />
      }
      selectedDatePeriod={selectedDatePeriod}
      setDatePeriod={
        selectedDatePeriod.period !== Period.Year ? setDatePeriod : undefined
      }
      selectedCategory={selectedCategory}
      setCategory={setCategory}
    >
      <div style={{ padding: spacing.large }} ref={ref}>
        <EmissionGraph
          width={elSize?.width ?? 1600}
          setPeriod={setDatePeriod}
          period={selectedDatePeriod}
          emissions={emissions}
          prevEmissions={showPreviousYear ? prevEmissions : []}
          selectedCategory={selectedCategory}
          setCategory={setCategory}
          loading={loading}
        />
      </div>
    </CardTemplate>
  )
}

interface ControlProps {
  showPrevControl: boolean
  showPreviousYear: boolean
  setShowPreviousYears: (show: boolean) => void
  selectedDatePeriod: IFilterDatePeriod
  setDatePeriod: React.Dispatch<React.SetStateAction<IFilterDatePeriod>>
}

function Controls({
  showPrevControl,
  showPreviousYear,
  setShowPreviousYears,
  selectedDatePeriod,
  setDatePeriod,
}: ControlProps) {
  const { defaultDatePeriod } = useContext(FilterContext)
  return (
    <Flex itemsCenter>
      <SelectLightBorder
        value={selectedDatePeriod.period}
        onChange={(event) => {
          if (event.target.value) {
            const newPeriod = event.target.value as Period
            switch (newPeriod) {
              case Period.Year:
                setDatePeriod(allYearsDatePeriod)
                setShowPreviousYears(false)
                break
              default:
                setDatePeriod(defaultDatePeriod)
            }
          }
        }}
        style={{ minWidth: 150 }}
        data-testid='period-select'
      >
        <MenuItem
          selected={Period.Year === selectedDatePeriod.period}
          value={Period.Year}
          key={Period.Year}
        >
          Visa per år
        </MenuItem>
        <MenuItem
          selected={Period.Month === selectedDatePeriod.period}
          value={Period.Month}
          key={Period.Month}
        >
          Visa per månad
        </MenuItem>
      </SelectLightBorder>
    </Flex>
  )
}
