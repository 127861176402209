// As we are moving to use more MUI component we should also align the typography we use with it
// the goal here is to progressively move to use only the element from this file and replace
// Typography.tsx when we are done.
import React from 'react'
import { Typography, TypographyProps } from '@mui/material'
import styled from 'styled-components'
import { colors } from '../theme'

export const sub2 = '₂'
export const textCO2e = `CO${sub2}e`

const BoldTypography = styled(Typography)`
  font-weight: bold;
`

interface BoldableProp extends TypographyProps {
  bold?: boolean
}

// A typography that can be set as bold by passing the bold property
// useful when we want to set the text as bold programmatically
const BoldableTypography = styled(Typography).withConfig({
  shouldForwardProp: (prop) => prop !== 'bold', // do not forward the bold property to the underlying object
})<{ bold?: boolean }>`
  ${(props) => props.bold && `font-weight: bold !important;`}
`

// Example use: the percentage numbers in the metadata cards
export function H3({ children, ...props }: TypographyProps): React.JSX.Element {
  return (
    <Typography {...props} variant='h3'>
      {children}
    </Typography>
  )
}

// Example use: the big number in the middle of the pie chart
export function H4({ children, ...props }: TypographyProps): React.JSX.Element {
  return (
    <Typography {...props} variant='h4'>
      {children}
    </Typography>
  )
}

// Example use: the title in the top left of the pages
export function H4Bold({
  children,
  ...props
}: TypographyProps): React.JSX.Element {
  return (
    <BoldTypography {...props} variant='h4'>
      {children}
    </BoldTypography>
  )
}

// Example use: the question on the login pages
export function H5({ children, ...props }: TypographyProps): React.JSX.Element {
  return (
    <Typography {...props} variant='h5'>
      {children}
    </Typography>
  )
}

// Example use: the categories in the helper page (ex: Beräkningsmetodik)
export function H5Bold({
  children,
  ...props
}: TypographyProps): React.JSX.Element {
  return (
    <BoldTypography {...props} variant='h5'>
      {children}
    </BoldTypography>
  )
}

// Example use: The title at the top of the table of content
export function H6({ children, ...props }: BoldableProp): React.JSX.Element {
  return (
    <BoldableTypography {...props} variant='h6'>
      {children}
    </BoldableTypography>
  )
}

// Example use: the rank in the top list card
export function H6Bold({
  children,
  ...props
}: TypographyProps): React.JSX.Element {
  return (
    <BoldTypography {...props} variant='h6'>
      {children}
    </BoldTypography>
  )
}

// Example use: the emissions in the top list card
export function S1({ children, ...props }: BoldableProp): React.JSX.Element {
  return (
    <BoldableTypography {...props} variant='subtitle1'>
      {children}
    </BoldableTypography>
  )
}

// Example use: the "title" on the login page
export function S2Bold({
  children,
  ...props
}: TypographyProps): React.JSX.Element {
  return (
    <BoldTypography {...props} variant='subtitle2'>
      {children}
    </BoldTypography>
  )
}

// Example use: any default text
export function Body1({ children, ...props }: BoldableProp): React.JSX.Element {
  return (
    <BoldableTypography {...props} variant='body1'>
      {children}
    </BoldableTypography>
  )
}

// Example use: the cards titles
export function Body1Bold({
  children,
  ...props
}: TypographyProps): React.JSX.Element {
  return (
    <BoldTypography {...props} variant='body1'>
      {children}
    </BoldTypography>
  )
}

// Example use: the cards subtitles
export function Body2({ children, ...props }: BoldableProp): React.JSX.Element {
  return (
    <BoldableTypography {...props} variant='body2'>
      {children}
    </BoldableTypography>
  )
}

// Example use: the columns titles in the pie card visa mer
export function Body2Bold({
  children,
  ...props
}: TypographyProps): React.JSX.Element {
  return (
    <BoldTypography {...props} variant='body2'>
      {children}
    </BoldTypography>
  )
}

// Example use: the "Visa per anställd" checkbox text
export function Caption({
  children,
  ...props
}: TypographyProps): React.JSX.Element {
  return (
    <Typography {...props} variant='caption'>
      {children}
    </Typography>
  )
}

// Example use: the text in the navigation bar
export function CaptionBold({
  children,
  ...props
}: TypographyProps): React.JSX.Element {
  return (
    <BoldTypography {...props} variant='caption'>
      {children}
    </BoldTypography>
  )
}

// Example use: white text to have on a red background for errors
export function ErrorTextWhite({
  children,
  style,
  ...props
}: TypographyProps): React.JSX.Element {
  return (
    <BoldTypography
      {...props}
      style={{ color: colors.white, ...style }}
      variant='body1'
    >
      {children}
    </BoldTypography>
  )
}

// Example use: the parent category in the search field dropdown
export function Overline({
  children,
  style,
  ...props
}: BoldableProp): React.JSX.Element {
  return (
    <BoldableTypography
      {...props}
      style={{ fontSize: '0.625rem', lineHeight: '0.75rem', ...style }}
      variant='overline'
    >
      {children}
    </BoldableTypography>
  )
}
