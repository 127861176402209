import React from 'react'
import { useHistory } from 'react-router-dom'
import { LoginPageWrapper } from '../authentication/components/LoginPageWrapper'
import { ButtonGreen, ButtonLightBorder } from '../../components/Buttons'
import { H5 } from '../../components/Typography'
import { SSOLogin } from '../authentication/SSOLogin'
import { spacing } from '../../theme'

export function PublicLanding(): React.JSX.Element {
  const history = useHistory()
  const [error, setError] = React.useState('')

  if (window.location.hostname === process.env.REACT_APP_SWAMID_HOST) {
    return (
      <LoginPageWrapper error={error}>
        <SSOLogin setError={setError} error={error} />
      </LoginPageWrapper>
    )
  }

  return (
    <LoginPageWrapper error=''>
      <H5 style={{ marginBottom: spacing.xl }} data-testid='welcome'>
        Välkommen till Carbon Intelligence system!{' '}
      </H5>

      <ButtonGreen
        style={{ width: '100%' }}
        onClick={() => {
          history.push('/registration')
        }}
      >
        Starta förfrågan om konto
      </ButtonGreen>
      <ButtonLightBorder
        style={{ width: '100%' }}
        onClick={() => history.push('/login')}
      >
        Logga in
      </ButtonLightBorder>
    </LoginPageWrapper>
  )
}
