import React from 'react'
import { Body1, H5 } from '../../../../components/Typography'

export function SorryToHear(): React.JSX.Element {
  return (
    <>
      <H5>Ok! Bollen är hos dig nu.</H5>
      <Body1>
        Du är alltid välkommen att höra av dig till oss när du är redo.
      </Body1>
    </>
  )
}
