import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import CardTemplate from '../../components/CardTemplate'
import { Flex } from '../../components/Flex'
import { ScrollToElement } from '../../components/ScrollToElement'
import { Body1, Body2, H6Bold, S1, Overline } from '../../components/Typography'
import { useFilterInput } from '../../hooks/useFilterInput'
import { colors, spacing } from '../../theme'
import {
  getNumberFormatter,
  unitDescription,
} from '../../utils/adaptiveNumberFormat'
import { testHasData } from '../../utils/testHasData'
import { useTopListData } from './useTopListData'
import { Category } from '../../graphql/generated'
import { FilterContext } from '../../context/FilterContext'
import { AppDataContext } from '../../context/AppDataContext'

const Rank = styled(H6Bold).withConfig({
  shouldForwardProp: (prop) => !['deepGreen'].includes(prop),
})<{ deepGreen?: boolean }>`
  padding-right: ${spacing.medium};
  width: 35px;
  ${(props) => props.deepGreen && `color: ${colors.deepGreen};`}
`

const OrgRow = styled.div`
  display: flex;
  align-items: center;
  height: 51px;
  padding: ${spacing.medium};
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
`

const Emission = styled(S1)`
  margin-left: auto;
  white-space: nowrap;
`

const ParentName = styled(Overline)`
  opacity: 0.45;
  margin-top: ${spacing.small};
`

const TopListRows = styled(Flex)`
  flex-direction: column;
  overflow-y: scroll;
  height: 360px;
  padding: 0 24px 0 24px;
`

export function TopListCard(): React.JSX.Element {
  const { organisation } = useContext(AppDataContext)
  const { defaultDatePeriod } = useContext(FilterContext)
  const [category, setCategory] = useState<Category | undefined>()
  const [selectedDatePeriod, setDatePeriod] = useState(defaultDatePeriod)
  const filter = useFilterInput(
    category,
    selectedDatePeriod,
    organisation.useAnnualWorkforce,
  )
  const topList = useTopListData(filter)

  useEffect(() => {
    // This may overwrite the date set by a user, but this would only happen
    // when loading data from fortnox for the first time in the year
    setDatePeriod(defaultDatePeriod)
  }, [defaultDatePeriod])

  return (
    <CardTemplate
      titleData={{
        title: 'Topplista för avdelningar på institutionsnivå',
        subtitle: unitDescription(filter),
      }}
      loading={topList.loading}
      error={topList?.error}
      hasData={testHasData(topList.data)}
      tooltip={
        <Body1>
          Visar alla avdelningar på institutionsnivå och listar dem utifrån
          utsläppsnivåer. Lägst utsläpp, högst upp.
        </Body1>
      }
      selectedCategory={category}
      setCategory={setCategory}
      selectedDatePeriod={selectedDatePeriod}
      setDatePeriod={setDatePeriod}
    >
      <TopListRows>
        {topList.data.map((d, index) => (
          <ScrollToElement scroll={d.highlight} key={d.name}>
            <OrgRow>
              <Rank deepGreen={d.highlight}>{index + 1}</Rank>
              <Flex column>
                <Body2 bold={d.highlight}>{d.name}</Body2>
                <ParentName bold={d.highlight}>{d.parentName}</ParentName>
              </Flex>
              <Emission bold={d.highlight}>
                {`${getNumberFormatter([d.totalCO2e]).format(
                  d.totalCO2e,
                )}${unitDescription(filter, { hideUnit: true })}`}
              </Emission>
            </OrgRow>
          </ScrollToElement>
        ))}
      </TopListRows>
    </CardTemplate>
  )
}
