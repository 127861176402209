import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { spacing } from '../theme'
import { BoxTitle } from './Box'
import { Flex } from './Flex'
import { TooltipInfo } from './InfoIcon'
import { Body2 } from './Typography'

const TitleWrapper = styled(Flex)`
  flex-direction: column;
`

const SubTitle = styled(Body2)`
  font-weight: normal;
`
const TitleSpan = styled('span')`
  margin-right: ${spacing.medium};
  white-space: nowrap;
`

interface Props {
  title: string | ReactNode
  sub: string | ReactNode
  infoDescription?: string | React.JSX.Element
  titleStyle?: React.CSSProperties
  hoverText?: string
}

export function TitleWithSub({
  title,
  sub,
  infoDescription,
  titleStyle,
  hoverText,
}: Props): React.JSX.Element {
  return (
    <TitleWrapper>
      <Flex row itemsCenter>
        <TitleSpan style={titleStyle} title={hoverText}>
          {typeof title === 'string' || title instanceof String ? (
            <BoxTitle>{title}</BoxTitle>
          ) : (
            title
          )}
        </TitleSpan>
        {infoDescription && <TooltipInfo info={infoDescription} />}
      </Flex>

      {sub instanceof String ? <SubTitle>{sub}</SubTitle> : sub}
    </TitleWrapper>
  )
}
