import React, { useContext, useState } from 'react'
import { Button, FormHelperText } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {
  Flex,
  FlexingBox,
  FlexingBoxContent,
  HeadRow,
  InnerBox,
  InnerBoxText,
  InnerInnerBox,
} from '../Flex'
import { ButtonGreen, ButtonLightBorder } from '../Buttons'
import { RecatContext } from '../../context/RecatContext'
import { TitleWithSub } from '../TitleWithSub'
import { RecatType } from './components/SelectRecatType'
import { SearchLine } from './components/search/SearchLine'
import { Body2, Body2Bold } from '../Typography'
import { TransactionsBox } from './components/search/TransactionBox'
import { TransactionsInfoBox } from './components/search/TransactionsInfoBox'
import { RecatBox } from './RecatBox'
import { spacing } from '../../theme'

export function SearchBox(): React.JSX.Element {
  const {
    searchCriteria,
    updateSearchCriteria,
    search,
    validationError,
    addSearchCriterion,
    getAvaillableSearchFields,
    resetSearch,
    transactions,
    loading,
  } = useContext(RecatContext)

  const [recatType, setRecatType] = useState(RecatType.HIDE)

  return (
    <FlexingBox style={{ minHeight: '270px' }}>
      <HeadRow>
        <TitleWithSub
          title='Transaktioner'
          sub=''
          infoDescription='Ange dina sökkriterier här och se dina resultat i transaktionsrutan'
        />
      </HeadRow>
      <FlexingBoxContent>
        <InnerBox>
          <InnerBoxText>
            <Body2Bold>Sök</Body2Bold>
            <Body2>
              Fyll i sökvillkor för att visa matchande transaktioner.
            </Body2>
          </InnerBoxText>
          <InnerInnerBox>
            {searchCriteria.map((criterion) => {
              return (
                <SearchLine
                  updateSearchCriteria={updateSearchCriteria}
                  searchCriterion={criterion}
                  key={`search-field-${criterion.id}`}
                  canRemoe={searchCriteria.length > 1}
                />
              )
            })}
            <ButtonLightBorder
              onClick={() => addSearchCriterion()}
              startIcon={<AddIcon style={{ paddingBottom: '3px' }} />}
              disabled={getAvaillableSearchFields().length === 0}
            >
              LÄGG TILL VILLKOR
            </ButtonLightBorder>

            <Flex
              style={{
                padding: '16px 8px 16px 8px',
                gap: spacing.small,
              }}
            >
              <Button onClick={() => resetSearch()}>RENSA</Button>
              <ButtonGreen
                onClick={() => {
                  setRecatType(RecatType.HIDE)
                  search()
                }}
                disabled={!!validationError || loading}
              >
                Sök
              </ButtonGreen>
            </Flex>
            {validationError && (
              <FormHelperText data-testid='validation-error-msg'>
                {validationError}
              </FormHelperText>
            )}
          </InnerInnerBox>
        </InnerBox>
        <InnerBox>
          <Body2Bold>Sökresultat</Body2Bold>
          {!!transactions?.length && !loading && <TransactionsInfoBox />}
          <TransactionsBox />
        </InnerBox>
        <Flex justifyCenter stretchWidth>
          <ButtonLightBorder
            onClick={() => {
              search()
              setRecatType(RecatType.CHOOSE)
            }}
            disabled={!!validationError || !transactions?.length}
            data-testid='search-button'
          >
            SKAPA REGEL FÖR TRANSAKTIONER
          </ButtonLightBorder>
        </Flex>
        {recatType !== RecatType.HIDE && (
          <InnerBox>
            <RecatBox setRecatType={setRecatType} recatType={recatType} />
          </InnerBox>
        )}
      </FlexingBoxContent>
    </FlexingBox>
  )
}
