import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLegend,
  VictoryStack,
  VictoryTooltip,
} from 'victory'
import dayjs from 'dayjs'
import { Category, Emission, Period } from '../../../graphql/generated'
import { AppDataContext } from '../../../context/AppDataContext'
import { getNumberFormatter } from '../../../utils/adaptiveNumberFormat'
import { formatPeriod } from '../../../utils/date'
import { colors } from '../../../theme'
import { IFilterDatePeriod } from '../../../utils/datePeriod'
import { getYear } from '../../../hooks/useFilterInput'
import { FilterContext } from '../../../context/FilterContext'
import { periodLabel } from '../../../components/filter/SelectYear'
import { defaultChartTheme } from '../../../chartTheme'

type DataPoint = {
  index: number
  category: Category | undefined
  xNumber: number
  x: string
  y: number
}

type Label = {
  name: string
  category?: Category
  labels?: { fill: string }
}

interface EmissionGraphProp {
  width: number
  period: IFilterDatePeriod
  setPeriod: (period: IFilterDatePeriod) => void
  emissions: Emission[]
  prevEmissions: Emission[]
  selectedCategory: Category | undefined
  setCategory: React.Dispatch<React.SetStateAction<Category | undefined>>
  loading?: boolean
}

// The order of the category does not match the order we want for the collo distribution
// so we hard code it here.
const catIdToIndex = new Map<number, number>([
  [1, 1], //Energi
  [2, 4], //Fastigheter & Anläggningsarbeten
  [3, 5], //Mat & logi
  [4, 0], //Övrigt
  [5, 2], //Tjänster
  [6, 6], //Transport & resor
  [7, 3], //Varor
])

export function EmissionGraph({
  width,
  period,
  setPeriod,
  emissions,
  prevEmissions: previousYearEmissions,
  selectedCategory,
  setCategory,
  loading,
}: EmissionGraphProp): React.JSX.Element {
  const { categories } = useContext(AppDataContext)
  const { yearPeriods } = useContext(FilterContext)

  const [graphData, setGraphData] = useState<Array<Array<DataPoint>>>([])
  const [graphLabels, setGraphLabels] = useState<Map<string, number>>(new Map())
  const [prevGraphData, setPrevGraphData] = useState<Array<Array<DataPoint>>>(
    [],
  )
  const [prevGraphLabels, setPrevGraphLabels] = useState<Map<string, number>>(
    new Map(),
  )

  const displayCat = selectedCategory
    ? catIdToIndex.get(selectedCategory.id) ?? -1
    : -1

  const height = width / 4

  //takes a date string which is either a year (ex: 2023) or a year with month (ex: 2023-01)
  //returns the year as a number if it is a year. Retrun the number of the month if it is a year with month
  const getDateNumber = (dateStr: string) => {
    if (dateStr.includes('-')) {
      const dateStrs = dateStr.split('-')
      return Number(dateStrs[1])
    }
    return Number(dateStr)
  }

  const zeroPad = (num: number, places: number) =>
    String(num).padStart(places, '0')

  // Takes a number then if the periodicity is month return the name of the month matching the number
  // if the periodicity is the year, check if there is a year peariod matching the number and return the corresponding value
  const getPeriodLabelFromNumber = useCallback(
    (num: number) => {
      if (period.period === Period.Month) {
        return formatPeriod(
          dayjs.utc(`2000-${zeroPad(num, 2)}-01`),
          Period.Month,
        )
      }
      const yearPeriod = yearPeriods.find((p) => p.name === `${num}`)
      if (yearPeriod) {
        // if the date match a year period, build the label to handle partial years
        return periodLabel(yearPeriod)
      }
      // otherwise return the parsed date
      return `${num}`
    },
    [period.period, yearPeriods],
  )

  // Takes the data as returned by the server and return datapoint that can be displayed by the victory graph.
  // To build the stack bar the victory graph expect one array of data point per category.
  // Each array should be of same size. So, if there is no data for a category in a period we should create a data point
  // with value 0 for it.
  // The arrays should also be in the same order
  const processData = useCallback(
    (
      data: Emission[],
      setData: React.Dispatch<React.SetStateAction<DataPoint[][]>>,
      setLabels: React.Dispatch<React.SetStateAction<Map<string, number>>>,
    ) => {
      if (!data || data.length === 0) {
        // if there is no data just set an empty array
        setData([])
        setLabels(new Map())
        return
      }
      // to be able to fill in empty periods we need to know from when to when we need to have data
      // the aggregation key 1 is the period
      const dates = data.map((emission) =>
        getDateNumber(emission.aggregationKeys[1]),
      )
      const startDate = Math.min(...dates)
      const endDate = Math.max(...dates)

      // to be able to fill in empty categories we need to list of categories. We only display the root categories
      const categoriesIds = categories
        .filter((cat) => !cat.parentId)
        .map((cat) => {
          return cat.id
        })

      // matrix representing the data, with the categories as row and the dates as collumns
      const dataMatrix = new Map<number, Map<number, DataPoint>>()

      // initialize the rows of the matrix
      categoriesIds.forEach((id) =>
        dataMatrix.set(id, new Map<number, DataPoint>()),
      )

      // fillup the matrix with the data
      data.forEach((emission) => {
        const xNumber = getDateNumber(emission.aggregationKeys[1])
        const dataPoint: DataPoint = {
          //the aggregation key 0 is the category (see aggregations in EmissionOverTimeCard)
          //the ?? 0 is just here for typescript not to complain but the get should always return something
          index:
            catIdToIndex.get(
              Number.parseInt(emission.aggregationKeys[0], 10),
            ) ?? 0,
          category: categories.find(
            (cat) => cat.id.toString() === emission.aggregationKeys[0],
          ),
          xNumber,
          x: getPeriodLabelFromNumber(xNumber),
          y: emission.totalCO2e,
        }
        dataMatrix
          .get(Number.parseInt(emission.aggregationKeys[0], 10))
          ?.set(dataPoint.xNumber, dataPoint)

        return dataMatrix
      })

      const totals: Map<string, number> = new Map()

      const dps: Array<Array<DataPoint>> = []
      // go through the matrix line by line and collumn by collumn to fill up the data points array
      // that will be used by victory chart
      categoriesIds.forEach((id) => {
        // go through the rows
        const dataRow = dataMatrix.get(id)
        const row: Array<DataPoint> = []
        for (let i = startDate; i <= endDate; i++) {
          // go through the collumns
          let dp = dataRow?.get(i)
          if (!dp) {
            // if there is no datapoint for this category and period create one with value 0
            dp = {
              index: catIdToIndex.get(id) ?? 0,
              category: categories.find((cat) => cat.id === id),
              xNumber: i,
              x: getPeriodLabelFromNumber(i),
              y: 0,
            }
          }
          // if there is a datapoint update the total for this period and add the datapoint to the row
          const total = totals.get(dp.x) ?? 0
          totals.set(dp.x, dp.y + total)
          row.push(dp)
        }
        // It should normally not happen that we don't find the id in catIdToIndex
        // so the ?? Number(id) should only be here for typescript to compile.
        dps[catIdToIndex.get(id) ?? Number(id)] = row
        return dps
      })
      setData(dps)
      setLabels(totals)
    },
    [categories, getPeriodLabelFromNumber],
  )

  //process the data for the selected period
  useEffect(() => {
    if (!loading) {
      processData(emissions, setGraphData, setGraphLabels)
    }
  }, [emissions, loading, processData])

  //process the data for the previous year
  useEffect(() => {
    processData(previousYearEmissions, setPrevGraphData, setPrevGraphLabels)
  }, [previousYearEmissions, processData])

  const formatter = getNumberFormatter(
    graphData.flatMap((serry) => {
      return serry.map((entry) => {
        return entry.y
      })
    }),
  )

  const getBarWidth = () => {
    // The base width is the width of the card minus 400 to cover for the marges between the bars
    // divided by the number of bars in the year containing the most bars
    // with a minimum of 6 to not have too large bars.
    // If the goal line will be rendered one year beyond where the data ends,
    // add one to the bar count to use spacing consistent with when that year is normally shown.
    const numBars = Math.max(
      6,
      Math.max(graphLabels.size, prevGraphLabels.size),
    )
    const baseWidth = (width - 400) / numBars

    // when displaying the previous year we need to display two collumns instead of one
    // divide the width of the collumn by 3 (instead of 2) to have some space between the collumns
    if (prevGraphData.length > 0) {
      return baseWidth / 3
    }
    return baseWidth
  }

  const handleLegendClick = (
    evt: React.SyntheticEvent<any, Event>,
    prop: { index: number },
  ) => {
    const labelObj = getLegendData()[prop.index]
    if (!('category' in labelObj)) {
      // Clicked legend for either goal or previous year: do nothing.
      return
    }
    const cat = labelObj.category
    if (selectedCategory === cat) {
      // if clicking again on the displayed categroy
      // go back to display all categories
      setCategory(undefined)
    } else {
      setCategory(cat)
    }
  }

  const getLegendCollorScale = () => {
    // graphData contains one serry per category.
    // Pick the collor for each of these categories
    const colorScale = graphData
      // The concat is here to get a coppy of the graphData so the reverse does not mutate it
      .concat()
      // Reverse the sorting to get other as the last element of the legend
      .reverse()
      .map((serry) => {
        const color = serry[0].category?.color
        let transparency = 'FF'
        //Makes the colors transparent if they are not the selected category
        if (!!selectedCategory && serry[0].category !== selectedCategory) {
          transparency = '55'
        }
        return color + transparency
      })

    if (previousYearEmissions.length > 0) {
      //add the collor for previous year
      colorScale.push(colors.gray)
    }
    return colorScale
  }

  const getLegendData = () => {
    // graphData contains one serry per category.
    // Pick the name for each of these categories
    const data = graphData
      // The concat is here to get a coppy of the graphData so the reverse does not mutate it
      .concat()
      // Reverse the sorting to get other as the last element of the legend
      .reverse()
      .map<Label>((serry) => {
        let labels
        //Makes the labels grey if they are not for the selected category
        if (selectedCategory && serry[0].category !== selectedCategory) {
          labels = { fill: colors.gray }
        }
        return {
          name: serry[0].category?.name ?? '',
          category: serry[0].category,
          labels: labels,
          symbol: { type: 'square' },
        }
      })
    if (previousYearEmissions.length > 0) {
      //add the name for previous year
      data.push({
        name: `${(getYear(period) ?? 0) - 1}`,
      })
    }
    return data
  }

  // this number have been empirically found and ensure that we never have legends items that are hiden
  const getItemsPerRow = () => {
    if (width < 827) {
      return 6
    } else if (width < 900) {
      return 7
    }
    return 8
  }

  const getGraphCollorScale = () => {
    // graphData contains one serry per category.
    // Pick the collor for each of the displayed categories
    return (
      graphData
        .map((serry, index) => {
          if (displayCat < 0 || index === displayCat) {
            return serry[0].category?.color
          }
          return undefined
        })
        //filter undefined entries. Still need to cast for typescript
        .filter((entry) => !!entry) as Array<string>
    )
  }

  const getBars = (
    data: DataPoint[][],
    labels: Map<string, number>,
    isPreviousYear: boolean,
  ) => {
    return data.map((serry, index) => {
      if (displayCat < 0 || index === displayCat) {
        return (
          <VictoryBar
            cornerRadius={{
              // only set the radis on the block at the top of the bar
              top: index === data.length - 1 || index === displayCat ? 2 : 0,
            }}
            barWidth={getBarWidth()}
            data={serry}
            key={`emmission-over-time-cat${
              isPreviousYear ? '-prev' : ''
            }-${serry[0].category?.id}`}
            data-testid={`test-emmission-over-time-cat${
              isPreviousYear ? '-prev' : ''
            }-${serry[0].category?.id}`}
            labels={(datum) => {
              if (index === displayCat) {
                // if only one category displayed show the value for it
                return `${formatter.format(datum.datum?.y ?? 0)}`
              }
              // show the total of all categories
              return `${formatter.format(labels.get(datum.datum?.xName) ?? 0)}`
            }}
            labelComponent={
              <VictoryTooltip
                pointerLength={0}
                // This will show the number at the top of the graph
                // This is a compromise as there is no way to know the hight
                // of the stack (was aggreed with the designer)
                y={70}
                flyoutPadding={10}
                flyoutStyle={{ fill: colors.white }}
              />
            }
            style={{
              data: {
                // If we are at the year level show that the bar is clickable
                cursor: period.period === Period.Year ? 'pointer' : 'auto',
              },
            }}
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onClick: (evt, props) => {
                    if (period.period === Period.Year) {
                      //if we are at the year level zoom on the year that have been clicked on
                      // the years in yearPeriods are in reverse order hence the lenght - ...
                      setPeriod(
                        yearPeriods[yearPeriods.length - 1 - props.index],
                      )
                    }
                  },
                },
              },
            ]}
          />
        )
      }
      return undefined
    })
  }

  return (
    <VictoryChart
      padding={{
        top: 40,
        left: 80,
        right: 24,
        bottom: 30,
      }}
      domainPadding={{ x: getBarWidth() / 2 + 10 }}
      width={width}
      height={height}
      theme={defaultChartTheme}
    >
      <VictoryAxis orientation='bottom' />
      <VictoryLegend
        gutter={20}
        orientation='horizontal'
        colorScale={getLegendCollorScale()}
        data={getLegendData()}
        // Show "clickable" cursor for label/icon if they are a category, but not if they are the goal or prev. year.
        style={{
          data: {
            cursor: (props) => ('category' in props.datum ? 'pointer' : 'auto'),
            stroke: (params) => {
              // style is not in the type, but it is in the object we get when running the code
              // if style does not exist we just don't get a stroke, so it is ok to take the risk of using any here.
              return (params as any).style?.fill
            },
            strokeLinejoin: 'round',
            strokeWidth: 2,
          },
          labels: {
            cursor: (props) => ('category' in props.datum ? 'pointer' : 'auto'),
          },
        }}
        events={[
          {
            target: 'data',
            eventHandlers: {
              onClick: handleLegendClick,
            },
          },
          {
            target: 'labels',
            eventHandlers: {
              onClick: handleLegendClick,
            },
          },
        ]}
        itemsPerRow={getItemsPerRow()}
      />
      {/* Draw axis (especially the guide lines) over the goal line/area */}
      <VictoryAxis
        dependentAxis
        fixLabelOverlap
        orientation='left'
        tickFormat={(emission: number) => formatter.format(emission)}
        style={{
          axis: { stroke: 'transparent' },
          grid: {
            stroke: 'rgba(0,0,0,0.2)',
            strokeDasharray: '4, 8',
          },
        }}
      />
      <VictoryGroup offset={prevGraphData.length > 0 ? getBarWidth() + 10 : 0}>
        {prevGraphData.length > 0 ? (
          <VictoryStack
            colorScale={[colors.gray]}
            style={{
              data: { stroke: colors.gray, strokeWidth: 1 },
            }}
          >
            {getBars(prevGraphData, prevGraphLabels, true)}
          </VictoryStack>
        ) : (
          // victory group does not like to have only one child
          // put an empty bar graph to fill up (not visible on screen)
          // Note: The default bar is not empty, and the "empty" bar can briefly display
          //      black bars when switching graph parameter selections.
          //      An explicit placeholder value near the expected data span helps prevent this,
          //      though it is a bit of a hack.
          //      Also note that if `data` is the empty list, something in victory throws
          //      "Cannot read properties of undefined" (the VictoryGroup perhaps).
          <VictoryBar data={[{ x: 2020, y: 0 }]} />
        )}
        <VictoryStack colorScale={getGraphCollorScale()}>
          {getBars(graphData, graphLabels, false)}
        </VictoryStack>
      </VictoryGroup>
    </VictoryChart>
  )
}
