import React from 'react'
import { MenuItem } from '@mui/material'
import { Category } from '../../../graphql/generated'
import { SelectLightBorder } from '../../Select'

interface Props {
  categories: Category[]
  selectedCategory?: Category
  setCategory: (cat?: Category) => void
}

export function FilterCategory({
  categories,
  selectedCategory,
  setCategory,
}: Props): React.JSX.Element {
  return (
    <SelectLightBorder
      value={selectedCategory?.name ?? 'Alla kategorier'}
      onChange={(event) => {
        const category = categories.find((c) => c.name === event.target.value)
        setCategory(category)
      }}
    >
      <MenuItem
        selected={!selectedCategory}
        value='Alla kategorier'
        key='Alla kategorier'
      >
        Alla kategorier
      </MenuItem>
      {categories.map((c) => {
        return (
          <MenuItem
            selected={c.name === selectedCategory?.name}
            value={c.name}
            key={c.name}
          >
            {c.name}
          </MenuItem>
        )
      })}
    </SelectLightBorder>
  )
}
