import React, { useCallback, useState } from 'react'
import { AlertProps, Step, StepButton, Stepper, Tooltip } from '@mui/material'
import { colors } from '../../../theme'
import { ProfileStep } from './ProfileStep'
import { Body1Bold, Body2, Body2Bold } from '../../../components/Typography'
import { InnerInnerBox } from '../../../components/Flex'
import { NavButtons } from '../utils/NavButtons'

export const steps = ['Översikt', 'Fordon', 'Lokaler']

export interface Stepprops {
  setSnackbar: React.Dispatch<
    React.SetStateAction<Pick<AlertProps, 'children' | 'severity'> | null>
  >
  editEnabled: boolean
  isLastStep?: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setSaveAndContinue: React.Dispatch<React.SetStateAction<() => Promise<void>>>
  setSaveAndClose: React.Dispatch<React.SetStateAction<() => Promise<void>>>
  forward: () => void
  close: () => void
  setReset: React.Dispatch<React.SetStateAction<() => Promise<void>>>
}

export interface StepperPrpos extends Stepprops {
  activeStep: number
}

export function ProfileStepper({
  setSnackbar,
  isProfileCreation,
}: {
  setSnackbar: React.Dispatch<
    React.SetStateAction<Pick<AlertProps, 'children' | 'severity'> | null>
  >
  isProfileCreation?: boolean
}): React.JSX.Element {
  const [activeStep, setActiveStep] = React.useState(0)
  const [isEditEnabled, enableEdit] = useState(!!isProfileCreation)
  const [loading, setLoading] = useState(false)
  const [saveAndContinue, setSaveAndContinue] = useState<() => Promise<void>>(
    () => () => Promise.resolve(),
  )
  const [saveAndClose, setSaveAndClose] = useState<() => Promise<void>>(
    () => () => Promise.resolve(),
  )
  const [reset, setReset] = useState<() => Promise<void>>(
    () => () => Promise.resolve(),
  )

  const forward = useCallback(() => {
    setActiveStep(activeStep + 1)
  }, [activeStep, setActiveStep])

  const close = useCallback(() => {
    enableEdit(false)
  }, [enableEdit])

  return (
    <>
      <InnerInnerBox>
        <Body1Bold>Klimatprofilen</Body1Bold>
        <Stepper
          activeStep={activeStep}
          style={{ width: '100%' }}
          nonLinear={!isProfileCreation}
        >
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <Tooltip
                  title={
                    !isProfileCreation && isEditEnabled
                      ? 'Du måste spara eller avbryta dina ändringar innan du kan lämna denna sida'
                      : ''
                  }
                >
                  <span>
                    <StepButton
                      sx={{
                        '& .Mui-completed ': { color: `${colors.deepGreen}` },
                        '& .Mui-active ': { color: `${colors.deepGreen}` },
                      }}
                      onClick={() => {
                        setActiveStep(index)
                      }}
                      disabled={isProfileCreation || isEditEnabled || loading}
                    >
                      {activeStep === index ? (
                        <Body2Bold>{label}</Body2Bold>
                      ) : (
                        <Body2>{label}</Body2>
                      )}
                    </StepButton>
                  </span>
                </Tooltip>
              </Step>
            )
          })}
        </Stepper>
      </InnerInnerBox>
      <ProfileStep
        setSnackbar={setSnackbar}
        editEnabled={isEditEnabled}
        setLoading={setLoading}
        activeStep={activeStep}
        setSaveAndClose={setSaveAndClose}
        setSaveAndContinue={setSaveAndContinue}
        forward={forward}
        close={close}
        setReset={setReset}
      />
      {activeStep !== steps.length && (
        <NavButtons
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          loading={loading}
          isProfileCreation={isProfileCreation}
          enableEdit={enableEdit}
          editEnabled={isEditEnabled}
          saveAndClose={saveAndClose}
          saveAndContinue={saveAndContinue}
          reset={reset}
        />
      )}
    </>
  )
}
