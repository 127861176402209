import React, { useContext } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Tooltip } from '@mui/material'
import {
  Job,
  Role,
  useDeleteErrorJobMutation,
  useGetAndProcessFortnoxDataMutation,
  useMeQuery,
} from '../../graphql/generated'
import { goToFortnox } from '../../utils/FortnoxUtils'
import { ButtonGreenBorder, ButtonWhiteBorder } from '../Buttons'
import { Loader } from '../Loader'
import { FortnoxContext } from '../../context/FortnoxContext'

import { Flex } from '../Flex'
import { Body1 } from '../Typography'
import { JobContext } from '../../context/JobContext'
import { spacing } from '../../theme'
import { sideColumnMinWidthRem } from '../TableOfContent'
import { SnackAlert } from '../SnackAlert'

export function FortnoxButton(): React.JSX.Element {
  const { data: meData } = useMeQuery()
  const user = meData?.me
  const { jobRunning, setJobRunning, handleJob, currentState, jobSnack } =
    useContext(JobContext)
  const { tokenexpired, permissionError } = useContext(FortnoxContext)
  const [getAndProcessFortnoxData] = useGetAndProcessFortnoxDataMutation()
  const [deleteErrorJob] = useDeleteErrorJobMutation()

  const getData = async () => {
    setJobRunning(true)
    const response = await getAndProcessFortnoxData()
    handleJob(response.data?.getAndProcessFortnoxData as Job)
  }

  const renewFortnoxToken = async () => {
    await deleteErrorJob()
    await goToFortnox()
  }

  const canPull =
    [Role.Admin, Role.SysAdmin, Role.Accountant].includes(
      user?.role ?? Role.User,
    ) && !permissionError.error

  return (
    <>
      <SnackAlert
        open={!!tokenexpired}
        severity='error'
        action={
          <ButtonWhiteBorder size='small' onClick={renewFortnoxToken}>
            Anslut
          </ButtonWhiteBorder>
        }
      >
        <Flex style={{ gap: spacing.medium }}>
          <Body1>Fortnox-anslutningen har löpt ut, vänligen anslut igen</Body1>
        </Flex>
      </SnackAlert>

      <Tooltip
        title={
          !canPull
            ? permissionError.error ?? 'du saknar behörighet för denna åtgärd'
            : 'Hämta data från Fortnox'
        }
      >
        <span>
          <ButtonGreenBorder
            style={{
              fontWeight: 'bold',
              // keep the button in the dimmention of the remaining of the header
              height: '33px',
              // avoid the button changing size while processing data
              // using `rem` based on the expected longest text,
              // in the expected fonts.
              minWidth: `${sideColumnMinWidthRem}rem`,
            }}
            id='fortnox-button'
            data-testid='fortnox-button'
            onClick={getData}
            disabled={jobRunning || jobSnack.severity === 'error' || !canPull}
            startIcon={
              jobRunning ? (
                <Loader size={15} borderSize={4} />
              ) : (
                <ArrowDownwardIcon />
              )
            }
          >
            {/* 
              `text-wrap` is a relatively new in CSS (2023/2024), and is not in React's types.
              If it exists it will be applied, and will prevent the button text from
              breaking into multiple lines, even if the user sets a different font size/family.
            */}
            <span style={{ textWrap: 'nowrap' } as React.CSSProperties}>
              {currentState ? `${currentState}` : 'Hämta data'}
            </span>
          </ButtonGreenBorder>
        </span>
      </Tooltip>
    </>
  )
}
