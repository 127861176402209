import _ from 'lodash'
import * as React from 'react'
import {
  VictoryLabel,
  VictoryLabelProps,
  VictoryStyleObject,
  VictoryTooltip,
} from 'victory'
import { getTextMetrics } from '../../../utils/chart'
import { labelIsForChart, labelWithoutChartName } from './labelUtils'
import { CustomDataProp, LabelWithIcon, TextMetric } from './LabelWithIcon'
import { chartNames } from './chartConstants'
import { theme } from '../../../theme'

type ChartCursorLabelProps = VictoryLabelProps & {
  /** Last year with emission data. */
  lastEmissionYear?: number | undefined
}

/**
 * Note: the texts must be a list of strings.
 *
 * @param props
 * @returns
 */
export function ChartCursorLabel(
  props: ChartCursorLabelProps,
): React.JSX.Element | null {
  const { text: victoryText, datum, lastEmissionYear } = props
  // We expect the texts to be lines in an array.
  if (!Array.isArray(victoryText)) {
    return null
  }

  // Get the year currently hovered;
  // `datum.x` should be the year as a number.
  // Note: We could throw after this if the year is undefined,
  //       but since Victory seems to have few guarantees about the types,
  //       and an error would display the error screen,
  //       assume the year can be undefined and handle that case.
  const hoveredYear: number | undefined =
    (datum && 'x' in datum && typeof datum.x === 'number' && datum.x) ||
    undefined

  // Convert the hovered year to a string, put it in a list
  // for convenience, since we want to combine it with the
  // label texts.
  const yearStrList: string[] =
    hoveredYear !== undefined ? [hoveredYear.toFixed(0)] : []

  // Chart labels, skipping budget label when it overlaps with emissions;
  // Exclude the budget label for the last emission year,
  // as there is not actually a budget that year;
  // the budget point for that year is set only because
  // the budget chart must start there to be drawn properly.
  const labelsWithChartName = victoryText.filter(
    (s) =>
      !(
        hoveredYear === lastEmissionYear &&
        labelIsForChart(s, chartNames.budgetArea)
      ),
  )

  // The font family and size are needed to perform icon placement.
  // The `style` prop is also needed by Victory components to
  // perform the automatic text size, box size, etc.
  // The font size value must also be a number for calculations to work.
  // For convenience, the theme font settings are used.
  const fontSize: number = theme.typography.fontSize ?? 14
  const fontFamily: string =
    theme.typography.fontFamily ?? '"Roboto", "Helvetica", "Arial", sans-serif'
  const style: VictoryStyleObject = {
    fontSize,
    fontFamily,
  }
  // Pixel padding between label lines, this is passed both
  // to Victory for automatic adjustment, as well as to the
  // custom icon component to match icons to the texts.
  const lineBottomPadding = fontSize / 4
  // The icon size uses half font size, which roughly works as the radius of the icons.
  const iconRadius = fontSize / 2

  // Victory performs automatic layout based on the actual texts,
  // so pass texts without the formatting prefix block (see labelUtils).
  // Note: these texts will not be rendered, this is only for automatic layout.
  const layoutTexts = yearStrList.concat(
    labelsWithChartName.map(labelWithoutChartName),
  )

  // Get text metrics, which are needed for icon placement.
  const textMetrics: TextMetric[] = layoutTexts
    .map((t) => getTextMetrics(t, `${fontSize}px ${fontFamily}`))
    .filter((m): m is TextMetric => !!m)

  // The widest text line determines flyout box adjustments:
  const maxMetric = _.maxBy(textMetrics, (m) => m?.actual.width)

  const dataProp: CustomDataProp = {
    maxMetric,
    iconRadius,
    fontSize,
    // Pass the original text lines WITH the formatting block prefix,
    // as this is needed to determine which icon to render for a line.
    // Lines lacking the formatting info have no icon, which
    // is the intent for the year.
    textsWithFormatting: yearStrList.concat(labelsWithChartName),
    lineBottomPadding,
  }

  // Omit props which have incompatible types between VictoryLabel and VictoryTooltip,
  // and pass the rest to the tooltip.
  // This is a hack, but these fields are currently not used / have no discernable effect,
  // but many of the other fields must be passed (x, y, height, datum, etc).
  const tooltipProps = _.omit(props, ['angle', 'dx', 'dy', 'events'])

  return (
    <VictoryTooltip
      {...tooltipProps}
      style={style}
      // The `mouseFollowTooltips` on the `VictoryVoronoiContainer` causes the
      // flyout to follow the cursor. Setting `constrainToVisibleArea` here
      // will prevent the flyout from going outside the SVG.
      constrainToVisibleArea
      centerOffset={{
        // Display contents to the right of the cursor.
        x: 32 + (maxMetric?.actual.width ?? 0) / 2,
      }}
      // Victory quirk: The "pointer" is part of the normal "flyout",
      // it is an arrow pointing from the tooltip to the active point
      // the chart is currently displaying a tooltip for.
      // For a Voronoi container, it is displayed only if there
      // is only one currently active data point, as opposed to many.
      // Setting the length to zero hides it for all cases.
      pointerLength={0}
      // Texts for the automatic layout calculations (flyout box size).
      text={layoutTexts}
      // Add padding to the flyout, and add extra left padding
      // so the custom icons will fit.
      flyoutPadding={{
        left: 10 + iconRadius * 2.25, // plus a bit of extra air left of the icon.
        right: 10,
        bottom: 10 + fontSize / 4, // a little extra air after final label.
        top: 10,
      }}
      labelComponent={
        <VictoryLabel
          // The custom text component also renders icons.
          textComponent={<LabelWithIcon style={style} svalnaProps={dataProp} />}
          // The style is used by VictoryLabel to create the text labels and spacing.
          style={style}
          // This left-aligns the texts. but does so from the centre of the
          // flyout box -- this is counter-acted with a text transform
          // inside the custom label component, offseting the text by half the width.
          textAnchor='start'
          // Add space between the label lines with padding.
          // Note: padding must be supplied once per text line, hence the array.
          backgroundPadding={layoutTexts.map(() => ({
            bottom: lineBottomPadding,
          }))}
        />
      }
    />
  )
}
