import React, { useCallback, useEffect, useId, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import * as Sentry from '@sentry/react'
import { TourId, useUpdateUserTourMutation } from '../../graphql/generated'
import { UnderlinedTextButton, ButtonGreen } from '../../components/Buttons'
import { InnerInnerBox } from '../../components/Flex'
import { LOCALSTORAGE_KEY_SHOW_EMIS_TOUR_NO_DELAY } from '../../components/tour/tourConstants'
import { Body1 } from '../../components/Typography'
import { useEmissionTourContext } from '../../context/EmissionTourContext'
// Only imported for the purpose of linking in JSDoc.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { EmissionTourContext } from '../../context/EmissionTourContext'

/**
 * Full-page dialog popup for emission tour.
 *
 * Opens a popup for the emission tour after some time passes,
 * if the tour has not been seen yet.
 *
 * Must be mounted inside an {@link EmissionTourContext}.
 */
export function EmissionTourPopup({
  onError,
}: {
  /**
   * Callback triggered if the tour cannot be started.
   */
  onError: () => void
}): React.JSX.Element {
  const { tourSeen, ongoing, startTour } = useEmissionTourContext()
  const [updateUserTourMutation] = useUpdateUserTourMutation()
  // separate local state to track if the popup has been seen,
  // to prevent a race condition between the GQL mutation and the popup
  // closing.
  const [popupSeen, setPopupSeen] = useState(false)
  const [open, setOpen] = useState(false)

  const titleId = useId()
  const descriptionId = useId()

  // Display the popup conditionally:
  useEffect(() => {
    if (!popupSeen && !ongoing && !tourSeen) {
      // If this local storage field is set, the user pressed the
      // "watch again" button in help -- they should not have to wait
      // for the timeout in that case.
      const skipTimer =
        localStorage.getItem(LOCALSTORAGE_KEY_SHOW_EMIS_TOUR_NO_DELAY) ===
        'true'
      if (skipTimer) {
        setPopupSeen(true)
        setOpen(true)
      } else {
        const tid = setTimeout(() => {
          setPopupSeen(true)
          setOpen(true)
        }, 20000)
        return () => {
          clearTimeout(tid)
        }
      }
    }
  }, [popupSeen, ongoing, tourSeen])

  const close = useCallback(() => {
    localStorage.removeItem(LOCALSTORAGE_KEY_SHOW_EMIS_TOUR_NO_DELAY)
    setOpen(false)
    updateUserTourMutation({
      variables: {
        tourID: TourId.EmissionTour,
        seen: true,
      },
    }).catch((e) => {
      onError()
      Sentry.captureException(e)
    })
  }, [onError, updateUserTourMutation])

  return (
    <Dialog
      aria-labelledby={titleId}
      aria-describedby={descriptionId}
      open={open}
      sx={{
        backdropFilter: 'blur(2px)',
      }}
      maxWidth='sm'
    >
      <DialogTitle
        sx={{
          // Make sure there is some space for the close button.
          maxWidth: '95%',
        }}
        // Uses the expected font settings from the theme.
        // Note that DialogTitle generates an <h2> element
        // regardless of this setting.
        variant='h5'
        fontWeight='bold'
      >
        <span id={titleId}>
          <span style={{ display: 'inline-block' }}>
            👋 Välkommen till Carbon intelligence systemet
          </span>
          <span style={{ display: 'inline-block' }}>
            - ditt stöd i hållbarhetesarbetet!
          </span>
        </span>
      </DialogTitle>

      {/*
          Header right-side close button, outside of <DialogTitle>
          to prevent it from being considered part of the WAI-ARIA
          header text.
      */}
      <IconButton
        aria-label='stäng'
        onClick={close}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <InnerInnerBox>
          <Body1 id={descriptionId}>
            <span style={{ display: 'inline-block' }}>
              Låt oss börja med att ta dig på en kort rundtur.
            </span>
            <span style={{ display: 'inline-block' }}>
              Vill du hellre gå igenom den senare? Helt lugnt. Du kan alltid
              hitta den på supportsidan i menyn till vänster.
            </span>
          </Body1>
          <Body1 style={{ display: 'flex', alignItems: 'center' }}>
            <span>Har du redan koll på allt?</span>
            <UnderlinedTextButton onClick={close} color='secondary'>
              Hoppa över rundturen.
            </UnderlinedTextButton>
          </Body1>
        </InnerInnerBox>
      </DialogContent>
      <DialogActions>
        <ButtonGreen
          onClick={() => {
            localStorage.removeItem(LOCALSTORAGE_KEY_SHOW_EMIS_TOUR_NO_DELAY)
            setOpen(false)
            startTour()
          }}
          style={{
            marginBottom: 8,
            marginRight: 8,
          }}
        >
          STARTA RUNDTUR
        </ButtonGreen>
      </DialogActions>
    </Dialog>
  )
}
