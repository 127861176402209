import React from 'react'
import { Body1, H5 } from '../../../../components/Typography'

export function ThanksPage(): React.JSX.Element {
  return (
    <>
      <H5>Tack!</H5>
      <Body1>Vi hör av oss. Ha en bra dag!</Body1>
    </>
  )
}
