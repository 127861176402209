import React, { useEffect, useState } from 'react'
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
} from '@mui/x-data-grid'
import SaveIcon from '@mui/icons-material/Save'
import { Section, SectionId, SysadminSectionId } from '../../emission/Section'
import { FlexingBox, HeadRow } from '../../../components/Flex'
import { TitleWithSub } from '../../../components/TitleWithSub'
import { Body1 } from '../../../components/Typography'
import {
  OrganisationsQuery,
  useAddSsoMutation,
} from '../../../graphql/generated'
import { spacing } from '../../../theme'
import { SnackAlert } from '../../../components/SnackAlert'

interface Props {
  active: {
    scroll: boolean
    sectionId: SectionId
  }
  handleSectionVisibilityChange: (
    sectionId: SectionId,
    isVisible: boolean,
  ) => void
  orgData: OrganisationsQuery | undefined
}

type Row = {
  id: string
  orgId: string
  idp: string
  adminAttributeName: string
  adminAttributeValue: string
  userAttributeName: string
  userAttributeValue: string
}

export function SSO({
  active,
  handleSectionVisibilityChange,
  orgData,
}: Props): React.JSX.Element {
  const [addSSO] = useAddSsoMutation({
    refetchQueries: ['Organisations', 'Users'],
  })
  const [rows, setRows] = useState<GridRowsProp<Row>>([])
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  )
  const [error, setError] = React.useState('')

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const columns: GridColDef[] = [
    { field: 'orgId', headerName: 'organisation id', flex: 1, editable: true },
    { field: 'idp', headerName: 'idp', flex: 1, editable: true },
    {
      field: 'adminAttributeName',
      headerName: 'admin attribute name',
      flex: 1,
      editable: true,
    },
    {
      field: 'adminAttributeValue',
      headerName: 'admin attribute value',
      flex: 1,
      editable: true,
    },
    {
      field: 'userAttributeName',
      headerName: 'user attribute name',
      flex: 1,
      editable: true,
    },
    {
      field: 'userAttributeValue',
      headerName: 'user attribute value',
      flex: 1,
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={`sso-save-${id}`}
              icon={<SaveIcon />}
              label='Save'
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
          ]
        }

        return []
      },
    },
  ]

  const editableId = 'editableId'
  useEffect(() => {
    const id = editableId
    const r: Row[] = [
      {
        id,
        orgId: '',
        idp: '',
        adminAttributeName: '',
        adminAttributeValue: '',
        userAttributeName: '',
        userAttributeValue: '',
      },
    ]

    r.push(
      ...(orgData?.organisations
        ?.filter((org) => org.ssoMapping)
        .map((org) => {
          return {
            id: org.id,
            orgId: org.id,
            idp: org.idp ?? '',
            adminAttributeName: org.ssoMapping?.adminAttributeName ?? '',
            adminAttributeValue: org.ssoMapping?.adminAttributeValue ?? '',
            userAttributeName: org.ssoMapping?.userAttributeName ?? '',
            userAttributeValue: org.ssoMapping?.userAttributeValue ?? '',
          }
        }) ?? []),
    )

    setRows(r)
  }, [orgData])

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    // do not get out of edit mode when clicking out of the cell
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const processRowUpdate = async (newRow: GridRowModel<Row>) => {
    setError('')
    const updatedRow = { ...newRow, id: newRow.orgId }
    if (
      !(
        updatedRow.id &&
        updatedRow.idp &&
        updatedRow.adminAttributeName &&
        updatedRow.adminAttributeValue &&
        updatedRow.userAttributeName &&
        updatedRow.userAttributeValue
      )
    ) {
      throw new Error('You must set values for all the columns')
    }
    await addSSO({
      variables: {
        id: updatedRow.id,
        idp: updatedRow.idp,
        adminAttributeName: updatedRow.adminAttributeName,
        adminAttributeValue: updatedRow.adminAttributeValue,
        userAttributeName: updatedRow.userAttributeName,
        userAttributeValue: updatedRow.userAttributeValue,
      },
    })
    const id = editableId
    const newRows: Row[] = [
      {
        id,
        orgId: '',
        idp: '',
        adminAttributeName: '',
        adminAttributeValue: '',
        userAttributeName: '',
        userAttributeValue: '',
      },
    ]
    newRows.push(
      ...rows.map((row) => (row.id === newRow.id ? updatedRow : row)),
    )
    setRows(newRows)
    return updatedRow
  }

  const handleProcessRowUpdateError = React.useCallback((err: Error) => {
    setError(`Error updating user: ${err.message}`)
  }, [])

  useEffect(() => {
    //set the editable row as editable
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [editableId]: { mode: GridRowModes.Edit },
    }))
  }, [rows])

  return (
    <Section
      sectionId={SysadminSectionId.sso}
      active={active}
      onVisibilityChange={handleSectionVisibilityChange}
      key='sso-section'
    >
      <SnackAlert open={!!error} severity='error' onClose={() => setError('')}>
        <Body1>{error}</Body1>
      </SnackAlert>
      <FlexingBox>
        <HeadRow>
          <TitleWithSub title='SSO' sub='' infoDescription='sso mappings' />
        </HeadRow>
        <div style={{ padding: spacing.large }}>
          <DataGrid
            rows={rows ?? []}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            isCellEditable={(params) => params.row.id === editableId}
            editMode='row'
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          />
        </div>
      </FlexingBox>
    </Section>
  )
}
