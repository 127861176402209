import React from 'react'
import { Section, SectionId, SysadminSectionId } from '../../emission/Section'
import { UserManagement } from '../UserManagement'

interface Props {
  active: {
    scroll: boolean
    sectionId: SectionId
  }
  handleSectionVisibilityChange: (
    sectionId: SectionId,
    isVisible: boolean,
  ) => void
}

export function Users({
  active,
  handleSectionVisibilityChange,
}: Props): React.JSX.Element {
  return (
    <Section
      sectionId={SysadminSectionId.users}
      active={active}
      onVisibilityChange={handleSectionVisibilityChange}
      key='users-section'
    >
      <UserManagement sysAdmin />
    </Section>
  )
}
