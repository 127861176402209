import styled from 'styled-components'
import { spacing } from '../theme'

export const contentMaxWidthPx = 1300
export const contentMinWidthPx = 750
export const contentFlex = 6

export const LayoutContent = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing.xl};
  align-items: stretch;
  width: 100%;
  max-width: ${contentMaxWidthPx}px;
  min-width: ${contentMinWidthPx}px;
  flex: ${contentFlex};
  gap: ${spacing.large};
`
