import styled from 'styled-components'
import { Flex } from '../../../components/Flex'
import { spacing } from '../../../theme'

export const ProfileBoxContent = styled(Flex)`
  flex-direction: column;
  margin: ${spacing.large};
  gap: ${spacing.xxxl};
`

export const ProfileTableBox = styled(Flex)`
  flex-direction: column;
  gap: ${spacing.medium};
  width: 100%;
`
