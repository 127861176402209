import React, { useContext, useEffect, useState } from 'react'
import { AppDataContext } from '../../../context/AppDataContext'
import { OrganisationFragment, OrgUnit } from '../../../graphql/generated'
import { Dropdown, OutsideClickCatcher } from '../../dropdown/Dropdown'
import { List } from '../../List'
import { Search } from '../../SearchField'
import { useSearchResults } from './useSearchResults'

interface Props {
  organisation: OrganisationFragment
  setOrgUnit: (orgUnit: OrgUnit) => void
}

export function FilterSearch({
  organisation,
  setOrgUnit,
}: Props): React.JSX.Element {
  const { orgUnitMap } = useContext(AppDataContext)
  const [isOpen, setOpen] = useState(false)
  const [search, setSearchValue] = useState('')
  const searchResults = useSearchResults(search, organisation.orgUnits)

  useEffect(() => setOpen(searchResults.hasResults), [searchResults, setOpen])

  const handleClickOutside = () => {
    if (isOpen) {
      setOpen(false)
      setSearchValue('')
    }
  }

  return (
    <OutsideClickCatcher onClickOutside={handleClickOutside}>
      <Search
        onChange={setSearchValue}
        placeholder='Sök på avdelning...'
        search={search}
      />
      <Dropdown width={220} isOpen={isOpen} offsetX={0}>
        <OrgList
          rowKey={(item) => item.id}
          items={searchResults.orgUnits}
          rowText={(item) => item.name}
          label={(item) => orgUnitMap[item.parentId ?? '']?.name ?? ''}
          onClick={(item) => {
            setOrgUnit(item)
            setSearchValue('')
            setOpen(false)
          }}
          isSelected={() => false}
        />
      </Dropdown>
    </OutsideClickCatcher>
  )
}

const OrgList = List<OrgUnit>
