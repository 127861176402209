import React, { useContext } from 'react'
import { Flex } from '../../../components/Flex'
import { Body1, H5Bold } from '../../../components/Typography'
import { AppDataContext } from '../../../context/AppDataContext'
import { OrgType } from '../../../graphql/generated'
import { FortnoxNoDataView } from './FortnoxNoDataView'
import { NoProfileView } from '../NoProfileView'
import {
  contentPadding,
  headerHeight,
  contentWidth,
  navbarWidth,
  spacing,
} from '../../../theme'

export function NotReadyView(): React.JSX.Element | null {
  const { organisation, orgHasInitializedData } = useContext(AppDataContext)

  if (orgHasInitializedData && organisation.profileCreated) {
    return null
  }

  let content = (
    <>
      <H5Bold>Här kommer det visas grafer över ert klimatavtryck sen!</H5Bold>
      <Body1 data-testid='default-no-data-view'>
        Dina uppgifter har inte behandlats ännu. Vi meddelar dig när de är
        klara.
      </Body1>
    </>
  )

  if (!organisation.profileCreated) {
    content = <NoProfileView />
  } else if (organisation.orgType === OrgType.Fortnox) {
    content = <FortnoxNoDataView />
  }
  return (
    <Flex
      column
      itemsCenter
      style={{
        position: 'fixed',
        left: navbarWidth,
        top: headerHeight,
        padding: contentPadding,
        zIndex: 100,
        height: '100%',
        width: contentWidth,
        backdropFilter: 'blur(7px)',
        gap: spacing.medium,
      }}
    >
      {content}
    </Flex>
  )
}
