/**
 * CSS class name which must be assigned to the main nav bar,
 * since the inline style for it must be manually fixed after
 * the tour is completed -- Joyride adds "overflow: initial"
 * if any step in the tour highlights an elelemnt in the nav bar,
 * which breaks the vertical scroll on the nav bar.
 */
export const tourNavbarClassName = 'tour-main-nav-bar'

/**
 * Unique IDs for tour steps.
 */
export const enum StepId {
  e1EmissionNav,
  e2PieDiagram,
  e3PieAccordion,
  e4PerOrg,
  e5SupportNav,
}

/** CSS class names for tour steps, used to target the components in the tour. */
export const emissionTourClassNames: {
  [stepId in StepId]: string
} = {
  [StepId.e1EmissionNav]: 'tour-nav-bar-emission',
  [StepId.e2PieDiagram]: 'tour-pie-card',
  [StepId.e3PieAccordion]: 'tour-pie-card-accordion',
  [StepId.e4PerOrg]: 'tour-org-select',
  [StepId.e5SupportNav]: 'tour-nav-bar-help',
}

/**
 * Local storage field, indicating the emissions tour
 * should start without delay.
 */
export const LOCALSTORAGE_KEY_SHOW_EMIS_TOUR_NO_DELAY =
  'show-emis-tour-no-delay'
