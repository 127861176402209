import styled from 'styled-components'
import { spacing } from '../../../theme'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 360px;

  & > button {
    margin-top: ${spacing.small};
  }
`
