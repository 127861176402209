import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import dayjs from 'dayjs'
import {
  OrgGoalCurveType,
  useGetEmissionRangeQuery,
  useGetOrgGoalQuery,
} from '../graphql/generated'
import { generateGoalCurve } from '../components/goal/goalCurve'
import {
  DataPoint,
  getReductionFactors,
  GoalContext,
  GoalContextData,
  GoalData,
  GoalReductionFactors,
} from './GoalContext'
import { useInterpolatedEmissions } from '../components/goal/useInterpolatedEmissions'
import { usePartialYearEmission } from '../hooks/usePartialYearEmission'

type GoalSettingContextData = {
  maybeTargetFactor: number | undefined
  setReferenceYear: (referenceYear: GoalData['referenceYear']) => void
  setTargetYear: (targetYear: GoalData['targetYear']) => void
  setMaybeTargetFactor: (
    targetFactor: GoalData['targetFactor'] | undefined,
  ) => void
  setCurveType: (curveType: GoalData['curveType']) => void
  resetToDefault: () => void
  /** True if the goal is changed compared to the default. */
  hasChanges: boolean
}

export const GoalSettingContext = React.createContext<GoalSettingContextData>({
  maybeTargetFactor: undefined,
  setReferenceYear: () => {},
  setTargetYear: () => {},
  setMaybeTargetFactor: () => {},
  setCurveType: () => {},
  resetToDefault: () => {},
  hasChanges: false,
})

/**
 * Provides a GoalContext and a means of setting the values.
 * Note: setting the values only changes this context --
 * no GQL mutation is performed.
 */
export function GoalSettingContextProvider({
  children,
}: {
  children: React.ReactNode
}): React.JSX.Element {
  // Currently saved goal, if any:
  const {
    data: orgGoal,
    loading: orgGoalLoading,
    error: orgGoalError,
  } = useGetOrgGoalQuery()

  // Org-wide min/max year where emission data exists:
  const {
    data: emisRangeData,
    loading: emisRangeLoading,
    error: emisRangeError,
  } = useGetEmissionRangeQuery()

  const firstDataYearIfExists: number | undefined = emisRangeData
    ?.getEmissionRange.from
    ? dayjs.utc(emisRangeData.getEmissionRange.from).year()
    : undefined

  const lastDataYearIfExists: number | undefined = emisRangeData
    ?.getEmissionRange.to
    ? dayjs.utc(emisRangeData.getEmissionRange.to).year()
    : undefined

  let defaultRefYear: number | undefined
  if (_.isNumber(orgGoal?.getOrgGoal?.referenceYear) && orgGoal?.getOrgGoal) {
    defaultRefYear = orgGoal.getOrgGoal.referenceYear
  } else if (_.isNumber(firstDataYearIfExists)) {
    defaultRefYear = firstDataYearIfExists
  }

  // Default target is the currently saved target, or we fall back to a few years after the last data year:
  let defaultTargetYear: number | undefined
  if (_.isNumber(orgGoal?.getOrgGoal?.targetYear) && orgGoal?.getOrgGoal) {
    defaultTargetYear = orgGoal.getOrgGoal.targetYear
  } else if (_.isNumber(lastDataYearIfExists)) {
    defaultTargetYear = lastDataYearIfExists + 5
  }

  const defaultCurveType: OrgGoalCurveType =
    orgGoal?.getOrgGoal?.curveType ?? OrgGoalCurveType.S

  const defaultTargetFactor = orgGoal?.getOrgGoal?.targetFactor ?? 0.05 // fallback: 95 % reduction

  const [referenceYear, setReferenceYear] = useState<number | undefined>(
    defaultRefYear,
  )
  const [targetYear, setTargetYear] = useState<number | undefined>(
    defaultTargetYear,
  )

  // To allow the percent input field in GoalParameters to be empty,
  // a separate value is required to track the empty state,
  // here represented by undefined.
  const [maybeTargetFactor, setMaybeTargetFactor] = useState<
    number | undefined
  >(defaultTargetFactor)

  const [curveType, setCurveType] = useState<OrgGoalCurveType>(defaultCurveType)

  // For calculation purposes, treat goal target "not set" as "unchanged" = 1 = 100 % of reference emissions.
  const targetFactor: number = maybeTargetFactor ?? 1

  // The emissions never have a partial year:
  const {
    interpolatedEmissions,
    emissionRanges,
    emissonGaps,
    loading: emissionsLoading,
    error: emissionsError,
  } = useInterpolatedEmissions({ useAnnualWorkForce: false })

  // Get partial year emissions separately (if any):
  const {
    loading: partialEmisLoading,
    error: partialEmisError,
    partialYearEmission,
  } = usePartialYearEmission()

  // Update defaults with server values when/if they are loaded.
  useEffect(() => {
    if (!orgGoalLoading && orgGoal?.getOrgGoal) {
      setMaybeTargetFactor(orgGoal.getOrgGoal.targetFactor)
      setCurveType(orgGoal.getOrgGoal.curveType)
    }
    // default years can change when either org goal or emission ranges change:
    if (_.isNumber(defaultRefYear)) {
      setReferenceYear(defaultRefYear)
    }
    if (_.isNumber(defaultTargetYear)) {
      setTargetYear(defaultTargetYear)
    }
  }, [
    orgGoalLoading,
    orgGoal?.getOrgGoal,
    orgGoal?.getOrgGoal?.targetFactor,
    orgGoal?.getOrgGoal?.curveType,
    defaultRefYear,
    defaultTargetYear,
  ])

  // Goal curve, as emissions over years.
  const goalCurve = useMemo(() => {
    if (!_.isNumber(referenceYear) || !_.isNumber(targetYear)) {
      return []
    }
    return generateGoalCurve(
      curveType,
      referenceYear,
      targetYear,
      targetFactor,
      interpolatedEmissions,
    )
  }, [
    curveType,
    interpolatedEmissions,
    targetFactor,
    referenceYear,
    targetYear,
  ])

  const goalContext = useMemo<GoalContextData>(() => {
    if (
      orgGoalLoading ||
      emisRangeLoading ||
      emissionsLoading ||
      partialEmisLoading
    ) {
      return {
        loading: true,
      }
    }

    if (emisRangeError || orgGoalError || emissionsError || partialEmisError) {
      return {
        loading: false,
        error:
          emisRangeError || orgGoalError || emissionsError || partialEmisError,
        data: undefined,
      }
    }

    // At this point, we know the GQL data has loaded without error,
    // do the following values are expected to be defined;
    // these checks are not expected to throw.
    const referenceEmission = goalCurve.find((p) => p.x === referenceYear)?.y
    if (!_.isNumber(referenceEmission)) {
      return {
        loading: false,
        error: new Error('Hittade ingen data för basåret'),
        data: undefined,
      }
    }

    if (
      !_.isNumber(firstDataYearIfExists) ||
      !_.isNumber(lastDataYearIfExists)
    ) {
      return {
        loading: false,
        error: new Error('Hittade inga utsläppsdata'),
        data: undefined,
      }
    }

    if (!_.isNumber(defaultRefYear) || !_.isNumber(defaultTargetYear)) {
      // This should never happen, since the "__DataYearIfExists" are known to be defined.
      return {
        loading: false,
        error: new Error('Hittade inga utsläppsdata'),
        data: undefined,
      }
    }

    const factorCurve = goalCurve.map<DataPoint>((p) => ({
      x: p.x,
      // If reference year emissions are zero, all years must be zero. Otherwise compute factor relative the reference year emissions.
      y: referenceEmission === 0 ? 0 : p.y / referenceEmission,
    }))

    const reductionFactors = getReductionFactors({
      emissions: interpolatedEmissions,
      goalFactors: factorCurve,
      referenceEmission,
    })

    const partialYearReductionFactors: GoalReductionFactors | undefined =
      partialYearEmission &&
      getReductionFactors({
        emissions: [partialYearEmission],
        goalFactors: factorCurve,
        referenceEmission,
      })

    if (!reductionFactors) {
      return {
        loading: false,
        error: new Error('Kunde inte beräkna utsläppsreduktion'),
        data: undefined,
      }
    }

    const gcd: GoalContextData = {
      loading: false,
      error: undefined,
      data: {
        referenceYear: referenceYear ?? defaultRefYear,
        targetYear: targetYear ?? defaultTargetYear,
        curve: factorCurve,
        curveType,
        targetFactor,
        emissionYearGaps: emissonGaps,
        emissionYearRanges: emissionRanges,
        interpolatedEmissions: interpolatedEmissions,
        goalEmissionCurve: goalCurve,
        referenceEmission,
        targetEmission: referenceEmission * targetFactor,
        firstOrgDataYear: firstDataYearIfExists,
        lastOrgDataYear: lastDataYearIfExists,
        reductionFactors,
        partialYearEmission,
        partialYearReductionFactors,
      },
    }
    return gcd
  }, [
    orgGoalLoading,
    emisRangeLoading,
    emissionsLoading,
    partialEmisLoading,
    emisRangeError,
    orgGoalError,
    emissionsError,
    partialEmisError,
    interpolatedEmissions,
    partialYearEmission,
    referenceYear,
    firstDataYearIfExists,
    targetYear,
    lastDataYearIfExists,
    curveType,
    targetFactor,
    emissonGaps,
    emissionRanges,
    goalCurve,
    defaultRefYear,
    defaultTargetYear,
  ])

  const reset = useCallback(() => {
    setCurveType(defaultCurveType)
    setReferenceYear(defaultRefYear)
    setTargetYear(defaultTargetYear)
    setMaybeTargetFactor(defaultTargetFactor)
  }, [defaultCurveType, defaultRefYear, defaultTargetFactor, defaultTargetYear])

  const goalSettingsData = useMemo<GoalSettingContextData>(() => {
    const sameTargetFactor =
      Math.abs(
        (maybeTargetFactor ?? defaultTargetFactor) - defaultTargetFactor,
      ) < 0.001
    return {
      maybeTargetFactor,
      setCurveType,
      setReferenceYear,
      setTargetYear,
      setMaybeTargetFactor,
      resetToDefault: reset,
      hasChanges:
        curveType !== defaultCurveType ||
        referenceYear !== defaultRefYear ||
        targetYear !== defaultTargetYear ||
        !sameTargetFactor,
    }
  }, [
    maybeTargetFactor,
    reset,
    curveType,
    defaultCurveType,
    referenceYear,
    defaultRefYear,
    defaultTargetYear,
    targetYear,
    defaultTargetFactor,
  ])

  return (
    <GoalSettingContext.Provider value={goalSettingsData}>
      <GoalContext.Provider value={goalContext}>
        {children}
      </GoalContext.Provider>
    </GoalSettingContext.Provider>
  )
}
