import React, { useContext, useEffect, useState } from 'react'
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import ClearIcon from '@mui/icons-material/Clear'
import dayjs from 'dayjs'
import { Flex } from '../../../Flex'
import {
  NumberSearchTypeDropdown,
  StringSearchTypeDropdown,
} from '../search/SearchTypeDropdown'
import {
  Maybe,
  NumberSearchType,
  SearchField,
  StringSearchType,
  TransactionSearchCriterion,
} from '../../../../graphql/generated'
import { RecatContext } from '../../../../context/RecatContext'
import { spacing } from '../../../../theme'

function searchFieldToName(field: SearchField): string {
  switch (field) {
    case SearchField.SupplierName:
      return 'Leverantörs namn'
    case SearchField.SupplierId:
      return 'Leverantörs-id'
    case SearchField.AccountId:
      return 'Konto-id'
    case SearchField.AccountName:
      return 'Kontonamn'
    case SearchField.Comment:
      return 'Beskrivning'
    case SearchField.Date:
      return 'Datum'
    default:
      throw new Error('should never happen')
  }
}

interface SearchFieldProps {
  searchField: SearchField
  setSearchField: React.Dispatch<React.SetStateAction<SearchField>>
}
function SearchFieldDropdown({
  searchField,
  setSearchField,
}: SearchFieldProps): React.JSX.Element {
  const { getAvaillableSearchFields } = useContext(RecatContext)
  const searchFields = getAvaillableSearchFields()

  return (
    <Select
      value={searchField}
      onChange={(event) => {
        setSearchField(event.target.value as SearchField)
      }}
      style={{ minWidth: '180px' }}
    >
      <MenuItem selected value={searchField} key={searchField}>
        {searchFieldToName(searchField)}
      </MenuItem>
      {searchFields
        .sort((field1, field2) =>
          searchFieldToName(field1).localeCompare(searchFieldToName(field2)),
        )
        .map((field) => (
          <MenuItem value={field} key={field}>
            {searchFieldToName(field)}
          </MenuItem>
        ))}
    </Select>
  )
}

interface StringSearchProps {
  onChange: (search: string) => void
  search: string
}

function StringSearchBox({
  onChange,
  search,
}: StringSearchProps): React.JSX.Element {
  return (
    <TextField
      value={search}
      type='text'
      onChange={(ev) => {
        onChange(ev.target.value as string)
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  )
}

interface DateSearchProp {
  setDateSearchType: React.Dispatch<React.SetStateAction<NumberSearchType>>
  dateSearchType: NumberSearchType
  setStartDate: React.Dispatch<React.SetStateAction<Maybe<Date> | undefined>>
  startDate: Maybe<Date> | undefined
  setEndDate: React.Dispatch<React.SetStateAction<Maybe<Date> | undefined>>
  endDate: Maybe<Date> | undefined
}
function DateSearch({
  setDateSearchType,
  dateSearchType,
  setStartDate,
  startDate,
  setEndDate,
  endDate,
}: DateSearchProp): React.JSX.Element {
  return (
    <>
      <NumberSearchTypeDropdown
        setSearchType={setDateSearchType}
        searchType={dateSearchType}
      />
      <DatePicker
        views={['year', 'month', 'day']}
        slotProps={{
          field: {
            clearable: true,
            onClear: () => setStartDate(undefined),
          },
        }}
        onChange={(value) => {
          setStartDate(value?.isValid() ? value.toDate() : undefined)
        }}
        value={dayjs.utc(startDate)}
        timezone='UTC'
      />
      {dateSearchType === NumberSearchType.Between && (
        <DatePicker
          views={['year', 'month', 'day']}
          slotProps={{
            field: {
              clearable: true,
              onClear: () => setEndDate(undefined),
            },
            textField: {
              inputProps: {
                'data-testid': 'end-date-selector',
              },
            },
          }}
          onChange={(value) =>
            setEndDate(value?.isValid() ? value.toDate() : undefined)
          }
          value={dayjs.utc(endDate)}
          minDate={dayjs.utc(startDate)}
          timezone='utc'
        />
      )}
    </>
  )
}

interface SearchLineProps {
  updateSearchCriteria: (searchCriteria: TransactionSearchCriterion) => void
  searchCriterion: TransactionSearchCriterion
  canRemoe?: boolean
}

export function SearchLine({
  updateSearchCriteria,
  searchCriterion,
  canRemoe,
}: SearchLineProps): React.JSX.Element {
  const { removeSearchCriterion } = useContext(RecatContext)
  const [searchField, setSearchField] = useState<SearchField>(
    searchCriterion.searchField,
  )
  const [stringSearchType, setStringSearchType] = useState<StringSearchType>(
    searchCriterion.stringSearchType ?? StringSearchType.Equal,
  )
  const [searchString, setsearchString] = useState(
    searchCriterion.searchString ? searchCriterion.searchString[0] : '',
  )
  const [dateSearchType, setDateSearchType] = useState(
    searchCriterion.numberSearchType ?? NumberSearchType.Equal,
  )
  const [startDate, setStartDate] = useState<Maybe<Date> | undefined>(
    searchCriterion.startDate,
  )
  const [endDate, setEndDate] = useState<Maybe<Date> | undefined>(
    searchCriterion.endDate,
  )

  useEffect(() => {
    updateSearchCriteria({
      searchField,
      searchString: [searchString],
      stringSearchType,
      numberSearchType: dateSearchType,
      startDate,
      endDate,
      id: searchCriterion.id,
    })
  }, [
    dateSearchType,
    endDate,
    searchCriterion.id,
    searchField,
    searchString,
    startDate,
    stringSearchType,
    updateSearchCriteria,
  ])

  return (
    <Flex itemsCenter style={{ gap: spacing.medium }} data-testid='search-line'>
      <SearchFieldDropdown
        searchField={searchField}
        setSearchField={setSearchField}
      />
      {searchCriterion.searchField === SearchField.Date ? (
        <DateSearch
          {...{
            setDateSearchType,
            dateSearchType,
            setStartDate,
            startDate,
            setEndDate,
            endDate,
          }}
        />
      ) : (
        <>
          <StringSearchTypeDropdown
            setSearchType={setStringSearchType}
            searchType={stringSearchType}
          />
          <StringSearchBox onChange={setsearchString} search={searchString} />
        </>
      )}
      <IconButton
        onClick={() => removeSearchCriterion(searchCriterion)}
        disabled={!canRemoe}
        data-testid='search-remove-button'
      >
        <ClearIcon />
      </IconButton>
    </Flex>
  )
}
