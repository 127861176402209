import styled from 'styled-components'
import { Flex } from './Flex'

export const WrapperFlex = styled(Flex)`
  position: relative;
  height: 100%;
  width: 100%;
  flex-direction: row;
  align-items: center;
`
