export const planPatternSpacing = 92
export const planPatternStrokeWidth = 46
export const planPatternAngle = 45
// TODO: Add to general theme palette ?
export const planPatternForeground = '#E5E6E5'
export const planPatternBackground = '#FBFBFB'

export const budgetSvgPatternId = 'budget-svg-pattern'
export const planLegendStripeId = 'plan-legend-svg-pattern'

export const emissionAreaUrlId = 'emission-area-background'
export const emissionLineUrlId = 'emission-line-background'

export const goalLegendLabelName = 'Målkurva'
export const partialYearLegendLabelName = 'Hittils i år'

/**
 * Unique names of the various goal charts;
 * must be passed as the `name` prop to the
 * relevant victory charts.
 */
export const chartNames = {
  emissionArea: 'emissions',
  emissionDot: 'emissionsDots',
  budgetArea: 'budgetArea',
  partialYearLine: 'partialYear',
  partialYearDot: 'partialYearDot',
  goalLine: 'goalLine',
} as const

type ChartNameType = typeof chartNames
/** Sum type of keys in `chartNames` */
export type ChartNameKey = keyof ChartNameType
/** Sum type of values in `chartNames` */
export type ChartNameValue = ChartNameType[ChartNameKey]
