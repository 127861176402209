import React, { useCallback, useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid'
import _ from 'lodash'
import dayjs from 'dayjs'
import { Body1 } from '../../../components/Typography'
import { Flex, InnerInnerBox } from '../../../components/Flex'
import { ButtonLightBorder } from '../../../components/Buttons'
import {
  preProcessEditNumberStrict,
  renderEditCell,
  validateNumber,
} from '../utils/Checkers'
import {
  EnumTextTuple,
  GetPremisesDocument,
  HeatSystem,
  Premises,
  useGetPremisesQuery,
  useGetHeatSystemTextQuery,
  useReplacePremisesMutation,
} from '../../../graphql/generated'
import { Stepprops } from './ProfileStepper'
import { ProfileTableBox } from './styles'
import { useTableCardHelper } from '../utils/TableCardHelper'
import { spacing } from '../../../theme'

interface PremisesRow extends Premises {
  isNew?: boolean
}

export function PremisesStep({
  setSnackbar,
  editEnabled,
  setLoading,
  setSaveAndClose,
  setSaveAndContinue,
  forward,
  close,
  setReset,
}: Stepprops): React.JSX.Element {
  const { data: premisesData, loading } = useGetPremisesQuery()
  const [replacePremises] = useReplacePremisesMutation({
    refetchQueries: [{ query: GetPremisesDocument }],
  })
  const [rows, setRows] = useState<PremisesRow[]>([])
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'address',
      sort: 'asc',
    },
  ])
  const [submitting, setSubmitting] = useState(false)
  const { data: heatingSystemTextData, loading: loadingText } =
    useGetHeatSystemTextQuery()
  const [heatingSystemText, setHeatingSystemText] = useState<EnumTextTuple[]>(
    [],
  )

  const apiRef = useGridApiRef()

  useEffect(() => {
    setLoading(submitting || loading || loadingText)
  }, [loading, loadingText, setLoading, submitting])

  const setInitialRows = useCallback(() => {
    setRows(premisesData?.getPremises ?? [])
  }, [premisesData?.getPremises])

  useEffect(() => {
    setHeatingSystemText(heatingSystemTextData?.getHeatSystemText ?? [])
  }, [heatingSystemTextData])

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
    apiRef.current.setCellFocus(id, 'address')
  }

  const validateRow = useCallback((row: PremisesRow) => {
    let errorMessage = validateString(row.address)
    if (errorMessage) {
      return `Ogiltig adress: ${errorMessage}`
    }
    errorMessage = validateString(row.city)
    if (errorMessage) {
      return `Ogiltig stad: ${errorMessage}`
    }
    errorMessage = validateNumber(row.area)
    if (errorMessage) {
      return `Ogiltig yta: ${errorMessage}`
    }
    return undefined
  }, [])

  function validateString(value: unknown): string | undefined {
    if (typeof value !== 'string') {
      return 'måste vara en text'
    }
    if (!value) {
      return 'Du måste ange ett värde'
    }
    return undefined
  }

  function preProcessEditString(params: GridPreProcessEditCellProps) {
    const errorMessage = validateString(params.props.value)
    return { ...params.props, error: errorMessage }
  }

  const columns: GridColDef[] = [
    {
      field: 'address',
      headerName: 'Adress',
      flex: 2,
      editable: editEnabled,
      preProcessEditCellProps: preProcessEditString,
      renderEditCell,
    },
    {
      field: 'city',
      headerName: 'Kommun',
      flex: 2,
      editable: editEnabled,
      preProcessEditCellProps: preProcessEditString,
      renderEditCell,
    },
    {
      field: 'area',
      headerName: 'Yta (kvm)',
      flex: 1,
      editable: editEnabled,
      type: 'number',
      preProcessEditCellProps: preProcessEditNumberStrict,
      renderEditCell,
      renderCell: (props: GridRenderCellParams) => {
        const { value } = props
        if (value < 0) {
          return 'Okänd'
        }
        return value
      },
    },
    {
      field: 'heatingSystem',
      headerName: 'Uppvärmningsform',
      flex: 2,
      editable: editEnabled,
      type: 'singleSelect',
      valueOptions: heatingSystemText,
      getOptionValue: (value: any) => value.key,
      getOptionLabel: (value: any) => value.value,
    },
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      cellClassName: 'actions',
      flex: 1,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
        if (!isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label='Ändra'
              onClick={handleEditClick(id)}
              color='inherit'
              key='edit'
              disabled={!editEnabled}
            />,
          ]
        }
        return []
      },
    },
  ]

  const handleAddRow = () => {
    const id = dayjs.utc().valueOf()
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        orgId: '',
        address: '',
        city: '',
        area: 0,
        heatingSystem: HeatSystem.Okand,
        isNew: true,
      },
    ])
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'address' },
    }))

    //reset the sort model to make sure that the new row is at the top
    setSortModel([
      {
        field: 'address',
        sort: 'asc',
      },
    ])
  }

  const save = useCallback(
    async (toSave: PremisesRow[]): Promise<boolean> => {
      try {
        setSubmitting(true)
        const invalidRows = toSave.filter((r) => validateRow(r))
        if (invalidRows.length > 0) {
          setSnackbar({
            children: `Följande lokaler har ogiltiga poster, vänligen åtgärda dem: ${invalidRows
              .map((r) => r.address)
              .join(', ')}`,
            severity: 'error',
          })
          return false
        }
        await replacePremises({
          variables: {
            premises: toSave.map((r) => {
              // remove the fields that are not part of Premises
              return _.omit(r, 'isNew')
            }),
          },
        })
        return true
      } catch (error) {
        let msg = error
        if ((error as Error).message !== undefined) {
          msg = (error as Error).message
        }
        setSnackbar({
          children: `Det gick inte att spara lokalerna ${msg}`,
          severity: 'error',
        })
      } finally {
        setSubmitting(false)
      }
      return false
    },
    [replacePremises, setSnackbar, validateRow],
  )

  const {
    processRowUpdate,
    selectedRows,
    setSelectedRows,
    handleDeleteClick,
    handleProcessRowUpdateError,
  } = useTableCardHelper({
    rowModesModel,
    setRowModesModel,
    setSaveAndContinue,
    setSaveAndClose,
    forward,
    close,
    setReset,
    setInitialRows,
    rows,
    save,
    validateRow,
    setSnackbar,
    setRows,
    apiRef,
  })

  return (
    <InnerInnerBox>
      <Body1>
        Nedan listas de adresser där er organisation har registrerade lokaler
        enligt Proff.se. Lägg till information om lokalyta och uppvärmningsform.
        Informationen hjälper oss att vid behov kontrollera och verifiera era
        Scope 2-utsläpp.
      </Body1>
      <ProfileTableBox>
        <Flex
          style={{
            gap: spacing.small,
            alignSelf: 'end',
            opacity: editEnabled ? 1 : 0,
          }}
        >
          <ButtonLightBorder
            startIcon={<DeleteOutlinedIcon />}
            onClick={handleDeleteClick}
            disabled={!editEnabled || !selectedRows.length}
          >
            Radera valda rader
          </ButtonLightBorder>
          <ButtonLightBorder
            startIcon={<AddIcon />}
            onClick={handleAddRow}
            disabled={!editEnabled}
          >
            LÄGG TILL
          </ButtonLightBorder>
        </Flex>
        <Flex stretchWidth style={{ minHeight: '150px' }}>
          <DataGrid
            apiRef={apiRef}
            rows={rows ?? []}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            style={{
              width: '100%',
              height: !rows?.length ? '150px' : 'auto',
            }}
            rowModesModel={rowModesModel}
            editMode='row'
            onRowModesModelChange={setRowModesModel}
            processRowUpdate={processRowUpdate}
            sortModel={sortModel}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            loading={loading || loadingText}
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                whiteSpace: 'break-spaces',
                lineHeight: 1,
              },
              '&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer':
                {
                  pl: 1,
                },
            }}
            disableVirtualization
            checkboxSelection
            onRowSelectionModelChange={(ids) => {
              setSelectedRows(ids as number[])
            }}
          />
        </Flex>
      </ProfileTableBox>
    </InnerInnerBox>
  )
}
