import React from 'react'
import { useHistory } from 'react-router-dom'
import { SSOLogin } from './SSOLogin'
import { LoginSelector } from './LoginSelector'
import { LoginPageWrapper } from './components/LoginPageWrapper'
import { Flex } from '../../components/Flex'
import { ButtonLightBorder } from '../../components/Buttons'
import { spacing } from '../../theme'

function RedirectToRegister() {
  const history = useHistory()
  return (
    <Flex
      column
      itemsCenter
      style={{
        width: '80%',
        gap: spacing.medium,
        marginTop: spacing.medium,
      }}
    >
      <span>Är din organisation inte registerad ännu?</span>
      <ButtonLightBorder
        style={{ width: '100%' }}
        onClick={() => history.push('/registration')}
      >
        Starta förfrågan om registrering
      </ButtonLightBorder>
    </Flex>
  )
}

export function LoginPage(): React.JSX.Element {
  const [error, setError] = React.useState('')

  return (
    <LoginPageWrapper error={error} redirectBlock={<RedirectToRegister />}>
      {window.location.hostname === process.env.REACT_APP_SWAMID_HOST ? (
        <SSOLogin setError={setError} error={error} />
      ) : (
        <LoginSelector setError={setError} />
      )}
    </LoginPageWrapper>
  )
}
