import React, { ReactNode, useContext } from 'react'
import styled from 'styled-components'
import type { Swiper as SwiperType } from 'swiper'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
// CSS required for the swiper library.
import 'swiper/css/bundle'
import CloseSvg from '../../assets/icons/close.svg'
import LineSvg from '../../assets/icons/remove-outline.svg'
import ResizeSvg from '../../assets/icons/resize-outline.svg'
import { Icon } from '../../components/Icon'
import { Body1Bold, H5Bold } from '../Typography'
import { OnboardContext } from '../../context/OnboardingSnackContext'
// CSS with customisations of swiper components.
// For example, this file implements the shadow gradients displayed when
// content in the onboarding box overflows (user must scroll to see all of it).
import './OnboardSnack.css'
import { Flex } from '../Flex'
import { OnboardFooter } from './OnboardFooter'
import { spacing } from '../../theme'

// Disable "useless fragment" since each "body" in `OnboardTopics` below must be a (string or) single ReactNode, which means
// the framgents are necessary when grouping multiple paragraphs, but not for a single paragraph.
// So, to prevent having to alternate between defining a fragment or not when changing the number of paragraphs,
// the rule is disabled for this file.
/* eslint-disable react/jsx-no-useless-fragment */

interface Props {
  onboardTopics: Array<{
    head: ReactNode
    body: ReactNode
    footer: ReturnType<typeof OnboardFooter>
  }>
  displayKey: string
}

export function OnboardSnack({
  onboardTopics,
  displayKey,
}: Props): React.JSX.Element | null {
  const { currentSlide, setCurrentSlide } = useContext(OnboardContext)
  const [minimised, setMinimised] = React.useState<boolean>(false)
  const [swiper, setSwiper] = React.useState<SwiperType | null>(null)
  const [rerenderFlag, setRerenderFlag] = React.useState<boolean>(false)

  const slideIndex = currentSlide ?? 0
  let showIntro = true
  try {
    showIntro =
      slideIndex === undefined ||
      JSON.parse(localStorage.getItem(displayKey) ?? 'true')
  } catch (_e) {
    // ignore
  }
  const rerender = () => setRerenderFlag(!rerenderFlag)

  if (!showIntro) {
    return null
  }

  const FullSnack = (
    <div
      style={{
        display: 'flex',
        position: 'fixed',
        bottom: 0,
        right: 40,
        width: '50em',
        height: '50ex',
        minWidth: 400,
        minHeight: 460,
        maxWidth: '70%',
        maxHeight: '70%',
        background: 'white',
        flexDirection: 'column',
        boxShadow: '0 0 6px 1px gray',
        borderRadius: 8,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '6ex',
          paddingLeft: spacing.large,
          paddingRight: spacing.large,
          borderBottom: 1,
          borderBottomStyle: 'solid',
          borderBottomColor: 'lightgray',
        }}
      >
        <H5Bold>Intro</H5Bold>
        <Flex>
          <button
            type='button'
            style={{
              border: 0,
              backgroundColor: 'transparent',
              cursor: 'pointer',
            }}
            onClick={() => setMinimised(true)}
          >
            {/* <Icon src={ResizeSvg} style={{ width: '5em', cursor: 'pointer' }} /> */}
            <Icon src={LineSvg} style={{ width: '5em' }} />
          </button>
          <button
            type='button'
            style={{
              border: 0,
              backgroundColor: 'transparent',
              cursor: 'pointer',
            }}
            onClick={() => {
              localStorage.setItem(displayKey, 'false')
              rerender()
            }}
          >
            <Icon src={CloseSvg} style={{ width: '5em' }} />
          </button>
        </Flex>
      </div>
      <Swiper
        initialSlide={slideIndex}
        onSwiper={setSwiper}
        onSlideChange={(sw) => {
          setCurrentSlide(sw.realIndex)
        }}
        centeredSlides
        slidesPerView={1}
        modules={[Pagination]}
        allowTouchMove={false}
        pagination={{
          clickable: true,
          type: 'bullets',
        }}
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
        }}
      >
        {onboardTopics.map(({ head, body }, idx) => {
          const key = idx * 10
          return (
            <SwiperSlide key={`slide-${key}`}>
              <Flex
                column
                style={{
                  height: 'calc(100% - 32px)',
                  overflowY: 'auto',
                }}
              >
                <Flex column style={{ gap: spacing.medium }}>
                  {head}
                  {body}
                </Flex>
              </Flex>
            </SwiperSlide>
          )
        })}
      </Swiper>

      {onboardTopics[slideIndex].footer({ rerender, swiper, displayKey })}
    </div>
  )

  const MinimisedSnack = (
    <div
      style={{
        display: 'flex',
        position: 'fixed',
        bottom: 0,
        right: 40,
        minWidth: 100,
        minHeight: 50,
        background: 'white',
        flexDirection: 'column',
        boxShadow: '0 0 6px 1px gray',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <H5Bold>Intro</H5Bold>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <button
            type='button'
            style={{
              border: 0,
              backgroundColor: 'transparent',
              cursor: 'pointer',
            }}
            onClick={() => setMinimised(false)}
          >
            <Icon src={ResizeSvg} style={{ width: '5em' }} />
          </button>
          <button
            type='button'
            style={{
              border: 0,
              backgroundColor: 'transparent',
              cursor: 'pointer',
            }}
            onClick={() => {
              localStorage.setItem(displayKey, 'false')
              rerender()
            }}
          >
            <Icon src={CloseSvg} style={{ width: '5em' }} />
          </button>
        </div>
      </div>
    </div>
  )

  return (minimised && MinimisedSnack) || FullSnack
}

export const OnboardBoldText = styled(Body1Bold)`
  display: inline;
`
