import React, { useContext } from 'react'
import { FormControl, TextField } from '@mui/material'
import { FormikErrors, FormikTouched } from 'formik'
import { Flex, InnerInnerBox } from '../../Flex'
import { RecatContext } from '../../../context/RecatContext'
import { Category, EmissionLayerInput } from '../../../graphql/generated'
import { colors, spacing } from '../../../theme'
import { RecatType } from './SelectRecatType'
import { TransactionsInfoBox } from './search/TransactionsInfoBox'
import { Body1, Body2 } from '../../Typography'

// TODO RECAT put this commented out section back when the new emission model is published
// const StyledFormControl = styled(FormControl)`
//   flex-direction: row;
//   align-items: center;
// `

interface Props {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void
  handleBlur: {
    (e: React.FocusEvent<any, Element>): void
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void
  }
  formValues: {
    category: Category | undefined
    comment: string
    emissionIntensity: number
    emissionLayers: EmissionLayerInput
  }
  handleChange: {
    (e: React.ChangeEvent<any>): void
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1,
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void
  }
  formErrors: FormikErrors<{
    comment: string
    emissionIntensity: number
  }>
  touched: FormikTouched<{
    comment: string
    apply: boolean
    emissionIntensity: number
    recatType: RecatType
  }>
}

export function SetEmissionsBox({
  handleSubmit,
  handleBlur,
  formValues,
  handleChange,
  formErrors,
  touched,
}: Props): React.JSX.Element {
  const { totalKr, containsRuleOverLap } = useContext(RecatContext)

  return (
    <form onSubmit={handleSubmit} id='recatForm'>
      <InnerInnerBox>
        <Body2>
          Detta kommer att ställa in det totala utsläppsvärdet för alla
          befintliga transaktioner som matchar följande kriterier. Uppdatera
          sökningen för att ändra kriterierna. När du lägger till regeln kommer
          den inte att tillämpas direkt, utan sparas i en lista av Regler att
          tillämpa längre ner.
        </Body2>
        <TransactionsInfoBox />

        <Flex style={{ gap: spacing.medium, alignItems: 'baseline' }}>
          <TextField
            id='emissionIntensity'
            label='utsläpp (Kg)'
            type='number'
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            value={formValues.emissionIntensity}
            variant='standard'
            required
            error={
              !!touched.emissionIntensity && !!formErrors.emissionIntensity
            }
            helperText={
              touched.emissionIntensity && formErrors.emissionIntensity
            }
            inputProps={{ 'data-testid': 'emission-setter', min: 0 }}
          />

          <Body1>
            (intensitet: {(formValues.emissionIntensity / totalKr).toFixed(5)}{' '}
            KgCO2/kr)
          </Body1>
        </Flex>

        <FormControl sx={{ minWidth: '420px' }}>
          <TextField
            name='comment'
            id='comment'
            label='Var fick du det här numret ifrån?'
            onChange={handleChange}
            onBlur={handleBlur}
            value={formValues.comment}
            required
            multiline
            inputProps={{ 'data-testid': 'comment-setter', min: 0 }}
            error={!!touched.comment && !!formErrors.comment}
            helperText={touched.comment && formErrors.comment}
          />
        </FormControl>
        {/* TODO RECAT put this commented out section back when the new emission model is published */}
        {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='advance'
            id='advance-header'
          >
            Avancerade alternativ
          </AccordionSummary>
          <AccordionDetails>
            <Flex
              style={{
                fontWeight: 'bold',
              }}
            >
              I Svalnas system beräknas utsläpp med en ekonomisk modell som
              innefattar alla aktiviteter i den ekonomiska verksamheten i
              produktionskedjan. Om du tex har fått ett LCA-värde från din
              leverantör så är det vanligt att det har beräknats med en
              systemgräns som innefattar de delar som direkt hör ihop med
              produktionen av produkten, som energianvändning och insatsvaror,
              men inte andra delar av verksamheten, som investeringar i maskiner
              eller tjänster som leverantören använder. För att utsläppsvärdet
              ska bli konstekvent med resten av resultaten kan systemet koppla
              ditt värde till de utsläppsskickt som det motsvarar. Välj de typer
              av utsäpp som omfattas av ditt värde.
            </Flex>
            <FormGroup>
              <StyledFormControl>
                <FormControlLabel
                  name='emissionLayers.ttm'
                  onChange={handleChange}
                  control={<Checkbox checked={formValues.emissionLayers.ttm} />}
                  label={layerKeyToName('ttm')}
                />
                <TooltipInfo
                  style={{ display: 'inline-flex' }}
                  info='Utsläpp från transport och handelstjänster kopplade till att få produkten från producenten till köparen'
                />
              </StyledFormControl>
              <StyledFormControl>
                <FormControlLabel
                  name='emissionLayers.scope1'
                  onChange={handleChange}
                  control={
                    <Checkbox checked={formValues.emissionLayers.scope1} />
                  }
                  label={layerKeyToName('scope1')}
                />
                <TooltipInfo
                  style={{ display: 'inline-flex' }}
                  info='Utsläpp av växthusgaser som sker direkt från leverantörens aktiviteter, som tex förbränning av bränslen'
                />
              </StyledFormControl>
              <StyledFormControl>
                <FormControlLabel
                  name='emissionLayers.energy'
                  onChange={handleChange}
                  control={
                    <Checkbox checked={formValues.emissionLayers.energy} />
                  }
                  label={layerKeyToName('energy')}
                />
                <TooltipInfo
                  style={{ display: 'inline-flex' }}
                  info='Utsläpp från generering och tillhandahållande av den el och värme som leverantören använder'
                />
              </StyledFormControl>
              <StyledFormControl>
                <FormControlLabel
                  name='emissionLayers.products'
                  onChange={handleChange}
                  control={
                    <Checkbox checked={formValues.emissionLayers.products} />
                  }
                  label={layerKeyToName('products')}
                />
                <TooltipInfo
                  style={{ display: 'inline-flex' }}
                  info='Utsläpp från produktion av de varor som leverantören förbrukar i produktionen'
                />
              </StyledFormControl>
              <StyledFormControl>
                <FormControlLabel
                  name='emissionLayers.investments'
                  onChange={handleChange}
                  control={
                    <Checkbox checked={formValues.emissionLayers.investments} />
                  }
                  label={layerKeyToName('investments')}
                />
                <TooltipInfo
                  style={{ display: 'inline-flex' }}
                  info='Utsläpp från tillverkning av leverantörens utrustning och lokaler'
                />
              </StyledFormControl>
              <StyledFormControl>
                <FormControlLabel
                  name='emissionLayers.services'
                  onChange={handleChange}
                  control={
                    <Checkbox checked={formValues.emissionLayers.services} />
                  }
                  label={layerKeyToName('services')}
                />
                <TooltipInfo
                  style={{ display: 'inline-flex' }}
                  info='Utsläpp från de tjänster leverantören använder, tex innefattar det uppströms utsläpp från lokaler, IT-utrustning o.s.v.'
                />
              </StyledFormControl>
            </FormGroup>
          </AccordionDetails>
        </Accordion> */}
        {containsRuleOverLap && (
          <div
            style={{
              background: colors.orange,
              padding: spacing.small,
              marginTop: spacing.medium,
            }}
            data-testid='overlap-warning'
          >
            Varning: utsläppsvärdet för vissa transaktioner styrs redan av en
            regel som du har skapat. Den nya regeln kommer att skriva över
            värdet för dessa transaktioner.
          </div>
        )}
      </InnerInnerBox>
    </form>
  )
}
