import React from 'react'
import { ButtonGreen, ButtonLightBorder } from '../../../../components/Buttons'
import { RegistrationViews } from '../RegistrationPage'
import { useRemoveOrgRegistrationMutation } from '../../../../graphql/generated'
import { Body1, H5 } from '../../../../components/Typography'

export function CannotRegisterPage({
  setError,
  setView,
  registrationCode,
  confirmationCode,
}: {
  setError: React.Dispatch<React.SetStateAction<string>>
  setView: React.Dispatch<React.SetStateAction<RegistrationViews>>
  registrationCode: string
  confirmationCode: string
}): React.JSX.Element {
  const [removeRegistration] = useRemoveOrgRegistrationMutation()
  const [loading, setLoading] = React.useState(false)

  const handleNoThanks = async () => {
    setLoading(true)
    try {
      await removeRegistration({
        variables: { id: registrationCode, confirmationCode: confirmationCode },
      })
      setView(RegistrationViews.sorryToHear)
    } catch (error) {
      let msg = error
      if ((error as Error).message !== undefined) {
        msg = (error as Error).message
      }
      setError(`${msg}`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <H5>Vi kan inte hjälpa er...än!</H5>
      <Body1>
        Vi har ännu inte stöd för ert ekonomisystem, men vi lägger till nya
        kontinuerligt. Kan vi behålla din e-postadresss och meddela så snart vi
        är klara?
      </Body1>
      <ButtonGreen
        style={{ width: '100%' }}
        onClick={() => setView(RegistrationViews.thanks)}
      >
        Ja, hör gärna av er
      </ButtonGreen>
      <ButtonLightBorder
        style={{ width: '100%' }}
        onClick={handleNoThanks}
        disabled={loading}
      >
        Nej tack
      </ButtonLightBorder>
    </>
  )
}
