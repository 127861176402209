import React, { useContext } from 'react'
import styled from 'styled-components'
import { FortnoxContext } from '../../../context/FortnoxContext'
import { Body1, H5Bold, H6Bold } from '../../../components/Typography'
import { Loader } from '../../../components/Loader'
import { Flex, FlexingBox } from '../../../components/Flex'
import { spacing } from '../../../theme'
import { JobContext } from '../../../context/JobContext'
import { FortnoxAddAccountantForm } from './FortnoxAddAccountantForm'
import { Role, useMeQuery } from '../../../graphql/generated'

const Container = styled(FlexingBox)`
  margin-top: ${spacing.large};
  margin-right: ${spacing.large};
  max-width: 680px;
  padding: ${spacing.xl};
  gap: ${spacing.xxxl};
`

export function FortnoxNoDataView(): React.JSX.Element {
  const { data: meData } = useMeQuery()
  const user = meData?.me
  const { jobRunning, currentState } = useContext(JobContext)
  const { permissionError } = useContext(FortnoxContext)

  if (jobRunning) {
    return (
      <>
        <H5Bold>Här kommer det visas grafer över ert klimatavtryck sen!</H5Bold>
        <Body1 data-testid='working'>
          Vi laddar och behandlar era uppgifter.
        </Body1>
        <Loader size={80} borderSize={20} />
        <H6Bold>{currentState ?? 'Få data från Fortnox'}</H6Bold>
      </>
    )
  }
  if ([Role.Admin, Role.SysAdmin].includes(user?.role ?? Role.User)) {
    if (permissionError?.hasBeenSet) {
      if (permissionError.error) {
        return (
          <Container>
            <H5Bold data-testid='no-permission-admin'>
              Vi saknar godkännde från er för att hämta data från Fortnox och
              kan därför inte visa resultat.
            </H5Bold>
            <FortnoxAddAccountantForm />
          </Container>
        )
      }
      return (
        <Body1>
          Vi kommer att börja ladda ner och behandla dina uppgifter inom kort.
        </Body1>
      )
    }
  } else if (user) {
    // if it is not an admin the user can't load the data from Fortnox
    return (
      <Container>
        <H5Bold data-testid='no-permission-user'>
          Vi saknar godkännde från er för att hämta data från Fortnox och kan
          därför inte visa resultat.
        </H5Bold>
        <Flex column style={{ gap: spacing.medium }}>
          <Body1>
            En administratör eller en revisor måste logga in och ladda ner data
            från Fortnox innan du kan se något resultat här.
          </Body1>
        </Flex>
      </Container>
    )
  }
  // If user or permissionError is not set yet just show a loading page
  return <Loader size={80} borderSize={20} />
}
