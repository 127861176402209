import styled from 'styled-components'
import {
  SmallCardBox as BaseSmallCardBox,
  SmallCardHeader as BaseSmallCardHeader,
} from '../../../routes/dashboard/meta-data/smallCardBox'
// Note: Re-exporting unchanged styles from small card template to avoid confusion by having dependent code import both.
export {
  SmallCardContent,
  SmallCardFooter,
  SmallCardTitle,
  SmallCardWrapper,
} from '../../../routes/dashboard/meta-data/smallCardBox'

export type GeneralSmallCardProps = {
  inlined?: boolean
}

// TODO: This does not really belong in this file, but having a separate file just for this is also weird.
export const noGoalDefinedErrorMessage = 'Inget mål har definierats'

export const SmallCardBox = styled<typeof BaseSmallCardBox>(
  BaseSmallCardBox,
).withConfig({
  shouldForwardProp: (prop) => !['inlined'].includes(prop),
})<{ inlined?: boolean }>`
  ${({ inlined }) =>
    inlined
      ? {
          border: 'none',
          borderRadius: '0',
          filter: 'none',
          // Note: Since the augmented "styled" component defines margin/padding in "@media" sections,
          //      the "styled" library generates separate classes wich are applied *after* these styles,
          //      which mean they override all margin/padding unless specified as "!important".
          margin: '0!important',
          padding: '0!important',
          maxWidth: 330,
        }
      : {}}
`

export const SmallCardHeader = styled(BaseSmallCardHeader).withConfig({
  shouldForwardProp: (prop) => !['inlined'].includes(prop),
})<{ inlined?: boolean }>`
  ${({ inlined }) => (inlined ? { borderBottom: '1px solid lightgray' } : {})}
`
