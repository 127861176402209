import { ApolloClient, ApolloLink } from '@apollo/client'
import { cache } from './apolloCache'
import { authLink } from './authLink'
import { errorLink } from './errorLink'
import { httpLink } from './httpLink'
import { removeTypenameLink } from './removeTypenameLink'

export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([removeTypenameLink, errorLink, authLink, httpLink]),

  name: 'ci-web-client',
  version: '1.0',
  queryDeduplication: false,
})
