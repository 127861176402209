import React from 'react'
import styled from 'styled-components'
import { VictoryPie } from 'victory'
import { defaultChartTheme } from '../../../chartTheme'
import { CO2eFormat } from '../../../constants'
import { Category } from '../../../graphql/generated'
import { getNumberFormatter } from '../../../utils/adaptiveNumberFormat'
import { Flex } from '../../Flex'
import type { EmissionData, EmissionsData } from './useCategoryPieData'
import { Body1, H4 } from '../../Typography'

const Container = styled(Flex)`
  position: relative;
  margin: 0 auto 0 auto;
`

const TotalCO2 = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

interface Props {
  data: EmissionsData | undefined
  onCategoryClick?: (category: Category | undefined) => void
  selectedCategory?: Category
  canGoDeeper: (emission?: EmissionData) => boolean
  dig: (emission: EmissionData | undefined) => void
}

export function CategoryPie({
  data,
  onCategoryClick,
  selectedCategory,
  canGoDeeper,
  dig,
}: Props): React.JSX.Element {
  const emissions = data?.emissions ?? []
  const totalCO2e = data?.totalCO2e ?? 0

  const formatter = getNumberFormatter([totalCO2e ?? 0])

  return (
    <Container>
      <TotalCO2>
        <H4>{formatter.format(totalCO2e, { hideUnit: true })}</H4>
        <Body1>
          {formatter.unit} {CO2eFormat}
        </Body1>
      </TotalCO2>
      <svg height={300} width={300} overflow='visible'>
        <VictoryPie
          standalone={false}
          data={
            emissions?.length
              ? emissions.map((emission) => emission.totalCO2e)
              : undefined
          }
          theme={defaultChartTheme}
          labels={
            emissions?.length
              ? emissions.map((e) =>
                  selectedCategory?.id === e.category.id ? `${e.percent}%` : '',
                )
              : []
          }
          colorScale={emissions.map((e) =>
            selectedCategory?.id === e.category.id
              ? e.category.color
              : 'transparent',
          )}
          innerRadius={130}
          radius={145}
          animate={false}
          style={{
            labels: { fontSize: '16px', fontWeight: 'bold' },
          }}
        />
        <MainPie
          canGoDeeper={canGoDeeper}
          dig={dig}
          emissions={emissions}
          onCategoryClick={onCategoryClick}
        />
      </svg>
    </Container>
  )
}

interface MainPieProps {
  onCategoryClick?: (category: Category | undefined) => void
  emissions: EmissionData[]
  canGoDeeper: (emission?: EmissionData) => boolean
  dig: (emission: EmissionData | undefined) => void
}

// VictoryChart does not handle well having a rerender during an annimation.
// This happen when navigating between categories or years. Here is the flow of event to understand why
// The user click on the navigation buttion -> the categori or year value is changed -> this trigger a rerender ->
// during this rerender the values that need to be displayed are updated -> this trigger a second rerender.
// As the second rerender happen during the chart animation VictoryChart may get stuck on displaying the old values from
// the first animation.
// To avoid this problem we use React.memo and only rerender the chart if the emissions values have changed
const MainPie = React.memo(
  ({ onCategoryClick, emissions, canGoDeeper, dig }: MainPieProps) => {
    const handleRowDoubleClick = (emission: EmissionData | undefined) => {
      if (canGoDeeper(emission)) {
        dig(emission)
      }
    }

    return (
      <VictoryPie
        standalone={false}
        style={{
          data: {
            cursor: 'pointer',
          },
        }}
        data={
          emissions?.length
            ? emissions.map((emission) => emission.totalCO2e)
            : undefined
        }
        theme={defaultChartTheme}
        labels={[]}
        colorScale={emissions.map((e) => e.category.color)}
        animate={{
          duration: 200,
          onEnter: { duration: 0 },
          onExit: { duration: 0 },
        }}
        events={[
          {
            target: 'data',
            eventHandlers: {
              onClick: () => [
                {
                  mutation: ({ index }) => {
                    if (onCategoryClick) {
                      onCategoryClick(emissions[index]?.category)
                    }
                    return null
                  },
                },
              ],
              onDoubleClick: () => [
                {
                  mutation: ({ index }) => {
                    handleRowDoubleClick(emissions[index])
                    return null
                  },
                },
              ],
            },
          },
        ]}
        innerRadius={85}
        radius={120}
      />
    )
  },
  (prevProps: Readonly<MainPieProps>, nextProps: Readonly<MainPieProps>) => {
    // canGoDeeper, onCategoryClick and dig are function that only use what is passed as parameter to them
    // so they should not change.
    // So we just need to check if the emission have changed.
    return prevProps.emissions.every((emission, index) => {
      const nextEmission = nextProps.emissions[index]
      return (
        emission.category.id === nextEmission?.category.id &&
        emission.totalCO2e === nextEmission?.totalCO2e &&
        emission.period === nextEmission?.period
      )
    })
  },
)
