import React, { useCallback, useMemo } from 'react'
import { CategoryPieCard } from '../../components/CategoryPieCard'
import { TrendChartCard } from '../../components/charts/trend-chart/TrendChartCard'
import { CompareChartCard } from '../../components/CompareChartCard'
import { Filter } from '../../components/filter/Filter'
import { Flex } from '../../components/Flex'
import { Layout } from '../../components/Layout'
import { LayoutContent } from '../../components/LayoutContent'
import { SideColumn } from '../../components/TableOfContent'
import { AppDataContext } from '../../context/AppDataContext'
import { TopListCard } from '../dashboard/TopListCard'
import { CategoryLineChartCard } from '../trends/category-line-charts/CategoryLineCharts'
import { EmissionPerOrgUnitCard } from './emission-per-org-unit/EmissionPerOrgUnit'
import { EmissionSectionId, Section, SectionDef, SectionId } from './Section'
import { EmissionCard } from './emission-graph/EmissionCard'
import { MetaData } from '../dashboard/meta-data/MetaData'
import { useMetaData } from '../dashboard/meta-data/useMetaData'
import { Body1 } from '../../components/Typography'
import { NotReadyView } from '../dashboard/noDataViewComponents/NotReadyView'
import { spacing } from '../../theme'
import { Tour } from '../../components/tour/Tour'
import { EmissionTourContextProvider } from '../../context/EmissionTourContext'
import { EmissionTourPopup } from './EmissionTourPopup'
import { SnackAlert } from '../../components/SnackAlert'

export function EmissionPage(): React.JSX.Element {
  const { organisation } = React.useContext(AppDataContext)

  const [showTourError, setShowTourError] = React.useState(false)
  const [active, setActiveSessionId] = React.useState<{
    scroll: boolean
    sectionId: SectionId
  }>({ scroll: false, sectionId: EmissionSectionId.category })
  const metaData = useMetaData()
  const isLoading = metaData.loading

  const handleSectionVisibilityChange = useCallback(
    (sectionId: SectionId, isVisible: boolean) => {
      if (isVisible) {
        setActiveSessionId({ scroll: false, sectionId })
      }
    },
    [setActiveSessionId],
  )

  const sections: Map<SectionId, SectionDef> = useMemo(
    () =>
      new Map([
        [
          EmissionSectionId.category,
          {
            text: 'Utsläpp fördelat på kategorier',
            sectionId: EmissionSectionId.category,
            section: (
              <Section
                sectionId={EmissionSectionId.category}
                active={active}
                onVisibilityChange={handleSectionVisibilityChange}
                key='category-section'
              >
                <CategoryPieCard />
              </Section>
            ),
          },
        ],
        [
          EmissionSectionId.subDepartments,
          {
            text: 'Utsläpp fördelat på underavdelningar',
            sectionId: EmissionSectionId.subDepartments,
            section: (
              <Section
                sectionId={EmissionSectionId.subDepartments}
                active={active}
                onVisibilityChange={handleSectionVisibilityChange}
                key='subDepartments-section'
              >
                <EmissionPerOrgUnitCard />
              </Section>
            ),
          },
        ],
        [
          EmissionSectionId.emissionsOverTime,
          {
            text: 'Utsläpp fördelat över tid',
            sectionId: EmissionSectionId.emissionsOverTime,
            section: (
              <Section
                sectionId={EmissionSectionId.emissionsOverTime}
                active={active}
                onVisibilityChange={handleSectionVisibilityChange}
                key='emissionsOverTid-section'
              >
                <EmissionCard />
              </Section>
            ),
          },
        ],
        [
          EmissionSectionId.toplist,
          {
            text: 'Topplista över avdelningar på institutionsnivå',
            sectionId: EmissionSectionId.toplist,
            section: (
              <Section
                sectionId={EmissionSectionId.toplist}
                active={active}
                onVisibilityChange={handleSectionVisibilityChange}
                key='toplist-section'
              >
                <TopListCard />
              </Section>
            ),
          },
        ],
        [
          EmissionSectionId.compare,
          {
            text: 'Jämförelser',
            sectionId: EmissionSectionId.compare,
            section: (
              <Section
                sectionId={EmissionSectionId.compare}
                active={active}
                onVisibilityChange={handleSectionVisibilityChange}
                key='compare-section'
              >
                <CompareChartCard />
              </Section>
            ),
          },
        ],
        [
          EmissionSectionId.trend,
          {
            text: 'Trendutveckling',
            sectionId: EmissionSectionId.trend,
            section: (
              <Section
                sectionId={EmissionSectionId.trend}
                active={active}
                onVisibilityChange={handleSectionVisibilityChange}
                key='trend-section'
              >
                <TrendChartCard />
              </Section>
            ),
          },
        ],
        [
          EmissionSectionId.trendPerCategory,
          {
            text: 'Trendutveckling per kategori',
            sectionId: EmissionSectionId.trendPerCategory,
            section: (
              <Section
                sectionId={EmissionSectionId.trendPerCategory}
                active={active}
                onVisibilityChange={handleSectionVisibilityChange}
                key='trendPerCategory-section'
              >
                <CategoryLineChartCard />
              </Section>
            ),
          },
        ],
      ]),
    [active, handleSectionVisibilityChange],
  )

  const tableOfContent = useMemo(() => {
    if (organisation.orgType === 'university') {
      return [
        sections.get(EmissionSectionId.category),
        sections.get(EmissionSectionId.subDepartments),
        sections.get(EmissionSectionId.emissionsOverTime),
        // Hide certain cards when org lacks PAW data.
        ...(organisation.useAnnualWorkforce
          ? [
              sections.get(EmissionSectionId.toplist),
              sections.get(EmissionSectionId.compare),
            ]
          : []),
        sections.get(EmissionSectionId.trend),
        sections.get(EmissionSectionId.trendPerCategory),
      ]
    } else if (organisation.orgType === 'fortnox') {
      return [
        sections.get(EmissionSectionId.category),
        sections.get(EmissionSectionId.emissionsOverTime),
        sections.get(EmissionSectionId.trend),
        sections.get(EmissionSectionId.trendPerCategory),
      ]
    } else {
      return [
        sections.get(EmissionSectionId.category),
        sections.get(EmissionSectionId.subDepartments),
        sections.get(EmissionSectionId.emissionsOverTime),
        sections.get(EmissionSectionId.trend),
        sections.get(EmissionSectionId.trendPerCategory),
      ]
    }
  }, [organisation.orgType, organisation.useAnnualWorkforce, sections])

  return (
    <EmissionTourContextProvider>
      {/* Joyride controller, displays overlay if tour is active. */}
      <Tour />
      {/* Dialog, displayed after a timeout if the tour has not been seen. */}
      <EmissionTourPopup
        onError={() => {
          setShowTourError(true)
        }}
      />
      <SnackAlert
        open={showTourError}
        severity='warning'
        onClose={() => setShowTourError(false)}
      >
        <Flex style={{ gap: spacing.medium }}>
          <Body1>Kunde inte starta rundturen</Body1>
        </Flex>
      </SnackAlert>

      <Layout headerContent={<Filter />} title='Utsläpp' loading={isLoading}>
        <Flex
          row
          stretchWidth
          style={{
            position: 'relative',
            gap: spacing.large,
            minWidth: 'fit-content',
          }}
        >
          <NotReadyView />
          <SideColumn />

          <LayoutContent>
            <MetaData {...metaData} />
            {tableOfContent.map((c) => {
              if (c) {
                return c.section
              } else {
                return undefined
              }
            })}
          </LayoutContent>
          {/* Empty ToC to have even spacing on the left/right side of main content. */}
          <SideColumn />
        </Flex>
      </Layout>
    </EmissionTourContextProvider>
  )
}
