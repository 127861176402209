import _ from 'lodash'
import React, { useContext } from 'react'
import { AppDataContext } from '../../../context/AppDataContext'
import { getNumberFormatter } from '../../../utils/adaptiveNumberFormat'
import { TooltipInfo } from '../../InfoIcon'
import { MaybeLoading } from '../../PageLoader'
import { H4Bold, S1 } from '../../Typography'
import {
  GeneralSmallCardProps,
  noGoalDefinedErrorMessage,
  SmallCardBox,
  SmallCardContent,
  SmallCardFooter,
  SmallCardHeader,
  SmallCardTitle,
} from './SmallBudgetCard'
import { GoalContext, DataPoint } from '../../../context/GoalContext'
import { Loader } from '../../Loader'
import { spacing } from '../../../theme'

function calculateEmissionBudget(
  emissions: DataPoint[],
  goalEmisByYear: DataPoint[],
  goalStartYear: number,
  currentYear: number,
): {
  goalBudget: number
  useByCurrentYear: number
} {
  const goalBudget = _.sumBy(goalEmisByYear, (t) => t.y)
  const useByCurrentYear = _.sumBy(
    _.filter(emissions, (e) => {
      const year = e.x
      return year <= currentYear && goalStartYear <= year
    }),
    (e) => e.y,
  )
  return {
    goalBudget,
    useByCurrentYear,
  }
}

export function UsedBudgetCard({
  inlined,
}: GeneralSmallCardProps): React.JSX.Element {
  const { orgHasInitializedData } = useContext(AppDataContext)
  const { loading, error, data } = useContext(GoalContext)
  // General note: the `MaybeLoading` component takes `hasData`,
  // which means any derived data should be guaranteed to be
  // defined when it is actually rendered --
  // and the various defaults/null-coalsecing in the calculations
  // in this component should not actually end up used during render.
  const hasData = !loading && !error && !!data
  const orgHasGoals = hasData && !!data?.curve.length

  const {
    interpolatedEmissions,
    goalEmissionCurve,
    referenceYear,
    targetYear,
    lastOrgDataYear,
  } = data ?? {}

  const emissions = interpolatedEmissions ?? []
  const goalEmisByYear = goalEmissionCurve ?? []

  const { goalBudget: totalBudget, useByCurrentYear: usedBudget } =
    calculateEmissionBudget(
      emissions,
      goalEmisByYear,
      referenceYear ?? lastOrgDataYear ?? 0,
      _.maxBy(emissions, (e) => e.x)?.x ?? 0,
    )

  const formatter =
    _.isNumber(usedBudget) && _.isNumber(totalBudget)
      ? getNumberFormatter([usedBudget, totalBudget])
      : undefined
  const formattedUsedBudget = formatter?.format(usedBudget)
  const formattedTotalBudget = formatter?.format(totalBudget)
  const percent =
    _.isNumber(usedBudget) && _.isNumber(totalBudget)
      ? Math.round((usedBudget / totalBudget) * 100)
      : undefined

  return (
    <SmallCardBox inlined={inlined}>
      <SmallCardHeader inlined={inlined}>
        {/* `SmallCardTitle` uses MUI typography, the spans inherit it. */}
        <SmallCardTitle>
          <span>Förbrukad budget</span>
          {loading ? (
            <Loader
              borderSize={2}
              size={14}
              style={{ display: 'inline-block', marginLeft: spacing.small }}
            />
          ) : (
            <span>
              {' '}
              {referenceYear}-{targetYear}
            </span>
          )}
        </SmallCardTitle>
        <TooltipInfo info='Anger hur stor andel av den totala budgeten från basår som är förbrukad.' />
      </SmallCardHeader>
      <MaybeLoading
        loading={loading}
        hasData={Boolean(
          hasData &&
            formattedUsedBudget &&
            formattedTotalBudget &&
            _.isNumber(percent) &&
            _.isNumber(targetYear),
        )}
        defaultErrorMessage={
          !orgHasGoals ? noGoalDefinedErrorMessage : undefined
        }
        orgHasInitializedData={orgHasInitializedData}
      >
        <SmallCardContent>
          <H4Bold>{percent}%</H4Bold>
          <S1>
            ({formattedUsedBudget} av {formattedTotalBudget})
          </S1>
        </SmallCardContent>
      </MaybeLoading>
      <SmallCardFooter />
    </SmallCardBox>
  )
}
