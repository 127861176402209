import styled from 'styled-components'
import { H6Bold } from '../../../../components/Typography'

export const Ol = styled.ol`
  counter-reset: item;
  padding-left: 0;
`
export const Li = styled.li`
  display: table;

  &:before {
    content: counters(item, '.') '\\00A0 ';
    display: table-cell;
    counter-increment: item;
  }
`

export const LiSection = styled(Li)`
  &:before {
    font-size: 1.25rem;
    font-weight: bold;
  }
`

export const RubricTitle = styled(H6Bold)`
  padding-top: 1rem;
`
