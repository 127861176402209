import React, { ReactNode, useRef, useState } from 'react'
import styled from 'styled-components'
import Svalna from '../@types'
import { defaultChartTheme } from '../chartTheme'
import { OutsideClickCatcher } from './dropdown/Dropdown'
import { Portal } from './Portal'
import { spacing } from '../theme'

const Root = styled('div')``

const theme = defaultChartTheme

const maxContentWidth = 400

const ToolTipOverlay = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: red;
  padding: ${spacing.medium};

  background-color: white;
  font-family: ${theme.tooltip.style.fontFamily};
  font-weight: ${theme.tooltip.style.fontWeight};
  font-size: ${theme.tooltip.style.fontSize}px;
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  max-width: ${maxContentWidth}px;
`

interface StyleProps {
  opacity: number
}

const ToolTipChildren = styled('div')<StyleProps>`
  opacity: ${(props) => props.opacity};
`

interface Props extends Svalna.PropWithChildren {
  info: ReactNode
  style?: React.CSSProperties
}

export function Tooltip({ children, info, style }: Props): React.JSX.Element {
  const [showTooltip, setShowToolTip] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const boundingRect = ref.current
    ? ref.current.getBoundingClientRect()
    : undefined
  return (
    <Root
      ref={ref}
      onMouseEnter={() => setShowToolTip(true)}
      onMouseLeave={() => setShowToolTip(false)}
      style={style}
    >
      <ToolTipChildren
        opacity={showTooltip ? 0.6 : 1}
        onClick={() => {
          setShowToolTip(true)
        }}
      >
        {children}
      </ToolTipChildren>
      {showTooltip && boundingRect && (
        <OutsideClickCatcher onClickOutside={() => setShowToolTip(false)}>
          <Portal>
            <ToolTipOverlay
              style={{
                transform: `
                  translate(
                    ${Math.min(
                      window.innerWidth - maxContentWidth - 20,
                      boundingRect.x,
                    )}px,
                    ${Math.min(
                      window.innerHeight - 100,
                      boundingRect.y + boundingRect.height,
                    )}px
                  )`,
              }}
            >
              {info}
            </ToolTipOverlay>
          </Portal>
        </OutsideClickCatcher>
      )}
    </Root>
  )
}
