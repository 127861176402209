import styled from 'styled-components'
import { Box } from '../Box'

export const DropdownOverlay = styled(Box).withConfig({
  shouldForwardProp: (prop) => !['offsetX'].includes(prop),
})<{
  offsetX?: number
  top?: number
  left?: number
  offsetY?: number
  transform?: string
}>`
  position: absolute;
  z-index: 100;
  overflow-y: auto;
  max-height: calc(100vh - 100px);
  margin-top: ${(props) => props.offsetY ?? 10}px;
  margin-left: ${(props) => props.offsetX ?? 0}px !important;
  border: 1px solid #bbbbbb;
  top: ${(props) => (props.top ? `${props.top}px` : undefined)};
  left: ${(props) => (props.top ? `${props.left}px` : undefined)};
  transform: ${(props) => (props.transform ? `${props.transform}` : undefined)};
`
