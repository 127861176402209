import React, { useContext, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import Svalna from '../@types'
import { OrgUnit, Period } from '../graphql/generated'
import { parseYear } from '../utils/date'
import {
  createYearRange,
  getCurrentYearRange,
  IFilterDatePeriod,
} from '../utils/datePeriod'
import { AppDataContext } from './AppDataContext'

export type FilterContextType = {
  orgUnit?: OrgUnit
  setOrgUnit: (org?: OrgUnit) => void
  yearPeriods: IFilterDatePeriod[]
  defaultDatePeriod: IFilterDatePeriod
}

const initialValues: FilterContextType = {
  setOrgUnit: () => {},
  yearPeriods: [],
  defaultDatePeriod: {
    name: getCurrentYearRange()[getCurrentYearRange().length - 1].name,
    end: getCurrentYearRange()[getCurrentYearRange().length - 1].end,
    period: Period.Year,
  },
}

export const FilterContext =
  React.createContext<FilterContextType>(initialValues)

const { Provider } = FilterContext

type partialYearInfo = {
  partialYear: string
  partialEndMonthName: string
  janName: string | undefined
  end: Date
}

const getPartialYearInfo = (
  dataUntil: string | undefined,
): partialYearInfo | undefined => {
  if (!dataUntil) {
    return undefined
  }
  const end = dayjs.utc(dataUntil)

  const partialYear = end.year()
  const partialEndMonth = end.month() // NB: 0-indexed
  const partialEndMonthName = end.format('MMM')
  const janName =
    partialEndMonth !== 0 ? dayjs.utc('2000-01-12').format('MMM') : undefined

  return {
    partialYear: partialYear.toFixed(0),
    partialEndMonthName,
    janName,
    end: end.toDate(),
  }
}

const createYearPeriods = (
  yearRange: IFilterDatePeriod[],
  partialYearInfo: partialYearInfo | undefined,
) => {
  return [
    // If there is a partial year, put it at the start of the year periods
    ...(partialYearInfo
      ? [
          {
            name: partialYearInfo.partialYear,
            partialPeriodText: `${
              partialYearInfo.janName ? `${partialYearInfo.janName} - ` : ''
            }${partialYearInfo.partialEndMonthName}`,
            // start and end are known to be defined.
            end: partialYearInfo.end!.toISOString(),
            period: Period.Month,
            partialYear: true,
          },
        ]
      : []),
    // years in revers order
    ...yearRange,
  ]
}

export function FilterProvider({
  children,
}: Svalna.PropWithChildren): React.JSX.Element {
  const { emissionRange, organisation } = useContext(AppDataContext)
  const [orgUnit, setOrgUnit] = useState<OrgUnit | undefined>()

  const [yearPeriods, defaultDatePeriod] = useMemo(() => {
    let yearRange = getCurrentYearRange()

    if (emissionRange?.from) {
      yearRange = createYearRange(
        parseYear(emissionRange.to),
        parseYear(emissionRange.from),
      )
    }
    const partialYearInfo = getPartialYearInfo(
      organisation.dataUntil as string | undefined,
    )
    getPartialYearInfo(organisation.dataUntil as string | undefined)
    const yp = createYearPeriods(yearRange, partialYearInfo)

    return [yp, yp[partialYearInfo ? 1 : 0]]
  }, [emissionRange.from, emissionRange.to, organisation.dataUntil])

  return (
    <Provider
      value={{
        orgUnit,
        setOrgUnit,
        yearPeriods,
        defaultDatePeriod,
      }}
    >
      {children}
    </Provider>
  )
}
