import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'

type PendingSelection<T> = {
  pending: T | undefined
  addPending: (elem?: T | undefined) => void
  isDoubleClick: (el?: T | undefined) => boolean | 0 | undefined
}

export const usePendingSelection = <T>({
  fallback,
  isOpen,
  selected,
}: {
  isOpen: boolean
  fallback?: T
  selected?: T
}): PendingSelection<T> => {
  const [{ pending, lastClicked }, setPending] = useState<{
    pending?: T
    lastClicked?: number
  }>({ pending: undefined })

  const addPending = useCallback(
    (elem?: T) => {
      setPending({
        pending: elem,
        lastClicked: dayjs.utc().valueOf(),
      })
    },
    [setPending],
  )

  useEffect(() => {
    setPending({ pending: selected ?? fallback })
  }, [selected, fallback, addPending, isOpen])

  return {
    pending,
    addPending,
    isDoubleClick: (el?: T) => {
      if (el !== pending) {
        return false
      }
      const now = dayjs.utc().valueOf()
      const latency = 300
      return lastClicked && lastClicked + latency > now
    },
  }
}
