import React, { useCallback, useContext, useEffect, useState } from 'react'
import Svalna from '../@types'
import {
  CompareOrgDocument,
  EmissionMetaDataDocument,
  EmissionsDocument,
  Job,
  GetEmissionRangeDocument,
  GetTopListDocument,
  GetTrendDocument,
  useDeleteErrorJobMutation,
  useGetJobLazyQuery,
  Maybe,
  JobError,
  OrgType,
  SearchTransactionsDocument,
  GetRecatRulesDocument,
  HasTransactionsDocument,
  GetAggregatedDescriptiveStatsDocument,
  JobType,
} from '../graphql/generated'
import { handleJobInt, onSuccessCallback } from '../utils/JobUtils'
import { client } from '../apollo/apolloClient'
import { AppDataContext } from './AppDataContext'
import { SnackAlertProps } from '../components/SnackAlert'

export type JobContextType = {
  jobRunning: boolean
  setJobRunning: (running: boolean) => void
  handleJob: (
    // job to handle
    job: Job,
    // function to run on success, if not set run the default function.
    onSuccessFunc?: onSuccessCallback,
    // do not save the onSuccessFunc for the next handlejob call
    // this should only be set to true by the timer job, which want to reuse the
    // set on success function.
    skipSetOnSuccess?: boolean,
  ) => void
  currentState: string | undefined
  jobSnack: SnackAlertProps
  setFortnoxErrorHandler: React.Dispatch<
    React.SetStateAction<
      ((error: Maybe<JobError> | undefined) => boolean) | undefined
    >
  >
  fortnoxErrorHandler:
    | ((error: Maybe<JobError> | undefined) => boolean)
    | undefined
  setOnSuccess: (onSuccess: onSuccessCallback | undefined) => void
  onSuccessDefault: onSuccessCallback
}

const initialValues: JobContextType = {
  jobRunning: false,
  setJobRunning: () => {},
  handleJob: () => {},
  currentState: undefined,
  jobSnack: {},
  setFortnoxErrorHandler: () => {},
  fortnoxErrorHandler: undefined,
  setOnSuccess: () => {},
  onSuccessDefault: () => Promise.resolve(),
}

export const JobContext = React.createContext<JobContextType>(initialValues)

const { Provider } = JobContext

export function JobProvider({
  children,
}: Svalna.PropWithChildren): React.JSX.Element {
  const { organisation } = useContext(AppDataContext)

  const [jobRunning, setJobRunning] = useState(false)
  const [getJob] = useGetJobLazyQuery({
    fetchPolicy: 'no-cache', //we need to skip the cache to check if values have been updated on the server side only
  })
  const [intervalLength, setIntervalLength] = useState(1000)
  const [jobSnack, setJobSnack] = useState<SnackAlertProps>({})
  const [currentState, setCurrentState] = useState<string | undefined>(
    undefined,
  )
  const [deleteErrorJob] = useDeleteErrorJobMutation()
  const [fortnoxErrorHandler, setFortnoxErrorHandler] = useState<
    ((error: Maybe<JobError> | undefined) => boolean) | undefined
  >(undefined)
  const [onSuccess, setOnSuccess] = useState<onSuccessCallback | undefined>(
    undefined,
  )

  const ackJobError = useCallback(async () => {
    await deleteErrorJob()
    setJobRunning(false)
    setCurrentState(undefined)
    setJobSnack({})
  }, [deleteErrorJob])

  const onSuccessDefault = useCallback(async (job: Job) => {
    //get the data again to display the new values
    await client.refetchQueries({
      include: [
        GetEmissionRangeDocument,
        EmissionsDocument,
        GetTrendDocument,
        EmissionMetaDataDocument,
        CompareOrgDocument,
        GetTopListDocument,
        GetAggregatedDescriptiveStatsDocument,
        SearchTransactionsDocument,
        GetRecatRulesDocument,
        HasTransactionsDocument,
      ],
    })
    setJobRunning(false)
    setCurrentState(undefined)
    if ([JobType.RuleApply, JobType.RuleDeleteAndApply].includes(job?.type)) {
      setJobSnack({
        children: 'Utsläppsberäkningarna har uppdaterats med dina ändringar.',
        severity: 'success',
        onClose: () => setJobSnack({}),
        open: true,
      })
    }
  }, [])

  const setJobError = useCallback(
    (error: JobError) => {
      setJobSnack({
        children: error.message,
        severity: 'error',
        onClose: ackJobError,
        open: true,
      })
    },
    [ackJobError],
  )

  const errorHandler = useCallback(
    (error: Maybe<JobError> | undefined): boolean => {
      if (!error) {
        return false
      }
      let errorHandled = false
      if (
        organisation.orgType === OrgType.Fortnox &&
        fortnoxErrorHandler !== undefined
      ) {
        errorHandled = fortnoxErrorHandler(error)
      }
      if (!errorHandled) {
        setJobError(error)
      }
      return true
    },
    [fortnoxErrorHandler, organisation.orgType, setJobError],
  )

  const handleJob = useCallback(
    async (
      // job to handle
      job: Job,
      // function to run on success, if not set run the default function
      onSuccessFunc?: onSuccessCallback,
      // do not save the onSuccessFunc for the next handlejob call
      // this should only be set to true by the timer job, which want to reuse the
      // set on success function.
      skipSetOnSuccess?: boolean,
    ): Promise<void> => {
      if (!skipSetOnSuccess) {
        // set the on success function so that the timer does the next handle call with the same function
        setOnSuccess(() => onSuccessFunc)
      }
      const running = await handleJobInt(
        job,
        errorHandler,
        setCurrentState,
        onSuccessFunc ?? onSuccessDefault,
      )
      setJobRunning(running)
      if (!running) {
        //the job is not running, pull at a low frequence in case
        //another user start a job for this organisation
        setIntervalLength(10000)
      } else if (running) {
        //the job is running, pull frequently to get a up to date status for the job
        setIntervalLength(1000)
        setJobSnack({}) // reset the job snack as we have a new job running
      }
    },
    [errorHandler, onSuccessDefault],
  )

  useEffect(() => {
    const intId = setInterval(async () => {
      const job = await getJob()
      if (job.data?.getJob) {
        // handle the jobe with the set onSuccess function but do not reset it
        handleJob(job.data.getJob, onSuccess, true)
      }
    }, intervalLength)
    return () => {
      clearInterval(intId)
    }
  }, [getJob, handleJob, intervalLength, onSuccess, organisation.orgType])
  return (
    <Provider
      value={{
        jobRunning,
        setJobRunning,
        handleJob,
        currentState,
        jobSnack,
        setFortnoxErrorHandler,
        fortnoxErrorHandler,
        setOnSuccess,
        onSuccessDefault,
      }}
    >
      {children}
    </Provider>
  )
}
