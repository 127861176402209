import _ from 'lodash'
import { NumberFormatter } from '../../../utils/adaptiveNumberFormat'
import { ChartNameValue } from './chartConstants'

// This file contains utilities for passing chart information in label strings.
// The implementation details of these strings should not be directly used outside
// this file; only the functions defined here should access the formatted information.

// Implementation note:
// The formatting is added by prefixing the label string with an "information block"
// delimited by double curly brackets: {{ ... }}.
// This would break if the labels actually contained these characters,
// which is not currently the case, and probably never will be.

/**
 * Make a CO2e label string with extra formatting information for {@link ChartCursorLabel}.
 *
 * Note: `childName` is possibly only in `datum` when `VictoryVoronoiContainer` is used,
 *       and there is no documentation on what values it contains.
 *
 * @param formatter A number formatter for chart CO2e values.
 * @param datum A datum object, which is expected to contain
 *              y (the chart value CO2e),
 *              and childName -- this should be equal to the `name` prop for the chart the datum refers to,
 *              which is expected to be a name in {@link ChartNameValue}.
 * @returns A CO2e label with extra information, compatible with {@link ChartCursorLabel}.
 */
export function valueLabelWithChartName(
  formatter: NumberFormatter,
  { y, childName }: { y: number; childName: string },
): string {
  // Add a "{{chartName}}" prefix in front of the emission value:
  return `{{${_.escapeRegExp(childName)}}}${formatter.format(y)}`
}

/**
 * Extract the plain CO2e string from a string formatted with {@link valueLabelWithChartName}.
 *
 * @param s A string generated by {@link valueLabelWithChartName}
 * @returns A CO2e label string.
 */
export function labelWithoutChartName(s: string): string {
  // Remove the "{{chartName}}" prefix from the emission value:
  return s.replace(/^\{\{[^}]+\}\}/, '').trim()
}

/**
 * Check if the given label is for the given chart (by chart name).
 *
 * @param label A string generated by {@link valueLabelWithChartName}
 * @param chart The chart name to match {@link ChartNameValue}
 * @returns True if the label is for the chart.
 */
export function labelIsForChart(
  label: string,
  chartName: ChartNameValue,
): boolean {
  // Check "{{chartName}}" prefix for match:
  return !!label.match(`^\\{\\{${_.escapeRegExp(chartName)}\\}\\}`)
}

/**
 * Extract the chart name from a string formatted with {@link valueLabelWithChartName}.
 * The name is expected to be a value in {@link ChartNameValue}.
 *
 * @param s A string generated by {@link valueLabelWithChartName}
 * @returns The chart name for this label, or null.
 */
export function getChartNameFromLabel(s: string): string | null {
  const iconMatch = s.match(/^\{\{[^}]+\}\}/)
  if (!iconMatch) {
    return null
  }
  // get name from inside the two {{ ... }}
  return iconMatch[0].substring(2, iconMatch[0].length - 2)
}
