import { createTheme } from '@mui/material'
import type {} from '@mui/x-data-grid/themeAugmentation'

export const colors = {
  pink: '#EFA8B8',
  blue: '#789EFF',
  orange: '#FD8C4B',
  teal: '#58A3B0',
  brightBlue: '#55A9FF',
  deepGreen: '#00A96A',
  deepGreen08: 'rgb(0, 169, 106, 0.08)', // 8%
  deepGreen50: '#73C7A8', // 50%
  red: '#EA5A52',
  goldenYellow: '#FFD12B',
  darkGray: '#5B5B5B',
  lightGray: '#F2F2F2',
  gray: '#B0B0B0',
  lightGranit: '#515161',
  granit: '#40404C',
  black: '#000000',
  white: '#FFFFFF',
  silver: '#C0C0C0',
  bronze: '#CD7F32',
  textDisabled: '#A2ABBF',
  svalnaGray2: '#A2ABBF',
  svalnaGray1: '#E3E8EE',
  goalBlue: '#157FE9',
  backgroundWhite: '#F3F3F3', // background for <html> in index.scss
}

export interface Theme {
  colors: typeof colors
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true
  }
}

let t = createTheme({
  palette: {
    primary: {
      main: '#00A96A',
    },
    secondary: {
      main: '#40404c',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 30,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeaderTitle: {
          fontWeight: 'bold !important',
        },
        columnHeaders: {
          borderBottom: '2px solid #000000',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          backgroundColor: colors.deepGreen,
          color: colors.white,
        },
        filledError: {
          backgroundColor: colors.red,
          color: colors.white,
        },
        filledWarning: {
          backgroundColor: colors.orange,
          color: colors.white,
        },
        filledInfo: {
          backgroundColor: colors.goalBlue,
          color: colors.white,
        },
      },
    },
  },
})

t = createTheme(t, {
  palette: {
    white: t.palette.augmentColor({
      color: {
        main: colors.white,
      },
      name: 'white',
    }),
  },
})

export const theme = t

// trying to normalize the spacing based on the measurments indicated in
// https://www.figma.com/design/4Vo95hfTMNlpb9d2elSNLB/Omkategorisering?node-id=104-7210&node-type=canvas&m=dev

export const smallGap = 8
export const mediumGap = 16
export const xlGap = 32

export const spacing = {
  small: `${smallGap}px`,
  medium: `${mediumGap}px`,
  large: `24px`,
  xl: `${xlGap}px`,
  xxl: `40px`,
  xxxl: `48px`,
}

const headerHeightRem = 5
const headerPaddingRem = 2

/**
 * Width of the main navigation bar on the left side.
 */
export const navbarWidth = '10rem'

/**
 * Width of the main content. Covers the viewport.
 * Accounts for the width of the main nav bar.
 */
export const contentWidth = `calc(100% - ${navbarWidth})`

/**
 * Height of the main header.
 */
export const headerHeight = `${headerHeightRem}rem`

/**
 * Height of the header, plus padding before start of content under it.
 */
export const headerHeightPlusPad = `${headerHeightRem + headerPaddingRem}rem`

/** Padding for "air" around main top header */
export const headerPadding = `0 ${headerPaddingRem}rem`

/** Padding for "air" around the main content container. */
export const contentPadding = `${headerPaddingRem}rem`
