import _ from 'lodash'
import dayjs from 'dayjs'
import { Period } from '../graphql/generated'

export interface IFilterDatePeriod {
  name: string
  end: string | undefined
  period: Period
  partialYear?: boolean
  partialPeriodText?: string
}

const createYear = (year: number) => {
  const startDate = dayjs.utc(`${year}`)
  return {
    name: `${year}`,
    start: startDate.toISOString(),
    end: startDate.endOf('year').toISOString(),
    period: Period.Month,
  }
}

// create a range of years going from the fromYear to toYear
export const createYearRange = (
  fromYear: number,
  toYear: number,
): IFilterDatePeriod[] => {
  const step = fromYear < toYear ? 1 : -1
  return _.range(fromYear, toYear + step, step).map(createYear)
}

export const getCurrentYearRange = (): IFilterDatePeriod[] => {
  const year = dayjs.utc().year()
  return createYearRange(year, year)
}
