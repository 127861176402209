import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import Svalna from '../@types'

const Element = styled.div`
  display: inline;
  /*pointer-events: none;*/
  width: 100%;
`

interface Props extends Svalna.PropWithChildren {
  scroll?: boolean
  behavior?: 'smooth' | 'auto'
  block?: 'center' | 'end' | 'nearest' | 'start'
}

export function ScrollToElement({
  scroll,
  block = 'nearest',
  behavior = 'smooth',
  children,
}: Props): React.JSX.Element {
  const ref = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    if (ref?.current && scroll) {
      ref.current.scrollIntoView({
        behavior,
        inline: 'center',
        block,
      })
    }
  }, [scroll, ref, behavior, block])

  return <Element ref={ref}>{children}</Element>
}
