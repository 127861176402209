import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import { Button } from '@mui/material'
import checkmarkSvg from '../../../assets/icons/checkmark-bold.svg'
import ChevronDown from '../../../assets/icons/chevron-down.svg'
import ChevronUp from '../../../assets/icons/chevron-up.svg'
import { OrgUnit } from '../../../graphql/generated'
import { Icon } from '../../Icon'
import { Caption, CaptionBold } from '../../Typography'
import { colors, smallGap, spacing } from '../../../theme'

const MAX_NAME_WIDTH = 485

const levelMargin = (level: number) => (level + 1) * smallGap

export const Wrapper = styled(Button)`
  width: 100%;
  min-height: 39px;
  padding-left: ${spacing.small};
  display: flex;
  align-items: center;
  text-transform: none;
`

const CheckmarkIcon = styled(Icon)`
  margin-left: ${spacing.small};
`

const NameContainer = styled.div<{
  level: number
}>`
  padding-left: ${(props) => levelMargin(props.level)}px;
  width: 100%;
  display: flex;
  align-items: center;
  width: ${(props) => MAX_NAME_WIDTH - levelMargin(props.level)}px;
  white-space: nowrap;
`

interface NameProps {
  isActive: boolean
  level: number
}
const NameStyle = (level: number) => `
  max-width: ${MAX_NAME_WIDTH - levelMargin(level) - 30}px;
  text-overflow: ellipsis;
  overflow: hidden;
`
const SelectedName = styled(CaptionBold).withConfig({
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<NameProps>`
  ${(props) => NameStyle(props.level)}
`
const StyledName = styled(Caption).withConfig({
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<NameProps>`
  ${(props) => NameStyle(props.level)}
  color: ${(props) => (props.isActive ? colors.black : colors.darkGray)};
`

export interface Props {
  item: OrgUnit
  isSelected: boolean
  scrollToElem: boolean
  renderChildren: () => React.JSX.Element | null
  onClick: () => void
  isOpen: boolean
  showDropdown: boolean
  isActive: boolean
}

export function FilterOrgListItem({
  item,
  onClick,
  isSelected,
  renderChildren,
  isOpen,
  scrollToElem,
  showDropdown,
  isActive,
}: Props): React.JSX.Element {
  const myRef = useRef<HTMLDivElement>(null)
  const Name = isSelected ? SelectedName : StyledName

  useLayoutEffect(() => {
    if (
      scrollToElem &&
      myRef.current !== null &&
      myRef.current?.scrollIntoView !== undefined
    ) {
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [scrollToElem, myRef])

  return (
    <div ref={myRef}>
      <Wrapper onClick={onClick}>
        <NameContainer level={item.level}>
          <Name level={item.level} isActive={isActive}>
            {item.name}
          </Name>
          <CheckmarkIcon
            style={{
              opacity: isSelected ? 1 : 0,
            }}
            src={checkmarkSvg}
            width={10}
            height={8}
          />
        </NameContainer>

        <div style={{ minWidth: 35 }}>
          {showDropdown && (
            <Icon
              src={isOpen ? ChevronUp : ChevronDown}
              width={12}
              height={12}
            />
          )}
        </div>
      </Wrapper>

      {renderChildren()}
    </div>
  )
}
