import { useFormik } from 'formik'
import React, { useState } from 'react'
import { TextField } from '@mui/material'
import alertCircleOutlineSvg from '../../assets/icons/alert-circle-outline.svg'
import { AlertIcon, AlertSuccess, AlertWarning } from '../../components/alert'
import { CaptionBold } from '../../components/Typography'
import { useForgotPasswordMutation } from '../../graphql/generated'
import { AuthBox } from './components/AuthBox'
import { AuthContainer } from './components/AuthContainer'
import { AuthWrapper } from './components/AuthenticationWrapper'
import { AuthHeader } from './components/AuthHeader'
import { AuthNavButton } from './components/AuthNavButton'
import { Form } from './components/Form'
import { ButtonGreen } from '../../components/Buttons'

export function ForgotPassword(): React.JSX.Element {
  const [notRegisteredError, setNotRegisteredError] = useState(false)
  const [complete, setComplete] = useState(false)
  const [forgotPassword] = useForgotPasswordMutation()

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      const response = await forgotPassword({ variables: values })
      if (response.data?.forgotPassword === false) {
        setNotRegisteredError(true)
      } else {
        setComplete(true)
      }
    },
  })

  return (
    <AuthWrapper>
      <AuthBox>
        {complete ? (
          <AlertSuccess>
            <CaptionBold>
              Om ett konto med den mailen existerar så har vi skickat ett mail.
            </CaptionBold>
          </AlertSuccess>
        ) : (
          notRegisteredError && (
            <AlertWarning>
              <AlertIcon src={alertCircleOutlineSvg} />
              <CaptionBold>
                E-postadress är inte registrerad. Vänligen kontrollera stavning
                eller kontakta ansvarig på din organisation.
              </CaptionBold>
            </AlertWarning>
          )
        )}

        <AuthContainer>
          <AuthHeader />
          {/* TODO: Support changing password */}
          {/* <Flex column>
            <AuthTitle>Glömt lösenord?</AuthTitle>
            <Caption>
              Ange din e-postadress för att få instruktioner om hur du
              återställer ditt lösenord.
            </Caption>
          </Flex> */}

          <Form onSubmit={formik.handleSubmit}>
            <TextField
              type='email'
              name='email'
              label='Email'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              variant='standard'
              error={!!formik.touched.email && !!formik.errors.email}
              helperText={formik.touched.email && formik.errors.email}
              required
            />
            <ButtonGreen type='submit'>Återställ lösenord</ButtonGreen>

            <AuthNavButton route='/login'>Logga in</AuthNavButton>
          </Form>
        </AuthContainer>
      </AuthBox>
    </AuthWrapper>
  )
}
