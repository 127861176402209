import React, { useContext, useEffect, useMemo } from 'react'
import dayjs from 'dayjs'
import Svalna from '../@types'
import { PageLoader } from '../components/PageLoader'
import {
  Category,
  EmissionRange,
  OrganisationFragment,
  OrgType,
  OrgUnit,
  useGetCategoriesLazyQuery,
  useGetEmissionRangeLazyQuery,
  useMeLazyQuery,
  UserTour,
} from '../graphql/generated'
import { toCategoryMap } from '../utils/category'
import { toOrgUnitMap } from '../utils/org'
import { UserContext } from './UserContext'

interface IAppData {
  categoryMap: Record<string, Category | undefined>
  categories: Category[]
  organisation: OrganisationFragment
  orgUnits: OrgUnit[]
  orgUnitMap: Record<string, OrgUnit | undefined>
  initError?: string
  hasInitializedData?: boolean
  emissionRange: EmissionRange
  orgHasInitializedData: boolean
  userTours: UserTour[]
}

const initialState: IAppData = {
  categoryMap: {},
  categories: [],
  orgUnits: [],
  orgUnitMap: {},
  organisation: {
    id: '',
    name: '',
    orgUnits: [],
    orgType: OrgType.University,
    useAnnualWorkforce: false,
    topListAggLevel: 0,
    dataUntil: undefined,
    industrialSectorName: undefined,
    restrictedAccess: false,
  },
  hasInitializedData: false,
  emissionRange: {
    from: dayjs.utc().toDate(),
    to: dayjs.utc().toDate(),
  },
  orgHasInitializedData: false,
  userTours: [],
}

export const AppDataContext = React.createContext<IAppData>(initialState)

const { Provider } = AppDataContext

/**
 * Fetches and stores static data for logged in user
 * displays loading if no data is present
 */
export function AppDataProvider({
  children,
}: Svalna.PropWithChildren): React.JSX.Element {
  const { isLoggedIn } = useContext(UserContext)

  const [loadCategories, categoryResult] = useGetCategoriesLazyQuery()
  const [loadMe, meResult] = useMeLazyQuery()
  const [loadEmissionRange, emissionRangeResult] =
    useGetEmissionRangeLazyQuery()

  const me = meResult.data?.me
  const orgUnits =
    (me?.organisation?.orgUnits as OrgUnit[]) ?? initialState.orgUnits
  const categories =
    (categoryResult.data?.getCategories as Category[]) ??
    initialState.categories

  const emissionRange = emissionRangeResult.data
    ?.getEmissionRange as EmissionRange

  const categoryMap = useMemo(() => toCategoryMap(categories), [categories])
  const orgUnitMap = useMemo(() => toOrgUnitMap(orgUnits), [orgUnits])

  useEffect(() => {
    if (isLoggedIn) {
      loadMe()
      loadCategories()
      loadEmissionRange()
    }
  }, [isLoggedIn, loadCategories, loadEmissionRange, loadMe])

  const hasInitializedData = categoryResult.data !== undefined

  const isLoading =
    categoryResult.loading || meResult?.loading || emissionRangeResult?.loading

  const initError =
    categoryResult.error ?? meResult.error ?? emissionRangeResult.error

  //if the organization has some data in the database from will be set
  const orgHasInitializedData = !!emissionRange?.from

  let body = null
  let ermes = ''
  if (initError) {
    ermes = initError.message
    throw initError
  } else if (!isLoading && hasInitializedData) {
    body = children
  } else if (isLoading) {
    body = <PageLoader delayMs={250} />
  }

  return (
    <Provider
      value={{
        organisation: me?.organisation
          ? me?.organisation
          : initialState.organisation,
        categories,
        orgUnits,
        categoryMap,
        orgUnitMap,
        hasInitializedData,
        initError: ermes,
        emissionRange,
        orgHasInitializedData,
        userTours: me?.userTours ?? [],
      }}
    >
      {body}
    </Provider>
  )
}
