import styled from 'styled-components'
import { Flex } from './Flex'
import { headerHeightPlusPad } from '../theme'

export const sideColumnMinWidthRem = 11
export const SideColumnFlex = 1

export const SideColumn = styled(Flex)`
  flex-direction: column;
  position: sticky;
  top: ${headerHeightPlusPad};
  min-width: ${sideColumnMinWidthRem}rem;
  flex: ${SideColumnFlex};
`
