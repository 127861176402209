import React, { useContext } from 'react'
import styled from 'styled-components'
import { Flex } from '../../../Flex'
import { RecatContext } from '../../../../context/RecatContext'
import { formatISO } from '../../../../utils/date'
import { Body2, Body2Bold } from '../../../Typography'
import { spacing } from '../../../../theme'

const ScrollBox = styled(Flex)`
  column-gap: ${spacing.medium};
  flex-wrap: wrap;
  max-height: 200px;
  overflow: auto;
`

export function TransactionsInfoBox(): React.JSX.Element {
  const {
    affectedAccounts,
    affectedSuppliers,
    numTransactions,
    firstTransactionDate,
    lastTransactionDate,
    totalKr,
    transactionsCategories,
  } = useContext(RecatContext)

  return (
    <table
      style={{
        minWidth: '420px',
        borderSpacing: '1rem 0.5rem',
        borderLeft: '1px solid #b0b0b0',
      }}
    >
      <colgroup>
        <col width='200px' />
        <col />
      </colgroup>

      <tbody>
        <tr>
          <td>
            <Body2Bold>Antal transaktioner</Body2Bold>
          </td>
          <td>
            <Body2>{numTransactions}</Body2>
          </td>
        </tr>
        <tr>
          <td>
            <Body2Bold>totalt kr</Body2Bold>
          </td>
          <td>
            <Body2>{totalKr?.toFixed(0)}</Body2>
          </td>
        </tr>
        <tr>
          <td>
            <Body2Bold>Första transaktionsdatum</Body2Bold>
          </td>
          <td>
            <Body2>
              {firstTransactionDate ? formatISO(firstTransactionDate) : ''}
            </Body2>
          </td>
        </tr>
        <tr>
          <td>
            <Body2Bold>Sista transaktionsdatum</Body2Bold>
          </td>
          <td>
            <Body2>
              {lastTransactionDate ? formatISO(lastTransactionDate) : ''}
            </Body2>
          </td>
        </tr>
        {affectedSuppliers.length >= 1 && (
          <tr>
            <td>
              <Body2Bold>Leverantörs-ID</Body2Bold>
            </td>

            <td>
              <ScrollBox>
                {affectedSuppliers.map((supplier) => (
                  <div key={`supplier-info-${supplier.id ?? supplier.name}`}>
                    <Body2>
                      {supplier.id ?? <span>okänd</span>}
                      <span>&nbsp;(</span>
                      {supplier.name}
                      <span>)</span>
                    </Body2>
                  </div>
                ))}
              </ScrollBox>
            </td>
          </tr>
        )}
        {affectedAccounts.length >= 1 && (
          <tr>
            <td>
              <Body2Bold>Konto-ID</Body2Bold>
            </td>

            <td>
              <ScrollBox>
                {affectedAccounts.map((accountInfo) => (
                  <div key={`account-info-${accountInfo.id}`}>
                    <Body2>
                      {accountInfo.id} ({accountInfo.name})
                    </Body2>
                  </div>
                ))}
              </ScrollBox>
            </td>
          </tr>
        )}
        <tr>
          <td>
            <Body2Bold>Kategorier</Body2Bold>
          </td>

          <td>
            <ScrollBox>
              {transactionsCategories.map((category) => (
                <div key={category.id} style={{ whiteSpace: 'nowrap' }}>
                  <Body2>{category.name}.</Body2>
                </div>
              ))}
            </ScrollBox>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
