import React from 'react'
import styled from 'styled-components'
import { InputAdornment, TextField } from '@mui/material'
import magnitudeSvg from '../assets/icons/magnitude.svg'
import { Icon } from './Icon'
import { colors } from '../theme'

const SearchField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    height: '33px',
    fontSize: '14px',
    background: colors.white,
    width: '220px',
  },
})

interface Props {
  onChange: (search: string) => void
  placeholder: string
  search: string
}

export function Search({
  onChange,
  placeholder,
  search,
  ...props
}: Props): React.JSX.Element {
  return (
    <SearchField
      placeholder={placeholder}
      value={search}
      type='text'
      onChange={(ev) => {
        onChange(ev.target.value as string)
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Icon src={magnitudeSvg} width={16} height={16} />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
}
