import { Alert, AlertProps, Snackbar, SnackbarProps } from '@mui/material'
import React from 'react'

export interface SnackAlertProps
  extends Pick<AlertProps, 'children' | 'severity' | 'action'>,
    Pick<SnackbarProps, 'open'> {
  onClose?: () => void
}

export function SnackAlert({
  open,
  severity,
  children,
  action,
  onClose,
}: SnackAlertProps): React.JSX.Element {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      data-testid='snackbar'
      onClose={onClose} // close the snackbar on clickaway
      TransitionProps={{
        // do not animate on exit. This is because in most place we remove the content of the alert in the onClose.
        // This result in the snackbar becoming green and empty during the fade away.
        // If we want to put back the anymation we need to change all the onClose to set the open to false without deleting the conten of the alrert
        exit: false,
      }}
    >
      <Alert
        severity={severity}
        variant='filled'
        action={action}
        onClose={onClose} // put a close cross on the snackbar
      >
        {children}
      </Alert>
    </Snackbar>
  )
}
