import { AlertProps } from '@mui/material'
import { GridRowModes, GridRowModesModel } from '@mui/x-data-grid'
import { GridApiCommunity } from '@mui/x-data-grid/internals'
import React, { useCallback, useEffect, useState } from 'react'

interface Row {
  id: string | number
  isNew?: boolean
}

export interface Props<T extends Row> {
  rowModesModel: GridRowModesModel
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>
  setSaveAndContinue: React.Dispatch<React.SetStateAction<() => Promise<void>>>
  setSaveAndClose: React.Dispatch<React.SetStateAction<() => Promise<void>>>
  forward: () => void
  close: () => void
  setReset: React.Dispatch<React.SetStateAction<() => Promise<void>>>
  setInitialRows: () => void
  rows: T[]
  save: (rows: T[]) => Promise<boolean>
  validateRow: (row: T) => string | undefined
  setSnackbar: React.Dispatch<
    React.SetStateAction<Pick<AlertProps, 'children' | 'severity'> | null>
  >
  setRows: React.Dispatch<React.SetStateAction<T[]>>
  apiRef: React.MutableRefObject<GridApiCommunity>
}

export function useTableCardHelper<T extends Row>({
  rowModesModel,
  setRowModesModel,
  setSaveAndContinue,
  setSaveAndClose,
  forward,
  close,
  setReset,
  setInitialRows,
  rows,
  save,
  validateRow,
  setSnackbar,
  setRows,
  apiRef,
}: Props<T>): {
  processRowUpdate: (newRow: T) => T
  selectedRows: (string | number)[]
  setSelectedRows: React.Dispatch<React.SetStateAction<(string | number)[]>>
  handleDeleteClick: () => void
  handleProcessRowUpdateError: (error: Error) => void
} {
  useEffect(() => {
    setInitialRows()
  }, [setInitialRows])

  const [selectedRows, setSelectedRows] = useState<(number | string)[]>([])

  const handleDeleteClick = () => {
    const newRowModesModel: React.SetStateAction<GridRowModesModel> = {}
    Object.entries(rowModesModel).forEach(([id, prop]) => {
      if (!selectedRows.find((sr) => `${sr}` === id)) {
        newRowModesModel[id] = prop
      }
    })
    setRowModesModel(newRowModesModel)
    setRows(rows.filter((row) => !selectedRows.includes(row.id)))
  }

  const handleProcessRowUpdateError = useCallback(
    (error: Error) => {
      setSnackbar({ children: error.message, severity: 'error' })
    },
    [setSnackbar],
  )

  const processRowUpdate = (newRow: T) => {
    const updatedRow = { ...newRow, isNew: undefined }
    const errorMessage = validateRow(updatedRow)
    if (errorMessage) {
      throw new Error(errorMessage)
    }
    const newRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row))
    setRows(newRows)

    return updatedRow
  }

  const checkForEditRows = useCallback((): T[] => {
    const editRowValues: T[] = []
    Object.entries(rowModesModel).forEach(([id, prop]) => {
      if (prop.mode === GridRowModes.Edit) {
        editRowValues.push(apiRef?.current.getRowWithUpdatedValues(id, '') as T)
      }
    })
    return editRowValues
  }, [apiRef, rowModesModel])

  const getRowsToSave = useCallback(() => {
    const editRow = checkForEditRows()
    const toSave = rows.map((r) => {
      const er = editRow.find((e) => e.id === r.id)
      return er ?? r
    })
    return toSave
  }, [checkForEditRows, rows])

  const saveAndContinue = useCallback(async (): Promise<void> => {
    const saved = await save(getRowsToSave())
    if (saved) {
      forward()
    }
  }, [forward, getRowsToSave, save])

  useEffect(() => {
    setSaveAndContinue(() => saveAndContinue)
  }, [saveAndContinue, setSaveAndContinue])

  const saveAndClose = useCallback(async (): Promise<void> => {
    const saved = await save(getRowsToSave())
    if (saved) {
      close()
    }
  }, [close, getRowsToSave, save])

  useEffect(() => {
    setSaveAndClose(() => saveAndClose)
  })

  const reset = useCallback(async () => {
    const models: React.SetStateAction<GridRowModesModel> = {}
    Object.keys(rowModesModel).forEach((key) => {
      models[key] = { mode: GridRowModes.View, ignoreModifications: true }
    })
    setRowModesModel(models)
    setInitialRows()
    close()
  }, [close, rowModesModel, setInitialRows, setRowModesModel])

  useEffect(() => {
    setReset(() => reset)
  })

  return {
    processRowUpdate,
    selectedRows,
    setSelectedRows,
    handleDeleteClick,
    handleProcessRowUpdateError,
  }
}
