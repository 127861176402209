import React from 'react'
import styled from 'styled-components'
import Svalna from '../@types'
import trendingDownSvg from '../assets/icons/trending-down.svg'
import trendingUpSvg from '../assets/icons/trending-up.svg'
import { colors, spacing } from '../theme'
import { Flex } from './Flex'
import { Icon, IconColor } from './Icon'
import { H4Bold } from './Typography'

const Percentage = styled('span').withConfig({
  shouldForwardProp: (prop) => !['percentage'].includes(prop),
})<{ percentage: number }>`
  color: ${({ percentage }) => {
    if (percentage > 0) return colors.red
    if (percentage < 0) return colors.deepGreen
    return colors.black
  }};
`

type TrendColor = {
  color: string
  filter: IconColor
}

export function trendColor(
  trendPercentage: number | undefined | null,
): TrendColor {
  const pnum = trendPercentage ?? 0
  if (pnum > 0) {
    return { color: colors.red, filter: IconColor.Red }
  } else if (pnum < 0) {
    return { color: colors.deepGreen, filter: IconColor.DeepGreen }
  } else {
    return { color: colors.black, filter: IconColor.Black }
  }
}

interface TrendProps {
  percentage: number | undefined | null
  TextComponent?: Svalna.FC<unknown>
  iconSize?: number
  sign?: string // HACK: Trend and change are expected to have the same props: refactor instead.
}
export function TrendPercentage({
  percentage: trendPercentage,
  TextComponent = H4Bold,
  iconSize,
  ...props
}: TrendProps): React.JSX.Element {
  const pnum = trendPercentage ?? 0
  const iconColor = trendColor(trendPercentage).filter

  return (
    <Flex {...props} itemsCenter>
      <Icon
        style={{
          opacity: trendPercentage === undefined ? 0 : 1,
          marginRight: spacing.small,
        }}
        width={iconSize}
        height={iconSize}
        src={pnum > 0 ? trendingUpSvg : trendingDownSvg}
        color={iconColor}
      />
      <Percentage percentage={pnum}>
        <TextComponent>
          {trendPercentage === undefined
            ? ''
            : `${Math.round(Math.abs(pnum))}%`}
        </TextComponent>
      </Percentage>
    </Flex>
  )
}

interface ChangeProps {
  percentage: number | undefined | null
  TextComponent?: Svalna.FCRC
  iconSize?: number
  sign?: string
}

export function ChangePercentage({
  percentage: trendPercentage,
  TextComponent = H4Bold,
  iconSize,
  sign,
  ...props
}: ChangeProps): React.JSX.Element {
  const pnum = trendPercentage ?? 0

  const the_sign = sign ?? (pnum < 0 ? '-' : '+')

  return (
    <Flex {...props} itemsCenter>
      <Percentage percentage={pnum}>
        <TextComponent>
          {trendPercentage === undefined
            ? ''
            : `${the_sign} ${Math.round(Math.abs(pnum))}%`}
        </TextComponent>
      </Percentage>
    </Flex>
  )
}
