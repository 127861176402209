const redirectURI = `${window.origin}/fortnox`
const scope = encodeURIComponent(
  `supplierinvoice bookkeeping payment supplier companyinformation profile`,
)

const fortnoxURL = `https://apps.fortnox.se/oauth-v1/auth?client_id=${process.env.REACT_APP_FORTNOX_CLIENT_ID}&redirect_uri=${redirectURI}&scope=${scope}&access_type=offline&response_type=code`

export const goToFortnox = (registrationCode?: string | null): void => {
  /*
   * The state is here to CSRF attacks. We need to save it to check
   * that the state retruned in the "response" is the same
   */
  let state = Math.random().toString(36).slice(2)
  localStorage.setItem('fortnox_state', state)

  // add the registration id to the state if there is one so that we can get it back
  // when comming back from fortnox
  if (registrationCode) {
    state = `${state}regCode${registrationCode}`
  }

  window.location.assign(`${fortnoxURL}&state=${state}`)
}
