import _, { isArray, isObject } from 'lodash'

// TODO: Differentiate between missing data and zero emissions.
export function testHasData(data: unknown): boolean {
  if (isObject(data) || isArray(data)) {
    return !_.isEmpty(data)
  } else {
    return !!data
  }
}
