import { useCallback, useContext, useEffect, useState } from 'react'
import { AppDataContext } from '../../../context/AppDataContext'
import { OrgUnit } from '../../../graphql/generated'
import { findParents } from '../../../utils/org'

type OpenMap = {
  openMap: Record<string, boolean>
  openUnit: (orgUnit?: OrgUnit) => void
  closeUnit: (unitId: string) => void
}

export const useOpenMap = (pendingOrg?: OrgUnit): OpenMap => {
  const { orgUnitMap } = useContext(AppDataContext)
  const [openMap, setOpenMap] = useState<Record<string, boolean>>({})

  const openUnit = useCallback(
    (orgUnit?: OrgUnit) => {
      if (!orgUnit) {
        setOpenMap({})
        return
      }

      const parentIds = findParents(orgUnit, orgUnitMap).map(
        (parent) => parent.id,
      )

      const newOpenMap = parentIds.reduce(
        (map, parentId) => ({ ...map, [parentId]: true }),
        { [orgUnit.id]: true },
      )
      setOpenMap(newOpenMap)
    },
    [orgUnitMap],
  )

  useEffect(() => {
    openUnit(pendingOrg)
  }, [pendingOrg, openUnit])

  return {
    openMap,
    openUnit,
    closeUnit: (unitId: string) =>
      setOpenMap((map) => ({ ...map, [unitId]: false })),
  }
}
