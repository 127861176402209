import { useFormik } from 'formik'
import React, { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { TextField } from '@mui/material'
import alertCircleOutlineSvg from '../../assets/icons/alert-circle-outline.svg'
import { AlertIcon, AlertWarning } from '../../components/alert'
import { ErrorTextWhite, Caption } from '../../components/Typography'
import { UserContext } from '../../context/UserContext'
import {
  MeDocument,
  MeQuery,
  useChangePasswordWithTokenMutation,
} from '../../graphql/generated'
import { setToken } from '../../utils/auth'
import { toErrorMap } from '../../utils/toErrorMap'
import { AuthBox } from './components/AuthBox'
import { AuthContainer } from './components/AuthContainer'
import { AuthWrapper } from './components/AuthenticationWrapper'
import { AuthHeader } from './components/AuthHeader'
import { AuthNavButton } from './components/AuthNavButton'
import { AuthTitle } from './components/AuthTitle'
import { Form } from './components/Form'
import { ButtonGreen } from '../../components/Buttons'
import { spacing } from '../../theme'

const ForgotPasswordLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

export function ChangePasswordWithToken(): React.JSX.Element {
  const [changePassword] = useChangePasswordWithTokenMutation()
  const [tokenError, setTokenError] = useState('')
  const { token } = useParams<{ token: string }>()
  const history = useHistory()
  const { setLoggedIn } = useContext(UserContext)

  const formik = useFormik({
    initialValues: {
      newPassword: '',
    },
    onSubmit: async (values, { setErrors }) => {
      const response = await changePassword({
        variables: {
          newPassword: values.newPassword,
          token,
        },
        update: (cache, { data }) => {
          if (data?.changePasswordWithToken.user) {
            cache.writeQuery<MeQuery>({
              query: MeDocument,
              data: {
                __typename: 'Query',
                me: data?.changePasswordWithToken.user,
              },
            })
          }
        },
      })

      if (response.data?.changePasswordWithToken.errors) {
        const errorMap = toErrorMap(
          response.data.changePasswordWithToken.errors,
        )
        if ('token' in errorMap) {
          setTokenError(errorMap.token)
        }
        setErrors(errorMap)
        return
      }

      if (response.data?.changePasswordWithToken.authentication) {
        const {
          token: accessToken,
          expiresAt,
          refreshToken,
        } = response.data.changePasswordWithToken.authentication
        setToken(accessToken, refreshToken, expiresAt)
        setLoggedIn(true)
        history.push('/')
      } else {
        history.push('/login')
      }
    },
  })

  return (
    <AuthWrapper>
      <AuthBox>
        <AuthContainer>
          {tokenError && (
            <AlertWarning>
              <AlertIcon src={alertCircleOutlineSvg} />
              <ErrorTextWhite>
                <span>{`${tokenError} `}</span>
                <ForgotPasswordLink
                  onClick={() => history.replace('/forgot-password')}
                >
                  Klicka här för att skapa ett nytt
                </ForgotPasswordLink>
              </ErrorTextWhite>
            </AlertWarning>
          )}

          <AuthHeader />
          <AuthTitle>Byt lösenord</AuthTitle>
          <Caption>Ange ett nytt lösenord</Caption>

          <Form onSubmit={formik.handleSubmit}>
            <TextField
              style={{ marginTop: spacing.xxl }}
              type='text'
              name='newPassword'
              label='Nytt lösenord'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              variant='standard'
              error={
                !!formik.touched.newPassword && !!formik.errors.newPassword
              }
              helperText={
                formik.touched.newPassword && formik.errors.newPassword
              }
              required
            />
            <ButtonGreen type='submit'>Byt lösenord</ButtonGreen>

            <AuthNavButton route='/login'>Logga in</AuthNavButton>
          </Form>
        </AuthContainer>
      </AuthBox>
    </AuthWrapper>
  )
}
