/**
 * Open-ended range.
 */
export type OpenEndedRange = { start: number; openEnd: number }

/**
 * Get all gaps in an ascending sequence of numbers as a list of open-ended tuples.
 *
 * E.g. [1,2,5,6,8] => List([3,5], [7,8])
 *
 * @param xs A sorted sequence of numbers, in ascending order.
 * @returns The gaps in the sequence as open-ended tuples.
 */
export function getGaps(xs: number[]): OpenEndedRange[] {
  const gaps: OpenEndedRange[] = []
  let i = 0
  while (i < xs.length - 1) {
    const a = xs[i]
    const b = xs[i + 1]
    if (a + 1 !== b) {
      gaps.push({ start: a + 1, openEnd: b })
    }
    ++i
  }
  return gaps
}

/**
 * Get all ranges in an ascending sequence of numbers as a list of open-ended tuples.
 *
 * E.g. [1,2,5,6,8] => List([1,3], [5,7], [8,9])
 *
 * @param xs A sorted sequence of numbers, in ascending order.
 * @returns The ranges in the sequence as opend-ended tuples.
 */
export function getRanges(xs: number[]): OpenEndedRange[] {
  if (!xs.length) {
    return []
  }
  const ranges: OpenEndedRange[] = []
  let i = 0
  let rangeStart = xs[0]
  let rangeEnd = rangeStart
  while (i < xs.length - 1) {
    const a = xs[i]
    const b = xs[i + 1]
    if (a + 1 !== b) {
      // Current range: [start, a + 1]
      ranges.push({ start: rangeStart, openEnd: a + 1 })
      rangeStart = b
    }
    rangeEnd = b
    ++i
  }
  // Add final range, as the last element will not have an explicit bound.
  ranges.push({ start: rangeStart, openEnd: rangeEnd + 1 })
  return ranges
}
