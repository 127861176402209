import React from 'react'
import { TooltipRenderProps } from 'react-joyride'
import CloseIcon from '@mui/icons-material/Close'
import { Button, IconButton } from '@mui/material'
import styled from 'styled-components'
import { ButtonGreen } from '../Buttons'
import { Flex } from '../Flex'
import { Body1 } from '../Typography'
import { colors, spacing } from '../../theme'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  background-color: ${colors.white};
  padding: ${spacing.medium};
  border-radius: 10px;
  gap: ${spacing.small};
`

/**
 * Tooltip component for the product tour (using Joyride).
 *
 * @see https://docs.react-joyride.com/custom-components#tooltipcomponent [2024-09-25]
 */
export function TourTooltip(props: TooltipRenderProps): React.JSX.Element {
  const {
    tooltipProps,
    backProps,
    closeProps,
    primaryProps,
    index,
    size,
    step,
  } = props
  return (
    <Wrapper {...tooltipProps}>
      {/* Header */}
      <Flex row stretchWidth spaceBetween itemsCenter>
        {step.title}
        <IconButton
          {...closeProps}
          size='small' // tighter padding/margin around the 'X'
          style={{ color: colors.black }}
        >
          {/* Default MUI icon size is 24 px */}
          <CloseIcon />
        </IconButton>
      </Flex>
      {/* Body */}
      {step.content}
      {/* Footer */}
      <Flex row stretchWidth spaceBetween itemsCenter>
        <Body1 style={{ color: colors.lightGranit }}>
          {index + 1}/{size}
        </Body1>
        <Flex>
          {/* Back button, if applicable */}
          {index > 0 && (
            <Button {...backProps} color='secondary'>
              {backProps.title}
            </Button>
          )}
          {/* Joyride "primary" is either "next" or "done" depending on state */}
          <ButtonGreen {...primaryProps}>{primaryProps.title}</ButtonGreen>
        </Flex>
      </Flex>
    </Wrapper>
  )
}
