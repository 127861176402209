import React, { useRef } from 'react'
import styled from 'styled-components'
import Svalna from '../../@types'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { Flex } from '../Flex'
import { DropdownOverlay } from './DropdownOverlay'

const Content = styled(Flex)<{
  width?: number
}>`
  flex-direction: column;
  flex: auto;
  align-items: stretch;
  max-height: 60vh;
  overflow-y: auto;
  ${(props) => props.width && `width:${props.width}px;`}
`

interface DropdownProps extends Svalna.PropWithChildren {
  isOpen: boolean
  width?: number
  offsetX?: number
  offsetY?: number
  transform?: string
  top?: number
  left?: number
}

export function Dropdown({
  isOpen,
  children,
  width,
  offsetX,
  offsetY,
  transform,
  top,
  left,
}: DropdownProps): React.JSX.Element | null {
  if (!isOpen) {
    return null
  }
  return (
    <DropdownOverlay
      offsetX={offsetX}
      offsetY={offsetY}
      transform={transform}
      top={top}
      left={left}
    >
      <Content width={width}>{children}</Content>
    </DropdownOverlay>
  )
}

interface OutsideClickCatcherProps extends Svalna.PropWithChildren {
  onClickOutside: (event: MouseEvent | TouchEvent) => void
  props?: Object
}

export function OutsideClickCatcher({
  onClickOutside,
  children,
  props,
}: OutsideClickCatcherProps): React.JSX.Element {
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, onClickOutside)

  return (
    <div ref={ref} {...props}>
      {children}
    </div>
  )
}
