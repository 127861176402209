import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/sv'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { Maybe, NumberSearchType, Period } from '../graphql/generated'
import { numberTypeToName } from '../components/recategorisation/components/search/SearchTypeDropdown'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('utc')
dayjs.extend(weekOfYear)
dayjs.locale('sv')

export const formatDay = (date: Dayjs | Date): string =>
  dayjs.utc(date).format('DD/MM')

export const formatYear = (date: Dayjs | Date): string =>
  dayjs.utc(date).format('YYYY')

export const formatMonth = (date: Dayjs | Date): string =>
  dayjs.utc(date).format('MMM')

export const formatISO = (date: Dayjs | Date): string =>
  dayjs.utc(date).format('YYYY-MM-DD')

export const formatPeriod = (date: Dayjs | Date, period: Period): string => {
  switch (period) {
    case Period.Month:
      return formatMonth(date)
    case Period.Year:
      return formatYear(date)
    case Period.Week:
      return `v${dayjs.utc(date).week()}`
    case Period.Day:
      return formatDay(date)
    default:
      return ''
  }
}

export const startOfCurrentYear = (): Dayjs => dayjs.utc().startOf('year')

export const parseYear = (
  dateStr: string | Dayjs | Maybe<Date> | undefined,
): number => dayjs.utc(dateStr).year()

export function formatDateFilter(
  startDate: Dayjs | Maybe<Date> | undefined,
  endDate: Dayjs | Maybe<Date> | undefined,
  dateSearchType: NumberSearchType | undefined | null,
): string {
  if (!dateSearchType || !startDate) {
    return 'alla'
  }
  if (dateSearchType === NumberSearchType.Between) {
    return `${formatISO(startDate)} - ${endDate ? formatISO(endDate) : '...'}`
  } else if (dateSearchType === NumberSearchType.Equal) {
    return formatISO(startDate)
  }
  return `${numberTypeToName(dateSearchType)} ${formatISO(startDate)}`
}
