import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Svalna from '../../../@types'
import CardTemplate from '../../../components/CardTemplate'
import { ScrollToElement } from '../../../components/ScrollToElement'
import { Body1 } from '../../../components/Typography'
import { useFilterInput } from '../../../hooks/useFilterInput'
import { colors, spacing } from '../../../theme'
import {
  getNumberFormatter,
  unitDescription,
} from '../../../utils/adaptiveNumberFormat'
import { testHasData } from '../../../utils/testHasData'
import { useEmissionPerOrgUnitData } from './useEmissionPerOrgUnitData'
import { Category, FilterInput } from '../../../graphql/generated'
import { FilterContext } from '../../../context/FilterContext'

const containerHeight = 300

const Container = styled.div`
  position: relative;
  margin: ${spacing.large};
  user-select: none;
`

const Rows = styled.div`
  height: ${containerHeight}px;
  overflow: auto;
  padding-left: 1px;
  border-left: 1.2px solid black;
`

const RowData = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  /* TODO: 'wrap' is not a real rule, what was the intent? */
  /* wrap: no-wrap; */
  pointer-events: none;
`

const Line = styled.div.withConfig({
  shouldForwardProp: (prop) => !['fillPercentage', 'deepGreen'].includes(prop),
})<{ fillPercentage: number; deepGreen: boolean }>`
  height: 16px;
  background-color: #515161;
  border-start-end-radius: 4px;
  border-end-end-radius: 4px;
  width: ${(props) => props.fillPercentage}%;
  pointer-events: none;
  ${(props) => props.deepGreen && `background-color: ${colors.deepGreen};`}
`

const EmissionFooter = styled.div`
  display: flex;
  justify-content: space-between;
  pointer-events: none;
`

const Label = styled(Body1)`
  pointer-events: none;
`

export function EmissionPerOrgUnitCard(): React.JSX.Element {
  const { defaultDatePeriod } = useContext(FilterContext)
  const [useAnnualWorkForce, setUseAnnualWorkForce] = useState(false)
  const [category, setCategory] = useState<Category | undefined>()
  const [selectedDatePeriod, setDatePeriod] = useState(defaultDatePeriod)
  const filter = useFilterInput(
    category,
    selectedDatePeriod,
    useAnnualWorkForce,
  )
  const orgUnitsData = useEmissionPerOrgUnitData(
    filter,
    selectedDatePeriod.period,
  )

  useEffect(() => {
    // This may overwrite the date set by a user, but this would only happen
    // when loading data from fortnox for the first time in the year
    setDatePeriod(defaultDatePeriod)
  }, [defaultDatePeriod])

  return (
    <CardTemplate
      titleData={{
        title: useAnnualWorkForce
          ? 'Jämför utsläpp fördelat på underavdelningar per anställd'
          : 'Utsläpp fördelat på underavdelningar',
        subtitle: unitDescription(filter),
      }}
      loading={orgUnitsData.loading}
      hasData={testHasData(orgUnitsData.dataPoints)}
      error={undefined}
      useAnnualWorkForce={useAnnualWorkForce}
      setUseAnnualWorkForce={setUseAnnualWorkForce}
      tooltip={<Body1>Visar utsläppsfördelningen på underavdelningar.</Body1>}
      selectedCategory={category}
      setCategory={setCategory}
      selectedDatePeriod={selectedDatePeriod}
      setDatePeriod={setDatePeriod}
    >
      <EmissionPerOrgUnit
        {...orgUnitsData}
        annualWorkForce={useAnnualWorkForce}
        filter={filter}
      />
    </CardTemplate>
  )
}

type Props = ReturnType<typeof useEmissionPerOrgUnitData> & {
  annualWorkForce?: boolean
  filter: FilterInput
}

function OrgUnitEntry({
  d,
  scale,
  annualWorkForce,
  filter,
}: {
  d: ReturnType<typeof useEmissionPerOrgUnitData>['dataPoints'][0]
  scale: number
  annualWorkForce?: boolean
  filter: FilterInput
}) {
  const ref = useRef<HTMLDivElement>(null)
  const [highlight, setHighlight] = useState(false)
  const [mx, setMx] = useState(0)

  // Compute hover info offsets relative to the cursor;
  // the info should be rendered inside the card.
  const { width } = ref.current?.getClientRects()?.[0] ?? { width: 1 }
  // TODO: This position is relative to the parent, regardless of scroll.
  const top = ref.current?.offsetTop ?? 1

  // HACK: The label will be longer if either all years, or partial year, is selected.
  //      Take that into account when positioning the label near the right edge.
  const hack_extra_space_left = filter.partialYear || !filter.year ? -108 : 0

  const hover_x_offset =
    width - 128 + hack_extra_space_left < mx ? -108 + hack_extra_space_left : 24
  const hover_y_offset = top + 50 > containerHeight ? -40 : 20

  return (
    <div
      ref={ref}
      style={{
        position: 'relative',
      }}
      onMouseMove={(e) => {
        setMx(e.clientX - (ref.current?.getClientRects()?.[0].x ?? 0))
        setHighlight(true)
      }}
      onMouseOut={(_e) => {
        setMx(0)
        setHighlight(false)
      }}
      onFocus={() => {
        setMx(0)
        setHighlight(true)
      }}
      onBlur={() => {
        setMx(0)
        setHighlight(false)
      }}
    >
      {/* Hover info box */}
      <div
        style={{
          display: highlight ? 'flex' : 'none',
          flexDirection: 'column',
          position: 'absolute',
          cursor: 'default',
          left: hover_x_offset + mx,
          top: hover_y_offset,
          backgroundColor: 'white',
          border: '1px solid black',
          boxShadow: '1px 1px 6px 0.25px rgba(0, 0, 0, 0.2)',
          padding: spacing.small,
          borderRadius: 8,
          zIndex: 2,
          userSelect: 'none',
          pointerEvents: 'none', // Do not block mouse events from other components.
        }}
      >
        <Body1>
          {getNumberFormatter([d.y]).format(d.y)}
          {unitDescription(filter, { hideUnit: true })}
        </Body1>
        {!annualWorkForce && <Body1>{Math.round(d.percentage)} %</Body1>}
      </div>
      {/* Text and line */}
      <RowData>
        <Label bold={highlight || d.highlight}>{d.orgName}</Label>
        <Line deepGreen={d.highlight} fillPercentage={d.percentage * scale} />
      </RowData>
    </div>
  )
}

export function EmissionPerOrgUnit({
  dataPoints,
  annualWorkForce,
  filter,
}: Props): React.JSX.Element {
  const biggest = dataPoints[0]
  const scale = biggest?.percentage ? 100 / biggest.percentage : 0
  const maxCO2e = biggest?.y
  const formatter = getNumberFormatter(dataPoints?.map((x) => x.y) ?? [])

  return (
    <Container>
      <Rows>
        {/* 
          This div will auto-size to have the same height as the available scroll height,
          enabling the lines to be properly sized when using "100%" height.
        */}
        <div
          style={{
            height: 'auto',
            minHeight: containerHeight, // Needed when scroll would be smaller than container.
            position: 'relative',
          }}
        >
          {/* TODO: Draw a fixed number (quarters?) and align with labels. */}
          {[0.25, 0.5, 0.75, 1.0].map((x, index) => {
            // const frac = (index + 1) / Math.max(1, dataPoints.length)
            const frac = x
            return (
              <div
                key={`${x}-line`}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: `${frac * 99.8}%`, // Hackish: 99.8% prevents final line from causing horisontal scroll.
                  height: '100%',
                  borderRight: '1px dashed rgba(0, 0, 0, 0.2)',
                }}
              />
            )
          })}
          {dataPoints.map((d) => (
            <ScrollToElement scroll={d.highlight} key={d.orgName}>
              <OrgUnitEntry
                d={d}
                scale={scale}
                annualWorkForce={annualWorkForce}
                filter={filter}
              />
            </ScrollToElement>
          ))}
        </div>
      </Rows>
      <EmissionFooter>
        <CO2eItem transform='left'>{formatter.format(0)}</CO2eItem>
        <CO2eItem transform='left'>{formatter.format(maxCO2e * 0.25)}</CO2eItem>
        <CO2eItem>{formatter.format(maxCO2e * 0.5)}</CO2eItem>
        <CO2eItem transform='right'>
          {formatter.format(maxCO2e * 0.75)}
        </CO2eItem>
        <CO2eItem transform='right'>{formatter.format(maxCO2e)}</CO2eItem>
      </EmissionFooter>
    </Container>
  )
}

// const BarLabel = (x: any) => {
//   console.log('BarLabel', x)
//   return <VictoryTooltip />
// }

// export const EmissionPerOrgUnit: React.FC<Props> = ({ dataPoints }) => {
//   const [ref, size] = useElementSize()

//   const formatter = getNumberFormatter(dataPoints?.map((x) => x.y) ?? [])

//   const width = size?.width ?? 1600
//   const height = width / 3.2

//   return (
//     <Flex row ref={ref}>
//       <VictoryChart
//         theme={defaultChartTheme}
//         width={width}
//         height={height}
//         domain={calculateDomain(dataPoints)}
//         padding={{
//           top: 40,
//           bottom: 40,
//           left: 60,
//           right: 60,
//         }}
//       >
//         <VictoryAxis
//           orientation='left'
//           style={{ tickLabels: { textAnchor: 'start', fontSize: 20, }, }}
//           tickLabelComponent={<VictoryLabel dy={-36} dx={24} />}
//         />
//         <VictoryAxis
//           dependentAxis
//           fixLabelOverlap
//           orientation='bottom'
//           tickFormat={(x) => {
//             return formatter.format(x)
//           }}
//           style={{
//             axis: { stroke: 'transparent' },
//             grid: {
//               stroke: 'rgba(0,0,0,0.2)',
//               strokeDasharray: '4, 8',
//             },
//             tickLabels: { fill: 'black', },
//           }}
//         />
//         <VictoryBar
//           sortKey='y'
//           horizontal
//           data={dataPoints}
//           cornerRadius={6}
//           style={{
//             data: {
//               fill: '#515161',
//             },
//             labels: {
//               left: (eut) => {console.log('eut', eut); return -600}
//             }
//           }}
//           labelComponent={<VictoryTooltip constrainToVisibleArea pointerOrientation='bottom' dy={-42} />}
//           events={[{
//             target: 'data',
//             eventHandlers: {
//               onMouseOver: () => {
//                 return [
//                   {
//                     target: 'data',
//                     mutation: () => ({ style: { fill: colors.deepGreen } })
//                   },
//                   {
//                     target: 'labels',
//                     mutation: (e) => {
//                       console.log('me', e)
//                       return { active: true }
//                     }
//                   },
//                 ]
//               },
//               onMouseOut: () => {
//                 return [
//                   {
//                     target: 'data',
//                     mutation: () => ({ })
//                   },
//                   {
//                     target: 'labels',
//                     mutation: () => ({ active: false })
//                   },
//                 ]
//               },
//             }
//           }]}
//         />
//       </VictoryChart>
//     </Flex>
//     // <Container>
//     //   <Rows>
//     //     {dataPoints.map((d) => {
//     //       return (
//     //         <ScrollToElement scroll={d.highlight} key={d.orgName}>
//     //           <div
//     //             onMouseOver={() => { console.log('asdasdasd'); d.highlight = true; }}
//     //             onMouseOut={() => { console.log('asdasdasd'); d.highlight = false; }}
//     //             onFocus={() => { console.log('asdasdasd'); d.highlight = true; }}
//     //             onBlur={() => { d.highlight = false }}
//     //           >
//     //             <RowData>
//     //               <Label bold={d.highlight} onMouseOver={() => { d.highlight = true; }}>
//     //                 {d.orgName}
//     //               </Label>
//     //               <Line
//     //                 deepGreen={d.highlight}
//     //                 fillPercentage={d.percentage * scale}
//     //               />
//     //             </RowData>
//     //           </div>
//     //         </ScrollToElement>
//     //       )
//     //     })}
//     //   </Rows>
//     //   <EmissionFooter>
//     //     <CO2eItem transform='left'>{formatter.format(0)}</CO2eItem>
//     //     <CO2eItem transform='left'>{formatter.format(maxCO2e * 0.25)}</CO2eItem>
//     //     <CO2eItem>{formatter.format(maxCO2e * 0.5)}</CO2eItem>
//     //     <CO2eItem transform='right'>
//     //       {formatter.format(maxCO2e * 0.75)}
//     //     </CO2eItem>
//     //     <CO2eItem transform='right'>{formatter.format(maxCO2e)}</CO2eItem>
//     //   </EmissionFooter>
//     // </Container>
//   )
// }

const CO2Container = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['transformLeft', 'transformRight'].includes(prop),
})<{
  transformLeft?: number
  transformRight?: number
}>`
  margin: 0;
  margin-top: 8px;
  font-size: 12px;
`

interface CO2Props extends Svalna.PropWithChildren {
  transform?: 'left' | 'right'
}
function CO2eItem({ children, transform }: CO2Props) {
  const ref = useRef<HTMLDivElement>(null)
  const elementWidth = ref?.current?.offsetWidth
  return (
    <CO2Container
      ref={ref}
      transformLeft={
        transform === 'left' && elementWidth ? elementWidth / 2 : undefined
      }
      transformRight={
        transform === 'right' && elementWidth ? elementWidth / 2 : undefined
      }
    >
      {children}
    </CO2Container>
  )
}
