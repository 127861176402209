import React, { useContext } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CircleIcon from '@mui/icons-material/Circle'
import styled from 'styled-components'
import { IconButton, Tooltip } from '@mui/material'
import { AppDataContext } from '../../../context/AppDataContext'
import { Category, FilterInput } from '../../../graphql/generated'
import { getNumberFormatter } from '../../../utils/adaptiveNumberFormat'
import { getEmission, type EmissionData } from './useCategoryPieData'
import { colors, spacing } from '../../../theme'
import { Body2 } from '../../Typography'
import { useEmissionTourContext } from '../../../context/EmissionTourContext'
import { StepId } from '../../tour/tourConstants'

const Grid = styled.div`
  display: table;
  width: 50%;
  table-layout: fixed;
`

const HeaderName = styled(Body2)`
  color: ${colors.lightGranit};
`

const GridCell = styled.div.withConfig({
  shouldForwardProp: (prop) => !['alignRight', 'selected'].includes(prop),
  // As usual, habing no space between ">" and "`" messes with
  // the vscode-styled-components extension;
  // eslint-disable-next-line prettier/prettier
})<{ alignRight?: boolean; selected?: boolean }> `
  display: table-cell;
  vertical-align: middle;
  text-align: ${(props) => (props.alignRight ? 'end' : 'start')};
  padding-left: ${spacing.small};
  padding-right: ${spacing.small};
  background-color: ${(props) =>
    props.selected ? colors.deepGreen08 : colors.white};
  height: 40px;
  &:first-child {
    padding-left: ${spacing.small};
    border-start-start-radius: 10px;
    border-end-start-radius: 10px;
  }
  &:last-child {
    padding-left: ${spacing.small};
    border-start-end-radius: 10px;
    border-end-end-radius: 10px;
  }
  & > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

const HearderRow = styled.div`
  display: table-row;
  height: 2rem;
`

const HidenIcon = styled(IconButton)`
  opacity: 0;
  &:focus {
    opacity: 1;
  }
`

const GridRow = styled.div<{ $tourHover?: boolean }>`
  display: table-row;
  height: 2rem;

  /* -- Tour handling: highlight cells as if hovered/focused */
  & ${GridCell} {
    ${({ $tourHover }) =>
      $tourHover ? `background-color: ${colors.lightGray};` : ''}
  }
  & ${HidenIcon} {
    ${({ $tourHover }) => ($tourHover ? `opacity: 1;` : '')}
  }
  /* -- End of tour handling */

  &:hover ${GridCell} {
    background-color: ${colors.lightGray};
  }
  &:focus-visible ${GridCell} {
    background-color: ${colors.lightGray};
  }
  &:hover ${HidenIcon} {
    opacity: 1;
  }
  &:focus-visible ${HidenIcon} {
    opacity: 1;
  }
`

function GridCellWithTooltip({
  content,
  alignRight,
  selected,
}: {
  content: string
  alignRight?: boolean
  selected?: boolean
}): React.JSX.Element {
  return (
    <Tooltip title={content} disableInteractive>
      <GridCell alignRight={alignRight} selected={selected}>
        <Body2>{content}</Body2>
      </GridCell>
    </Tooltip>
  )
}

interface Props {
  emissions: EmissionData[]
  selectedCategory?: Category
  onCategoryClick: (cat: Category | undefined) => void
  filter: FilterInput
  canGoDeeper: (emission?: EmissionData) => boolean
  dig: (emission: EmissionData | undefined) => void
}

export function CategoryLabels({
  emissions,
  selectedCategory,
  onCategoryClick,
  filter,
  canGoDeeper,
  dig,
}: Props): React.JSX.Element {
  const { categoryId } = filter
  const { categories } = useContext(AppDataContext)
  const { ongoing, steps, stepIndex } = useEmissionTourContext()

  if (!categoryId) {
    // fill missing primary categories
    const current = new Set(emissions.map((x) => x.category.id))
    categories.forEach((x) => {
      if (!(x.parentId || current.has(x.id)))
        emissions.push({ category: x, totalCO2e: 0, percent: '0', period: '' })
    })
  }

  const getCategory = (id: number) => {
    const emissionCat = getEmission(id, emissions)
    if (emissionCat) {
      return emissionCat.category
    }
    if (!categoryId) {
      //we may have filled in missing primary categories
      return categories.find((cat) => cat.id === id)
    }
  }

  const handleRowClick = (id: number) => {
    onCategoryClick(getCategory(id))
  }

  const handleRowDoubleClick = (row: EmissionData) => {
    if (row.totalCO2e !== 0) {
      const emission = getEmission(row.category.id, emissions)
      if (canGoDeeper(emission)) {
        dig(emission)
      }
    }
  }

  const setArrowButton = (row: EmissionData) => {
    if (row.totalCO2e !== 0) {
      const emission = getEmission(row.category.id, emissions)
      if (canGoDeeper(emission)) {
        return true
      }
    }
    return false
  }

  return (
    <Grid>
      <HearderRow>
        <GridCell style={{ width: '30px' }}>
          <div />
        </GridCell>
        <GridCell>
          <HeaderName>KATEGORI</HeaderName>
        </GridCell>
        <GridCell alignRight style={{ width: '30%' }}>
          <HeaderName>UTSLÄPP</HeaderName>
        </GridCell>
        <GridCell alignRight style={{ width: '20%' }}>
          <HeaderName>%</HeaderName>
        </GridCell>
        <GridCell style={{ width: '50px' }}>
          <div />
        </GridCell>
      </HearderRow>
      {emissions.map((row, index) => {
        // "hover" first row if tour is on the relevant pie steps.
        const tourHover =
          ongoing &&
          index === 0 &&
          (steps[stepIndex]?.data?.id === StepId.e2PieDiagram ||
            steps[stepIndex]?.data?.id === StepId.e3PieAccordion)
        return (
          <GridRow
            key={row.category.name}
            $tourHover={tourHover}
            tabIndex={0}
            onClick={() => handleRowClick(row.category.id)}
            onDoubleClick={() => handleRowDoubleClick(row)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                if (selectedCategory?.id === row.category.id) {
                  handleRowDoubleClick(row)
                } else {
                  handleRowClick(row.category.id)
                }
              }
            }}
            data-testid={`row-${row.category.name}${tourHover ? '-tour' : ''}`}
          >
            <GridCell selected={selectedCategory?.id === row.category.id}>
              <CircleIcon
                fontSize='small'
                sx={{
                  color: row.category.color,
                }}
              />
            </GridCell>
            <GridCellWithTooltip
              content={row.category.name}
              selected={selectedCategory?.id === row.category.id}
            />
            <GridCellWithTooltip
              content={getNumberFormatter([row.totalCO2e]).format(
                row.totalCO2e,
              )}
              alignRight
              selected={selectedCategory?.id === row.category.id}
            />
            <GridCellWithTooltip
              content={`${row.percent}%`}
              alignRight
              selected={selectedCategory?.id === row.category.id}
            />
            {setArrowButton(row) ? (
              <GridCell selected={selectedCategory?.id === row.category.id}>
                <HidenIcon
                  onClick={(event) => {
                    handleRowDoubleClick(row)
                    event.stopPropagation()
                  }}
                  disabled={row.totalCO2e === 0}
                  // put the total co2 as part of the key so that when we dig into "övrigt" we get a different key
                  key={`button-${row.category.name}-${row.totalCO2e}`}
                  data-testid={`go-to-${row.category.name}`}
                >
                  <ArrowForwardIcon />
                </HidenIcon>
              </GridCell>
            ) : (
              // empty grid cell so that the lines have the same size if they have a button or not
              <GridCell selected={selectedCategory?.id === row.category.id} />
            )}
          </GridRow>
        )
      })}
    </Grid>
  )
}
