import React from 'react'
import { Flex } from '../../Flex'
import { ButtonLightBorder } from '../../Buttons'
import { Body2Bold } from '../../Typography'
import { spacing } from '../../../theme'

export enum RecatType {
  CHANGE_CATEGORY,
  SET_EMISSIONS,
  CHOOSE,
  HIDE,
}

interface Props {
  switchRecatType: (type: RecatType) => void
}

export function SelectRecatType({ switchRecatType }: Props): React.JSX.Element {
  return (
    <Flex
      column
      justifyCenter
      itemsCenter
      stretchWidth
      style={{ gap: spacing.xl, minHeight: '100px' }}
    >
      <Body2Bold>
        Välj vilken typ av regel du vill skapa. Vad vill du göra med de valda
        transaktionerna?
      </Body2Bold>
      <Flex
        row
        justifyCenter
        itemsCenter
        stretchWidth
        style={{ gap: spacing.medium }}
      >
        <ButtonLightBorder
          onClick={() => switchRecatType(RecatType.CHANGE_CATEGORY)}
        >
          ÄNDRA KATEGORI
        </ButtonLightBorder>
        <ButtonLightBorder
          onClick={() => switchRecatType(RecatType.SET_EMISSIONS)}
        >
          ÄNDRA UTSLÄPPSVÄRDE
        </ButtonLightBorder>
      </Flex>
    </Flex>
  )
}
