import React, { RefObject } from 'react'
import styled from 'styled-components'
import { Box } from './Box'
import { spacing } from '../theme'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  itemsCenter?: boolean
  itemsStretch?: boolean
  spaceAround?: boolean
  spaceBetween?: boolean
  justifyCenter?: boolean
  justifyStretch?: boolean
  justifyEnd?: boolean
  column?: boolean
  row?: boolean
  doWrap?: boolean
  auto?: boolean
  basis?: string
  ref?: RefObject<HTMLDivElement>
  stretchWidth?: boolean
}

const StyledFlex = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    ![
      'itemsCenter',
      'itemsStretch',
      'spaceAround',
      'spaceBetween',
      'justifyCenter',
      'justifyStretch',
      'justifyEnd',
      'column',
      'row',
      'doWrap',
      'stretchWidth',
    ].includes(prop),
})<Props>`
  display: flex;
  align-items: ${({ itemsCenter, itemsStretch }) => {
    if (itemsCenter) return 'center'
    if (itemsStretch) return 'stretch'
    return 'flex-start'
  }};
  justify-content: ${({
    justifyCenter,
    justifyEnd,
    spaceBetween,
    justifyStretch,
    spaceAround,
  }) => {
    if (justifyCenter) return 'center'
    if (justifyEnd) return 'flex-end'
    if (justifyStretch) return 'stretch'
    if (spaceBetween) return 'space-between'
    if (spaceAround) return 'space-around'
    return 'flex-start'
  }};
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  flex-wrap: ${({ doWrap }) => (doWrap ? 'wrap' : 'nowrap')};
  flex-basis: ${({ basis }) => basis};
  ${({ auto }) => (auto ? 'flex: auto;' : '')}
  ${({ stretchWidth: stretch }) => (stretch ? 'width: 100%;' : '')}
`

export function Flex({ children, ...props }: Props): React.JSX.Element {
  if (children) {
    return <StyledFlex {...props}>{children}</StyledFlex>
  }
  return <StyledFlex {...props} />
}

export const FlexingBox = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 340px;
`

export const HeadRow = styled(Flex)`
  flex-direction: row;
  border-bottom: 1px solid #b0b0b0;
  padding: ${spacing.large};
`

export const FlexingBoxContent = styled(Flex)`
  flex-direction: column;
  margin: ${spacing.large};
  gap: ${spacing.xxl};
`

export const InnerBox = styled(Flex)`
  flex-direction: column;
  padding: ${spacing.large};
  gap: ${spacing.xl};
  border: 1px solid #b0b0b0;
  width: 100%;
`

export const InnerInnerBox = styled(Flex)`
  flex-direction: column;
  gap: ${spacing.large};
  width: 100%;
`

export const InnerBoxText = styled(Flex)`
  flex-direction: column;
  gap: ${spacing.small};
  width: 100%;
`
