import React from 'react'
import styled from 'styled-components'
import { OrganisationFragment, OrgUnit } from '../../../graphql/generated'
import { findChildrenFor, findTopLevelOrgUnits } from '../../../utils/org'
import { Flex } from '../../Flex'
import { FilterOrgListItem } from './FilterOrgListItem'

const Wrapper = styled(Flex)`
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

interface Props {
  selectedOrgUnit?: OrgUnit
  organisation: OrganisationFragment
  onClick: (orgUnit?: OrgUnit, isLeaf?: boolean) => void
  openMap: Record<string, boolean>
}

export function FilterOrgList({
  selectedOrgUnit,
  organisation,
  onClick,
  openMap,
}: Props): React.JSX.Element {
  const rootOrg = {
    id: organisation.id,
    name: organisation.name,
    level: 0,
  }

  const renderChildren = (item: OrgUnit) => {
    const isRoot = item.id === organisation.id
    if (!openMap[item.id] && !isRoot) {
      return null
    }

    const children: OrgUnit[] = isRoot
      ? findTopLevelOrgUnits(organisation)
      : findChildrenFor(item.id, organisation)

    if (children.length === 0) return null

    return (
      <Wrapper>
        {children?.map((unit: OrgUnit) => {
          const isLeaf = findChildrenFor(unit.id, organisation).length > 0
          return (
            <FilterOrgListItem
              key={unit.name}
              item={unit}
              isSelected={unit.id === selectedOrgUnit?.id}
              scrollToElem={unit.id === selectedOrgUnit?.id}
              renderChildren={() => renderChildren(unit)}
              onClick={() => onClick(unit, isLeaf)}
              isOpen={openMap[unit.id]}
              showDropdown={isLeaf}
              isActive={item.id === selectedOrgUnit?.id || openMap[unit.id]}
            />
          )
        })}
      </Wrapper>
    )
  }

  return (
    <Wrapper column itemsStretch>
      <FilterOrgListItem
        item={{
          id: organisation.id,
          name: organisation.name,
          level: 0,
        }}
        scrollToElem={organisation.id === selectedOrgUnit?.id}
        renderChildren={() => renderChildren(rootOrg)}
        onClick={() => onClick(undefined, true)}
        isSelected={selectedOrgUnit?.id === undefined}
        isOpen
        showDropdown={false}
        isActive
      />
    </Wrapper>
  )
}
