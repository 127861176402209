import React, { useCallback, useContext, useEffect, useState } from 'react'
import Svalna from '../@types'
import {
  Job,
  JobError,
  JobErrorType,
  Maybe,
  MeDocument,
  useCheckPermissionsMutation,
  useGetAndProcessFortnoxDataMutation,
} from '../graphql/generated'
import { AppDataContext } from './AppDataContext'
import { JobContext } from './JobContext'

export type FortnoxContextType = {
  tokenexpired: boolean
  permissionError: {
    error: string
    hasBeenSet: boolean
  }
}

const initialValues: FortnoxContextType = {
  tokenexpired: false,
  permissionError: { error: '', hasBeenSet: false },
}

export const FortnoxContext =
  React.createContext<FortnoxContextType>(initialValues)

const { Provider } = FortnoxContext

export function FortnoxProvider({
  children,
}: Svalna.PropWithChildren): React.JSX.Element {
  const { organisation, orgHasInitializedData } = useContext(AppDataContext)
  const { handleJob, setFortnoxErrorHandler, fortnoxErrorHandler } =
    useContext(JobContext)
  const [tokenexpired, setTokenExpired] = useState(false)
  const [permissionError, setPermissionError] = useState({
    error: '',
    hasBeenSet: false,
  })
  const [getAndProcessFortnoxData] = useGetAndProcessFortnoxDataMutation()

  const [checkPermissions] = useCheckPermissionsMutation({
    // the user may be set as admin
    refetchQueries: [{ query: MeDocument }],
    awaitRefetchQueries: true,
  })

  useEffect(() => {
    if (organisation.orgType !== 'fortnox') {
      setPermissionError({
        error: 'not a fortnox organisation',
        hasBeenSet: true,
      })
      return
    }
    checkPermissions().then((result) => {
      if (
        result.data?.checkPermissions.error?.type ===
        JobErrorType.Permissiondenied
      ) {
        setPermissionError({
          error: result.data.checkPermissions.error.message,
          hasBeenSet: true,
        })
      } else if (
        result.data?.checkPermissions.error?.type === JobErrorType.Tokenexpired
      ) {
        setTokenExpired(true)
      } else {
        setPermissionError({ error: '', hasBeenSet: true })
      }
    })
  }, [checkPermissions, organisation.orgType])

  const errorHandler = useCallback((error: Maybe<JobError> | undefined) => {
    if (error?.type === JobErrorType.Tokenexpired) {
      setTokenExpired(true)
      return true
    }
    return false
  }, [])

  useEffect(() => {
    // When given a function as argument the state setter handle it as a function that return the state
    // so to set a function as a state we need to pass a function that return a function
    setFortnoxErrorHandler(() => errorHandler)
  }, [errorHandler, setFortnoxErrorHandler])

  const getData = useCallback(async () => {
    const response = await getAndProcessFortnoxData()
    handleJob(response.data?.getAndProcessFortnoxData as Job)
  }, [getAndProcessFortnoxData, handleJob])

  useEffect(() => {
    if (
      permissionError.hasBeenSet &&
      !permissionError.error &&
      fortnoxErrorHandler &&
      !orgHasInitializedData
    ) {
      getData()
    }
  }, [getData, orgHasInitializedData, permissionError, fortnoxErrorHandler])

  return (
    <Provider
      value={{
        tokenexpired,
        permissionError,
      }}
    >
      {children}
    </Provider>
  )
}
