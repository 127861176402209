import React, { useContext } from 'react'
import { TooltipInfo } from '../../../components/InfoIcon'
import { MaybeLoading } from '../../../components/PageLoader'
import { H3 } from '../../../components/Typography'
import { testHasData } from '../../../utils/testHasData'
import {
  Stat,
  StatContent,
  StatFooter,
  StatHeader,
  StatTitle,
} from './metaDataCardStyle'
import { ChangePercentage } from '../../../components/TrendPercentage'
import { useMetaData } from './useMetaData'
import { AppDataContext } from '../../../context/AppDataContext'

type Props = {
  rangeText: string
}

export function ChangeCard({ rangeText }: Props): React.JSX.Element {
  const { loading, error, metaData } = useMetaData()
  const { orgHasInitializedData } = useContext(AppDataContext)
  return (
    <Stat data-testid='metadata-total-change-card'>
      <StatHeader>
        <StatTitle>
          <span>Total förändring</span>
          <br />
          {rangeText}
        </StatTitle>
        <TooltipInfo info='Visar den totala förändringen i utsläpp det senaste avslutade året jämfört med föregående.' />
      </StatHeader>
      <MaybeLoading
        loading={loading}
        error={error}
        hasData={testHasData(metaData?.yearlyPercentage)}
        orgHasInitializedData={orgHasInitializedData}
      >
        <StatContent>
          <ChangePercentage
            iconSize={42}
            TextComponent={H3}
            percentage={metaData?.yearlyPercentage}
          />
        </StatContent>
      </MaybeLoading>
      {/* empty footer required for content alignment with the other cards */}
      <StatFooter />
    </Stat>
  )
}
