import React, { useContext } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import Svalna from '../../@types'
import { Body2, H4Bold } from '../Typography'
import { Flex } from '../Flex'
import { Loader } from '../Loader'
import { OrgType, useGetResultVersionQuery } from '../../graphql/generated'
import { AppDataContext } from '../../context/AppDataContext'
import { FortnoxButton } from '../fortnox/FortnoxButton'
import { JobIndicator } from '../jobs/JobIndicator'
import { spacing } from '../../theme'
import { SideColumnFlex, sideColumnMinWidthRem } from '../TableOfContent'
import {
  contentFlex,
  contentMaxWidthPx,
  contentMinWidthPx,
} from '../LayoutContent'

const Wrapper = styled(Flex)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid rgb(176, 176, 176);
  gap: ${spacing.large};
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // same as in layout so that the header content and the content of the page align
  min-width: ${sideColumnMinWidthRem}rem;
  flex: ${SideColumnFlex};
`

const LoaderContainer = styled.div`
  margin: 0 16px;
`

const MiddleContainer = styled.div`
  width: 100%;
  // same as in layout so that the header content and the content of the page align
  max-width: ${contentMaxWidthPx}px;
  min-width: ${contentMinWidthPx}px;
  flex: ${contentFlex};
`

const RightContainer = styled.div`
  position: relative;
  // same as in layout so that the header content and the content of the page align
  min-width: ${sideColumnMinWidthRem}rem;
  flex: ${SideColumnFlex};
`

const LastUpdate = styled(Body2)`
  font-size: 10px;
  /*
    On position absolute and right 0:
    The Fortnox button is supposed to align vertically with
    the filter buttons, and the "last updated" text should
    be displayed just under the button, right-aligned.
    This is accomplished by setting the container of the
    Fortnox button and this text as "relative",
    and setting these styles here.
  */
  position: absolute;
  right: 0;
  /* Make sure the "last updated" text does not split into multiple lines. */
  white-space: nowrap;
`

interface Props extends Svalna.PropWithChildren {
  title: string
  hideJobButton?: boolean
  loading?: boolean
}

export function Header({
  children,
  title,
  hideJobButton,
  loading,
}: Props): React.JSX.Element {
  const { organisation } = useContext(AppDataContext)
  const { data: resultVersionData } = useGetResultVersionQuery()

  let lastUpdateDate: string | undefined

  if (resultVersionData?.getResultVersion?.timestamp) {
    const timestamp = resultVersionData.getResultVersion.timestamp
    lastUpdateDate = dayjs.unix(timestamp).format('YYYY-MM-DD')
  }

  return (
    <Wrapper>
      {/* Title section. */}
      <TitleWrapper>
        <H4Bold>{title}</H4Bold>
        {loading && (
          <LoaderContainer>
            <Loader size={20} borderSize={5} />
          </LoaderContainer>
        )}
      </TitleWrapper>
      {/* 
        Middle section expands to fill all space between
        the left and right parts of the header.
        The content inside is left-aligned.
      */}
      <MiddleContainer>{children}</MiddleContainer>
      {/* Right section. */}
      <RightContainer>
        {!hideJobButton && (
          <>
            <Flex stretchWidth justifyEnd>
              {organisation.orgType === OrgType.Fortnox ? (
                <FortnoxButton />
              ) : (
                <JobIndicator />
              )}
            </Flex>
            {lastUpdateDate && (
              <LastUpdate>
                <span>Data senast uppdaterad</span>{' '}
                <span>{lastUpdateDate}</span>
              </LastUpdate>
            )}
          </>
        )}
      </RightContainer>
    </Wrapper>
  )
}
