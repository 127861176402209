import { OrganisationFragment, OrgUnit } from '../graphql/generated'

export const toOrgUnitMap = (orgUnits?: OrgUnit[]): Record<string, OrgUnit> => {
  if (!orgUnits) return {}

  return orgUnits.reduce<Record<string, OrgUnit>>(
    (map, unit) => ({
      ...map,
      [unit.id]: unit,
    }),
    {},
  )
}

export function findParents(
  orgUnit: OrgUnit,
  orgUnitMap: Record<string, OrgUnit | undefined>,
): OrgUnit[] {
  const parents: OrgUnit[] = []

  let currentParent = orgUnitMap[orgUnit?.parentId ?? '']

  while (currentParent !== undefined) {
    parents.push(currentParent)
    currentParent = orgUnitMap[currentParent.parentId ?? '']
  }

  return parents
}

export const findChildrenFor = (
  orgUnitId: string,
  organisation: OrganisationFragment,
): OrgUnit[] =>
  organisation.orgUnits.filter((org) => org.parentId === orgUnitId)

export const findTopLevelOrgUnits = (
  organisation: OrganisationFragment,
): OrgUnit[] => organisation.orgUnits.filter((org) => !org.parentId)
