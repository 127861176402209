import React from 'react'
import {
  GridEditCellProps,
  GridEditInputCell,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
} from '@mui/x-data-grid'
import styled from 'styled-components'
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material'

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}))

//Add a tooltip under a cell being edited if there is an error with the input
function EditInputCell(props: GridRenderEditCellParams) {
  const { error } = props

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  )
}

export function renderEditCell(
  params: GridRenderEditCellParams,
): React.JSX.Element {
  return <EditInputCell {...params} />
}

export function validateNumber(
  value: unknown,
  strictlyPositive = false,
): string | undefined {
  if (typeof value !== 'number' || value < 0) {
    return 'Detta måste vara noll, eller ett positivt tal'
  }
  if (strictlyPositive && value === 0) {
    return 'Detta måste vara ett positivt tal'
  }
  return undefined
}

export function preProcessEditNumber(
  params: GridPreProcessEditCellProps,
): GridEditCellProps & { error: string | undefined } {
  const errorMessage = validateNumber(params.props.value)
  return { ...params.props, error: errorMessage }
}

export function preProcessEditNumberStrict(
  params: GridPreProcessEditCellProps,
): GridEditCellProps & { error: string | undefined } {
  const errorMessage = validateNumber(params.props.value, true)
  return { ...params.props, error: errorMessage }
}
