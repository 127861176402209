import React, { HTMLProps } from 'react'
import type { Swiper as SwiperType } from 'swiper'
import ChevronBack from '../../assets/icons/chevron-back-outline.svg'
import ChevronForward from '../../assets/icons/chevron-forward-outline.svg'
import { Icon } from '../../components/Icon'
import { spacing } from '../../theme'

function FooterButton({
  children,
  style,
  ...props
}: Omit<HTMLProps<HTMLButtonElement>, 'type'>) {
  return (
    <button
      type='button'
      {...props}
      style={{
        display: 'flex',
        border: 'none',
        backgroundColor: 'white',
        fontSize: 16,
        marginRight: spacing.medium,
        marginLeft: spacing.medium,
        marginBottom: spacing.medium,
        ...style,
      }}
    >
      {children}
    </button>
  )
}

export enum FooterType {
  Start,
  BackForward,
  Done,
}

interface FooterArg {
  rerender: () => void
  swiper: SwiperType | null
  displayKey: string
}

export function OnboardFooter(
  type: FooterType,
): (arg: FooterArg) => React.JSX.Element | undefined {
  return ({ rerender, swiper, displayKey }: FooterArg) => {
    if (type === FooterType.Start) {
      return (
        <FooterButton
          style={{ alignSelf: 'flex-end' }}
          onClick={() => {
            if (swiper) {
              swiper.slideNext()
            }
          }}
        >
          <span>Starta intro</span>
          <Icon
            src={ChevronForward}
            style={{ width: '1em', alignSelf: 'end' }}
          />
        </FooterButton>
      )
    } else if (type === FooterType.Done) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <FooterButton
            onClick={() => {
              if (swiper) {
                swiper.slidePrev()
              }
            }}
          >
            <Icon
              src={ChevronBack}
              style={{ width: '1em', alignSelf: 'end' }}
            />
            <span>Tillbaka</span>
          </FooterButton>
          <FooterButton
            onClick={() => {
              localStorage.setItem(displayKey, 'false')
              rerender()
            }}
          >
            <span>Börja utforska!</span>
            <Icon
              src={ChevronForward}
              style={{ width: '1em', alignSelf: 'end' }}
            />
          </FooterButton>
        </div>
      )
    } else if (type === FooterType.BackForward) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <FooterButton
            onClick={() => {
              if (swiper) {
                swiper.slidePrev()
              }
            }}
            style={{ display: 'flex' }}
          >
            <Icon
              src={ChevronBack}
              style={{ width: '1em', alignSelf: 'end' }}
            />
            <span>Tillbaka</span>
          </FooterButton>
          <FooterButton
            onClick={() => {
              if (swiper) {
                swiper.slideNext()
              }
            }}
          >
            <span>Nästa</span>
            <Icon
              src={ChevronForward}
              style={{ width: '1em', alignSelf: 'end' }}
            />
          </FooterButton>
        </div>
      )
    }
  }
}
