import _ from 'lodash'
import React, { useState } from 'react'
import styled from 'styled-components'
import { VictoryAxis, VictoryChart, VictoryLine } from 'victory'
import { chartTheme } from '../../../chartTheme'
import CardTemplate from '../../../components/CardTemplate'
import { useTrendGraphData } from '../../../components/charts/trend-chart/useTrendChartData'
import { Flex } from '../../../components/Flex'
import { Loader } from '../../../components/Loader'
import { TitleWithSub } from '../../../components/TitleWithSub'
import {
  trendColor,
  TrendPercentage,
} from '../../../components/TrendPercentage'
import { Body1, Body1Bold, H4Bold, H6 } from '../../../components/Typography'
import { CO2eFormat } from '../../../constants'
import { Category, FilterInput } from '../../../graphql/generated'
import { useElementSize } from '../../../hooks/useElementSize'
import { useFilterInput } from '../../../hooks/useFilterInput'
import {
  getNumberFormatter,
  NumberFormatter,
} from '../../../utils/adaptiveNumberFormat'
import { calculateDomain } from '../../../utils/chart'
import { roundToDecimals } from '../../../utils/math'
import { testHasData } from '../../../utils/testHasData'
import { useCategoryLineCharts } from './useCategoryLineCharts'
import { colors, spacing } from '../../../theme'

const Card = styled(Flex)<{
  width?: number | string
  height?: number | string
}>`
  background-color: ${colors.white};
  color: ${colors.black};
  width: ${(props) => props.width && `${props.width}`};
  height: ${(props) => props.height && `${props.height}`};
  padding: 0;
`

const PercentageText = styled(H4Bold)`
  font-size: smaller;
  line-height: unset;
`

const ValueText = styled(H6)`
  font-size: smaller;
  line-height: unset;
`

export function CategoryLineChartCard(): React.JSX.Element {
  const [useAnnualWorkForce, setUseAnnualWorkForce] = useState(false)
  const [category, setCategory] = useState<Category | undefined>()
  const filter = useFilterInput(category, undefined, useAnnualWorkForce)

  const categoryLinesData = useCategoryLineCharts(filter)

  return (
    <CardTemplate
      titleData={{
        title: 'Trendutveckling per kategori',
        subtitle: `${CO2eFormat} / år`,
      }}
      loading={categoryLinesData.loading}
      error={categoryLinesData.error}
      hasData={testHasData(categoryLinesData.categoryTrends)}
      useAnnualWorkForce={useAnnualWorkForce}
      setUseAnnualWorkForce={setUseAnnualWorkForce}
      tooltip={
        <Body1>
          Visar utsläpp och utvecklingstrenden över tid, per kategori.
          Procentvärdet representerar den genomsnittliga årliga utvecklingen
          över alla avslutade år med tillgänglig data.
        </Body1>
      }
      selectedCategory={category}
      setCategory={setCategory}
    >
      <div style={{ padding: spacing.large }}>
        <CategoryLineCharts catLineChart={categoryLinesData} filter={filter} />
      </div>
    </CardTemplate>
  )
}

type Props = {
  catLineChart: ReturnType<typeof useCategoryLineCharts>
  filter: FilterInput
}
function CategoryLineCharts({ catLineChart, filter }: Props) {
  const { categoryTrends } = catLineChart
  // const avg = categoryTrends
  //   .map((x) => ({
  //     categoryName: x.categoryName,
  //     avg: _.meanBy(x.emissions, (y) => y.totalCO2e),
  //   }))
  //   .map((x) => {
  //     if (!Number.isFinite(x.avg)) x.avg = 0
  //     return x
  //   })

  // const formatter = getNumberFormatter(avg.map((x) => x.avg))
  const [ref, size] = useElementSize()

  const width = 320
  const height = width / 2
  const cols = Math.floor((size?.width ?? 800) / width)

  return (
    <div ref={ref}>
      <Flex doWrap stretchWidth>
        {categoryTrends
          .filter((x) => _.sumBy(x.emissions, (y) => y.totalCO2e) > 0)
          .map((c) => (
            <SubCard
              key={`line-chart-${c.categoryId}`}
              categoryData={c}
              width={width}
              height={height}
              // avg={avg}
              cols={cols}
              // formatter={formatter}
              filter={filter}
            />
          ))}
      </Flex>
    </div>
  )
}

type SubCardProps = {
  categoryData: ReturnType<typeof useCategoryLineCharts>['categoryTrends'][0]
  width: number
  height: number
  cols: number
  filter: FilterInput
}
function SubCard({ categoryData, width, height, cols, filter }: SubCardProps) {
  const { emissions, categoryId, categoryName, categoryColor, percentage } =
    categoryData

  const chartData = emissions.map((emission) => ({
    x: emission.period,
    y: emission.totalCO2e,
  }))
  // const avgCO2e =
  //   avg.filter((x) => x.categoryName === categoryName)?.[0]?.avg ?? 0

  const maxlen = 25
  const shorten = categoryName.length > maxlen
  const displayName = shorten
    ? `${categoryName.substring(0, maxlen - 3)}...`
    : categoryName

  const trendData = useTrendGraphData({ ...filter, categoryId: categoryId })
  const sorted = _.sortBy(chartData, (x) => x.x)
  const first = sorted.slice(0)[0]
  const last = sorted.slice(-1)[0]
  const formatter = getNumberFormatter(chartData.map((x) => x.y) ?? [0])

  const title = (
    <Flex row>
      <Body1Bold>{displayName}</Body1Bold>
      {!!trendData.loading && (
        <Loader
          size={14}
          borderSize={2}
          style={{ verticalAlign: 'middle', marginLeft: spacing.small }}
        />
      )}
    </Flex>
  )

  return (
    <Card
      width={`${100 / cols}%`}
      style={{ padding: '0 20px' }}
      key={`line-chart:${categoryName}`}
      column
    >
      <Flex
        row
        spaceBetween
        itemsCenter
        stretchWidth
        style={{ padding: '30px 5px 5px 20px' }}
      >
        <TitleWithSub
          title={title}
          sub=''
          titleStyle={{ fontSize: 'smaller' }}
          hoverText={shorten ? categoryName : undefined}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <TrendPercentage
            percentage={roundToDecimals(percentage, 1)}
            iconSize={25}
            TextComponent={PercentageText}
          />
          {trendData.trendRate !== undefined && (
            <ValueText>
              {`(${formatter.format(Math.abs(trendData.trendRate))})`}
            </ValueText>
          )}
        </div>
      </Flex>
      <Subchart
        percentage={percentage}
        width={width}
        height={height}
        chartData={chartData}
        categoryColor={categoryColor}
        formatter={formatter}
        first={first}
        last={last}
        trendData={trendData.trendData?.slice(0, -1)}
        trendRate={trendData.trendRate}
      />
    </Card>
  )
}

type DatumPoint = {
  x: string
  y: number
}

type SubchartProps = {
  percentage: number
  width: number
  height: number
  chartData: Array<DatumPoint>
  categoryColor: ReturnType<
    typeof useCategoryLineCharts
  >['categoryTrends'][0]['categoryColor']
  formatter: NumberFormatter
  first: DatumPoint | undefined
  last: DatumPoint | undefined
  trendData: Array<DatumPoint> | undefined
  trendRate: number | undefined
}
function Subchart({
  percentage,
  width,
  height,
  chartData,
  categoryColor,
  formatter,
  first,
  last,
  trendData,
  trendRate,
}: SubchartProps) {
  const isData = <T,>(x: T | undefined): x is T => x !== undefined
  const hasTrendData = [trendData, trendRate].every(isData)
  const minMax = [
    _.minBy(trendData, (d) => d.x)?.x,
    _.maxBy(trendData, (d) => d.x)?.x,
  ]
  return (
    <VictoryChart
      padding={{
        top: 10,
        left: 20,
        right: 20,
        bottom: 30,
      }}
      theme={chartTheme({ width, height })}
      domain={calculateDomain(chartData)}
    >
      <VictoryLine
        style={{
          data: { stroke: categoryColor, strokeWidth: 4 },
        }}
        data={chartData}
        minDomain={{
          x: 2015,
        }}
        interpolation='linear'
      />
      {/* Require data to be loaded. Also hide line when trend is extremely small */}
      {hasTrendData && Math.abs(trendRate ?? 0) > 1 && (
        <VictoryLine
          style={{
            data: {
              stroke: trendColor(percentage).color,
              strokeWidth: 0.66,
              strokeDasharray: '6, 14',
            },
          }}
          data={trendData}
          interpolation='linear'
          name='trend'
        />
      )}
      <VictoryAxis
        dependentAxis
        fixLabelOverlap
        orientation='left'
        tickFormat={() => ''}
      />
      <VictoryAxis
        fixLabelOverlap
        orientation='bottom'
        style={{
          tickLabels: {
            // Note: Victory types are unclear; it seems the datum type here contains 'text'.
            display: (d: { text: string }) =>
              minMax.includes(d.text) ? 'flex' : 'none',
          },
        }}
      />
    </VictoryChart>
  )
}
