import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { AccordionDetails, AccordionSummary } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Category, FilterInput } from '../graphql/generated'
import { useFilterInput } from '../hooks/useFilterInput'
import { colors, spacing } from '../theme'
import { unitDescription } from '../utils/adaptiveNumberFormat'
import { testHasData } from '../utils/testHasData'
import CardTemplate from './CardTemplate'
import { CategoryPie } from './charts/category-pie/CategoryPie'
import { CategoryLabels } from './charts/category-pie/CategoryPieLabels'
import {
  CategroyPieData,
  EmissionData,
  applyColor,
  selectEmissions,
  useCategoryPieData,
} from './charts/category-pie/useCategoryPieData'
import { Flex, FlexingBoxContent } from './Flex'
import { FilterContext } from '../context/FilterContext'
import { Body1 } from './Typography'
import { AppDataContext } from '../context/AppDataContext'
import { CategoryBreadCrumbs } from './charts/category-pie/CategoryBreadCrumbs'
import { CategoryInfo } from './charts/category-pie/CategoryInfo'
import { useEmissionTourContext } from '../context/EmissionTourContext'
import { StepId, emissionTourClassNames } from './tour/tourConstants'

const accordionTransitionMs = 200

const PieContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  justify-content: space-between;
`

const Accordion = styled(MuiAccordion)`
  box-shadow: none;
  &::before {
    background-color: ${colors.white};
  }
`

export function CategoryPieCard(): React.JSX.Element {
  const { defaultDatePeriod } = useContext(FilterContext)
  const [useAnnualWorkForce, setUseAnnualWorkForce] = useState(false)
  const [selectedDatePeriod, setDatePeriod] = useState(defaultDatePeriod)
  const [category, setCategory] = useState<Category>()
  const filter = useFilterInput(
    category,
    selectedDatePeriod,
    useAnnualWorkForce,
  )

  useEffect(() => {
    // This may overwrite the date set by a user, but this would only happen
    // when loading data from fortnox for the first time in the year
    setDatePeriod(defaultDatePeriod)
  }, [defaultDatePeriod])

  const pieData = useCategoryPieData(
    filter,
    selectedDatePeriod.period,
    category,
  )

  return (
    <CardTemplate
      titleData={{
        title: 'Utsläpp fördelat på kategorier',
        subtitle: unitDescription(filter),
      }}
      useAnnualWorkForce={useAnnualWorkForce}
      setUseAnnualWorkForce={setUseAnnualWorkForce}
      selectedDatePeriod={selectedDatePeriod}
      setDatePeriod={setDatePeriod}
      loading={pieData.loading}
      error={pieData.error}
      hasData={testHasData(pieData.data)}
      loadingPlaceholderHeight={404}
      tooltip={
        <Body1>
          Visar utsläpp fördelat på kategorier och underkategorier. Dubbelklicka
          för att se ytterligare detaljer - de största utsläppskällorna inom
          varje kategori, inklusive information om leverantörer och
          bokföringskonton.
        </Body1>
      }
      className={emissionTourClassNames[StepId.e2PieDiagram]}
    >
      <Contents
        pieData={pieData}
        filter={filter}
        setCategory={setCategory}
        category={category}
      />
    </CardTemplate>
  )
}

type ContentProps = {
  pieData: CategroyPieData
  filter: FilterInput
  setCategory: React.Dispatch<React.SetStateAction<Category | undefined>>
  category: Category | undefined
}

function Contents({ pieData, filter, setCategory, category }: ContentProps) {
  const { categoryMap } = useContext(AppDataContext)
  const {
    ongoing,
    tourActive,
    steps,
    stepIndex,
    pieAccordionOpen,
    setPieAccordionOpen,
    pieAccordionClosed,
    setPieAccordionClosed,
  } = useEmissionTourContext()
  const [showCategoryInfo, setShowCategoryInfo] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState<
    Category | undefined
  >()
  const [emissions, setEmissions] = useState(pieData.data)

  useEffect(() => {
    setEmissions(pieData.data)
  }, [pieData.data])

  // For the emission intro tour:
  // The <Accordion> should open if the tour is ongoing, and the
  // current step is supposed to highlight the contents.
  // To accomplish that, we need to wait for the animation to complete
  // and notify the tour when it can continue.
  // The tour has to be "not active", or the step highlight misses the target.
  // Likewise, if going back from the step where it is open,
  // notify when the accordion has been closed.
  // Ideally, this would happen in response to an event from the <Accordion>,
  // tied to when the opening/closing animation completes,
  // but this does not appear to be possible/reliable, so this effect uses
  // the same timeout as the accordion transtion, with a bit of margin.
  // Specifically, `onTransitionEnd` does not seem to always generate
  // events for the Accordion.
  useEffect(() => {
    const timerClearFns: Array<() => void> = []
    if (
      ongoing &&
      !tourActive &&
      steps[stepIndex]?.data?.id === StepId.e3PieAccordion &&
      !pieAccordionOpen
    ) {
      // Signal to tour that the accordion is open.
      const tid = setTimeout(() => {
        setPieAccordionOpen(true)
      }, accordionTransitionMs + 100)
      timerClearFns.push(() => {
        clearTimeout(tid)
      })
    }

    if (
      ongoing &&
      !tourActive &&
      steps[stepIndex]?.data?.id === StepId.e2PieDiagram &&
      !pieAccordionClosed
    ) {
      // Signal to tour that the accordion is closed.
      const tid = setTimeout(() => {
        setPieAccordionClosed(true)
      }, accordionTransitionMs + 100)
      timerClearFns.push(() => {
        clearTimeout(tid)
      })
    }

    // Cancel timers if the component re-renders.
    return () => {
      timerClearFns.forEach((f) => f())
    }
  }, [
    setPieAccordionOpen,
    steps,
    stepIndex,
    pieAccordionOpen,
    pieAccordionClosed,
    setPieAccordionClosed,
    tourActive,
    ongoing,
  ])

  const canGoDeeper = (emission?: EmissionData) => {
    // if the emission contains aggregated sub emissions or if it has childresn
    // then we can dig in to get more details
    return !!emission?.aggregated || !!emission?.category.children.length
  }

  const dig = (emission: EmissionData | undefined) => {
    if (!emission) {
      return
    }
    if (emission.aggregated) {
      const otherCategory = categoryMap['4'] as Category
      const newTotalCO2e = _.sumBy(emission.aggregated, (x) => x.totalCO2e)
      let newEmissions = selectEmissions(emission.aggregated, otherCategory)
      if (category) {
        newEmissions = applyColor(newEmissions, category)
      }
      setEmissions({
        emissions: newEmissions,
        totalCO2e: newTotalCO2e,
        history: emissions,
      })
      setSelectedCategory(undefined)
    } else {
      setCategory(emission.category)
      setSelectedCategory(undefined)
    }
  }

  const onCategoryClick = (cat: Category | undefined) => {
    if (cat) {
      setSelectedCategory(cat)
      setShowCategoryInfo(true)
    }
  }

  return (
    <>
      <FlexingBoxContent>
        <CategoryBreadCrumbs
          category={category}
          setCategory={setCategory}
          setSelectedCategory={setSelectedCategory}
          emissions={emissions}
          setEmissions={setEmissions}
          pieData={pieData}
          selectedCategory={selectedCategory}
        />
        <Flex row itemsCenter>
          <Flex column style={{ width: '50%' }}>
            <PieContainer column justifyCenter>
              <Flex
                column
                stretchWidth
                itemsCenter
                style={{ gap: spacing.large }}
              >
                <CategoryPie
                  data={emissions}
                  onCategoryClick={setSelectedCategory}
                  selectedCategory={selectedCategory}
                  canGoDeeper={canGoDeeper}
                  dig={dig}
                />
              </Flex>
            </PieContainer>
          </Flex>
          <CategoryLabels
            emissions={emissions?.emissions ?? []}
            onCategoryClick={onCategoryClick}
            selectedCategory={selectedCategory}
            filter={filter}
            canGoDeeper={canGoDeeper}
            dig={dig}
          />
        </Flex>
      </FlexingBoxContent>
      <Accordion
        expanded={
          !!showCategoryInfo ||
          (ongoing && steps[stepIndex]?.data?.id === StepId.e3PieAccordion)
        }
        onChange={() => setShowCategoryInfo((value) => !value)}
        className={emissionTourClassNames[StepId.e3PieAccordion]}
        TransitionProps={{
          // Use a specific timeout so we can have a decent idea of when
          // the accordion is fully open/closed, for the sake of the tour.
          timeout: accordionTransitionMs,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='advance'
          id='advance-header'
          style={{ flexDirection: 'row-reverse' }}
        >
          <Body1>Leverantörer och Bokföringskonton</Body1>
        </AccordionSummary>
        <AccordionDetails>
          <CategoryInfo
            category={
              // If tour is on the accordion step, act as if the first
              // category is selected.
              ongoing && steps[stepIndex]?.data?.id === StepId.e3PieAccordion
                ? emissions?.emissions?.[0]?.category
                : category
            }
            emissions={emissions}
            filter={filter}
            selectedCategory={selectedCategory}
            showCategoryInfo
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}
