import _ from 'lodash'
import { DataPoint } from '../../context/GoalContext'

type PlanningParams = {
  emissions: DataPoint[]
  goalpoints: DataPoint[]
  /**
   * A value between 0 and 1; overrides optimisation and returns the resulting candidate plan.
   * (For development purposes).
   */
  weightOverride?: number
}

/**
 * Find a future budget plan for a given set of emissions and goal curve.
 * The plan aims to minimise the difference to the budget implied by the goal curve.
 *
 * The plan, if found, begins at the last year found in `emissions` with the same emission value,
 * and ends on the last year in `goalpoints` at a lower or equal emission value.
 *
 * @returns A plan, or the empty list if no solution was found.
 */
export function getBudgetPlan({
  emissions,
  goalpoints,
  weightOverride,
}: PlanningParams): DataPoint[] {
  const maxDataYear = _.maxBy(emissions, (e) => e.x)?.x
  const maxGoalYear = _.maxBy(goalpoints, (e) => e.x)?.x
  const referenceYear = _.minBy(goalpoints, (g) => g.x)?.x
  const emissionAtCurrentYear = emissions.find((e) => e.x === maxDataYear)?.y

  if (
    !_.isNumber(maxDataYear) ||
    !_.isNumber(maxGoalYear) ||
    !_.isNumber(referenceYear) ||
    !_.isNumber(emissionAtCurrentYear)
  ) {
    return []
  }

  const sCurveMinEndYear = maxDataYear + 1
  const sCurveMaxEndYear = Math.min(
    maxGoalYear,
    Math.max(sCurveMinEndYear + 1, maxGoalYear - 4),
  )
  const sCurveEndMaxEmission = Math.min(
    goalpoints.find((g) => g.x === sCurveMaxEndYear)?.y ??
      emissionAtCurrentYear,
    emissionAtCurrentYear,
  )

  if (
    sCurveMaxEndYear < sCurveMinEndYear ||
    sCurveMaxEndYear <= maxDataYear ||
    !_.isNumber(sCurveEndMaxEmission)
  ) {
    return []
  }

  const plannerInput: Omit<PlannerInput, 'weight'> = {
    maxDataYear,
    sCurveMaxEndYear,
    sCurveEndMaxEmission,
    emissionAtCurrentYear,
    maxGoalYear,
    goalpoints,
  }

  if (_.isNumber(weightOverride)) {
    return makeplan({
      weight: weightOverride,
      ...plannerInput,
    })
  }

  return findOptimalPlan({
    ...plannerInput,
    emissions,
    referenceYear,
  })
}

/**
 * The logistic function (a/"the" sigmoid function).
 *
 * @param x
 * @param L Supremum of function values (max return value).
 * @param k Logistic growth rate.
 * @param x0 Midpoint of x values.
 * @returns Sigmoid value.
 */
export function logisticSigmoid(
  x: number,
  L: number = 1,
  k: number = 1,
  x0: number = 0,
): number {
  return L / (1 + Math.exp(-k * (x - x0)))
}

/**
 * Linear interpolation of one value.
 * @param w [0, 1] selection of interpolation.
 * @param a First value.
 * @param b Second value.
 * @returns Linear interpolation between `a` and `b` for `w` in [0, 1]
 */
export function lerp(w: number, a: number, b: number): number {
  return a + w * (b - a)
}

type TransformFn<X> = (x: X) => X

/**
 * Compose a list of functions [f1, f2, ..., fn] such that the resulting function is
 * "f1 o f2 o ... o fn".
 *
 * Note: The domain and range must be equal for all functions.
 *
 * @param fs List of functions to compose.
 * @returns The composed function.
 */
export function composeTransforms<X>(...fs: TransformFn<X>[]): TransformFn<X> {
  return fs.reduceRight(
    (inner, f) => (x) => f(inner(x)),
    (x) => x,
  )
}

/**
 * Create a sigmoid data set of x/y pairs.
 *
 * x has range `[x0, x0 + numSamples[` (open end).
 *
 * y has (approx.) range `[0, 1]`, monotonically increasing over x.
 *
 * @param numSamples Number of points in the sigmoid.
 * @param k Steepness of the sigmoid (specifically the `k` parameter for the logistic function).
 * @param middleFactor Middle point of sigmoid as a factor in range ]0, 1[ where 0 is the beginning and 1 the end.
 * @param x0 Lowest value of x in the output.
 * @returns A sigmoid-shaped data set of x/y pairs.
 */
export function makeSigmoid(
  numSamples: number,
  k: number,
  middleFactor: number,
  x0: number,
): DataPoint[] {
  return _.range(0, Math.round(numSamples + 1)).map((x) => ({
    x: x0 + x,
    y: logisticSigmoid(
      lerp(x / numSamples, -7, 7),
      1,
      k,
      lerp(middleFactor, -7, 7),
    ),
  }))
}

/**
 * Create data set of normalised sigmoid data points.
 *
 * Normalised refers to the sigmoid min/max y values being
 * exactly zero and one, respectively.
 *
 * @param numSamples Number of data points in sigmoid (evenly spaced).
 * @param k Steepness of sigmoid.
 * @param middleFactor Middle point of sigmoid.
 * @param x0 Starting `x` value in output.
 * @param inverted 'S' shape when false, horisontally mirrored S when true.
 * @returns A normalised sigmoid data set.
 */
export function normalisedSigmoid(
  numSamples: number,
  k: number,
  middleFactor: number,
  x0: number,
  inverted: boolean = false,
): DataPoint[] {
  const base = makeSigmoid(numSamples, k, middleFactor, x0)
  const sigmoidTransform = composeTransforms<DataPoint[]>(
    inverted ? invertY : (s) => s,
    // Scale values to ensure the sigmoid ends at exactly one.
    scaleToOne,
    // Make sure sigmoid starts at exactly y = zero (moves all points accordingly).
    alignToZero,
  )
  return sigmoidTransform(base)
}

//
// Various sigmoid transform helpers

const alignToZero = (xs: DataPoint[]): DataPoint[] => {
  const y0 = _.minBy(xs, 'x')?.y!
  return xs.map((p) => ({ x: p.x, y: p.y - y0 }))
}

const scaleYBy =
  (scalar: number) =>
  (xs: DataPoint[]): DataPoint[] =>
    _.map(xs, (p) => ({ ...p, y: p.y * scalar }))

const scaleToOne = (xs: DataPoint[]): DataPoint[] => {
  const yMax = _.maxBy(xs, (p) => p.y)?.y ?? 1
  return scaleYBy(1 / yMax)(xs)
}

const invertY = (xs: DataPoint[]): DataPoint[] => {
  return xs.map((p) => ({ x: p.x, y: 1 - p.y }))
}

// End of sigmoid transform helpers
//

/**
 * Generate a sigmoid-shaped data set.
 *
 * @param numPoints Number of points in the output, approx. evenly spread over `x`.
 * @param k Sigmoid steepness.
 * @param x0 Starting value for `x` in the output series.
 * @param middleFactor Middle point of sigmoid.
 * @param minY Lowest `y` value in the output.
 * @param maxY Greatest `y` value in the output.
 * @param invert When false, output has 'S'-shape, i.e. `y` increases as `x` increases,
 *              and when true it has a horisontally inverted 'S'-shape, meaning `y` decreases over `x`.
 * @returns A list of data points forming a sigmoid.
 */
export function generateSigmoid(
  numPoints: number,
  k: number,
  x0: number,
  middleFactor: number,
  minY: number,
  maxY: number,
  invert: boolean = true,
): DataPoint[] {
  const s1 = normalisedSigmoid(numPoints, k, middleFactor, x0, invert)
  // Scale sigmoid so max value will be at ceiling after moving the floor.
  const s2 = _.map(s1, (p) => ({
    ...p,
    y: p.y * (maxY - minY),
  }))
  // Move the floor.
  const s3 = _.map(s2, (p) => ({ ...p, y: p.y + minY }))

  return s3
}

type OptimiserInputs = {
  /** Number of years the sigmoid part spans. */
  span: number
  /** Steepness of sigmoid. */
  k: number
  /** Location of steepest part of sigmoid as factor (0 = start, 1 = end). */
  middleXFactor: number
  /** Minimum value where the sigmoid ends (it always begins at the current year's emission). */
  minY: number
}

type WeightMappingInput = {
  weight: number
  maxDataYear: number
  sCurveMaxEndYear: number
  sCurveEndMaxEmission: number
}
/**
 * Map a weight value to control inputs for the emission plan optimiser,
 * using additional parameters as the basis for certain control inputs.
 *
 * The weight has a range from 0 (least strict) to 1 (most strict).
 *
 * The generated control inputs from a larger weight shall always
 * generate a stricter plan than those genereted by a smaller weigtht.
 * I.e. if a given weight generates optimiser inputs which yield a solution,
 * all weights lower than that (less strict weights) also yield solutions,
 * which are less fit.
 *
 * @returns Input values for the emission plan optimiser.
 */
function mapWeightToControlArgs({
  weight,
  maxDataYear,
  sCurveMaxEndYear,
  sCurveEndMaxEmission,
}: WeightMappingInput): OptimiserInputs {
  const span = lerp(1 - weight, maxDataYear + 1, sCurveMaxEndYear) - maxDataYear
  return {
    span,
    k: lerp(weight, 0.6, 1),
    middleXFactor: lerp(weight, 0.8, 0.3),
    minY: lerp(weight, sCurveEndMaxEmission, 0),
  }
}

type PlannerInput = WeightMappingInput & {
  emissionAtCurrentYear: number
  maxGoalYear: number
  goalpoints: PlanningParams['goalpoints']
}
function makeplan({
  weight,
  maxDataYear,
  sCurveMaxEndYear,
  sCurveEndMaxEmission,
  emissionAtCurrentYear,
  maxGoalYear,
  goalpoints,
}: PlannerInput): DataPoint[] {
  const { k, middleXFactor, minY, span } = mapWeightToControlArgs({
    weight,
    sCurveMaxEndYear,
    sCurveEndMaxEmission,
    maxDataYear,
  })
  // Generate a sigmoid-shaped partial plan which starts at the current data year,
  // and ends at some point prior to the final goal year (exact year depends on control paraameter).
  const sigmoidPlanPart = generateSigmoid(
    span,
    k,
    maxDataYear,
    middleXFactor,
    minY,
    emissionAtCurrentYear,
    true,
  )

  // Continue sigmoid as a straight line until the goal line is intersected,
  // then follow the goal line until it ends.
  const sigmoidEnd = _.maxBy(sigmoidPlanPart, (p) => p.x)
  if (!sigmoidEnd) {
    return sigmoidPlanPart
  }
  const planTail: DataPoint[] = _.range(sigmoidEnd.x + 1, maxGoalYear + 1).map(
    (x) => {
      const gp = goalpoints.find((p) => p.x === x)
      let y = sigmoidEnd.y
      // If a goal point exist for this year, do not go over it.
      if (gp && _.isNumber(gp.y)) {
        y = Math.min(y, gp.y)
      }
      return { x, y }
    },
  )

  return sigmoidPlanPart.concat(planTail)
}

type ErrorCalcInput = {
  plan: DataPoint[]
  emissions: PlanningParams['emissions']
  goalpoints: PlanningParams['goalpoints']
  referenceYear: number
  maxDataYear: number
  maxGoalYear: number
}
/**
 * Compute the numerical error for a given emission plan relative a given goal curve
 * and historical emissions.
 *
 * If error <= 0 the given plan is a solution,
 * i.e. the sum of the plan emissions, plus the historical emissions
 * occuring during the goal years, are less than or equal to the sum of the goal emissions.
 *
 * @returns The plan error (difference between plan and goal emissions)
 */
export function getPlanError({
  plan,
  emissions,
  goalpoints,
  referenceYear,
  maxDataYear,
  maxGoalYear,
}: ErrorCalcInput): number {
  const emissionsBeforePlanStart = _.sumBy(
    emissions.filter((e) => _.inRange(e.x, referenceYear, maxDataYear)),
    (e) => e.y,
  )
  const planSum = emissionsBeforePlanStart + _.sumBy(plan, (pn) => pn.y)
  const goalCurveSum = _.sumBy(
    goalpoints.filter((g) => g.x >= referenceYear && g.x <= maxGoalYear),
    (g) => g.y,
  )
  return planSum - goalCurveSum
}

type PlanOptimiserInput = Omit<PlannerInput, 'weight'> & {
  emissions: PlanningParams['emissions']
  referenceYear: number
}
/**
 * Generate an emission plan for future years (data points of planned emissions over years),
 * such that the sum of the plan plus historical emissions are at most
 * equal to the sum of emissions given by a goal curve (data points of emissions over years),
 * optimising for making the difference between their emission sums are as small as possible.
 *
 * @returns
 */
function findOptimalPlan({
  referenceYear,
  emissions,
  ...plannerInputs
}: PlanOptimiserInput): DataPoint[] {
  let bestWeight: number | undefined
  let bestError: number | undefined
  let minWeight = 0
  let maxWeight = 1
  let lastW: number | undefined
  let n = 0 // Loop guard, in case the control args do not converge over weight.
  let stop = false
  // Generate plans using a weight half-way between the min and max weight values,
  // updating min and max to halve the search space until the current candidate plan
  // has (nearly) no difference from the previous weight's plan.
  while (!stop && n < 40) {
    ++n
    const weight = lerp(0.5, minWeight, maxWeight)
    const candidate = makeplan({
      ...plannerInputs,
      weight,
    })
    const error = getPlanError({
      ...plannerInputs,
      emissions,
      referenceYear,
      plan: candidate,
    })

    if (error <= 0) {
      // A solution, but possibly too strict.
      maxWeight = weight
      // Track best solution.
      if (!_.isNumber(bestError) || Math.abs(error) < Math.abs(bestError)) {
        bestWeight = weight
        bestError = error
      }
    } else {
      // Too lenient plan
      minWeight = weight
    }

    if (_.isNumber(lastW) && Math.abs(weight - lastW) < 1e-7) {
      stop = true
    }
    lastW = weight
  }
  const hasSolution = _.isNumber(bestError) && bestError <= 0
  if (bestWeight && hasSolution) {
    return makeplan({
      ...plannerInputs,
      weight: bestWeight,
    })
  }
  return []
}
