import { colors, theme } from './theme'

const {
  blue,
  deepGreen,
  goldenYellow,
  teal,
  brightBlue,
  red,
  orange,
  deepGreen50,
  bronze,
  pink,
} = colors

export const colorScale = [
  pink,
  blue,
  orange,
  teal,
  deepGreen50,
  goldenYellow,
  brightBlue,
  red,
  bronze,
  deepGreen,
]

const charcoal = '#252525'
const grey = '#969696'

// Typography
const font = theme.typography.fontFamily
const letterSpacing = 'normal'
const fontSize = 14

// Layout
const baseProps = {
  width: 450,
  height: 300,
  padding: 50,
  colorScale,
}

// Labels
const baseLabelStyles = {
  fontFamily: font,
  letterSpacing,
  fontSize,
  padding: 10,
  fill: charcoal,
}

const flyoutStyles = {
  stroke: charcoal,
  strokeWidth: 1,
  fill: '#FFFFFF',
  pointerEvents: 'none',
}

const centeredLabelStyles = { ...baseLabelStyles, textAnchor: 'middle' }

// Strokes
const strokeLinecap = 'round'
const strokeLinejoin = 'round'

// Put it all together...
export const chartTheme: any = (props: Partial<typeof baseProps> = {}) => {
  return {
    area: {
      ...props,
      style: {
        data: {
          fill: charcoal,
        },
        labels: baseLabelStyles,
      },
    },
    axis: {
      ...props,
      style: {
        axis: {
          fill: 'transparent',
          stroke: charcoal,
          strokeWidth: 1,
          strokeLinecap,
          strokeLinejoin,
        },
        axisLabel: {
          ...centeredLabelStyles,
          padding: 25,
          fontSize: 8,
        },
        grid: {
          fill: 'none',
          stroke: 'none',
          pointerEvents: 'painted',
        },
        ticks: {
          fill: 'transparent',
          size: 1,
          stroke: 'transparent',
        },
        tickLabels: baseLabelStyles,
      },
    },
    bar: {
      ...props,
      style: {
        data: {
          fill: charcoal,
          padding: 8,
          strokeWidth: 0,
        },
        labels: baseLabelStyles,
      },
    },
    boxplot: {
      ...props,
      style: {
        max: { padding: 8, stroke: charcoal, strokeWidth: 1 },
        maxLabels: { ...baseLabelStyles, padding: 3 },
        median: { padding: 8, stroke: charcoal, strokeWidth: 1 },
        medianLabels: { ...baseLabelStyles, padding: 3 },
        min: { padding: 8, stroke: charcoal, strokeWidth: 1 },
        minLabels: { ...baseLabelStyles, padding: 3 },
        q1: { padding: 8, fill: grey },
        q1Labels: { ...baseLabelStyles, padding: 3 },
        q3: { padding: 8, fill: grey },
        q3Labels: { ...baseLabelStyles, padding: 3 },
      },
      boxWidth: 20,
    },
    candlestick: {
      style: {
        ...props,
        data: {
          stroke: charcoal,
          strokeWidth: 1,
        },
        labels: { ...baseLabelStyles, padding: 5 },
      },
      candleColors: {
        positive: '#ffffff',
        negative: charcoal,
      },
    },
    chart: props,
    errorbar: {
      ...props,
      borderWidth: 8,
      style: {
        data: {
          fill: 'transparent',
          stroke: charcoal,
          strokeWidth: 2,
        },
        labels: baseLabelStyles,
      },
    },
    group: {
      ...props,
      colorScale,
    },
    histogram: {
      ...props,
      style: {
        data: {
          fill: grey,
          stroke: charcoal,
          strokeWidth: 2,
        },
        labels: baseLabelStyles,
      },
    },
    legend: {
      colorScale,
      gutter: 10,
      orientation: 'vertical',
      titleOrientation: 'top',
      style: {
        data: {
          type: 'circle',
        },
        labels: baseLabelStyles,
        title: { ...baseLabelStyles, padding: 5 },
      },
    },
    line: {
      ...props,
      style: {
        data: {
          fill: 'transparent',
          stroke: charcoal,
          strokeWidth: 1,
        },
        labels: baseLabelStyles,
      },
    },
    pie: {
      style: {
        data: {
          padding: 10,
          stroke: 'transparent',
          strokeWidth: 1,
        },
        labels: { ...baseLabelStyles, padding: 15 },
      },
      colorScale,
      width: 300,
      height: 300,
      padding: 32,
    },
    scatter: {
      ...props,
      style: {
        data: {
          fill: charcoal,
          stroke: 'transparent',
          strokeWidth: 0,
        },
        labels: baseLabelStyles,
      },
    },
    stack: {
      ...props,
      colorScale,
    },
    tooltip: {
      style: {
        ...baseLabelStyles,
        fontSize: 14,
        padding: 0,
        pointerEvents: 'none',
      },
      flyoutStyle: {
        ...flyoutStyles,
      },
      flyoutPadding: 10,
      cornerRadius: 5,
      pointerLength: 10,
    },
    voronoi: {
      ...props,
      style: {
        data: {
          fill: 'transparent',
          stroke: 'transparent',
          strokeWidth: 0,
        },
        labels: {
          ...baseLabelStyles,
          padding: 5,
          pointerEvents: 'none',
        },
        flyout: {
          ...flyoutStyles,
        },
      },
    },
  }
}

export const defaultChartTheme = chartTheme(baseProps)
