import type { NumericDictionary } from 'lodash'
import _ from 'lodash'
import { getGaps, getRanges, OpenEndedRange } from '../../utils/sequence'

/**
 * Interpolate the gaps of a given sequence of objects with respect to a given key name.
 *
 * The given key name shall be a numeric field which is present in every object in the given sequence.
 * The key shall be an integer, and must be unique for every object in the sequence.
 *
 * The returned sequence will contain new objects such that the sequence of keys is continuous,
 * and will have a range from smallest to greatest keys found in the input sequence.
 *
 * @param xs A list of objects.
 * @param keyName The field name to use as a key when interpolating, must hold a number.
 * @param fillFn A generator function which will be called for each missing key in each gap.
 *              It shall generate an object to fill a given element gap in the sequence.
 *              It receives the following parameters:
 *              - `key` the current key for one of the missing elements in this gap
 *              - `index` the index of this gap element in the current gap
 *              - `gapLength` the length of the current gap
 *              - `preGap` the element directly preceding this gap
 *              - `postGap` the element directly following this gap
 * @returns An object containing the original sequence of objects with interpolated objects filling the gaps,
 *          as well as lists of the ranges and gaps in the given sequence of keys.
 */
export function interpolateSequence<
  T extends { [x in K]: number },
  K extends keyof T,
>(
  xs: T[],
  keyName: K,
  fillFn: (
    key: T[K],
    index: number,
    gapLength: number,
    preGap: T,
    postGap: T,
  ) => T,
): {
  filledSequence: T[]
  ranges: OpenEndedRange[]
  gaps: OpenEndedRange[]
} {
  if (!xs.length) {
    return {
      filledSequence: [],
      ranges: [],
      gaps: [],
    }
  }
  const keyLookupTable: NumericDictionary<T> = {}
  const unsortedKeys: Array<T[K]> = []
  xs.forEach((obj) => {
    const key = obj[keyName]
    keyLookupTable[key] = obj
    unsortedKeys.push(key)
  })
  // Note: The keys must be a sorted sequence of numbers for `getGaps` and `getRanges`.
  const keys = _.sortBy(unsortedKeys)

  // Get all gaps and use `fillFn` to fill them.
  const keyGaps = getGaps(keys)
  const filledGaps = _.flatMap(keyGaps, (gap) =>
    _.range(gap.start, gap.openEnd).map((key) => {
      const k = key as T[K] // Hackish cast: Key type "T[K]" must be number; silence linter warning.
      const before = keyLookupTable[gap.start - 1]
      const after = keyLookupTable[gap.openEnd]
      if (before === undefined || after === undefined) {
        // This should not throw if the `getGaps` function is correctly implemented;
        // There must be an element with the key preceding the gap, and another element directly following the gap,
        // since that is the definition of a gap for that function.
        throw Error('Could not find pre/post gap elements')
      }
      return fillFn(k, key - gap.start, gap.openEnd - gap.start, before, after)
    }),
  )

  return {
    filledSequence: xs.concat(filledGaps),
    ranges: getRanges(keys),
    gaps: keyGaps,
  }
}

type SvgStopData = {
  /** The given stop:s offset, as a fraction. The range is [0, 1] (inclusive). */
  offset: number
  /**
   * Wheter this stop represents actual data ('data') or a gap in the dat asequence ('gap').
   */
  kind: 'data' | 'gap'
  /**
   * A unique key, which can be used to sort a list of stops
   * from the same call to `makeSvgStopData` in such a way that
   * they can be processed in that sorted order to generate
   * the expected SVG <stop>:s for segments of 'data' and 'gap'.
   * */
  key: number
}

/**
 * From a non-overlapping list of ranges representing values for which data is defined, and where
 * gaps between ranges indicate gaps in data, this function gives a list of "stop":s
 * suitable for use when generating SVG <stop> elements when defining an SVG <linearGradient>.
 *
 * The output is only well-defined if the ranges DO NOT overlap.
 *
 * Note that for stops beside the first and last, two stops with the same offset are produced:
 * this is necessary for the <linearGradient> to behave correctly, as the values would otherwise
 * become interpolated if two adjacent sections were given diffrent colours.
 *
 * @example
 * Expected output for the sequence of open-end ranges { [1,2], [3,4] } is illustrated by the following:
 * Legend:
 *  * Numbers at the bottom are the range limits
 *  * Letters at top indicate offsets
 *  * Vertical lines are guidelines for the ranges/values
 *  * Underscores over guidelines display the ranges, lack of underscore indicates a gap
 *  * Dots indicate "half-ranges" outside the ranges at each end --
 *    in this example, (1) and (3) will only be half the width of (2),
 *    as half of their widths fall outside what will be displayed in a graph,
 *    i.e. the graph begins centered on (1) and ends centered on (3)
 *
 *    a  b     c  d
 * ...____     ____...
 * .  |  |  |  |  |  .
 *    1     2     3
 *
 * Note that there are three total segments to be coloured:
 *    1) [a, b] has data
 *    2) ]b, c[ has no data
 *    3) [c, d] has data
 * Also note that there are four total regions of interest:
 * Two uniform and equally-sized regions around each number, minus the two
 * regions which fall outside the range at each end.
 *
 * This gives the offsets:
 *  a = 0
 *  b = 0.25  ==  1 / 4   (four regions, right edge of the first region)
 *  c = 0.75  ==  3 / 4   (four regions, left edge of the final region)
 *  d = 1
 *
 * And the stops would be (in order of output):
 *  ( 0,    data )
 *  ( 0.25, data )
 *  ( 0.25, gap  )
 *  ( 0.75, gap  )
 *  ( 0.75, data )
 *  ( 0,    data )
 *
 * @param ranges A list of non-overlapping ranges where data exists.
 * @returns A sorted list of stop-definitions suitable for generating SVG <stop>:s from.
 */
export function makeSvgStopData(
  ranges: readonly OpenEndedRange[],
): SvgStopData[] {
  // Sort ranges by start, to ensure output "stop":s will be in the expected order:
  // "0%" is at the lowest value, "100%" at the greatest.
  const sortedRanges = _.sortBy(ranges, (range) => range.start)

  const start = sortedRanges[0]?.start
  const end = sortedRanges[sortedRanges.length - 1]?.openEnd
  if (!_.isNumber(start) || !_.isNumber(end)) {
    // Should only happen if input is empty.
    // These checks are also made to make the type checker accept
    // 'start' and 'end' as numbers going forward.
    return []
  }

  // Length of sequence, taking into account it may start/end at a negative value.
  // Note that this is normally diffrent from the number of ranges (`ranges.length`).
  const len = Math.max(start, end) - Math.min(start, end)
  // Refer to "@example" in JSDoc for an explanation on areas of interest in the sequence, including "regions":
  // 'w' is the fractional width of the regions (there are "len - 1" regions total --
  // where 'len' is defined as the smallest start to the greatest open end)
  const w = 1 / (len - 1 || 1)

  // Key tally used to give each stop a unique key.
  let keyInt = 0

  // The first "stop" is always at "0%" and by definition of kind 'data'.
  const rangeStops: SvgStopData[] = [{ offset: 0, kind: 'data', key: keyInt++ }]

  let index = 0
  while (index < sortedRanges.length) {
    const range = sortedRanges[index]

    // Fractional positions of offsets: note that each offset, besides the first/last at "0%" and "100%",
    // are always half-way between numbers, hence "- 0.5".
    //
    // Last gap ends here (except first range, which has no gap), and this range begins here.
    const startOffset = range.start - start - 0.5
    // Next gap begins here (except last range, which has no gap), and this range ends here.
    const endOffset = range.openEnd - start - 0.5

    const startW = startOffset * w
    const endW = endOffset * w

    // Only include "starts" for elements beyond the first element.
    if (index > 0 && startW > 0) {
      const starts: SvgStopData[] = [
        // End previous gap
        { offset: startW, kind: 'gap', key: keyInt++ },
        // Start current data
        { offset: startW, kind: 'data', key: keyInt++ },
      ]
      rangeStops.push(...starts)
    }
    // Only include "ends" for elements preceding the last element.
    if (index < sortedRanges.length - 1 && endW < 1) {
      const ends: SvgStopData[] = [
        // End current data
        { offset: endW, kind: 'data', key: keyInt++ },
        // Start next gap
        { offset: endW, kind: 'gap', key: keyInt++ },
      ]
      rangeStops.push(...ends)
    }
    ++index
  }

  // Add the final stop at "100%", which is by definition of kind 'data'.
  return rangeStops.concat({ offset: 1, kind: 'data', key: keyInt })
}
