import React from 'react'
import { GeneralStep } from './GeneralStep'
import { VehiclesStep } from './VehiclesStep'
import { PremisesStep } from './PremisesStep'
import { EndStep } from './EndStep'
import { StepperPrpos } from './ProfileStepper'

export function ProfileStep({
  activeStep,
  setSnackbar,
  editEnabled,
  setLoading,
  setSaveAndClose,
  setSaveAndContinue,
  forward,
  close,
  setReset,
}: StepperPrpos): React.JSX.Element {
  switch (activeStep) {
    case 0:
      return (
        <GeneralStep
          setSnackbar={setSnackbar}
          editEnabled={editEnabled}
          setLoading={setLoading}
          setSaveAndClose={setSaveAndClose}
          setSaveAndContinue={setSaveAndContinue}
          close={close}
          forward={forward}
          setReset={setReset}
        />
      )
    case 1:
      return (
        <VehiclesStep
          setSnackbar={setSnackbar}
          editEnabled={editEnabled}
          setLoading={setLoading}
          setSaveAndClose={setSaveAndClose}
          setSaveAndContinue={setSaveAndContinue}
          close={close}
          forward={forward}
          setReset={setReset}
        />
      )
    case 2:
      return (
        <PremisesStep
          setSnackbar={setSnackbar}
          editEnabled={editEnabled}
          setLoading={setLoading}
          setSaveAndClose={setSaveAndClose}
          setSaveAndContinue={setSaveAndContinue}
          close={close}
          forward={forward}
          setReset={setReset}
        />
      )
    case 3:
      return <EndStep />
    default:
      throw new Error(`Unknown step ${activeStep}`)
  }
}
