import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import { FortnoxAddAccountantForm } from './FortnoxAddAccountantForm'
import { FortnoxContext } from '../../../context/FortnoxContext'
import { AppDataContext } from '../../../context/AppDataContext'
import { OrgType } from '../../../graphql/generated'

export function FortnoxAddAccountantPopup(): React.JSX.Element {
  const { permissionError } = React.useContext(FortnoxContext)
  const { organisation, orgHasInitializedData } =
    React.useContext(AppDataContext)

  const [showAccountantForm, setShowAccountantFrom] = React.useState(false)

  React.useEffect(() => {
    if (
      organisation.orgType === OrgType.Fortnox &&
      !orgHasInitializedData &&
      permissionError?.hasBeenSet &&
      permissionError.error
    ) {
      setShowAccountantFrom(true)
    }
  }, [orgHasInitializedData, organisation.orgType, permissionError])

  return (
    <Dialog
      open={showAccountantForm}
      onClose={() => {
        setShowAccountantFrom(false)
      }}
    >
      <DialogTitle data-testid='add-accountant-popup-title'>
        Vi saknar godkännde från er för att hämta data från Fortnox
      </DialogTitle>
      <DialogContent>
        <FortnoxAddAccountantForm
          onSuccess={() => setShowAccountantFrom(false)}
        />
      </DialogContent>
    </Dialog>
  )
}
