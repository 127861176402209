import React from 'react'
import { FortnoxLoginProvider } from './fortnox/FortnoxLoginContext'
import { FortnoxPage } from './fortnox/FortnoxPage'

export function Fortnox(): React.JSX.Element {
  return (
    <FortnoxLoginProvider>
      <FortnoxPage />
    </FortnoxLoginProvider>
  )
}
