import React from 'react'
import { AlertProps } from '@mui/material'
import { Flex } from '../../components/Flex'
import { Box } from '../../components/Box'
import { WelcomeStep } from './ProfileSteps/WelcomeStep'
import { ProfileStepper } from './ProfileSteps/ProfileStepper'
import { Layout } from '../../components/Layout'
import { FortnoxAddAccountantPopup } from '../dashboard/noDataViewComponents/FortnoxAddAccountantPopup'
import { ProfileBoxContent } from './ProfileSteps/styles'
import { SnackAlert } from '../../components/SnackAlert'

export function ProfileCreationPage(): React.JSX.Element {
  const [showWelcome, setShowWelcome] = React.useState(true)
  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)

  const handleCloseSnackbar = () => setSnackbar(null)

  return (
    <Layout title='Klimatprofilen' hideJobButton>
      <FortnoxAddAccountantPopup />
      <Flex column itemsCenter data-testid='profile-creation'>
        <Box style={{ maxWidth: '820px' }}>
          <ProfileBoxContent>
            {showWelcome ? (
              <WelcomeStep setShowWelcome={setShowWelcome} />
            ) : (
              <ProfileStepper setSnackbar={setSnackbar} isProfileCreation />
            )}
          </ProfileBoxContent>
        </Box>

        <SnackAlert
          open={!!snackbar}
          onClose={handleCloseSnackbar}
          {...snackbar}
        />
      </Flex>
    </Layout>
  )
}
