import React, { useContext, useEffect, useState } from 'react'
import { AppDataContext } from '../context/AppDataContext'
import { useElementSize } from '../hooks/useElementSize'
import { useFilterInput } from '../hooks/useFilterInput'
import { unitDescription } from '../utils/adaptiveNumberFormat'
import { testHasData } from '../utils/testHasData'
import CardTemplate from './CardTemplate'
import { CompareChart, useGetCompareChartData } from './charts/CompareChart'
import { Category } from '../graphql/generated'
import { FilterContext } from '../context/FilterContext'
import { Body1 } from './Typography'

export function CompareChartCard(): React.JSX.Element | null {
  const { organisation } = useContext(AppDataContext)
  const { defaultDatePeriod } = useContext(FilterContext)
  const [useAnnualWorkForce] = useState(organisation.useAnnualWorkforce)
  const [ref, size] = useElementSize()
  const [category, setCategory] = useState<Category | undefined>()
  const [selectedDatePeriod, setDatePeriod] = useState(defaultDatePeriod)
  const filter = useFilterInput(
    category,
    selectedDatePeriod,
    useAnnualWorkForce,
  )

  useEffect(() => {
    // This may overwrite the date set by a user, but this would only happen
    // when loading data from fortnox for the first time in the year
    setDatePeriod(defaultDatePeriod)
  }, [defaultDatePeriod])

  const compareChart = useGetCompareChartData(filter)

  if (!organisation.useAnnualWorkforce) {
    return null
  }

  return (
    <CardTemplate
      titleData={{
        title: filter.orgUnitId
          ? 'Jämförelse med medelvärde inom organisationen'
          : 'Jämförelse med liknande organisationer',
        subtitle: unitDescription(filter),
      }}
      loading={compareChart.loading}
      error={compareChart.error}
      hasData={testHasData(compareChart.chartData)}
      useAnnualWorkForce
      tooltip={
        <Body1>
          Visar jämförelser i utsläpp mellan den valda delen av organisationen
          och relevanta avdelningar/organisationer.
        </Body1>
      }
      selectedCategory={category}
      setCategory={setCategory}
      selectedDatePeriod={selectedDatePeriod}
      setDatePeriod={setDatePeriod}
    >
      <div ref={ref}>
        <CompareChart
          {...compareChart}
          width={size?.width ?? 600}
          height={(size?.width ?? 600) / 5}
          filter={filter}
        />
      </div>
    </CardTemplate>
  )
}
