import React, { ReactNode, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as Sentry from '@sentry/react'
import { ArrowForward } from '@mui/icons-material'
import { Layout } from '../../components/Layout'
import { LayoutContent } from '../../components/LayoutContent'
import { Body1, H4Bold, H5Bold, H6Bold } from '../../components/Typography'
import { LOCALSTORAGE_KEY_SHOW_EMIS_TOUR_NO_DELAY } from '../../components/tour/tourConstants'
import { LOCALSTORAGE_KEY_SHOW_RECAT_INTRO } from '../../components/onboard/RecatOnboardSnack'
import { Flex, InnerBoxText, InnerInnerBox } from '../../components/Flex'
import { SideColumn } from '../../components/TableOfContent'
import { ButtonLightBorder } from '../../components/Buttons'
import { spacing } from '../../theme'
import {
  Feature,
  Role,
  TourId,
  useMeQuery,
  useUpdateUserTourMutation,
} from '../../graphql/generated'
import { AppDataContext } from '../../context/AppDataContext'
import { SnackAlert } from '../../components/SnackAlert'

// Disable "useless fragment" since each "answer" in `FaqTexts` below must be a (string or) single ReactNode, which means
// the framgents are necessary when grouping multiple paragraphs, but not for a single paragraph.
// So, to prevent having to alternate between defining a fragment or not when changing the number of paragraphs,
// the rule is disabled for this file.
/* eslint-disable react/jsx-no-useless-fragment */

// TODO: Move FAQ texts and categories to a database.

// TODO: Refactor this component into something less messy.

// TODO: Accessibility, such as tab navigation.
// Eg: https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/dialog-modal/js/dialog.js

const MailtoLink = styled('a')`
  display: flex;
  color: black;
  text-decoration-color: black;
`

const ReportLink = styled.a`
  display: flex;
  color: black;
  text-decoration-color: black;
`

const Foo = styled('div')`
  width: 100%;
`

const LinkList = styled('ul')`
  list-style: none;
  padding-left: 0;
  margin: 0;
`

const LinkListItem = styled('li')`
  &:not(:first-child) {
    padding-top: ${spacing.small};
  }
`

enum Subject {
  Computation,
  Usage,
}

export function HelpPage(): React.JSX.Element {
  const history = useHistory()
  const [startTourErrorMsg, setStartTourErrorMsg] = useState<
    string | undefined
  >()
  const [updateUserTourMutation] = useUpdateUserTourMutation()
  const { organisation } = useContext(AppDataContext)
  const { data } = useMeQuery()
  const user = data?.me

  const showRecatIntroButton =
    organisation.profileCreated &&
    organisation.enabledFeatures?.features?.includes(Feature.Transactions) &&
    user?.role &&
    [Role.SysAdmin, Role.Admin].includes(user.role)

  return (
    <>
      <SnackAlert
        open={!!startTourErrorMsg?.length}
        severity='warning'
        onClose={() => setStartTourErrorMsg(undefined)}
      >
        <Flex style={{ gap: spacing.medium }}>
          <Body1>{startTourErrorMsg}</Body1>
        </Flex>
      </SnackAlert>

      <Layout title='Hjälp'>
        <Flex
          row
          stretchWidth
          style={{ paddingTop: spacing.large, gap: spacing.large }}
        >
          <SideColumn style={{ gap: spacing.xl, minWidth: '10rem' }}>
            <InnerInnerBox column>
              <H6Bold>Rundturer</H6Bold>
              <InnerBoxText column>
                <ButtonLightBorder
                  endIcon={<ArrowForward />}
                  onClick={() => {
                    // Set flag in local storage to indicate that the tour should
                    // be started without any timer-based delay.
                    localStorage.setItem(
                      LOCALSTORAGE_KEY_SHOW_EMIS_TOUR_NO_DELAY,
                      'true',
                    )
                    updateUserTourMutation({
                      variables: {
                        tourID: TourId.EmissionTour,
                        seen: false,
                      },
                    })
                      .then(() => {
                        // The mutation updates the local cache, when it has completed
                        // the emissions page will display the tour again.
                        history.push('/emission')
                      })
                      .catch((e) => {
                        setStartTourErrorMsg(
                          'Kunde inte starta rundturen för Utsläpp',
                        )
                        Sentry.captureException(e)
                      })
                  }}
                >
                  Utsläpp
                </ButtonLightBorder>
                {/* Only display if recat is available to this user */}
                {showRecatIntroButton && (
                  <ButtonLightBorder
                    endIcon={<ArrowForward />}
                    onClick={() => {
                      localStorage.setItem(
                        LOCALSTORAGE_KEY_SHOW_RECAT_INTRO,
                        'true',
                      )
                      history.push('/recat')
                    }}
                  >
                    Transaktioner
                  </ButtonLightBorder>
                )}
              </InnerBoxText>
            </InnerInnerBox>

            <Flex column style={{ gap: spacing.large }}>
              <H6Bold>Kontakt</H6Bold>
              <LinkList>
                <LinkListItem>
                  <MailtoLink href='mailto:support.cis@svalna.se'>
                    support.cis@svalna.se
                  </MailtoLink>
                </LinkListItem>
                <LinkListItem>
                  <MailtoLink href='mailto:info@svalna.se'>
                    info@svalna.se
                  </MailtoLink>
                </LinkListItem>
              </LinkList>
            </Flex>
            <Flex column style={{ gap: spacing.large }}>
              <H6Bold>Läs mer</H6Bold>
              <LinkList>
                <LinkListItem>
                  <ReportLink href='https://www.svalna.se/sites/default/files/Svalna%20metodrapport%20v%201.0.pdf'>
                    Metodrapport
                  </ReportLink>
                </LinkListItem>
                <LinkListItem>
                  <ReportLink href='/user-agreement' target='_blank'>
                    Användarvillkor
                  </ReportLink>
                </LinkListItem>
                <LinkListItem>
                  <ReportLink href='/privacy-policy' target='_blank'>
                    Integritetspolicy
                  </ReportLink>
                </LinkListItem>
                <LinkListItem>
                  <ReportLink href='https://www.svalna.se'>
                    Svalna.se
                  </ReportLink>
                </LinkListItem>
              </LinkList>
            </Flex>
          </SideColumn>
          <LayoutContent>
            <FAQModal />
          </LayoutContent>
          {/* Empty ToC to have even spacing on the left/right side of main content. */}
          <SideColumn style={{ minWidth: '10rem' }} />
        </Flex>
      </Layout>
    </>
  )
}

export function FAQModal(): React.JSX.Element {
  return (
    <Flex column style={{ gap: spacing.xl }}>
      <H4Bold>Frågor och svar</H4Bold>
      {/* TODO: Generate these from a database. */}
      <Flex column>
        <H5Bold paragraph>Innehåll</H5Bold>
        <Body1>Beräkningsmetodik</Body1>
        <Body1>Användning</Body1>
      </Flex>
      <Foo>
        <H5Bold paragraph>Beräkningsmetodik</H5Bold>
        {FaqTexts[Subject.Computation].map(({ question, answer }) => (
          <FaqItem
            header={question}
            text={answer}
            key={question?.toLocaleString()}
          />
        ))}
      </Foo>
      <Foo>
        <H5Bold paragraph>Användning</H5Bold>
        {FaqTexts[Subject.Usage].map(({ question, answer }) => (
          <FaqItem
            header={question}
            text={answer}
            key={question?.toLocaleString()}
          />
        ))}
      </Foo>
    </Flex>
  )
}

function FaqItem({
  header,
  text,
}: {
  header: string | ReactNode
  text: string | ReactNode
}) {
  const [showText, setShowText] = React.useState(false)
  return (
    <div
      style={{
        marginLeft: spacing.small,
        marginRight: spacing.small,
        marginTop: spacing.small,
        borderRadius: 8,
        padding: spacing.small,
        backgroundColor: 'white',
      }}
    >
      <button
        type='button'
        onClick={() => setShowText(!showText)}
        style={{
          width: '100%',
          border: 0,
          backgroundColor: 'transparent',
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 18,
            fontWeight: 'bold',
          }}
        >
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            {header}
          </span>
          {/* TODO: Proper icon '+' */}
          <span>{showText ? '-' : '+'}</span>
        </div>
      </button>
      <div
        style={{
          padding: spacing.small,
          display: showText ? 'block' : 'none',
        }}
      >
        {showText && text}
      </div>
    </div>
  )
}

const FaqTexts: {
  [key in Subject]: {
    question: string | ReactNode
    answer: string | ReactNode
  }[]
} = {
  [Subject.Computation]: [
    {
      question: `Hur har beräkningarna gjorts?`,
      answer: (
        <>
          <Body1>
            Grundprincipen är att utsläppen beräknas genom att multiplicera
            inköpsbeloppet i SEK med utsläppsintensiteten för motsvarande
            inköpskategori. Utsläppsintensiteterna som Svalna använder för att
            beräkna utsläppen grundar sig på Statistiska Centralbyråns
            företagsregister, miljöräkenskaper och SNI-systemet för svensk
            näringslivsindelning. För att beräkna utsläppen har vi först
            kategoriserat alla köp i syfte att matcha dem med en lämplig
            utsläppsintensitet.
          </Body1>
          {/*
                  TODO: Link the methology report.
                  <p>
                      Vill du veta mer om hur beräkningarna har gjorts? <a href=''>Här</a> finns en rapport som redogör för metoden i mer detalj.
                  </p>
                  */}
        </>
      ),
    },

    {
      question: `Vilken data har använts för att beräkna klimatpåverkan?`,
      answer: (
        <>
          <Body1 paragraph>
            Det beror på vad din organisation och Svalna har kommit överens om,
            och vilka data som har funnits tillgängliga inom din organisation. I
            huvudsak bygger beräkningsmetoden på att använda information från
            bokföringen för att beräkna utsläppen av växthusgaser per spenderad
            krona inom olika inköpskategorier. Dataunderlaget består primärt av
            ekonomiska data, såsom konteringsbelopp, bokföringskonto och
            leverantör. Att använda ekonomiska data ger en god bild av
            utsläppen, utan risk för dubbelräkning.
          </Body1>
          <Body1>
            I vissa fall används även fysiska data som komplement för att
            beräkna utsläppen från transport, resor och användning av energi (t
            ex kWh använd energi uppdelat på olika energislag, samt uppgifter om
            färdsätt och färdsträcka).
          </Body1>
        </>
      ),
    },

    {
      question: `Vilka utsläpp ingår?`,
      answer: (
        <>
          <Body1 paragraph>
            Alla utsläpp kopplade till organisationens ekonomiska verksamhet och
            som ingår i det bokföringsunderlag som vi får tillgång till, ingår.
            Såväl direkta utsläpp som sker på plats (t ex från fordon och
            maskiner), som indirekta utsläpp som sker på andra platser. Svalna
            beräknar de konsumtionsbaserade utsläppen, oavsett var i världen de
            sker, och inkluderar samtliga utsläppsscope (scope 1, 2 och 3).
          </Body1>
          <Body1>
            Utsläppsfaktorerna som vi använder för att beräkna utsläppen baseras
            på Statistiska Centralbyråns miljöräkenskaper och har beräknats med
            hjälp av miljöanpassad multiregional input-output analys. Det är en
            metod som fångar alla utsläpp kopplat till användning av energi och
            andra fysiska insatsvaror i alla led: från råvaruproduktion, via
            tillverkning och transport, fram till att varan eller tjänsten
            levereras till slutkonsumenten. Utsläppsintensiteterna som vi
            använder för att beräkna utsläppen för företag och organisationer
            representerar total slutkonsumtion i Sverige (privat, ideell och
            offentlig).
          </Body1>
        </>
      ),
    },

    {
      question: 'Vilka transaktioner ingår när utsläppen beräknas?',
      answer: (
        <>
          <Body1>
            Inte alla ekonomiska transaktioner tillskrivs utsläpp.
            Grundprincipen är att utsläpp endast beräknas för transaktioner som
            avser inköp av varor och tjänster som organisationen konsumerar.
            Exempel på transaktioner som inte tillskrivs några utsläpp är
            löneutbetalningar, skatteinbetalningar, fakturor som har krediterats
            och inbetalningar till pensionssystem. Det samma gäller olika typer
            av överföringar mellan avdelningar eller verksamheter inom en
            organisation. Dessa transaktioner ses som intermediära pengaflöden
            som slutkonsumeras i senare led av privatpersoner och/eller aktörer
            inom offentlig verksamhet, och vars utsläpp skall tillskrivas
            slutkonsumenten.
          </Body1>
        </>
      ),
    },

    {
      question: `Hur säkra är resultaten?`,
      answer: (
        <>
          <Body1>
            Det är svårt att kvantifiera, men det finns flera källor till
            osäkerhet som är viktiga att känna till för en korrekt tolkning av
            resultaten. Vissa osäkerheter kan i framtiden minskas med hjälp av
            bättre data och mer detaljerade analyser, medan andra är betydligt
            svårare att göra något åt. En källa till osäkerhet är att de
            sektorer som utsläppsintensiteterna grundar sig på, är grova.
            Svårigheter att korrekt kategorisera alla inköp är en annan källa
            till osäkerheter, som hänger samman med kvalitén på bokföringsdatan.
          </Body1>
        </>
      ),
    },

    {
      question: `Hur förhåller sig Svalnas metod till GHG Protocol?`,
      answer: (
        <>
          <Body1>
            Svalnas beräkningsmetod kan ses som en sofistikerad tillämpning av
            GHG Protocol, som syftar till att på ett så heltäckande,
            kostnadseffektivt och tillförlitligt sätt som möjligt beräkna
            utsläppen inom samtliga utsläppsscope, men det finns viktiga
            skillnader. Till exempel kräver inte GHG Protocol att utsläppen
            kopplade till Scope 3 inkluderas i klimatberäkningar för
            verksamheter, medan Svalnas metod automatiskt inkluderar Scope 3.
            Svalnas metod är ännu inte helt kompatibel med GHG Protocol, men den
            långsiktiga målsättningen är att den ska bli det.
          </Body1>
        </>
      ),
    },

    {
      question: (
        <>
          <div>
            Under corona har en stor del av verksamheten övergått till digital
            form.
          </div>
          <div>
            Hur ser utsläppen ut från Zoom och liknande tjänster, och ingår de
            utsläppen?
          </div>
        </>
      ),
      answer: (
        <>
          <Body1 paragraph>
            Ja, utsläppen ingår, men de är utspridda i olika kategorier. För att
            använda digitala tjänster som t ex Zoom krävs hårdvara (datorer,
            kablar, telefoner, etc), mjukvara (datorprogram), el för att driva
            utrustningen, samt annan teknisk infrastruktur och stödtjänster
            (servrar, IT-konsulter, etc). Utsläppen kopplade till alla dessa
            delar inkluderas, men de hamnar i olika kategorier.
          </Body1>
          <Body1 paragraph>
            Hårdvara återfinns i kategorin varor, mjukvara och IT-konsulter
            återfinns i kategorin tjänster, el återfinns i kategorin energi,
            osv. Eftersom det är brukligt att betala fasta licenskostnader för
            exempelvis Zoom och andra liknande verktyg är det inte möjligt att
            följa den förmodade ökningen av utsläppen från deras servrar, då det
            inte återspeglas i kostnaderna.
          </Body1>
          <Body1>
            <a href='https://www.sciencedirect.com/science/article/abs/pii/S0921344920307072?via%3Dihub'>
              Här
            </a>{' '}
            <span>finns en artikel som beskriver utsläppsökningen.</span>
          </Body1>
        </>
      ),
    },

    {
      question: `Vad baseras kategoriseringen på?`,
      answer: (
        <>
          <Body1 paragraph>
            <span>
              Utsläppskategorierna som visas i Svalnas Carbon Intelligence
              System™ bygger på{' '}
            </span>
            <a href='https://www.scb.se/dokumentation/klassifikationer-och-standarder/standard-for-svensk-naringsgrensindelning-sni/'>
              SNI-systemet för svensk näringslivsindelning
            </a>
            <span>, och speglar hur utsläppen har beräknats.</span>
          </Body1>
          <Body1 paragraph>
            För att kategorisera köpen använder vi en algoritmbaserad lösning
            som automatiskt kategoriserar alla köp baserat på information om
            leverantörens SNI-kod(er) och vilka bokföringskonton köpen är
            bokförda på. I stort sett alla leverantörer (företag som ni köper
            varor och tjänster från) har nämligen en SNI-kod i SCB:s
            företagsregister (vi använder SNI 2007: SCB:s standard för svensk
            näringsgrensindelning från år 2007).
          </Body1>
          <Body1 paragraph>
            Därefter beräknas utsläppen med utsläppsintensiteterna för
            motsvarande SNI-kategorier. Det betyder att utsläppen för alla köp
            som kategoriserats som t ex “Dataprogrammering,
            datakonsultverksamhet o.d.”, har beräknats med samma
            utsläppsintensitet. Det betyder också att kategorierna som visas i
            Svalnas Carbon Intelligence System™ kan bestå av olika typer av köp
            som bokförts på olika bokföringskonton.
          </Body1>
          <Body1>
            SNI-systemet som används för att visualisera utsläppen består av 99
            underkategorier. För att underlätta visualiseringen har vi slagit
            ihop dessa i sju övergripande kategorier (Energi, Fastigheter,
            Varor, Tjänster, Transport &amp; resor, Mat &amp; logi, samt
            Övrigt), baserat på analys av vad som ingår i kategorierna i
            SNI-systemet. Denna hopslagning är ofrånkomligen associerad med
            vissa osäkerheter, varför resultaten ska tolkas med viss
            försiktighet.
          </Body1>
        </>
      ),
    },

    {
      question: `Vad kan vi göra för att förbättra utsläppsberäkningarna?`,
      answer: (
        <>
          <Body1 paragraph>
            För att minska risken för att köp felkategoriseras och skapa goda
            förutsättningar att genomföra detaljerade utsläppsberäkningar är det
            fördelaktigt om en stor andel som möjligt inköpen bokförs på
            ändamålsenliga och specifika bokföringskonton. Det är till exempel
            fördelaktigt om olika typer av resor bokförs på specifika
            bokföringskonton såsom “Resor med tåg”, “Resor med taxi”, osv,
            istället för att alla resor bokförs på ett och samma
            bokföringskonto. Det samma gäller inköp av energi och andra varor
            och tjänster.
          </Body1>
          <Body1>
            Generella konton av typen “Övrigt” där många olika slags varor och
            tjänster bokförs bör i största möjligaste mån undvikas, då köp
            bokförda på den typen av bokföringskonton är svåra att matcha mot
            rätt utsläppsintensitet.
          </Body1>
        </>
      ),
    },
  ],
  [Subject.Usage]: [
    {
      question:
        'När vi använder vår elektroniska utrustning, syns det då i kategorin varor?',
      answer: (
        <>
          <Body1>
            Nej, utsläpp från användning av elektronisk utrustning hamnar i
            kategorin energi (utsläpp från el). Kategorin varor avser utsläpp
            från inköp av olika slags fysiska varor, såsom kemikalier, datorer,
            maskiner, möbler, etc. Utsläppen avser de utsläpp som genereras
            genom hela tillverkningsprocessen inklusive brytning och
            framställning av insatsvaror(såsom metaller, plast, etc) fram tills
            att varorna levereras.
          </Body1>
        </>
      ),
    },
    {
      question:
        'Får vi lägre utsläpp om vi köper dyrare men mer miljövänliga varor av hög kvalitet som håller länge?',
      answer: (
        <>
          <Body1>
            Nej, inte direkt. Metoden kan bara se av vilken leverantör ni
            handlat, på vilket bokföringskonto det registrerats och hur mycket
            pengar ni spenderat. Sedan beräknas utsläppen med genomsnittliga
            utsläppsfaktorer för varje inköpskategori. Det innebär att dyrare
            varor får högre utsläpp. Men indirekt bör det synas på längre sikt,
            om varorna är av högre kvalitet, eftersom ni då inte behöver köpa
            nytt lika ofta.
          </Body1>
        </>
      ),
    },
  ],
}
