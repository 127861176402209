import { useContext, useMemo } from 'react'
import { AppDataContext } from '../../../context/AppDataContext'
import { FilterContext } from '../../../context/FilterContext'
import { Aggregation, FilterInput, Period } from '../../../graphql/generated'
import { useEmissionData } from '../../../hooks/useEmissionData'
import { getTotalCO2e } from '../../../utils/chart'
import { roundToDecimals } from '../../../utils/math'
import { findChildrenFor, findTopLevelOrgUnits } from '../../../utils/org'

type EmissionPerOrgUnitData = {
  loading: boolean
  dataPoints: {
    x: string
    y: number
    percentage: number
    orgName: string
    label: string
    highlight: boolean
  }[]
}

export const useEmissionPerOrgUnitData = (
  filter: FilterInput,
  period: Period,
): EmissionPerOrgUnitData => {
  const { orgUnitMap, organisation } = useContext(AppDataContext)
  const { orgUnit } = useContext(FilterContext)

  const children = useMemo(() => {
    if (orgUnit) {
      return findChildrenFor(orgUnit.id, organisation)
    }
    return findTopLevelOrgUnits(organisation)
  }, [orgUnit, organisation])

  let orgUnitLevel = 1
  if (orgUnit) {
    if (children.length) {
      orgUnitLevel = orgUnit.level + 1
    } else {
      orgUnitLevel = orgUnit.level
    }
  }

  const updatedFilter = {
    ...filter,
    orgUnitId: children.length === 0 ? orgUnit?.parentId : orgUnit?.id,
  }

  const { data: emissions, loading } = useEmissionData({
    aggregations: [Aggregation.OrgUnit],
    period,
    orgUnitLevel: orgUnitLevel,
    filter: updatedFilter,
  })

  const parentData = useEmissionData({
    aggregations: [],
    period,
    orgUnitLevel: null,
    filter: updatedFilter,
  })

  const total = getTotalCO2e(parentData.data)

  const dataPoints = useMemo(() => {
    return emissions
      .filter((emission) => emission.aggregationKeys[0] !== 'NA')
      .map((emission) => {
        const orgUnitId = emission.aggregationKeys[0]
        const curOrgUnit = orgUnitMap[orgUnitId]
        const orgName =
          curOrgUnit?.name ?? `Enhet ${emission.aggregationKeys[0]}`
        return {
          x: orgName,
          y: emission.totalCO2e,
          percentage: roundToDecimals(100 * (emission.totalCO2e / total), 2),
          orgName,
          label: 'remove',
          highlight: orgUnit ? orgUnit.id === orgUnitId : false,
        }
      })
      .sort((a, b) => a.percentage - b.percentage)
      .reverse()
  }, [emissions, orgUnitMap, total, orgUnit])

  return { loading, dataPoints }
}
