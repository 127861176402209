import React from 'react'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Form } from '../../components/Form'
import { ButtonGreen } from '../../../../components/Buttons'
import { colors, spacing } from '../../../../theme'
import { useRegisterOrganisationMutation } from '../../../../graphql/generated'
import { toErrorMap } from '../../../../utils/toErrorMap'
import { RegistrationViews } from '../RegistrationPage'
import { H5, S2Bold } from '../../../../components/Typography'

const BookkeepingSystems = [
  'NotSet',
  'Fortnox',
  'Bokio',
  'Visma',
  'Annat',
] as const

type BookkeepingSystem = (typeof BookkeepingSystems)[number]

const bookkeepingSystemRegex = new RegExp(
  BookkeepingSystems.filter((value) => value !== 'NotSet').join('|'),
)

type FormValues = {
  orgNumber: string
  email: string
  bookkeepingSystem: BookkeepingSystem
  otherBookingSystem: string
  acceptPolicies: boolean
}

const initialValues: FormValues = {
  orgNumber: '',
  email: '',
  bookkeepingSystem: 'NotSet',
  otherBookingSystem: '',
  acceptPolicies: false,
}

export function RegistrationForm({
  setError,
  setView,
  setRegistrationCode,
  setConfirmationCode,
}: {
  setError: React.Dispatch<React.SetStateAction<string>>
  setView: React.Dispatch<React.SetStateAction<RegistrationViews>>
  setRegistrationCode: React.Dispatch<React.SetStateAction<string>>
  setConfirmationCode: React.Dispatch<React.SetStateAction<string>>
}): React.JSX.Element {
  const [registerOrganisation] = useRegisterOrganisationMutation()

  const registerSchema = Yup.object().shape({
    orgNumber: Yup.string()
      .matches(
        /^[0-9]{6}-[0-9]{4}$/,
        'Det är inte ett giltigt organisationsnummer',
      )
      .required('Nödvändig'),
    email: Yup.string().email('Ogiltig e-postadress').required('Nödvändig'),
    bookkeepingSystem: Yup.string()
      .matches(bookkeepingSystemRegex, 'felaktigt bokföringssystem')
      .required('Nödvändig'),
    otherBookingSystem: Yup.string().when('bookkeepingSystem', (val) => {
      if (val?.length > 0 && val[0] === 'Annat') {
        return Yup.string().max(255, 'max 255').required('Nödvändig')
      } else {
        return Yup.string().notRequired()
      }
    }),
    acceptPolicies: Yup.boolean()
      .isTrue('Du måste acceptera villkoren')
      .required('Du måste acceptera villkoren'),
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: registerSchema,
    onSubmit: async (v, { setErrors }) => {
      try {
        const result = await registerOrganisation({
          variables: {
            orgNumber: v.orgNumber,
            email: v.email,
            bookingSystem:
              v.bookkeepingSystem === 'Annat'
                ? v.otherBookingSystem
                : v.bookkeepingSystem,
            acceptPolicies: v.acceptPolicies,
          },
        })
        if (result.data) {
          if (result.data.registerOrganisation.errors) {
            setErrors(toErrorMap(result.data.registerOrganisation.errors))
            return
          }

          setRegistrationCode(result.data.registerOrganisation.id ?? '')
          setConfirmationCode(
            result.data.registerOrganisation.deletionConfirmationCode ?? '',
          )
          if (result.data.registerOrganisation.canRegister) {
            setView(RegistrationViews.canRegister)
          } else {
            setView(RegistrationViews.cannotRegister)
          }
        }
      } catch (error) {
        let msg = error
        if ((error as Error).message !== undefined) {
          msg = (error as Error).message
        }
        setError(`${msg}`)
      }
    },
  })
  return (
    <>
      <S2Bold>Skicka förfrågan om testkonto (BETA)</S2Bold>
      <H5>Fyll i info om ditt företag</H5>
      <Form
        onSubmit={formik.handleSubmit}
        style={{ width: '100%', gap: spacing.medium }}
      >
        <TextField
          type='text'
          name='orgNumber'
          label='Organisationsnummer'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.orgNumber}
          variant='standard'
          placeholder='xxxxxx-xxxx'
          InputLabelProps={{ shrink: true }}
          error={!!formik.touched.orgNumber && !!formik.errors.orgNumber}
          helperText={formik.touched.orgNumber && formik.errors.orgNumber}
          required
        />

        <TextField
          type='email'
          name='email'
          label='Företagsmailadress'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          variant='standard'
          placeholder='namn@domän.se'
          InputLabelProps={{ shrink: true }}
          error={!!formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
          required
        />

        <FormControl fullWidth variant='standard'>
          <InputLabel
            id='accounting-system-label'
            required
            error={
              !!formik.touched.bookkeepingSystem &&
              !!formik.errors.bookkeepingSystem
            }
          >
            Bokföringssystem
          </InputLabel>
          <Select
            name='bookkeepingSystem'
            labelId='accounting-system-label'
            id='accounting-system-select'
            label='Bokföringssystem'
            onChange={formik.handleChange}
            value={formik.values.bookkeepingSystem}
            displayEmpty
            renderValue={
              formik.values.bookkeepingSystem !== 'NotSet'
                ? undefined
                : () => 'Välj'
            }
            error={
              !!formik.touched.bookkeepingSystem &&
              !!formik.errors.bookkeepingSystem
            }
          >
            {BookkeepingSystems.map((system) => {
              if (system !== 'NotSet') {
                return (
                  <MenuItem value={system} key={system}>
                    {system}
                  </MenuItem>
                )
              }
              return undefined
            })}
          </Select>
          {formik.touched.bookkeepingSystem &&
            formik.errors.bookkeepingSystem && (
              <FormHelperText style={{ color: colors.red }}>
                {formik.errors.bookkeepingSystem}
              </FormHelperText>
            )}
        </FormControl>

        {formik.values.bookkeepingSystem === 'Annat' && (
          <TextField
            type='text'
            name='otherBookingSystem'
            label='Bokföringssystemets namn'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.otherBookingSystem}
            variant='standard'
            placeholder='Bokföringssystem'
            InputLabelProps={{ shrink: true }}
            error={
              !!formik.touched.otherBookingSystem &&
              !!formik.errors.otherBookingSystem
            }
            helperText={
              formik.touched.otherBookingSystem &&
              formik.errors.otherBookingSystem
            }
            required
            data-testid='otherBookingSystem'
          />
        )}

        <FormControl
          required
          error={
            formik.touched.acceptPolicies && !!formik.errors.acceptPolicies
          }
        >
          <FormControlLabel
            name='acceptPolicies'
            required
            onChange={formik.handleChange}
            control={<Checkbox />}
            label={
              <>
                <span>Jag godkänner Svalnas</span>{' '}
                <a href='/user-agreement' target='_blank'>
                  användarvillkor
                </a>{' '}
                <span>och</span>{' '}
                <a href='/privacy-policy' target='_blank'>
                  integritetspolicy
                </a>
              </>
            }
            data-testid='acceptPolicies'
          />
          {formik.touched.acceptPolicies && (
            <FormHelperText>{formik.errors.acceptPolicies}</FormHelperText>
          )}
        </FormControl>

        <ButtonGreen
          type='submit'
          disabled={formik.isSubmitting}
          data-testid='submit'
        >
          Skicka förfrågan
        </ButtonGreen>
      </Form>
    </>
  )
}
