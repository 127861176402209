import * as React from 'react'

type SvgLinePatternProps = {
  /**
   * The ID this pattern can be referenced by, used e.g. `fill: url(#${referenceID})`
   *
   * MUST be unique for the page if multiple patterns of this type are defined.
   * A definition might enforce this with a random component, e.g. `stripe-${Math.random()}`.
   * */
  referenceID: string
  /** Distance between lines. */
  spacing: number
  strokeWidth: number
  /** Colour of the line pattern's main stripes. */
  stroke?: React.CSSProperties['color']
  /** Background colour behind the stripes. */
  fill?: React.CSSProperties['color']
  opacity?: number
  /**
   * Angle in degrees. Zero is horisontal, ninety is vertical.
   * Anti-clockwise rotation for increasing angles.
   * */
  angle?: number
}

/**
 * Creates an <svg> component with a definition of a tileable stripe pattern.
 *
 * The pattern can be referenced by another element in some other <svg> by its `referenceID`.
 *
 * E.g.
 * ``
 *  <rect fill=`url(#${referenceID})` ... />
 * ``
 */
export function SvgLinePattern({
  referenceID,
  spacing,
  strokeWidth,
  stroke = 'black',
  fill = 'none',
  opacity = 1.0,
  angle = 0,
}: SvgLinePatternProps): React.JSX.Element {
  // The SVG element is set to zero size, as we only want to inject a definition for the stripe pattern,
  // and its inclusion should not affect the layout of any other elements.
  return (
    <svg width={0} height={0}>
      <defs>
        {/* 
          This defines a repeating pattern of stripes.
          When the pattern is used as the background of some SVG element with
          `fill: url(#${referenceID})`, it will be repeated over the whole surface,
          causing a tiling effect which makes it appear as a consistent background pattern.

          This works by defining as its base a rectangle which
          is partially filled from the bottom up with a sub-rectangle
          of the prop `stroke` colour, with a height controlled by prop `strokeWidth`.
          Behind it is another rect with the `fill` colour -- this
          rectangle serves as the background colour, and covers the whole pattern.

          The "patternTransform" can rotate the rectangle to a given angle,
          causing the whole pattern to appear as rotated.
        */}
        <pattern
          id={referenceID}
          patternUnits='userSpaceOnUse'
          width={spacing}
          height={spacing}
          // Angle negated for anti-clockwise rotation.
          patternTransform={`rotate(-${angle})`}
        >
          {/* Background (in SVG, the order of elements decide which shape covers the other). */}
          <rect
            x={0}
            y={0}
            width={spacing}
            height={spacing}
            stroke='none'
            fill={fill}
            opacity={opacity}
          />
          {/* Foreground line/stripe */}
          <rect
            x={0}
            y={0}
            width={spacing}
            height={strokeWidth}
            stroke='none'
            fill={stroke}
            opacity={opacity}
          />
        </pattern>
      </defs>
    </svg>
  )
}
