import { ApolloError } from '@apollo/client'
import React from 'react'
import styled from 'styled-components'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel } from 'victory'
import { defaultChartTheme } from '../../chartTheme'
import {
  CompareOrgKey,
  FilterInput,
  useCompareOrgQuery,
} from '../../graphql/generated'
import { colors, spacing, theme } from '../../theme'
import {
  getNumberFormatter,
  NumberFormatter,
  unitDescription,
} from '../../utils/adaptiveNumberFormat'
import { calculateDomain, getAverage, getTextWidth } from '../../utils/chart'
import { TooltipInfo } from '../InfoIcon'
import { Body2, H4 } from '../Typography'

const chartColors = ['#789EFF', '#406CDD', '#D4E0FF']

export type CompareChartDatum = {
  functionKey: string
  x: number
  y: number
  label: string
  color: string
}

export const useGetCompareChartData = (
  filter: FilterInput,
): {
  chartData: CompareChartDatum[]
  loading: boolean
  error?: ApolloError
} => {
  const { data, loading, error, previousData } = useCompareOrgQuery({
    variables: { filter },
  })

  const compareOrg = loading ? previousData?.compareOrg : data?.compareOrg
  return {
    chartData:
      compareOrg?.map((item, index) => ({
        functionKey: item.functionKey,
        x: index + 1,
        y: item.CO2e,
        label: item.label,
        color: chartColors[index],
        tooltip: item.tooltip,
      })) ?? [],
    loading,
    error,
  }
}

const Info = styled.div`
  display: flex;
  padding: ${spacing.small};
`

const Percentage = styled(H4).withConfig({
  shouldForwardProp: (prop) => !['isHigher'].includes(prop),
})<{ isHigher?: boolean }>`
  margin-right: ${spacing.medium};
  color: ${({ isHigher }) => (isHigher ? colors.red : colors.deepGreen)};
`

const PercentageContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing.small};
`

const compareOrgs = (selfOrgCO2e?: number, withOrgCO2e?: number) => {
  if (!selfOrgCO2e || !withOrgCO2e) return undefined
  return {
    isHigher: selfOrgCO2e > withOrgCO2e,
    percentage: Math.abs(100 - Math.round(100 * (selfOrgCO2e / withOrgCO2e))),
  }
}

type Props = {
  height: number
  width: number
  filter: FilterInput
} & ReturnType<typeof useGetCompareChartData>

export const CompareChart: React.FC<Props> = React.memo(
  ({ chartData, width, height, filter }) => {
    const hasChartData = chartData.length > 0
    const selfOrgCO2e = chartData.find(
      (x) => x.functionKey === CompareOrgKey.Self,
    )?.y
    const referenceOrgCO2e = chartData.find(
      (x) => x.functionKey === CompareOrgKey.Reference,
    )?.y
    const top20CO2e = chartData.find(
      (x) => x.functionKey === CompareOrgKey.Top20,
    )?.y
    const referenceOrgComparison = compareOrgs(selfOrgCO2e, referenceOrgCO2e)
    const topOrgsComparison = compareOrgs(selfOrgCO2e, top20CO2e)

    const refLabel = filter.orgUnitId
      ? 'medelvärdet för hela organisationen'
      : 'liknande organisationer'
    const top20Label = filter.orgUnitId
      ? 'liknande avdelnigar med lägst utsläpp'
      : 'organisationerna med lägst utsläpp'
    const barWidth = height / 5
    const formatter = getNumberFormatter(chartData.map((x) => x.y))
    return (
      <>
        {hasChartData && (
          <Info>
            <PercentageContainer>
              <Percentage isHigher={referenceOrgComparison?.isHigher}>
                {referenceOrgComparison?.percentage}%
              </Percentage>
              <Body2>
                <span>
                  {referenceOrgComparison?.isHigher ? 'högre' : 'lägre'}{' '}
                </span>
                <span>klimatpåverkan än {refLabel}.</span>
              </Body2>
            </PercentageContainer>
            <PercentageContainer>
              <Percentage isHigher={topOrgsComparison?.isHigher}>
                {topOrgsComparison?.percentage}%
              </Percentage>
              <Body2>
                <span>{topOrgsComparison?.isHigher ? 'högre' : 'lägre'} </span>
                <span>klimatpåverkan än {top20Label}.</span>
              </Body2>
            </PercentageContainer>
          </Info>
        )}

        <VictoryChart
          horizontal
          theme={defaultChartTheme}
          padding={{
            left: 0,
            top: 0,
            right: 10,
            bottom: 0,
          }}
          style={{
            parent: {},
          }}
          domain={calculateDomain(chartData, {
            yMin: 1,
            offsetY: getAverage(chartData),
          })}
          width={width}
          height={height}
        >
          <VictoryBar
            data={chartData}
            cornerRadius={(0.8 * barWidth) / 2}
            style={{
              data: {
                fill: ({ datum }: any) => datum.color,
                //height: 35,
              },
            }}
            barWidth={barWidth}
            //@ts-ignore VictoryLabels will receive more props.
            labelComponent={<Label formatter={formatter} filter={filter} />}
          />

          <VictoryAxis
            crossAxis={false}
            dependentAxis
            theme={defaultChartTheme}
            style={{
              axis: { stroke: 'transparent' },
              grid: {
                //stroke: 'rgba(0,0,0,0.2)',
                //strokeDasharray: '4, 8',
              },
            }}
            tickFormat={() => ''}
          />
        </VictoryChart>
      </>
    )
  },
)

type LabelProps = {
  formatter: NumberFormatter
  filter: FilterInput
} & {
  datum: {
    x: number | string
    y: number | string
    label: string
    tooltip: string
  }
  x: number
  y: number
}
function Label(props: LabelProps) {
  const { formatter, filter, datum, x, y } = props
  const label = `${datum.label}`

  const emissionText = `${formatter.format(+datum.y)} ${unitDescription(
    filter,
    { hideUnit: true },
  )}`
  const margin = 8

  return (
    <g>
      <VictoryLabel
        {...props}
        text={emissionText}
        dy={-margin}
        style={{
          fontFamily: theme.typography.fontFamily,
          fontSize: 12,
          fontWeight: 'bold',
        }}
      />
      <VictoryLabel
        {...props}
        text={label}
        dy={margin}
        style={{
          fontFamily: theme.typography.fontFamily,
          fontSize: 12,
        }}
      />
      {datum.tooltip && (
        <foreignObject
          x={x + getTextWidth(label) + 35}
          y={y - 5}
          width={25}
          height={25}
        >
          <div style={{ transform: 'scale(0.75)' }}>
            <TooltipInfo info={datum.tooltip} />
          </div>
        </foreignObject>
      )}
    </g>
  )
}
