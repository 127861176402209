import React, { useCallback, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { PasswordLogin } from './PasswordLogin'
import { ButtonGreen } from '../../components/Buttons'
import { H5, S2Bold } from '../../components/Typography'

enum LoginType {
  PASSWORD,
  FORTNOX,
  SWAMID,
  UNSELECTED,
}

interface Props {
  setError: React.Dispatch<React.SetStateAction<string>>
}

export function LoginSelector({ setError }: Props): React.JSX.Element {
  const history = useHistory()
  const [loginType, setLoginType] = useState(LoginType.UNSELECTED)

  const handleButtonClick = useCallback(() => {
    setError('')
    switch (loginType) {
      case LoginType.SWAMID:
        if (!process.env.REACT_APP_SWAMID_HOST) {
          throw new Error('SWAMID is not properly configured')
        }
        window.location.replace(`https://${process.env.REACT_APP_SWAMID_HOST}`)
        break
      case LoginType.FORTNOX:
        history.push('/fortnox')
        break
      case LoginType.UNSELECTED:
        break
      // the login with password has its own button
      default:
        setError(`Unsuported loggin type.`)
    }
  }, [history, loginType, setError])

  return (
    <>
      <S2Bold>Logga in</S2Bold>
      <H5>Hur vill du logga in?</H5>
      <FormControl fullWidth variant='standard' data-testid='login-selector'>
        <InputLabel id='login-type-label'>Inloggningstyp</InputLabel>
        <Select
          labelId='login-type-label'
          id='login-type-select'
          label='Inloggningstyp'
          onChange={(event) => {
            setLoginType(event.target.value as LoginType)
          }}
        >
          <MenuItem value={LoginType.PASSWORD}>
            Logga in med användarnamn och lösenord
          </MenuItem>
          <MenuItem value={LoginType.FORTNOX}>Logga in med Fortnox</MenuItem>
          <MenuItem value={LoginType.SWAMID}>Logga in med SWAMID</MenuItem>
        </Select>
      </FormControl>
      {loginType === LoginType.PASSWORD ? (
        <PasswordLogin setError={setError} />
      ) : (
        <ButtonGreen
          style={{ width: '100%' }}
          onClick={handleButtonClick}
          disabled={loginType === LoginType.UNSELECTED}
          data-testid='login-button'
        >
          Logga In
        </ButtonGreen>
      )}
    </>
  )
}
