import { useContext } from 'react'
import dayjs from 'dayjs'
import { FilterContext } from '../context/FilterContext'
import { Category, FilterInput } from '../graphql/generated'
import { IFilterDatePeriod } from '../utils/datePeriod'

export const getYear = (datePeriod: IFilterDatePeriod): number | undefined => {
  return datePeriod?.end ? dayjs.utc(datePeriod?.end).year() : undefined
}

export const useFilterInput = (
  category?: Category,
  selectedDatePeriod?: IFilterDatePeriod,
  useAnnualWorkForce?: boolean,
): FilterInput => {
  const { orgUnit, defaultDatePeriod } = useContext(FilterContext)

  const datePeriod = selectedDatePeriod ?? defaultDatePeriod

  const year = getYear(datePeriod)?.toString()

  return {
    year,
    orgUnitId: orgUnit?.id,
    partialYear: datePeriod.partialYear,
    categoryId: category?.id,
    useAnnualWorkForce: !!useAnnualWorkForce,
  }
}
