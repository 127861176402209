import React, { useContext } from 'react'
import { Button } from '@mui/material'
import { AppDataContext } from '../../context/AppDataContext'
import { FilterContext } from '../../context/FilterContext'
import { WrapperFlex } from '../Wrappers'
import { FilterOrg } from './filter-org/FilterOrg'
import { FilterSearch } from './search/FilterSearch'
import { Flex } from '../Flex'
import { spacing } from '../../theme'
import { StepId, emissionTourClassNames } from '../tour/tourConstants'

export function Filter(): React.JSX.Element {
  const { organisation } = useContext(AppDataContext)
  const { setOrgUnit, orgUnit } = useContext(FilterContext)

  const resetFilter = () => {
    setOrgUnit(undefined)
  }

  const canReset = orgUnit !== undefined

  return (
    <WrapperFlex style={{ gap: spacing.medium }}>
      <WrapperFlex
        style={{
          gap: 'inherit',
          // Prevent button texts from introducing newlines
          // when the window is resized to a narrow width.
          minWidth: '500px',
        }}
      >
        {organisation.orgType !== 'fortnox' && (
          <Flex
            className={emissionTourClassNames[StepId.e4PerOrg]}
            style={{
              gap: 'inherit',
              alignItems: 'inherit',
            }}
          >
            <FilterOrg
              selectedOrgUnit={orgUnit}
              setOrgUnit={setOrgUnit}
              organisation={organisation}
            />

            <FilterSearch organisation={organisation} setOrgUnit={setOrgUnit} />

            {canReset && (
              <Button onClick={resetFilter} color='secondary'>
                återställ filter
              </Button>
            )}
          </Flex>
        )}
      </WrapperFlex>
    </WrapperFlex>
  )
}
