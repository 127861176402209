import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import './utils/date' // so that dayjs get initialized
import { AuthorizedRoutes } from './AppRoutes'
import { AppDataProvider } from './context/AppDataContext'
import { FilterProvider } from './context/FilterContext'
import { OnboardContextProvider } from './context/OnboardingSnackContext'
import { RecatProvider } from './context/RecatContext'
import { FortnoxProvider } from './context/FortnoxContext'
import { JobProvider } from './context/JobContext'
import { GoalContextProvider } from './context/GoalContext'

export function AuthorizedApp(): React.JSX.Element {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='sv'>
      <AppDataProvider>
        <FilterProvider>
          {/* Goal context uses the filter context internally (org unit). */}
          <GoalContextProvider>
            <JobProvider>
              <FortnoxProvider>
                <RecatProvider>
                  <OnboardContextProvider>
                    <AuthorizedRoutes />
                  </OnboardContextProvider>
                </RecatProvider>
              </FortnoxProvider>
            </JobProvider>
          </GoalContextProvider>
        </FilterProvider>
      </AppDataProvider>
    </LocalizationProvider>
  )
}
