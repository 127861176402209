import React from 'react'
import { Button } from '@mui/material'
import { appHistory } from '../../../utils/history'

type Props = {
  route: string
  children: React.ReactNode
}

// export const AuthNavButton: Svalna.FCRC<Props> = ({ route, children }) => (
export function AuthNavButton({ route, children }: Props): React.JSX.Element {
  return (
    <Button
      onClick={() => appHistory.replace(route)}
      color='secondary'
      size='small'
    >
      <i>{children}</i>
    </Button>
  )
}
