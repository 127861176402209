import React from 'react'
import styled from 'styled-components'
import { Button } from '@mui/material'
import { Flex } from './Flex'
import { Caption, CaptionBold, Overline } from './Typography'
import { colors } from '../theme'

const ListButton = styled(Button)`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  cursor: pointer;
  align-items: stretch;
  width: 100%;
  text-align: left;
  text-transform: none;

  &:hover {
    background-color: ${colors.lightGray};
  }
`

const Label = styled(Overline)`
  color: ${colors.gray};
`

interface ListProps<T> {
  items: T[]
  rowText: (item: T) => string
  rowKey: (item: T) => string
  onClick: (item: T) => void
  isSelected?: (item: T) => boolean
  label?: (item: T) => string | undefined
}

export function List<T extends unknown>({
  items,
  onClick,
  rowKey,
  isSelected,
  rowText,
  label,
}: ListProps<T>): React.JSX.Element {
  return (
    <>
      {items.map((item) => {
        const selected = isSelected !== undefined ? isSelected(item) : false
        const Text = selected ? CaptionBold : Caption
        return (
          <Flex row itemsStretch key={rowKey(item)}>
            <ListButton onClick={() => onClick(item)} color='secondary'>
              <Flex spaceBetween>
                <Text>{rowText(item)}</Text>
              </Flex>
              {label !== undefined && <Label>{label(item)}</Label>}
            </ListButton>
          </Flex>
        )
      })}
    </>
  )
}
