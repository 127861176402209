import React, { useContext } from 'react'
import styled from 'styled-components'
import { getNumberFormatter } from '../../utils/adaptiveNumberFormat'
import { Flex } from '../Flex'
import { Body1Bold, Caption, textCO2e } from '../Typography'
import { GoalGraphChart } from './GoalGraphChart'
import { GoalContext } from '../../context/GoalContext'
import { SmallCardWrapper } from './budget/SmallBudgetCard'
import { GoalProgressCard } from './budget/GoalProgressCard'
import { TotalBudgetCard } from './budget/TotalBudgetCard'
import { UsedBudgetCard } from './budget/UsedBudgetCard'
import { Loader } from '../Loader'
import { spacing } from '../../theme'

const SeparatorLine = styled.div`
  height: ${spacing.xxxl};
`

export function GoalGraph({ width }: { width: number }): React.JSX.Element {
  const { data, loading } = useContext(GoalContext)

  const formatter = getNumberFormatter(
    (data?.interpolatedEmissions ?? []).map((e) => e.y),
  )

  return (
    <Flex column>
      <Body1Bold>Målutveckling</Body1Bold>
      <Caption>
        {loading ? (
          <Loader borderSize={4} size={15} />
        ) : (
          `${formatter.unit} ${textCO2e}`
        )}
      </Caption>
      <Flex
        stretchWidth
        style={{
          borderBottom: '1px solid lightgray',
          marginTop: spacing.medium,
          marginBottom: spacing.medium,
        }}
      />
      <Flex column>
        <GoalGraphChart width={width} />
        <SeparatorLine />
        <SmallCardWrapper
          stretchWidth
          style={{
            gap: spacing.large,
            justifyContent: 'space-between',
          }}
        >
          <GoalProgressCard inlined />
          <TotalBudgetCard inlined />
          <UsedBudgetCard inlined />
        </SmallCardWrapper>
      </Flex>
    </Flex>
  )
}
