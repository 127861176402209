import React, { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { AppDataContext } from './context/AppDataContext'
import { ChangePasswordWithToken } from './routes/authentication/ChangePasswordWithToken'
import { ForgotPassword } from './routes/authentication/ForgotPassword'
import { Fortnox } from './routes/authentication/Fortnox'
import { LoginPage } from './routes/authentication/LoginPage'
import { EmissionPage } from './routes/emission/EmissionPage'
import { HelpPage } from './routes/help/HelpPage'
import { Settings } from './routes/settings/Settings'
import { Sysadmin } from './routes/settings/Sysadmin'
import { RecategorisationPage } from './routes/recategorisation/RecategorisationPage'
import { RegistrationPage } from './routes/authentication/registration/RegistrationPage'
import { PublicLanding } from './routes/landing/PublicLanding'
import { UserAgreementPage } from './routes/authentication/registration/UserAgreementPage'
import { PrivacyPolicy } from './routes/authentication/registration/PrivacyPolicy'
import { ProfileCreationPage } from './routes/landing/ProfileCreationPage'
import { DataProcessingAgreement } from './routes/authentication/registration/DataProcessingAgreement'
import { Role, useMeQuery } from './graphql/generated'
import { GoalPage } from './routes/goal/GoalPage'

export function AuthorizedRoutes(): React.JSX.Element {
  const { organisation } = useContext(AppDataContext)
  const { data: meData } = useMeQuery()

  return (
    <Switch>
      <Route exact path='/profile' component={ProfileCreationPage} />
      <Route exact path='/emission' component={EmissionPage} />
      <Route exact path='/goal' component={GoalPage} />
      <Route exact path='/help' component={HelpPage} />
      <Route exact path='/settings' component={Settings} />
      <Route exact path='/sysadmin' component={Sysadmin} />
      <Route exact path='/recat' component={RecategorisationPage} />
      <Route exact path='/user-agreement' component={UserAgreementPage} />
      <Route exact path='/privacy-policy' component={PrivacyPolicy} />
      <Route
        exact
        path='/data-processing-agreement'
        component={DataProcessingAgreement}
      />
      {!organisation.profileCreated &&
      [Role.Admin, Role.SysAdmin].includes(meData?.me.role ?? Role.User) ? (
        <Redirect to='/profile' />
      ) : (
        <Redirect to='/emission' />
      )}
    </Switch>
  )
}

export function UnauthorizedRoutes(): React.JSX.Element {
  return (
    <Switch>
      <Route exact path='/landing' component={PublicLanding} />
      <Route exact path='/registration' component={RegistrationPage} />
      <Route exact path='/login' component={LoginPage} />
      <Route exact path='/forgot-password' component={ForgotPassword} />
      <Route exact path='/fortnox' component={Fortnox} />
      <Route exact path='/user-agreement' component={UserAgreementPage} />
      <Route exact path='/privacy-policy' component={PrivacyPolicy} />
      <Route
        exact
        path='/data-processing-agreement'
        component={DataProcessingAgreement}
      />
      <Route
        exact
        path='/change-password/:token'
        component={ChangePasswordWithToken}
      />
      <Redirect to='/landing' />
    </Switch>
  )
}
