import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'
import { Flex } from '../../../components/Flex'
import { spacing } from '../../../theme'

interface Props {
  registrationEmail: string
  setRegistrationEmail: (url: string) => void
}
export function LinkSentPopup({
  registrationEmail,
  setRegistrationEmail,
}: Props): React.JSX.Element {
  return (
    <Dialog
      open={!!registrationEmail}
      onClose={() => {
        setRegistrationEmail('')
      }}
    >
      <DialogTitle data-testid='link-sent-popup' align='center'>
        Länken för registrering har skickats
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => setRegistrationEmail('')}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Flex column style={{ gap: spacing.small }}>
          <DialogContentText>
            En länk har skickats till följande mejladress. Genom att klicka på
            länken kommer de kunna skapa ett konto.
          </DialogContentText>
          <TextField
            value={registrationEmail}
            type='text'
            style={{ width: '100%' }}
          />
        </Flex>
      </DialogContent>
    </Dialog>
  )
}
