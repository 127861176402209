import React from 'react'
import { MenuItem, Select } from '@mui/material'
import {
  NumberSearchType,
  StringSearchType,
} from '../../../../graphql/generated'

interface Props {}

interface StrinProps extends Props {
  searchType: StringSearchType
  setSearchType: (type: StringSearchType) => void
}

interface NumberProps extends Props {
  searchType: NumberSearchType
  setSearchType: (type: NumberSearchType) => void
}

export function stringTypeToName(type: StringSearchType): string {
  switch (type) {
    case StringSearchType.Equal:
      return 'lika med'
    case StringSearchType.Contain:
      return 'innehåller'
    case StringSearchType.StartWith:
      return 'börjar med'
    default:
      return type.toString()
  }
}

export function StringSearchTypeDropdown({
  searchType,
  setSearchType,
}: StrinProps): React.JSX.Element {
  const searchTypes = Object.entries(StringSearchType)

  return (
    <Select
      value={searchType}
      onChange={(event) => {
        setSearchType(event.target.value as StringSearchType)
      }}
      style={{ minWidth: '190px' }}
    >
      {searchTypes
        .sort((t1, t2) =>
          stringTypeToName(t1[1]).localeCompare(stringTypeToName(t2[1])),
        )
        .map((type) => (
          <MenuItem
            selected={type[1] === searchType}
            value={type[1]}
            key={type[1]}
          >
            {stringTypeToName(type[1])}
          </MenuItem>
        ))}
    </Select>
  )
}

export function numberTypeToName(type: NumberSearchType): string {
  switch (type) {
    case NumberSearchType.Equal:
      return 'lika med'
    case NumberSearchType.Greater:
      return 'större än'
    case NumberSearchType.GreaterOrEqual:
      return 'större eller lika med'
    case NumberSearchType.Lower:
      return 'lägre än'
    case NumberSearchType.LowerOrEqual:
      return 'lägre eller lika med'
    case NumberSearchType.Between:
      return 'mellan'
    default:
      throw new Error('unknown search type')
  }
}

export function NumberSearchTypeDropdown({
  searchType,
  setSearchType,
}: NumberProps): React.JSX.Element {
  const searchTypes = Object.entries(NumberSearchType)

  return (
    <Select
      value={searchType}
      onChange={(event) => {
        setSearchType(event.target.value as NumberSearchType)
      }}
      style={{ minWidth: '190px' }}
    >
      {searchTypes
        .sort((t1, t2) =>
          numberTypeToName(t1[1]).localeCompare(numberTypeToName(t2[1])),
        )
        .map((type) => (
          <MenuItem
            selected={type[1] === searchType}
            value={type[1]}
            key={type[1]}
          >
            {numberTypeToName(type[1])}
          </MenuItem>
        ))}
    </Select>
  )
}
