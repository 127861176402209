import dayjs from 'dayjs'
import { client } from '../apollo/apolloClient'
import {
  ResfreshTokenDocument,
  ResfreshTokenMutation,
  ResfreshTokenMutationVariables,
} from '../graphql/generated'

export const getToken = ():
  | { accessToken: string; refreshToken: string; expiresAt: number }
  | undefined => {
  const auth = localStorage.getItem('token')
  if (!auth) {
    return undefined
  }
  return JSON.parse(auth) as {
    accessToken: string
    refreshToken: string
    expiresAt: number
  }
}

export const setToken = (
  accessToken: string,
  refreshToken: string,
  expiresAt: number,
): void => {
  localStorage.setItem(
    'token',
    JSON.stringify({ accessToken, expiresAt, refreshToken }),
  )
}

export const clearToken = (): void => {
  localStorage.removeItem('token')
}

export const updateToken = async (): Promise<{
  accessToken: string
  refreshToken: string
}> => {
  const refreshToken = getToken()?.refreshToken
  if (!refreshToken) {
    throw new Error('Not authorized')
  }

  const { data } = await client.mutate<
    ResfreshTokenMutation,
    ResfreshTokenMutationVariables
  >({
    mutation: ResfreshTokenDocument,
    variables: { refreshToken: getToken()?.refreshToken ?? '' },
  })

  const tokenData = data?.token
  if (!tokenData) {
    throw new Error('Not authorized')
  }

  setToken(tokenData.token, tokenData.refreshToken, tokenData.expiresAt)
  return { accessToken: tokenData.token, refreshToken: tokenData.refreshToken }
}

export const setLastLoggedOutTime = (): void => {
  localStorage.setItem(
    'lastLoggedOut',
    JSON.stringify(dayjs.utc().toISOString()),
  )
}

export const getLastLoggedOutTime = (): string | null => {
  const time = localStorage.getItem('lastLoggedOut')
  if (!time) {
    return null
  }
  return JSON.parse(time)
}
