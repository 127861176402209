import styled from 'styled-components'

/** To add a new color: https://codepen.io/sosuke/pen/Pjoqqp (this is supported in all current browsers) **/
export enum IconColor {
  DeepGreen = 'invert(45%) sepia(51%) saturate(4822%) hue-rotate(115deg) brightness(103%) contrast(102%)',
  Black = 'invert(0%) sepia(95%) saturate(21%) hue-rotate(2deg) brightness(92%) contrast(108%);',
  White = 'invert(97%) sepia(100%) saturate(11%) hue-rotate(135deg) brightness(103%) contrast(100%);',
  Red = 'invert(73%) sepia(63%) saturate(6719%) hue-rotate(331deg) brightness(106%) contrast(97%);',
}

export interface IconProps {
  color?: IconColor
  width?: number
  height?: number
}

export const Icon = styled.img<IconProps>`
  max-width: ${({ width }) => width ?? 24}px;
  max-height: ${({ height }) => height ?? 24}px;
  ${({ color }) => color && `filter: ${color}`};
`
