import React, { useContext } from 'react'
import { ButtonLightBorder } from '../Buttons'
import { Loader } from '../Loader'
import { JobContext } from '../../context/JobContext'
import { sideColumnMinWidthRem } from '../TableOfContent'

export function JobIndicator(): React.JSX.Element | null {
  const { jobRunning, currentState } = useContext(JobContext)
  if (jobRunning) {
    // using a button here to keep the same kind of design as the Fortnox button
    // even though we don't need the button capacities. We will need to see with a
    // designer if they have a better proposal
    return (
      <ButtonLightBorder
        style={{
          fontWeight: 'bold',
          // keep the button in the dimmention of the remaining of the header
          height: '33px',
          // avoid the button changing size while processing data
          minWidth: `${sideColumnMinWidthRem}rem`,
        }}
        disabled
        startIcon={<Loader size={15} borderSize={4} />}
      >
        {currentState}
      </ButtonLightBorder>
    )
  }
  return null
}
