import styled from 'styled-components'
import { Icon } from './Icon'
import { colors, spacing } from '../theme'

export const AlertBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${colors.red};
  color: white;
  display: flex;
  align-items: center;
  padding: 13px;
`

export const AlertWarning = styled(AlertBox)`
  background-color: ${colors.red};
`

export const AlertSuccess = styled(AlertBox)`
  background-color: ${colors.deepGreen};
  justify-content: center;
  z-index: 1;
`

export const AlertIcon = styled(Icon)`
  margin-right: ${spacing.small};
`
