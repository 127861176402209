import { useEffect, useState } from 'react'
import { OrgUnit } from '../../../graphql/generated'

interface SearchResults {
  orgUnits: OrgUnit[]
  hasResults: boolean
}

export function useSearchResults(
  search: string,
  orgUnits: OrgUnit[],
): SearchResults {
  const [searchResults, setSearchResults] = useState<SearchResults>({
    orgUnits: [],
    hasResults: false,
  })

  useEffect(() => {
    if (!search || search.length <= 0) {
      setSearchResults({
        orgUnits: [],
        hasResults: false,
      })
      return
    }

    const searchLowerCase = search.toLowerCase()

    const foundOrgUnits: OrgUnit[] = orgUnits.filter((unit) =>
      unit.name.toLowerCase().includes(searchLowerCase),
    )

    setSearchResults({
      orgUnits: foundOrgUnits,
      hasResults: foundOrgUnits.length > 0,
    })
  }, [search, orgUnits])

  return searchResults
}
