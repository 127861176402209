import React, { useContext } from 'react'
import { Autocomplete, FormControl, TextField } from '@mui/material'
import { FormikErrors } from 'formik'
import styled from 'styled-components'
import { Flex, InnerInnerBox } from '../../Flex'
import { RecatContext } from '../../../context/RecatContext'
import {
  Category,
  SearchField,
  StringSearchType,
} from '../../../graphql/generated'

import { AppDataContext } from '../../../context/AppDataContext'
import { stringTypeToName } from './search/SearchTypeDropdown'
import { formatDateFilter } from '../../../utils/date'
import { Body2, Body2Bold } from '../../Typography'
import { spacing } from '../../../theme'

const ScrollBox = styled(Flex)`
  column-gap: ${spacing.medium};
  flex-wrap: wrap;
  max-height: 200px;
  overflow: auto;
`

interface Props {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void
  handleBlur: {
    (e: React.FocusEvent<any, Element>): void
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void
  }
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) =>
    | Promise<
        FormikErrors<{
          category: Category | undefined
          comment: string
          apply: boolean
        }>
      >
    | Promise<void>
  formValues: {
    category: Category | undefined
    comment: string
  }
  handleChange: {
    (e: React.ChangeEvent<any>): void
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1,
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void
  }
}

export function ChangeCatBox({
  handleSubmit,
  handleBlur,
  setFieldValue,
  formValues: fromValues,
  handleChange,
}: Props): React.JSX.Element {
  const { categories } = useContext(AppDataContext)

  const { ruleSuppliers, ruleAccounts, getFieldCriterion } =
    useContext(RecatContext)

  const commentCriterion = getFieldCriterion(SearchField.Comment)
  const dateCriterion = getFieldCriterion(SearchField.Date)
  const accountCriterion = getFieldCriterion(SearchField.AccountId)

  const isParentAccountSearch = () => {
    if (!accountCriterion) {
      return false
    }
    return (
      /^[0-9]+$/.test(
        // in the ui we only let the user set one search string so it must be in position 0
        accountCriterion.searchString ? accountCriterion.searchString[0] : '',
      ) && accountCriterion.stringSearchType === StringSearchType.StartWith
    )
  }

  // sort by parent id and then alphabetically so that the categories are grouped by parent
  const categorySort = (a: Category, b: Category) => {
    const parentComp = (a.parentId ?? 0) - (b.parentId ?? 0)
    if (parentComp !== 0) {
      return parentComp
    }
    return a.name.localeCompare(b.name)
  }

  const getParentGroupName = (cat: Category) => {
    const parent = categories.find((value) => value.id === cat.parentId)
    if (parent) {
      return parent.name
    }
    return 'Toppkategorier'
  }

  return (
    <form onSubmit={handleSubmit} id='recatForm'>
      <InnerInnerBox>
        <Body2>
          Detta kommer att omkategorisera alla befintliga och framtida
          transaktioner som matchar följande kriterier. Uppdatera sökningen för
          att ändra kriterierna. När du lägger till regeln kommer den inte att
          tillämpas direkt, utan sparas i en lista av Regler att tillämpa längre
          ner.
        </Body2>
        {/* using a table here allow for the filter values to be aligned. But it also make for some strange
         * padding at the start of the line. So, putting the pading to 10px make it aligned with the other
         * elements that have a 10px padding
         */}
        <table
          style={{
            minWidth: '420px',
            borderSpacing: '1rem 0.5rem',
            borderLeft: '1px solid #b0b0b0',
          }}
        >
          <colgroup>
            <col width='130px' />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <td>
                <Body2Bold>Leverantörs-ID</Body2Bold>
              </td>
              {ruleSuppliers.length >= 1 ? (
                <td>
                  <ScrollBox>
                    {ruleSuppliers.map((supplier) => (
                      <div
                        key={supplier.id ?? supplier.name ?? 'NA'}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        <Body2>
                          {supplier.id ?? <span>okänd</span>}
                          <span>&nbsp;(</span>
                          {supplier.name}
                          <span>)</span>
                        </Body2>
                      </div>
                    ))}
                  </ScrollBox>
                </td>
              ) : (
                <td data-testid='any-supplier'>
                  <Body2>alla</Body2>
                </td>
              )}
            </tr>
            <tr>
              <td>
                <Body2Bold>Konto-ID</Body2Bold>
              </td>

              {ruleAccounts.length >= 1 ? (
                <td>
                  <ScrollBox>
                    {ruleAccounts.map((accountInfo) => (
                      <div
                        key={accountInfo.id}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        <Body2>
                          {accountInfo.id}
                          <span>&nbsp;(</span>
                          {accountInfo.name}
                          <span>)</span>
                        </Body2>
                      </div>
                    ))}
                  </ScrollBox>
                </td>
              ) : (
                <td>
                  {isParentAccountSearch() ? (
                    <div>
                      <Body2>{`börja med ${accountCriterion?.searchString}`}</Body2>
                    </div>
                  ) : (
                    <div data-testid='any-account'>
                      <Body2>alla</Body2>
                    </div>
                  )}
                </td>
              )}
            </tr>
            <tr>
              <td>
                <Body2Bold>Beskrivning</Body2Bold>
              </td>
              {commentCriterion ? (
                <td>
                  <Body2>{`${stringTypeToName(
                    commentCriterion.stringSearchType ?? StringSearchType.Equal,
                  )} ${commentCriterion.searchString}`}</Body2>
                </td>
              ) : (
                <td data-testid='any-comment'>
                  <Body2>alla</Body2>
                </td>
              )}
            </tr>
            <tr>
              <td>
                <Body2Bold>Datum</Body2Bold>
              </td>
              <td>
                <Body2>{`${formatDateFilter(
                  dateCriterion?.startDate,
                  dateCriterion?.endDate,
                  dateCriterion?.numberSearchType,
                )}`}</Body2>
              </td>
            </tr>
          </tbody>
        </table>
        <Autocomplete
          id='category'
          disableClearable
          options={categories
            .filter((cat) => cat.sni && !['45', '46', '47'].includes(cat.sni))
            .sort((a, b) => categorySort(a, b))} // do not take the parent cat that don't have a sni and the categories that do not have emissions
          groupBy={(option) => getParentGroupName(option)}
          getOptionLabel={(option) => option.name}
          sx={{ width: '100%' }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='standard'
              label='Ny kategori/Industrisektor'
            />
          )}
          onChange={(event, newValue) => {
            setFieldValue('category', newValue)
          }}
          onBlur={handleBlur}
          value={fromValues.category}
        />
        <FormControl sx={{ width: '100%' }}>
          <TextField
            minRows={3}
            name='comment'
            id='comment'
            onChange={handleChange}
            onBlur={handleBlur}
            label='Lägg till en kommentar för att förklara ditt val av omkategorisering:'
            multiline
            data-testid='comment-setter'
          />
        </FormControl>
      </InnerInnerBox>
    </form>
  )
}
